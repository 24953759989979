import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { formatLocalDate } from '../../utils/format';
import { PolicyInstance } from '../../views/handbook/types';
import { PolicyMarkdown } from '@mosey/components/handbook/PolicyMarkdown';
import { useRef } from 'react';
import { CopyButton } from '@mosey/components/buttons/CopyButton';
import { PolicyContentType } from '@mosey/components/handbook/types';
import { PolicyFullDetails } from '@mosey/components/handbook/PolicyFullDetails';

export const CopyablePolicyPreview = ({
  policyInstance,
}: {
  policyInstance: PolicyInstance;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { content, title, created_at } = policyInstance;
  return (
    <div className="rounded-sm border p-6">
      <div className="flex flex-col items-start justify-between gap-3 xl:flex-row xl:items-center">
        <h3 className="text-xl font-medium">{title}</h3>
        <CopyButton label="policy" valueToCopy={ref} />
      </div>
      <p className="mt-1 whitespace-nowrap text-sm">
        Policy generated on {formatLocalDate(new Date(created_at))}
      </p>
      <div className="mt-3 space-y-3">
        {content.main.map((item, index) => (
          <PolicyContentRenderer content={item} key={index} />
        ))}
        {content.full_details && (
          <PolicyFullDetails fullDetails={content.full_details} />
        )}
      </div>
      {/* These are hidden, but will be what get copied to the clipboard
            Copied headings should start from H1 */}
      <div className="hidden" ref={ref}>
        <h1>{title}</h1>
        <p>Policy generated on {formatLocalDate(new Date(created_at))}</p>
        {content.main.map((item, index) => (
          <PolicyContentRenderer content={item} key={index} />
        ))}
        {content.full_details && (
          <>
            <h2>Full policy details</h2>
            <PolicyMarkdown
              content={{
                type: PolicyContentType.Markdown,
                content: content.full_details,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
