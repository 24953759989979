import { FunctionComponent, useEffect } from 'react';
import * as d3 from 'd3';
import { RequirementOverview } from '../../types';
import { isoDate } from '../../utils/format';

type MonthCalendarProps = {
  requirements: RequirementOverview[];
  startDate: Date;
};

interface Month {
  month: number;
  year: number;
  days: MonthDay[];
}

interface MonthDay {
  date: Date;
  col: number;
  row: number;
  dueItems: RequirementOverview[];
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
  v0?: [MonthDay['x0'], MonthDay['y0']];
}

export const MonthCalendar: FunctionComponent<MonthCalendarProps> = ({
  startDate,
  requirements,
}) => {
  useEffect(() => {
    const calendar = d3.select('#monthcal');
    calendar.html('');

    const day = 86400000;
    const now = new Date();

    const month = startDate.getUTCMonth();
    const year = startDate.getUTCFullYear();

    const nextMonth = month === 11 ? 0 : month + 1;
    const nextMonthYear = month === 11 ? year + 1 : year;

    const followingMonth = nextMonth === 11 ? 0 : nextMonth + 1;
    const followingMonthYear =
      nextMonth === 11 ? nextMonthYear + 1 : nextMonthYear;
    const data: Month[] = [];

    function makeMonth(_month: number, _year: number) {
      const monthDays: MonthDay[] = [];
      let loopMonth = _month;
      const loopDay = 0;
      let loopDate = new Date(_year, loopMonth, loopDay);
      const loopStartDay = loopDate.getDay();
      while (loopMonth === _month) {
        const loopDateUTC = isoDate(loopDate);

        const dueItems = requirements.filter((i) => {
          return i.due_date === loopDateUTC;
        });

        monthDays.push({
          date: loopDate,
          col: loopDate.getDay(),
          row: Math.floor((loopDate.getDate() + loopStartDay) / 7),
          dueItems,
        });

        loopDate = new Date(loopDate.getTime() + day);
        loopMonth = loopDate.getMonth();
      }

      if (monthDays[0].date.getDate() > 1) {
        monthDays.shift();
      }
      if (monthDays[0].row > 0) {
        monthDays.forEach((d) => {
          const _d = d;
          _d.row -= 1;
          return _d;
        });
      }

      data.push({ month: _month, year: _year, days: monthDays });
    }

    makeMonth(month, year);
    makeMonth(nextMonth, nextMonthYear);
    makeMonth(followingMonth, followingMonthYear);

    const months = calendar
      .selectAll('.month')
      .data(data)
      .enter()
      .append('div')
      .attr('class', (d) => `pb-8 month month-${d.month}`);

    function getMonthName(n: number) {
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ][n];
    }

    months
      .append('div')
      .attr('class', 'month-name text-center font-bold pb-2')
      .text((d) => `${getMonthName(d.month)} ${d.year}`);

    const svg = months.append('svg');

    const g = svg.append('g').style('fill', 'none');

    const columns = d3.scaleBand<number>().domain(d3.range(0, 7));

    const rows = d3.scaleBand<number>().domain(d3.range(0, 6));

    const days = g
      .selectAll('.day')
      .data((d) => d.days)
      .enter()
      .append('g')
      .style('class', 'day')
      .classed('past', (d) => d.date.getTime() < now.getTime() - day)
      .classed(
        'today',
        (d) =>
          d.date.getDate() === now.getDate() &&
          d.date.getMonth() === now.getMonth() &&
          d.date.getFullYear() === now.getFullYear(),
      );

    const dayRects = days.append('rect').attr('class', (d) => {
      // Event
      if (d.dueItems.length > 0) {
        return 'fill-current text-rose-300';
      }
      // Past
      if (d.date.getTime() < now.getTime() - day) {
        return 'fill-current text-gray-100';
      }
      // Present
      if (
        d.date.getDate() === now.getDate() &&
        d.date.getMonth() === now.getMonth() &&
        d.date.getFullYear() === now.getFullYear()
      ) {
        return 'fill-current text-rose-700';
      }
      // Future
      return '';
    });

    const dayNums = days
      .append('text')
      .attr('class', (d) => {
        // Event
        if (d.dueItems.length > 0) {
          return 'text-sm font-bold fill-current text-gray-700';
        }
        // Past
        if (d.date.getTime() < now.getTime() - day) {
          return 'text-sm font-bold fill-current text-gray-700';
        }
        // Present
        if (
          d.date.getDate() === now.getDate() &&
          d.date.getMonth() === now.getMonth() &&
          d.date.getFullYear() === now.getFullYear()
        ) {
          return 'text-sm font-bold fill-current text-white';
        }
        // Future
        return 'text-sm font-bold fill-current text-gray-700';
      })
      .text((d) => d.date.getDate())
      .attr('dy', 4.5);

    const dayOfWeek = g
      .selectAll('.day-of-week')
      .data(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])
      .enter()
      .append('text')
      .attr(
        'class',
        'fill-current text-gray-500 text-sm font-semibold day-of-week',
      )
      .attr('dy', -12)
      .text((d) => d);

    function redraw() {
      const margin = { left: 0, right: 0, top: 32, bottom: 0 };

      const node = d3.select('.month').node() as Element;
      const box = node.getBoundingClientRect();
      const baseWidth =
        window.innerWidth <= 640
          ? Math.min(window.innerWidth, box.width)
          : box.width;
      const width = baseWidth - margin.left - margin.right;
      const baseHeight = Math.max(baseWidth / 2, 250);
      const height = baseHeight - margin.top - margin.bottom; // TODO: Figure this out w/r/t aspect ratio

      svg
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom);

      g.attr('transform', `translate(${[margin.left, margin.top]})`);

      columns.range([0, width]);

      rows.range([0, height]);

      data.forEach((datum) => {
        datum.days.forEach((d) => {
          const _d = d;
          _d.x0 = columns(_d.col);
          _d.x1 = (_d.x0 || 0) + columns.bandwidth();
          _d.y0 = rows(_d.row);
          _d.y1 = (_d.y0 || 0) + rows.bandwidth();
          _d.v0 = [_d.x0, _d.y0];

          return _d;
        });

        return datum;
      });

      dayOfWeek.attr('x', (_d, i) => columns(i)! + columns.bandwidth() / 3);

      days.attr('transform', (d) => {
        return `translate(${d.v0})`;
      });

      dayRects
        .attr('width', columns.bandwidth())
        .attr('height', rows.bandwidth());

      dayNums
        .attr('x', columns.bandwidth() / 3)
        .attr('y', rows.bandwidth() / 2);
    }

    redraw();
  });

  return <div id="monthcal" />;
};

type CalendarMonthProps = {
  year: number;
  month: number;
};

export const CalendarMonth: FunctionComponent<CalendarMonthProps> = ({
  year,
  month,
}) => {
  const date = new Date(year, month, 1);
  const monthName = date.toLocaleString('default', { month: 'long' });

  return (
    <div className="flex items-center justify-center">
      <div className="w-full bg-white dark:bg-gray-800">
        <div className="flex items-center justify-between px-4 leading-8">
          {/* <div className="flex items-center text-gray-800 dark:text-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="15 6 9 12 15 18" />
                </svg>
                </div> */}
          <h1 className="text-lg font-medium text-gray-800 dark:text-gray-100">
            {monthName} {year}
          </h1>
          {/* <div className="flex items-center text-gray-800 dark:text-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler ml-3 icon-tabler-chevron-right" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="9 6 15 12 9 18" />
                </svg>
                </div> */}
        </div>
        <div className="flex items-center justify-between overflow-x-auto pt-12">
          <table className="w-full">
            <thead>
              <tr>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Sun
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Mon
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Tue
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Wed
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Thu
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Fri
                    </p>
                  </div>
                </th>
                <th>
                  <div className="flex w-full justify-center">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-100">
                      Sat
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4" />
                </td>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4" />
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4" />
                </td>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      1
                    </p>
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      2
                    </p>
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      3
                    </p>
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      4
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      5
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      6
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      7
                    </p>
                  </div>
                </td>
                <td>
                  <div className="size-full">
                    <div className="flex w-full cursor-pointer items-center justify-center rounded-full">
                      <p className="flex size-14 items-center justify-center rounded-full bg-rose-700 text-lg font-medium text-white">
                        8
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      9
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      10
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      11
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      12
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      13
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      14
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      15
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      16
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      17
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      18
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      19
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      20
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      21
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      22
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      23
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      24
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg text-gray-500 dark:text-gray-100">
                      25
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      26
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      27
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      28
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      29
                    </p>
                  </div>
                </td>
                <td>
                  <div className="flex w-full  cursor-pointer justify-center p-4">
                    <p className="text-lg font-medium text-gray-500 dark:text-gray-100">
                      30
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
