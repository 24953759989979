import { LoaderFunction, useLoaderData, Link } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { SectionHeading } from '../../components';
import { Invite, User } from '../../types';
import { STRIPE_BILLING_URL } from '../../settings/config';
import { apiBatch } from '../../utils/fetchApi';
import { Team } from './Team';
import { useUser } from '../../hooks/useUser';

export const loader: LoaderFunction = async () => {
  return apiBatch({
    users: {
      url: '/api/users',
      method: 'GET',
    },
    invites: {
      url: '/api/invites',
      method: 'GET',
    },
    billing: {
      url: '/api/subscription/billing',
      method: 'GET',
      onError: () => ({
        customer_portal_url: STRIPE_BILLING_URL,
      }),
    },
  });
};

export const Component = () => {
  const user = useUser();
  const {
    users,
    invites,
    billing: { customer_portal_url: customerPortalUrl },
  } = useLoaderData() as {
    users: User[];
    invites: Invite[];
    billing: { customer_portal_url: string };
  };

  const filteredInvites = invites.filter((invite) => !invite.is_accepted);
  const mergedUsersAndInvites = [...users, ...filteredInvites];

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <SectionHeading className="flex-1 pb-4" text="Account" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          {/* Billing for Mosey Pro connected users is handled by the Mosey Pro account holder */}
          {!user.is_pro_user && (
            <section>
              <h2 className="text-lg font-medium">Billing</h2>

              <div className="mt-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
                <h3 className="text-sm font-medium text-gray-700">
                  Your Billing Plan
                </h3>
                <div className="flex space-x-2">
                  <p className="mt-2 flex-1 text-sm text-gray-700">
                    Manage your subscription and billing information.
                  </p>

                  <Button
                    as={Link}
                    size="small"
                    to={customerPortalUrl}
                    target="_blank"
                  >
                    Manage Billing
                  </Button>
                </div>
              </div>
            </section>
          )}

          <hr className="mb-6 mt-9" />

          <section>
            <Team usersAndInvites={mergedUsersAndInvites} />
          </section>
        </div>
      </div>
    </div>
  );
};
