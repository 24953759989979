import { Button } from '@mosey/components/buttons/Button';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useEffect, useRef } from 'react';
import { useFetcher } from 'react-router-dom';
import { Pill } from '@mosey/components/badges/Pill';
import { UpdatedIcon } from '@mosey/components/Icons';
import { PolicyCategoryFullDetails } from '../components/PolicyCategoryFullDetails';
import { CategoryItem } from '../types';
import { PolicyGroupMainContent } from './PolicyGroupMainContent';
import { CustomPolicyBlock } from './CustomPolicyBlock';

type PolicyDetailSectionProps = {
  item: CategoryItem;
  onAdopted: () => void;
};

export const PolicyCategoryDetailSection = ({
  item,
  onAdopted,
}: PolicyDetailSectionProps) => {
  const { Form, state, data } = useFetcher();
  const hasBeenAdopted = useRef(false);

  const isUpdatable = item.status === 'updateable';
  const isAdoptable = item.status === 'adoptable';
  const isUpdated = item.status === 'updated';

  useEffect(() => {
    if (data?.success && !hasBeenAdopted.current) {
      hasBeenAdopted.current = true;
      onAdopted();
    }
  }, [onAdopted, data?.success]);

  return (
    <Form id={item.slug} className="mb-10" method="POST">
      {item.policies && (
        <input
          type="hidden"
          name="policy_ids"
          value={item.policies.map((policy) => policy.id).join(',')}
        />
      )}
      <div className="flex min-h-12 items-center gap-3 border-b-1 border-gray-200 pb-2">
        <div className="flex flex-1 flex-col">
          <h2 className="font-semibold">{item.title}</h2>
        </div>
        {isUpdatable || isAdoptable ? (
          <Button
            variant="secondary"
            type="submit"
            name="intent"
            value={'adopt-policy-category'}
            isLoading={state !== 'idle'}
            aria-label={`${isUpdatable ? 'Update' : 'Adopt'} ${item.title}`}
          >
            {isUpdatable ? 'Update' : 'Adopt'}
          </Button>
        ) : (
          <Pill
            Icon={isUpdated ? UpdatedIcon : CheckCircleIcon}
            variant="success"
          >
            {isUpdated ? 'Updated' : 'Adopted'}
          </Pill>
        )}
      </div>
      <div className="flex max-w-2xl flex-col gap-4 pt-4">
        <CustomPolicyBlock item={item} />
        <div className="flex flex-col gap-4 pb-2">
          {item.groups.map(({ policies, regions }, index) => (
            <PolicyGroupMainContent
              key={index}
              policies={policies}
              regions={regions}
            />
          ))}
        </div>
        <PolicyCategoryFullDetails
          policies={item.policies.filter(
            (policy) => policy.content.full_details,
          )}
        />
      </div>
    </Form>
  );
};
