import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { Chart, DoughnutController, ArcElement } from 'chart.js';

Chart.register(DoughnutController);
Chart.register(ArcElement);

type DonutProps = {
  data: Array<DonutDatum>;
  summaryPercentage?: number;
  isClickable?: boolean;
};

const BigPercentage = ({ percentage }: { percentage: number }) => {
  const text = `${(percentage * 100).toFixed(0)}%`;
  return (
    <p
      className="absolute inset-0 flex size-full items-center justify-center text-4xl font-semibold text-teal-700 transition-colors duration-200 ease-in-out group-hover/clickableDonut:text-teal-800"
      aria-label={`${text} complete`}
    >
      {text}
    </p>
  );
};

type DatumTheme = 'primary' | 'secondary' | 'amber';

export type DonutDatum = {
  theme: DatumTheme;
  name: string;
  value: number;
};
type Theme = {
  color: string; // needs to be an actual color, not a tailwind class
  label: {
    text: string;
    background: string;
    border: string;
  };
};
const Themes: Record<DatumTheme, Theme> = {
  primary: {
    color: '#008489',
    label: {
      text: clsx('text-teal-50'),
      background: clsx('bg-teal-700'),
      border: clsx('border-teal-700'),
    },
  },
  secondary: {
    color: '#E2EBEB',
    label: {
      text: clsx('text-teal-800'),
      background: clsx('bg-teal-350'),
      border: clsx('border-teal-500'),
    },
  },
  amber: {
    color: '#D97706',
    label: {
      text: clsx('text-amber-50'),
      background: clsx('bg-amber-600'),
      border: clsx('border-amber-600'),
    },
  },
};

export const Donut = ({
  data,
  summaryPercentage,
  isClickable = false,
}: DonutProps) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const isSetup = useRef(false);

  useEffect(() => {
    if (!ref.current || isSetup.current) {
      return;
    }
    isSetup.current = true;
    new Chart(ref.current, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: data.map((datum) => datum.value),
            backgroundColor: data.map((datum) => Themes[datum.theme].color),
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutout: 50,
        events: [],
        aspectRatio: 1,
        devicePixelRatio: 4,
      },
    });
  });

  return (
    <figure
      className={clsx('flex items-center gap-8', {
        'group/clickableDonut': isClickable,
      })}
    >
      <div className="relative size-[158px] rounded-full bg-gray-50">
        <canvas
          ref={ref}
          aria-hidden={summaryPercentage !== undefined}
          aria-label={
            summaryPercentage === undefined
              ? 'Process not started yet'
              : undefined
          }
        />
        {summaryPercentage !== undefined && (
          <BigPercentage percentage={summaryPercentage} />
        )}
      </div>
      <figcaption>
        <ul className="space-y-3">
          {data.map((datum) => {
            const theme = Themes[datum.theme];
            return (
              <li
                key={datum.name}
                className="flex items-center gap-3 font-semibold text-zinc-700"
              >
                <div className="flex min-w-8 items-center justify-center">
                  <span
                    className={clsx(
                      'flex min-w-6 items-center justify-center rounded-full border px-1.5 py-0.5 text-xs shadow-sm',
                      ...Object.values(theme.label),
                    )}
                  >
                    {datum.value}
                  </span>
                </div>
                {datum.name}
              </li>
            );
          })}
        </ul>
      </figcaption>
    </figure>
  );
};
