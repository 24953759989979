import { ReactNode } from 'react';

type RipplingStepProps = {
  title: ReactNode;
  content?: ReactNode;
  images?: Array<string>;
};

export const RipplingStep = ({
  title,
  content,
  images = [],
}: RipplingStepProps) => {
  return (
    <li className="flex gap-3 [counter-increment:step]">
      <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-teal-350 text-center text-sm font-medium text-teal-800 before:[content:counter(step)]" />
      <div className="mt-1 w-full space-y-2">
        <div className="font-medium leading-6">{title}</div>
        {content}
        {images.map((image) => (
          <img
            src={image}
            key={image}
            alt=""
            className="rounded-lg border shadow"
          />
        ))}
      </div>
    </li>
  );
};
