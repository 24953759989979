import { CheckIcon } from '@heroicons/react/solid';
import { useCopy } from '../hooks/useCopy';
import { CopyIcon } from '../Icons';
import { Tooltip } from '../layout/Tooltip';
import { Button } from 'react-aria-components';

type CopyFieldProps = {
  children: string;
  label: string;
  id: string;
};

const COPY_DELAY = 2000;

export const CopyField = ({ label, children }: CopyFieldProps) => {
  const [onCopy, isCopied] = useCopy(children, COPY_DELAY);

  return (
    <Tooltip
      label={isCopied ? 'Copied!' : `Copy ${label}`}
      forceOpen={isCopied}
    >
      <Button
        className="flex w-fit items-center gap-2 rounded border border-teal-350 bg-teal-100 px-3 py-2 text-teal-800 outline-none ring-rose-600 hover:bg-teal-50 data-[focus-visible]:ring-2"
        aria-label={`Copy ${label}`}
        onPressStart={onCopy}
        type="button"
      >
        <span>{children}</span>
        {isCopied ? (
          <CheckIcon className="size-4" />
        ) : (
          <CopyIcon className="size-4" />
        )}
      </Button>
    </Tooltip>
  );
};
