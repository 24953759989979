import { FunctionComponent, useEffect, useState } from 'react';
import {
  useFormContext,
  useFieldArray,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { clsx } from 'clsx';
import { PeopleOptionsType, Person } from '../../types';
import { filterPeople } from '../../utils/intake';
import { Renderer } from './Renderer';
import { formSpecArrayFieldToFormFieldProps } from './translate';

type FieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT[];
  className?: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contents: any[];
  peopleType: PeopleOptionsType;
  singularPersonLabel?: string;
  peopleData?: Person[];
};

export const SupplementalPersonInfo: FunctionComponent<FieldProps> = ({
  name,
  label,
  error,
  className,
  description,
  contents,
  peopleType,
  singularPersonLabel,
  peopleData,
}) => {
  const [people, setPeople] = useState<Person[]>([]);
  const { register, control } = useFormContext();

  const { fields: suppPersonFields, append: appendSuppPersonInfo } =
    useFieldArray({
      control,
      name,
    });

  useEffect(() => {
    if (peopleData) {
      const filteredPeople = filterPeople(peopleType, peopleData);
      const peopleReferences: { ref_email: string }[] = filteredPeople.map(
        (person) => {
          return { ref_email: person.email };
        },
      );
      appendSuppPersonInfo(peopleReferences, { shouldFocus: false });
      setPeople(filteredPeople);
    }
  }, [peopleData]);

  return (
    <div
      className={clsx(
        {
          'mb-8': !className,
        },
        className,
      )}
    >
      <div>
        <div>
          <h2 className="block pb-2 text-xl font-semibold text-gray-700">
            {label}
          </h2>
          <div className="mb-4 text-sm text-gray-600">{description}</div>
          <ul>
            {suppPersonFields.map(
              (
                /* eslint-disable @typescript-eslint/no-explicit-any */ item: any /* eslint-enable @typescript-eslint/no-explicit-any */,
                index,
              ) => {
                const person = people?.find((o) => o.email === item.ref_email);
                return (
                  <li
                    key={item.id}
                    className="mb-4 rounded border border-gray-300 bg-gray-100 p-4"
                  >
                    <input
                      type="text"
                      className="hidden"
                      {...register(`${name}.${index}.ref_email` as ``)}
                      disabled
                    />
                    <label className="mb-4 block font-semibold">
                      {singularPersonLabel ? `${singularPersonLabel}: ` : ''}
                      {person?.first_name} {person?.last_name}
                    </label>
                    <Renderer
                      config={formSpecArrayFieldToFormFieldProps(
                        contents,
                        name,
                        index,
                      )}
                      errors={error?.[index]}
                    />
                  </li>
                );
              },
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SupplementalPersonInfo;
