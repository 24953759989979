import { Switch, SwitchProps } from '@headlessui/react';
import { FunctionComponent } from 'react';

type ToggleProps = Omit<SwitchProps, 'className'>;

export const Toggle: FunctionComponent<ToggleProps> = (props) => {
  return (
    <Switch
      {...props}
      className="group relative flex shrink-0 w-[30px] h-[18px] items-center cursor-pointer rounded-full bg-white border-1 border-gray-200 transition-colors duration-200 ease-in-out focus-visible:outline-2 focus-visible:outline-teal-600 data-[checked]:border-teal-700 data-[checked]:bg-teal-700"
    >
      <span
        aria-hidden="true"
        className="pointer-events-none inline-block ml-0.5 size-3 translate-x-0 rounded-full bg-gray-200 border-1 border-gray-300 ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-3 group-data-[checked]:bg-teal-100 group-data-[checked]:border-none"
      />
    </Switch>
  );
};
