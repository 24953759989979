import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link, LinkProps } from 'react-router-dom';
import { ReactNode } from 'react';

type SectionTitleProps = {
  to: LinkProps['to'];
  title: ReactNode;
};

export const SectionTitle = ({ to, title }: SectionTitleProps) => {
  return (
    <Link
      to={to}
      className="group inline-block rounded outline-none focus-visible:ring-2 focus-visible:ring-rose-600"
    >
      <h3 className="text-lg font-medium text-teal-800 group-hover:text-teal-900">
        {title}
        <ChevronRightIcon className="mb-0.5 ml-1 inline size-4" />
      </h3>
    </Link>
  );
};
