import { useContext, createContext, ReactNode, useReducer } from 'react';

export type State = { isImporting: boolean };
export type Action = { type: 'setIsImporting'; payload: boolean };
type LocationProviderProps = { children: ReactNode };

export const initialState: State = { isImporting: false };
export const LocationDetailContext = createContext<State>(initialState);
export const LocationDetailDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'setIsImporting': {
      return {
        ...state,
        isImporting: action.payload,
      };
    }

    default: {
      throw Error(
        `[LocationDetailContext] Unknown action type: ${JSON.stringify(action)}`,
      );
    }
  }
}

export function LocationDetailProvider({ children }: LocationProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LocationDetailContext.Provider value={state}>
      <LocationDetailDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDetailDispatchContext.Provider>
    </LocationDetailContext.Provider>
  );
}

export const useLocationDetailContext = (): [State, React.Dispatch<Action>] => {
  const state = useContext(LocationDetailContext);
  const dispatch = useContext(LocationDetailDispatchContext);

  if (!dispatch) {
    throw new Error(
      'useLocationDetailContext must be used within a LocationProvider',
    );
  }

  return [state, dispatch];
};
