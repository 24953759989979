import { FunctionComponent, forwardRef, useEffect } from 'react';
import {
  UseFormRegister,
  useFormContext,
  FieldError as FieldErrorT,
  FieldErrors as FieldErrorsT,
} from 'react-hook-form';
import { clsx } from 'clsx';
import { USStateName } from '@mosey/utils/constants/us-states';
import { FieldError } from '@mosey/components/forms/FieldError';
import { CountryCode } from '../../constants';
import { classes } from '@mosey/components/forms/BaseFormField';

type TextFieldProps = {
  label: string;
  error?: FieldErrorT;
  className?: string;
};

type SelectFieldProps = {
  options: { name?: string; value: string }[];
  error?: FieldErrorT;
  className?: string;
  defaultValue?: string;
  emptyHelpText?: string;
  limitedStateOptions?: string[];
};

const AddressTextField = forwardRef<
  HTMLInputElement,
  TextFieldProps & ReturnType<UseFormRegister<Record<string, unknown>>>
>(function AddressTextField(
  { onChange, onBlur, name, label, error, className },
  ref,
) {
  return (
    <div className="-mt-px">
      <input
        type="text"
        placeholder={label}
        className={clsx(
          'relative w-full border border-gray-300 transition-shadow duration-150 focus:z-10',
          {
            [classes.variant.error]: error,
            [classes.variant.default]: !error,
          },
          className,
        )}
        id={name}
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
});

const AddressSelectField = forwardRef<
  HTMLSelectElement,
  SelectFieldProps & ReturnType<UseFormRegister<Record<string, unknown>>>
>(function AddressSelectField(
  {
    onChange,
    onBlur,
    name,
    options,
    error,
    className,
    defaultValue,
    emptyHelpText,
    limitedStateOptions,
  },
  ref,
) {
  // keep default value empty since default option is not a valid state option
  const EMPTY_OPTION_VALUE = '';
  return (
    <div style={{ marginTop: '-1px' }}>
      <select
        aria-label="State"
        data-testid={`select-field-button-${name}`}
        className={clsx(
          'relative w-full border border-gray-300 transition-shadow duration-150 focus:z-10',
          {
            [classes.variant.error]: error,
            [classes.variant.default]: !error,
          },
          className,
        )}
        id={name}
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue || EMPTY_OPTION_VALUE}
      >
        <option key="_default" value={EMPTY_OPTION_VALUE} disabled>
          {emptyHelpText || 'Select one'}
        </option>
        {options?.map((option) => {
          if (
            !limitedStateOptions ||
            limitedStateOptions.includes(option.value)
          ) {
            return (
              <option key={option.value} value={option.value}>
                {option.name || option.value}
              </option>
            );
          }
          return <></>;
        })}
      </select>
    </div>
  );
});

type AddressProps = {
  // pass the drilled down error object
  // example:
  //   errors={errors.officers?.[index]?.mailing_address}
  error?: FieldErrorsT<{
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    postal_code: string;
  }>;
  // pass the fully defined prefix
  // example:
  //   registeredPrefix={`officers.${index}.mailing_address`}
  name?: string;
  label?: string;
  description?: string;
  descriptionLink?: { url: string; text: string };
  defaultState?: string;
  limitedStateOptions?: string[];
  roundedTop?: boolean;
};

export const Address: FunctionComponent<AddressProps> = ({
  error,
  name: registerPrefix,
  label,
  description,
  descriptionLink,
  defaultState,
  limitedStateOptions,
  roundedTop = true,
}) => {
  const { register } = useFormContext();
  const stateOptions: { value: string }[] = Object.values(USStateName).map(
    (state) => {
      return { value: state };
    },
  );

  return (
    <div className="mb-8">
      {label && <label className="mb-2 block font-semibold">{label}</label>}
      {description && (
        <p className="mb-4 text-sm text-gray-600">
          {description}
          {descriptionLink && (
            <a className="text-teal-600" target="_" href={descriptionLink.url}>
              {' '}
              {descriptionLink.text}
            </a>
          )}
        </p>
      )}
      <AddressTextField
        label="Address Line 1"
        className={clsx({
          'rounded-t': roundedTop,
        })}
        error={error?.address_line_1}
        {...register(`${registerPrefix}.address_line_1`, {
          required: 'This is required',
        })}
      />
      <AddressTextField
        label="Address Line 2"
        error={error?.address_line_2}
        {...register(`${registerPrefix}.address_line_2`)}
      />
      <AddressTextField
        label="City"
        error={error?.city}
        {...register(`${registerPrefix}.city`, {
          required: 'This is required',
        })}
      />
      <AddressSelectField
        error={error?.state}
        options={stateOptions}
        emptyHelpText="Select state"
        defaultValue={defaultState}
        limitedStateOptions={limitedStateOptions}
        {...register(`${registerPrefix}.state`, {
          required: 'This is required',
        })}
      />
      <AddressTextField
        label="Zip Code"
        className="rounded-b"
        error={error?.postal_code}
        {...register(`${registerPrefix}.postal_code`, {
          required: 'This is required',
        })}
      />
      {error && (
        <FieldError
          error={{
            type: 'required',
            message: 'Missing required address fields',
          }}
        />
      )}
    </div>
  );
};

type NonUSAddressProps = {
  // pass the drilled down error object
  // example:
  //   errors={errors.officers?.[index]?.mailing_address}
  error?: FieldErrorsT<{
    address_line_1: string;
    address_line_2: string;
    country: string;
    city: string;
    province: string;
    postal_code: string;
  }>;
  // pass the fully defined prefix
  // example:
  //   registeredPrefix={`officers.${index}.mailing_address`}
  name?: string;
  label?: string;
  description?: string;
  descriptionLink?: { url: string; text: string };
  defaultState?: string;
  limitedStateOptions?: string[];
  defaultCountry?: string;
  limitedCountryOptions?: string[];
};

export const NonUSAddress: FunctionComponent<NonUSAddressProps> = ({
  error,
  name: registerPrefix,
  label,
  description,
  descriptionLink,
  defaultCountry,
}) => {
  const { register, unregister, watch } = useFormContext();
  const countryOptions: { value: string }[] = Object.values(CountryCode).map(
    (c) => {
      return { value: c };
    },
  );

  // Watch the country field, if it's US then show the US address
  // field otherwise show the non-US version
  const countryValueWatch = watch(`${registerPrefix}.country`);

  useEffect(() => {
    // The watch value can be undefined so we should check that
    // otherwise we might unregister accidentally causing stale
    // renders
    if (countryValueWatch) {
      // Must keep the value of country when unregistering otherwise
      // the render function won't show the right address field set
      unregister(`${registerPrefix}.country`, { keepValue: true });
      // Just unregister the fields from this component
      const toUnregister = [
        `${registerPrefix}.address_line_1`,
        `${registerPrefix}.address_line_2`,
        `${registerPrefix}.city`,
        `${registerPrefix}.state`,
        `${registerPrefix}.postal_code`,
      ];
      toUnregister.forEach((i) => unregister(i));
    }
  }, [countryValueWatch]);

  return (
    <div className="mb-8">
      {label && <label className="mb-2 block font-semibold">{label}</label>}
      {description && (
        <p className="mb-4 text-sm text-gray-600">
          {description}
          {descriptionLink && (
            <a className="text-teal-600" target="_" href={descriptionLink.url}>
              {' '}
              {descriptionLink.text}
            </a>
          )}
        </p>
      )}
      <AddressSelectField
        className="rounded-t"
        error={error?.country}
        options={countryOptions}
        emptyHelpText="Select country"
        defaultValue={defaultCountry || CountryCode.US}
        {...register(`${registerPrefix}.country`, {
          required: 'This is required',
        })}
      />
      {(countryValueWatch === undefined && defaultCountry === CountryCode.US) ||
      countryValueWatch === CountryCode.US ? (
        <Address roundedTop={false} error={error} name={registerPrefix} />
      ) : (
        <>
          <AddressTextField
            label="Address"
            error={error?.address_line_1}
            {...register(`${registerPrefix}.address_line_1`, {
              required: 'This is required',
            })}
          />
          <AddressTextField
            label="Apartment, suite, etc."
            error={error?.address_line_2}
            {...register(`${registerPrefix}.address_line_2`)}
          />
          <AddressTextField
            label="City"
            error={error?.city}
            {...register(`${registerPrefix}.city`, {
              required: 'This is required',
            })}
          />
          <AddressTextField
            label="State / Province"
            error={error?.province}
            {...register(`${registerPrefix}.state`)}
          />
          <AddressTextField
            label="Zip / Postal Code"
            className="rounded-b"
            error={error?.postal_code}
            {...register(`${registerPrefix}.postal_code`)}
          />
          {error && (
            <FieldError
              error={{
                type: 'required',
                message: 'Missing required address fields',
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
