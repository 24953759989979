import { clsx } from 'clsx';

type SectionHeadingProps = {
  text: React.ReactNode;
  borderless?: boolean;
  className?: string;
  rightActionElement?: React.ReactNode;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const SectionHeading = ({
  text,
  borderless,
  // TODO: Remove `className` prop
  className,
  rightActionElement,
  level = 'h1',
}: SectionHeadingProps) => {
  const Heading = level;

  return (
    <header
      className={clsx(
        {
          'border-b': !borderless,
          'pb-4 flex items-center': !className,
        },
        className,
      )}
    >
      <Heading className="flex-1 text-2xl font-bold leading-10 tracking-tight text-gray-900">
        {text}
      </Heading>

      {rightActionElement}
    </header>
  );
};
