import { clsx } from 'clsx';
import { useSearchParams } from 'react-router-dom';
import inboxZeroImg from '../../../assets/tasks-framework/tasks-overview-inbox-zero.svg';
import automatedZeroImg from '../../../assets/tasks-framework/tasks-overview-automated-zero.svg';
import managedZeroImg from '../../../assets/tasks-framework/tasks-overview-managed-zero.svg';

export const TasksOverviewZeroState = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  let title = 'Inbox zero';
  let body = 'You have no outstanding tasks!';
  let imgSrc = inboxZeroImg;

  switch (status) {
    case 'overdue':
      title = 'You’re all caught up!';
      body = 'You have no overdue tasks.';
      break;

    case 'done':
      title = 'No completed tasks yet.';
      body = 'Tasks will show up here as you complete them.';
      break;

    case 'automated':
      title = 'No completed automations yet.';
      body = 'Automated tasks will show up here as they are completed.';
      imgSrc = automatedZeroImg;
      break;

    case 'managed':
      title = 'No managed tasks yet.';
      body = 'Tasks handled by integrations will show up here.';
      imgSrc = managedZeroImg;
      break;
  }

  return (
    <div className="rounded-lg border border-teal-350 bg-teal-100 text-zinc-800 sm:flex">
      <div className="mx-10 mt-10 flex grow flex-col justify-center gap-y-2 sm:mt-0">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p>{body}</p>
      </div>

      <img
        className={clsx(
          'ml-auto max-h-48 shrink object-cover md:max-h-full lg:max-h-48 xl:max-h-full',
          (status === 'automated' || status === 'managed') && 'mt-12',
          status === 'automated' && 'mr-20',
        )}
        src={imgSrc}
        alt=""
      />
    </div>
  );
};
