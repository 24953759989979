import { StepNavEntry } from './StepNavItem';
import { GuidedCredentialsStep } from './types';

type CredentialsStepsProps = {
  steps: Array<GuidedCredentialsStep>;
  currentStepIndex: number;
  onStepIndexChange: (stepIndex: number) => void;
};

export const CredentialsSteps = ({
  steps,
  currentStepIndex,
  onStepIndexChange,
}: CredentialsStepsProps) => {
  return (
    <nav className="flex flex-col justify-center" aria-label="Progress">
      <h4 className="mb-4 text-lg font-bold">Login Guide</h4>
      <ol className="flex flex-col gap-4">
        {steps.map((step, stepIndex) => (
          <StepNavEntry
            key={step}
            currentStepIndex={currentStepIndex}
            onStepIndexChange={onStepIndexChange}
            step={step}
            stepIndex={stepIndex}
          />
        ))}
      </ol>
    </nav>
  );
};
