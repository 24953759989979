import { PlatformTaskPath, PlatformTaskResolutionStep } from '../../../types';
import { ExtLinkIcon } from '@mosey/components/Icons';

interface TaskResolutionsProps {
  steps: PlatformTaskResolutionStep[];
}

export const TaskResolutions = ({ steps }: TaskResolutionsProps) => {
  if (!steps.length) {
    return null;
  }

  return (
    <div className="space-y-6 pt-6">
      <h2 className="text-xl font-bold">Steps</h2>

      <div>
        <ol className="[counter-reset:resolutions]">
          {steps.map((step: PlatformTaskResolutionStep, index: number) => {
            const isLast = index === steps.length - 1;

            return (
              <li
                key={step.id}
                className="relative isolate flex pb-5 [counter-increment:resolutions] before:relative before:z-10 before:block before:size-10 before:flex-none before:rounded-full before:bg-teal-800 before:pt-2 before:text-center before:font-bold before:text-white before:content-[counter(resolutions)]"
              >
                {!isLast && (
                  <span
                    className="absolute left-5 top-5 z-0 h-full w-px bg-teal-550"
                    aria-hidden="true"
                  />
                )}
                <article className="relative z-10 ml-3 space-y-4 pt-1.5">
                  <h3 className="font-bold">{step.title}</h3>
                  <p className="text-sm text-gray-500">{step.description}</p>

                  <ol className="ml-7 list-decimal text-sm text-gray-500">
                    {step.paths.map((path: PlatformTaskPath) => {
                      switch (path.type) {
                        case 'link':
                          return (
                            <li key={path.value}>
                              <a
                                href={path.value}
                                target="_blank"
                                className="text-teal-600 hover:underline"
                                rel="noreferrer"
                              >
                                {path.text || path.value}
                                <ExtLinkIcon className="ml-1 inline-block size-4 align-text-bottom" />
                              </a>
                            </li>
                          );

                        case 'mail':
                          return (
                            <li key={path.value}>
                              <address
                                dangerouslySetInnerHTML={{
                                  __html: path.value.replaceAll(
                                    ' - ',
                                    '<br />',
                                  ),
                                }}
                              />
                            </li>
                          );

                        case 'phone':
                          return (
                            <li key={path.value}>
                              <p>
                                Call{' '}
                                <a
                                  href={`tel:${path.value}`}
                                  className="text-teal-600 hover:underline"
                                >
                                  {path.value}
                                </a>
                              </p>
                            </li>
                          );

                        case 'fax':
                          return (
                            <li key={path.value}>
                              <p>Fax {path.value}</p>
                            </li>
                          );

                        case 'email':
                          return (
                            <li key={path.value}>
                              <p>
                                Email{' '}
                                <a
                                  href={`mailto:${path.value}`}
                                  className="text-teal-600 hover:underline"
                                >
                                  {path.value}
                                </a>
                              </p>
                            </li>
                          );

                        default:
                          return null;
                      }
                    })}
                  </ol>
                </article>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};
