import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

type SubNavItemProps = {
  name: React.ReactNode;
  truncate?: boolean;
} & Omit<NavLinkProps, 'className'>;

export const SubNavItem = ({
  name,
  truncate = false,
  ...props
}: SubNavItemProps) => {
  const location = useLocation();
  const currentRelativePath = location.pathname + location.hash;

  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          clsx(
            'mr-3 block px-2 py-0.5 text-sm text-gray-600 outline-2 -outline-offset-2 hover:underline focus-visible:outline-rose-700',
            isActive && currentRelativePath === props.to && 'font-semibold',
            truncate && 'overflow-hidden truncate',
          )
        }
        {...props}
      >
        {name}
      </NavLink>
    </li>
  );
};
