import { useState } from 'react';
import { Button } from '@mosey/components/buttons/Button';
import {
  ArrowRightIcon,
  StopwatchIcon,
  ClipboardCheckIcon,
  CheckIcon,
  RefreshIcon,
} from '@mosey/components/Icons';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { Pill, PillProps } from '@mosey/components/badges/Pill';
import {
  PlatformTask,
  PlatformTaskHostedResolution,
  PlatformTaskStatus,
} from '../../../types';
import { fetchApi } from '../../../utils/fetchApi';
import { ApiStatus } from '../../../utils/types';

export const statusIconMapping: Record<PlatformTaskStatus, PillProps['Icon']> =
  {
    [PlatformTaskStatus.Todo]: ClipboardCheckIcon,
    [PlatformTaskStatus.Done]: CheckIcon,
    [PlatformTaskStatus.Dismissed]: StopwatchIcon,
    [PlatformTaskStatus.Error]: StopwatchIcon,
    [PlatformTaskStatus.InProgress]: RefreshIcon,
  };

export const statusColorMapping: {
  [key in PlatformTaskStatus]: PillProps['variant'];
} = {
  [PlatformTaskStatus.Todo]: 'secondary',
  [PlatformTaskStatus.Done]: 'success',
  [PlatformTaskStatus.Dismissed]: 'inactive',
  [PlatformTaskStatus.Error]: 'primary',
  [PlatformTaskStatus.InProgress]: 'automation',
};

interface TaskStatusProps {
  task: PlatformTask;
  refreshView: () => void;
}

export const TaskStatus = ({
  task: {
    id,
    status,
    managed_provider: managedProvider,
    resolutions: { hosted },
  },
  refreshView,
}: TaskStatusProps) => {
  const [mutationError, setMutationError] = useState<string | null>(null);

  const handleStatusChange = async (
    taskId: string,
    newStatus: PlatformTaskStatus,
  ) => {
    setMutationError(null);

    // TODO: Make optimistic
    const response = await fetchApi({
      url: `/tasks/${taskId}`,
      method: 'PATCH',
      body: {
        status: newStatus,
      },
      isPlatform: true,
    });

    if (response.status === ApiStatus.Error) {
      setMutationError(response.error.detail);
    } else if (response.status === ApiStatus.Success) {
      refreshView();
    }
  };

  const handleAutomate = async (resolution: PlatformTaskHostedResolution) => {
    setMutationError(null);

    /* eslint-disable camelcase */
    const response = await fetchApi({
      url: resolution.url,
      method: 'POST',
      body: {
        callback_url: window.location.href,
      },
      isPlatform: true,
    });
    /* eslint-enable camelcase */

    if (response.status === ApiStatus.Error) {
      setMutationError('Error attempting to automate. Please try again.');
    } else if (response.status === ApiStatus.Success) {
      window.location.assign(response.data.automation_url);
    }
  };

  return (
    <div className="space-y-5">
      <div className="flex items-center gap-x-6">
        <div className="flex items-center gap-x-2">
          <span className="font-bold">Status:</span>
          {/* TODO: Should this be replaced with OVERDUE if overdue? */}
          <Pill
            Icon={statusIconMapping[status]}
            variant={statusColorMapping[status]}
          >
            {status}
          </Pill>
        </div>

        {!managedProvider && (
          <div className="flex grow gap-x-2 border-l pl-6">
            {status !== PlatformTaskStatus.InProgress && (
              <Button
                size="xsmall"
                variant="secondary"
                onClick={() =>
                  handleStatusChange(id, PlatformTaskStatus.InProgress)
                }
              >
                Mark in-progress
              </Button>
            )}

            <Button
              size="xsmall"
              variant={
                status === PlatformTaskStatus.Done
                  ? 'actionSuccess'
                  : 'secondary'
              }
              leftIcon={<CheckIcon className="w-4" />}
              onClick={() => {
                handleStatusChange(
                  id,
                  status !== PlatformTaskStatus.Done
                    ? PlatformTaskStatus.Done
                    : PlatformTaskStatus.Todo,
                );
              }}
            >
              Mark {status !== PlatformTaskStatus.Done ? 'Done' : 'To Do'}
            </Button>

            {status !== PlatformTaskStatus.Done &&
              status !== PlatformTaskStatus.Dismissed && (
                <Button
                  variant="secondary"
                  size="xsmall"
                  onClick={() =>
                    handleStatusChange(id, PlatformTaskStatus.Dismissed)
                  }
                >
                  Dismiss
                </Button>
              )}

            {hosted && status === PlatformTaskStatus.Todo && (
              <Button
                variant="indigoPrimary"
                size="xsmall"
                rightIcon={<ArrowRightIcon className="w-4" />}
                className="ml-auto"
                onClick={() => handleAutomate(hosted)}
              >
                ✨ Automate with Mosey
              </Button>
            )}
          </div>
        )}
      </div>

      <BlockAlert
        show={!!mutationError}
        variant="error"
        message={mutationError}
      />
    </div>
  );
};
