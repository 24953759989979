import { clsx } from 'clsx';
import { isUSStateCode, USStateName } from '@mosey/utils/constants/us-states';

interface StateSealCroppedProps {
  code: string;
  opacity?: number;
  tinted?: boolean;
}

const seals = import.meta.glob<typeof import('*.png')>('./assets/*.png', {
  eager: true,
});

export const StateSealCropped = ({
  code,
  opacity = 0.5,
  tinted = false,
}: StateSealCroppedProps) => {
  const normalizedCode = code.toUpperCase();

  if (!isUSStateCode(normalizedCode)) {
    throw new TypeError(`Invalid US state code: ${code}`);
  }

  const imgSrc = seals[`./assets/${code.toLowerCase()}-cropped.png`]?.default;

  return (
    <div
      className="relative mx-auto size-full overflow-hidden bg-white transition-opacity"
      style={{
        maskImage: `url(${imgSrc})`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskSize: 'contain',
      }}
    >
      <div
        className="absolute inset-0 overflow-visible bg-teal-100 mix-blend-color"
        aria-hidden
      />
      <div className="absolute inset-0">
        {imgSrc && (
          <img
            className={clsx(
              'size-full object-contain transition',
              tinted ? 'grayscale' : 'grayscale-0',
            )}
            style={{ opacity }}
            src={imgSrc}
            alt={`${USStateName[normalizedCode]} state seal`}
          />
        )}
      </div>
      <div
        className="absolute inset-0 bg-teal-400 mix-blend-color transition"
        style={{
          opacity: tinted ? opacity : 0,
        }}
        aria-hidden
      />
    </div>
  );
};
