import { Login } from '../../../types';

export enum GuidedCredentialsStep {
  VISIT_SITE,
  USERNAME,
  PASSWORD,
  OTP,
  EMAIL_OTP,
  COMPLETE,
}

export type GuidedStepContentProps = {
  login: Login;
  nextStep: () => void;
  reset: () => void;
};
