import { FC, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isAuthenticated } from '@mosey/utils/auth';
import { FilledLockIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { getSsoAuthUrl } from '../utils/auth';
import { TextField } from '@mosey/components/forms/TextField';
import logo from '../assets/logo.svg';

type SingleSignOnFormValue = {
  email: string;
};

export const SingleSignOn: FC = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>(searchParams.get('error') || '');

  const formMethods = useForm<SingleSignOnFormValue>();
  const { handleSubmit } = formMethods;

  const onSubmit = async (data: SingleSignOnFormValue) => {
    setError('');
    try {
      const { email } = data;
      const ssoResult = await getSsoAuthUrl(email, {
        redirect: searchParams.get('redirect') || '',
      });
      // trusted URL - server generated
      window.location.href = ssoResult;
    } catch (err) {
      setError(
        (err instanceof Error && err?.message) ||
          "Something went wrong, we're looking into it.",
      );
    }
  };

  return isAuthenticated() ? (
    <Navigate to="/" replace />
  ) : (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="mb-10 w-full max-w-md pb-4">
        <div className="w-20">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900">
            Single sign-on
          </h2>
        </div>
        <FormProvider {...formMethods}>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <BlockAlert show={!!error} variant="error" message={error} />
            <input type="hidden" name="remember" value="true" />
            <TextField
              hasMargin={false}
              inputClassName="relative focus:z-10"
              name="email"
              placeholder="Email address"
              type="email"
            />
            <div>
              <Button
                type="submit"
                size="large"
                leftIcon={<FilledLockIcon className="size-5" />}
                isFullWidth
              >
                Continue
              </Button>
            </div>
            <div className="block text-center">
              <Link
                to={`/login?${searchParams.toString()}`}
                className="mx-auto w-full font-medium text-teal-600 hover:text-teal-700"
              >
                Back to email and password sign in
              </Link>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
