import { Link, LinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { ExtLinkIcon } from '../Icons';

interface TextLinkProps extends Omit<LinkProps, 'className'> {
  variant?: 'primary' | 'secondary';
  skipInk?: boolean;
  skipIcon?: boolean;
}

export const TextLink = ({
  variant = 'primary',
  skipInk = true,
  skipIcon = false,
  children,
  ...props
}: TextLinkProps) => {
  return (
    <Link
      {...props}
      className={clsx(
        'font-medium underline outline-2 outline-offset-2 focus-visible:outline-rose-700',
        {
          'text-teal-700 hover:text-teal-800': variant === 'primary',
          'text-zinc-600 hover:text-zinc-700': variant === 'secondary',
          'decoration-skip-ink-none': !skipInk,
        },
      )}
      rel={props.target === '_blank' ? 'noreferrer' : props.rel}
    >
      {children}
      {props.target === '_blank' && !skipIcon && (
        <ExtLinkIcon
          aria-label="– opens in new tab"
          className="mb-px ml-[0.25em] inline-block size-3.5"
        />
      )}
    </Link>
  );
};
