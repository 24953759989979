import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import { Pill, PillProps } from '@mosey/components/badges/Pill';
import { RequirementComputedStatus, RequirementOverview } from '../../types';
import { requirementComputedStatus } from '../../utils/format';

export enum ProgressStatus {
  Complete = 'Complete',
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Overdue = 'Overdue',
}

export const statusFromPercentComplete = (complete: number): ProgressStatus => {
  switch (complete) {
    case 0:
      return ProgressStatus.NotStarted;
    case 100:
      return ProgressStatus.Complete;
    default:
      return ProgressStatus.InProgress;
  }
};

export const statusFromRequirement = (
  requirement: RequirementOverview,
): ProgressStatus => {
  const computedStatus = requirementComputedStatus(requirement);
  switch (computedStatus) {
    case RequirementComputedStatus.Done:
      return ProgressStatus.Complete;
    case RequirementComputedStatus.InProgress:
      return ProgressStatus.InProgress;
    case RequirementComputedStatus.Overdue:
      return ProgressStatus.Overdue;
    case RequirementComputedStatus.Todo:
    case RequirementComputedStatus.Deferred:
    default:
      return ProgressStatus.NotStarted;
  }
};

type ProgressStyles = {
  textColor: string;
  bgColor: string;
  bgHighlightColor: string;
  tagVariant: PillProps['variant'];
};

const statusToStyles = (status: ProgressStatus): ProgressStyles => {
  const defaultStyles: ProgressStyles = {
    textColor: 'text-gray-600',
    bgColor: 'bg-gray-200',
    bgHighlightColor: 'bg-gray-400',
    tagVariant: 'secondary',
  };

  switch (status) {
    case ProgressStatus.Complete:
      return {
        textColor: 'text-green-600',
        bgColor: 'bg-green-200',
        bgHighlightColor: 'bg-green-400',
        tagVariant: 'success',
      };
    case ProgressStatus.Overdue:
      return { ...defaultStyles, tagVariant: 'primary' };
    case ProgressStatus.InProgress:
      return {
        textColor: 'text-teal-700',
        bgColor: 'bg-teal-400',
        bgHighlightColor: 'bg-teal-700',
        tagVariant: 'automation',
      };
    case ProgressStatus.NotStarted:
    default:
      return defaultStyles;
  }
};

type ProgressBarProps = {
  complete: number;
  bgColor: string;
  bgHighlightColor: string;
};

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  complete,
  bgColor,
  bgHighlightColor,
}) => {
  const completeFormatted = `${complete}%`;

  return (
    <div
      className={clsx('mb-2 flex h-2 overflow-hidden rounded text-xs', bgColor)}
    >
      <div
        style={{ width: completeFormatted }}
        className={clsx(
          'flex flex-col justify-center whitespace-nowrap rounded text-center text-white shadow-none transition-[width]',
          bgHighlightColor,
        )}
      />
    </div>
  );
};

type StatusProgressBarProps = {
  label: string;
  complete: number;
  status: ProgressStatus;
};

export const StatusProgressBar: FunctionComponent<StatusProgressBarProps> = ({
  label,
  complete,
  status,
}) => {
  const { textColor, bgColor, bgHighlightColor, tagVariant } =
    statusToStyles(status);
  const completeFormatted = `${complete}%`;

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          {label && <span className="mr-2 font-semibold">{label}</span>}
          <Pill variant={tagVariant} size="small">
            {status}
          </Pill>
        </div>
        <div className="text-right">
          <span
            className={clsx('inline-block text-xs font-semibold', textColor)}
          >
            {completeFormatted}
          </span>
        </div>
      </div>
      <ProgressBar
        complete={complete}
        bgColor={bgColor}
        bgHighlightColor={bgHighlightColor}
      />
    </div>
  );
};

export default StatusProgressBar;
