import { captureMessage } from '@sentry/react';
import { api } from './fetchApi';

// should be used for any user-supplied value
export function safelyUpdateLocation(path: string, replace = false) {
  try {
    const url = new URL(path);
    if (url.protocol !== 'https:' && url.origin !== window.location.origin) {
      return;
    }

    // TODO: use an allow list of valid redirection targets as well
    captureMessage(`Safely redirecting user to: ${url.origin}`, 'debug');

    if (replace) {
      window.location.replace(url);
    } else {
      window.location.href = url.toString();
    }
  } catch (err) {
    return;
  }
}

export const getPostOnboardingResolverUrl = async () => {
  let summary;

  try {
    const response = await api({
      url: '/api/compliance/tasks/summary',
      method: 'GET',
    });
    summary = await response.json();
  } catch (error) {
    // If the endpoint errors out, it means they don't have gutz enabled
    return null;
  }

  if (summary) {
    let redirectUrl = null;
    const [assessmentAlert] = summary.assessment_alerts;
    const [setupAlert] = summary.setup_alerts;

    if (assessmentAlert?.region) {
      redirectUrl = `/locations/usa/${assessmentAlert.region.code.toLowerCase()}/resolver/assessment/`;
    } else if (setupAlert?.region) {
      redirectUrl = `/locations/usa/${setupAlert.region.code.toLowerCase()}/resolver/setup/`;
    }

    if (redirectUrl) {
      return redirectUrl;
    }
  }

  return null;
};
