import { useMemo } from 'react';
import { clsx } from 'clsx';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import {
  StopwatchIcon,
  ErrorIcon,
  ClipboardCheckIcon,
  CheckIcon,
  RobotIcon,
  ChevronDownIcon,
  UserIcon,
  LocationIcon,
} from '@mosey/components/Icons';
import { Tooltip } from '../../components/alerts/Tooltip';
import {
  LocationFilter,
  ManagedByTag,
  TaskDescription,
  TaskDueDate,
  TaskResolutions,
  TaskStatus,
  TaskTriggeredBy,
} from './common';
import {
  PlatformTaskStatus,
  PlatformTask,
  PlatformLegalEntityLocation,
  PlatformTaskCriteriaType,
} from '../../types';
import { getStateSealImage } from '../../utils/seals';

interface StateTaskProps {
  task: PlatformTask;
  parentTask?: PlatformTask;
  childTaskMap?: { [key: string]: PlatformTask[] };
  isLastChild?: boolean;
  location: PlatformLegalEntityLocation;
  refreshView: () => void;
}

const StateTask = ({
  task,
  parentTask,
  childTaskMap = {},
  isLastChild = false,
  location,
  refreshView,
}: StateTaskProps) => {
  const {
    managed_provider: managedProvider,
    due_date: dueDate,
    definition: { title, description, resources },
    resolutions,
    status,
    criteria_met: criteriaMet,
  } = task;
  let StatusIcon;
  let statusColor;

  switch (status) {
    case PlatformTaskStatus.Todo:
      StatusIcon = ClipboardCheckIcon;
      statusColor = 'bg-sage-600';
      break;

    case PlatformTaskStatus.Dismissed:
      StatusIcon = StopwatchIcon;
      statusColor = 'bg-yellow-500';
      break;

    case PlatformTaskStatus.Done:
      StatusIcon = CheckIcon;
      statusColor = 'bg-lime-500';
      break;

    case PlatformTaskStatus.Error:
      StatusIcon = ErrorIcon;
      statusColor = 'bg-red-700';
      break;

    case PlatformTaskStatus.InProgress:
      StatusIcon = StopwatchIcon;
      statusColor = 'bg-teal-800';
      break;
  }

  if (managedProvider && status === PlatformTaskStatus.InProgress) {
    StatusIcon = RobotIcon;
  }

  const isChildTask = !!parentTask;

  return (
    <li className="border-b last:border-none">
      <Disclosure
        as="div"
        className={clsx('relative py-6 pr-6', {
          'bg-sage-100 pl-20': isChildTask,
          'pl-6': !isChildTask,
        })}
      >
        {isChildTask && (
          <>
            <div
              className={clsx('absolute left-12 -ml-px w-px bg-sage-600', {
                'top-0 bottom-[calc(100%-42px)]': isLastChild,
                'inset-y-0': !isLastChild,
              })}
              aria-hidden="true"
            />
            <div
              className="absolute left-12 top-[42px] -ml-px h-px w-6 bg-sage-600"
              aria-hidden="true"
            />
          </>
        )}
        <header className="relative flex items-center gap-x-4">
          <div
            className={clsx(
              statusColor,
              'mx-1.5 flex size-9 items-center justify-center justify-self-center rounded-full text-white',
            )}
          >
            <StatusIcon className="size-5" />
          </div>

          <DisclosureButton className="group flex grow items-center gap-x-4 self-center">
            <h3 className="text-xl font-bold text-gray-900">
              {title
                .replace(new RegExp(`^${location.location.name}`), '')
                .trim()}
            </h3>

            <div className="ml-auto flex items-center gap-x-3">
              {!managedProvider && (
                <TaskDueDate dueDate={dueDate} onlyShowOverdue />
              )}
              <ManagedByTag managedProvider={managedProvider} />

              {criteriaMet.find(
                ({ key }) =>
                  key === PlatformTaskCriteriaType.RegionHasEmployees,
              ) &&
                status === PlatformTaskStatus.Todo && (
                  <div className="flex items-center justify-center rounded-full bg-sage-200 p-1.5 text-sage-700">
                    <Tooltip
                      name="has_employees_indicator"
                      label={
                        <span>
                          <strong>{title}</strong> appears because you have
                          employees in {location.location.code}.
                        </span>
                      }
                    >
                      <UserIcon className="size-4" />
                    </Tooltip>
                  </div>
                )}

              {criteriaMet.find(
                ({ key }) =>
                  key === PlatformTaskCriteriaType.RegionHasPhysicalLocation,
              ) &&
                status === PlatformTaskStatus.Todo && (
                  <div className="flex items-center justify-center rounded-full bg-sage-200 p-1.5 text-sage-700">
                    <Tooltip
                      name="has_physical_location_indicator"
                      label={
                        <span>
                          <strong>{title}</strong> appears because you have a
                          physical location in {location.location.code}.
                        </span>
                      }
                    >
                      <LocationIcon className="size-4" />
                    </Tooltip>
                  </div>
                )}

              <ChevronDownIcon className="ml-auto size-5 group-data-[open]:rotate-180" />
            </div>
          </DisclosureButton>
        </header>

        <Transition
          as="div"
          enter="transition duration-100 ease-out origin-top"
          enterFrom="transform scale-y-75 opacity-0"
          enterTo="transform scale-y-100 opacity-100"
          leave="transition duration-75 ease-out origin-top"
          leaveFrom="transform scale-y-100 opacity-100"
          leaveTo="transform scale-y-75 opacity-0"
        >
          <DisclosurePanel className="mt-4">
            <div className="space-y-6 pl-16 pr-7 text-sm">
              <TaskStatus task={task} refreshView={refreshView} />

              <TaskTriggeredBy criteriaMet={criteriaMet} status={status} />

              <TaskDescription
                description={description}
                resources={resources}
              />

              {resolutions.manual && (
                <TaskResolutions steps={resolutions.manual.steps} />
              )}
            </div>
          </DisclosurePanel>
        </Transition>
      </Disclosure>

      <ul>
        {childTaskMap[task.id] &&
          childTaskMap[task.id].map((child, childIndex) => {
            return (
              <StateTask
                key={child.id}
                task={child}
                location={location}
                parentTask={task}
                isLastChild={childIndex === childTaskMap[task.id].length - 1}
                refreshView={refreshView}
              />
            );
          })}
      </ul>
    </li>
  );
};

interface StateTasksProps {
  location: PlatformLegalEntityLocation;
  tasks: PlatformTask[];
  refreshView: () => void;
}

export const StateTasks = ({
  location,
  tasks,
  refreshView,
}: StateTasksProps) => {
  const [childTaskMap, reorderedTasks] = useMemo(() => {
    const childMap: { [key: string]: boolean } = {};
    const dependentTaskMap: {
      [key: string]: PlatformTask[];
    } = {};
    const results = [];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      for (let j = 0; j < task.criteria_met.length; j++) {
        const criteria = task.criteria_met[j];

        if (criteria.key === PlatformTaskCriteriaType.RequirementDone) {
          const parent = tasks.find(
            (task) => task.id === criteria.data?.task_id,
          );

          if (parent) {
            childMap[task.id] = true;
            if (!dependentTaskMap[parent.id]) {
              dependentTaskMap[parent.id] = [];
            }

            dependentTaskMap[parent.id].push(task);
          }
        }
      }
    }

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (!childMap[task.id]) {
        results.push(task);
      }
    }

    return [dependentTaskMap, results];
  }, [tasks]);

  return (
    <div className="mb-12 border bg-white">
      <header className="space-y-5 border-b px-4 py-5 sm:px-6">
        <div className="flex items-center gap-x-4">
          <img
            src={getStateSealImage(location.location.code as USStateAbbrev)}
            className="size-12"
            alt="State seal"
          />

          <h2 className="text-2xl font-bold">{location.location.name}</h2>
        </div>
      </header>

      <ul>
        {reorderedTasks.map((task) => (
          <StateTask
            key={task.id}
            task={task}
            childTaskMap={childTaskMap}
            location={location}
            refreshView={refreshView}
          />
        ))}
      </ul>
    </div>
  );
};

interface PlatformLocationsTasksProps {
  locations: PlatformLegalEntityLocation[];
  tasks: PlatformTask[];
  refreshView: () => void;
}

export const PlatformLocationsTasks = ({
  tasks,
  locations,
  refreshView,
}: PlatformLocationsTasksProps) => {
  const locationMap = useMemo(() => {
    const result: {
      [key: string]: PlatformLegalEntityLocation;
    } = {};

    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];
      result[location.location.code] = location;
    }

    return result;
  }, [locations]);

  const tasksByRegion = useMemo(() => {
    const regionMap: { [key: string]: PlatformTask[] } = {};

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (!regionMap[task.definition.location.code]) {
        regionMap[task.definition.location.code] = [];
      }

      regionMap[task.definition.location.code].push(task);
    }

    return Object.entries(regionMap)
      .map(([regionName, regionTasks]) => {
        return { location: locationMap[regionName], tasks: regionTasks };
      })
      .sort((a, b) => {
        return a.location.location.name.localeCompare(b.location.location.name);
      });
  }, [tasks, locationMap]);

  return (
    <>
      <div className="mb-6 flex items-center">
        <LocationFilter locations={locations} />
      </div>

      {tasksByRegion.map((region) => (
        <StateTasks
          location={region.location}
          key={region.location.location.name}
          tasks={region.tasks}
          refreshView={refreshView}
        />
      ))}
    </>
  );
};
