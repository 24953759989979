type PageTitleProps = {
  children: React.ReactNode;
};

export const PageTitle = ({ children }: PageTitleProps) => {
  return (
    <h1 className="flex flex-1 items-center gap-4 text-2xl font-bold text-gray-900">
      {children}
    </h1>
  );
};
