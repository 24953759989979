import { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { SentryRoutes as Routes } from '../sentry';
import { MailRoomView } from './MailRoomView';
import { LegalEntityRegion } from '../types';

type LocationDetailMailViewProps = {
  legalEntityRegion: LegalEntityRegion;
  triggerParentRefresh: () => void;
};

export const LocationDetailMailView: FunctionComponent<
  LocationDetailMailViewProps
> = ({ legalEntityRegion }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<MailRoomView legalEntityRegion={legalEntityRegion} />}
      />
    </Routes>
  );
};
