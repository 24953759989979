import { FunctionComponent, createElement } from 'react';
import { Navigate } from 'react-router-dom';

import { Loading } from '../views/Loading';
import { FatalError } from '../views/FatalError';
import { IApiData, ApiStatus } from '../utils/types';

type ApiStatusHandlerProps<T> = {
  status: ApiStatus;
  // We don't use this yet, but we might.
  error: Error;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  component: FunctionComponent<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraProps?: any;
};

/*
   Higher order component that handles API request states for loading,
   error, complete. This should be paired with the `useApi` hook.

   Generic type `P` is the props type to pass to `FunctionComponent`
   specified by `component` if the API request is successful. This
   provides type safety because you will get a type error if the props
   type doesn't match the component.
 */
export function ApiStatusHandler<P extends object>({
  status,
  data,
  component,
  extraProps,
}: ApiStatusHandlerProps<P>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {};
  switch (status) {
    case ApiStatus.ErrorUnauthorized:
      return <Navigate to="/logout" replace />;
    case ApiStatus.Error:
      return <FatalError />;
    case ApiStatus.Loading:
    case ApiStatus.Retrying:
    case ApiStatus.RefreshingToken:
      return <Loading />;
    case ApiStatus.Success:
      // Merge data with extraProps passed in by the caller
      for (const i of Object.entries(data)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [k, v] = i as any;
        props[k] = v;
      }

      if (extraProps) {
        for (const i of Object.entries(extraProps)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const [k, v] = i as any;
          props[k] = v;
        }
      }

      return createElement(component, props);

    // Compile time error if we haven't covered all ApiStatus variants
    default:
      ((defaultStatus: never) => {
        throw new Error(`${defaultStatus} was unhandled.`);
      })(status);
  }
}

type BatchApiStatusHandlerProps<T> = {
  batchResponse: IApiData[];
  // The component to render if the batch API call succeeds
  component: FunctionComponent<T>;
  ErrorComponent?: FunctionComponent;
  // Transforms the array of API data from `batchResponse` into props
  // for component
  componentProps: (data: IApiData[]) => T;
};

type KeyedBatchApiStatusHandlerProps<T> = {
  // TODO: Own
  batchResponse: { [key: string]: IApiData };
  // The component to render if the batch API call succeeds
  component: React.ElementType;
  // Transforms the array of API data from `batchResponse` into props
  // for component
  componentProps: (data: { [key: string]: IApiData }) => T;
};

/*
  Only succeeds if all API responses in the batch were successful.
*/
export function BatchApiStatusHandler<P extends object>({
  batchResponse,
  componentProps,
  component,
  ErrorComponent,
}: BatchApiStatusHandlerProps<P>) {
  const statuses = batchResponse.map((r) => r.status);

  // If any status is error, return an error
  // If any status is unauthorized, logout
  // If any status is loading, retrying, or refreshing, show loading
  // If each status is success, return the components

  if (statuses.includes(ApiStatus.ErrorUnauthorized)) {
    return <Navigate to="/logout" replace />;
  }

  if (statuses.includes(ApiStatus.Error)) {
    if (ErrorComponent) {
      return <ErrorComponent />;
    } else {
      return <FatalError />;
    }
  }

  if (
    statuses.includes(ApiStatus.Loading) ||
    statuses.includes(ApiStatus.Retrying) ||
    statuses.includes(ApiStatus.RefreshingToken)
  ) {
    return <Loading />;
  }

  if (statuses.every((i) => i === ApiStatus.Success)) {
    // Translate the batch of API response data into props for the
    // component
    const props = componentProps(batchResponse.map((r) => r.data));
    return createElement(component, props);
  }
  throw new Error(`${statuses} was unhandled.`);
}

/*
  Only succeeds if all API responses in the batch were successful.
*/
export function KeyedBatchApiStatusHandler<P extends object>({
  batchResponse,
  componentProps,
  component,
}: KeyedBatchApiStatusHandlerProps<P>) {
  const statuses = Object.values(batchResponse).map((r) => r.status);

  // If any status is error, return an error
  // If any status is unauthorized, logout
  // If any status is loading, retrying, or refreshing, show loading
  // If each status is success, return the components

  if (statuses.includes(ApiStatus.ErrorUnauthorized)) {
    return <Navigate to="/logout" replace />;
  }

  if (statuses.includes(ApiStatus.Error)) {
    return <FatalError />;
  }

  if (
    statuses.includes(ApiStatus.Loading) ||
    statuses.includes(ApiStatus.Retrying) ||
    statuses.includes(ApiStatus.RefreshingToken)
  ) {
    return <Loading />;
  }

  if (statuses.every((i) => i === ApiStatus.Success)) {
    // Translate the batch of API response data into props for the component
    const props = componentProps(
      Object.entries(batchResponse).reduce((acc, [key, { data }]) => {
        return { ...acc, [key]: data };
      }, {}),
    );

    return createElement(component, props);
  }

  throw new Error(`${statuses} was unhandled.`);
}
