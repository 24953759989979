import { FunctionComponent, ReactNode } from 'react';
import { FieldError as ReactHookFieldError } from 'react-hook-form';
import { clsx } from 'clsx';

import { FieldError } from './FieldError';

type DescriptionLinkProps = { url: string; text: string };

type FormFieldInternalProps = {
  children: ReactNode;
};

export type FormFieldProps = {
  name: string;
  label?: string;
  rightButton?: ReactNode;
  leftButton?: ReactNode;
  overlay?: ReactNode;
  error?: ReactHookFieldError;
  className?: string;
  description?: string;
  descriptionLink?: DescriptionLinkProps;
  hasMargin?: boolean;
};

export const FormField: FunctionComponent<
  FormFieldProps & FormFieldInternalProps
> = ({
  children,
  name,
  label,
  rightButton,
  leftButton,
  overlay,
  error,
  className,
  description,
  descriptionLink,
  hasMargin = true,
}) => {
  return (
    <div className={clsx(className, { 'mb-8': hasMargin })}>
      {label && (
        <label
          className="mb-2 block font-semibold text-gray-700"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      {description && (
        <p className="mb-2 text-sm text-gray-500">
          {description}
          {descriptionLink && (
            <a className="text-teal-600" target="_" href={descriptionLink.url}>
              {' '}
              {descriptionLink.text}
            </a>
          )}
        </p>
      )}
      <div className="relative">
        {children}
        {overlay && <div className="absolute inset-px">{overlay}</div>}
        {rightButton && (
          <div className="absolute inset-y-px right-0 inline-flex items-center justify-center gap-2 px-4">
            {leftButton}
            {rightButton}
          </div>
        )}
      </div>
      {error && <FieldError error={error} />}
    </div>
  );
};

export default FormField;
