type Pattern = {
  value: RegExp;
  message: string;
};

export const SsnPattern: Pattern = {
  value: /^((?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4})?$/g,
  message: 'The SSN must be valid xxx-xx-xxxx',
};

export const IdentifierPattern: Pattern = {
  value: /^((?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4})?$/g,
  message: 'The SSN or ITIN must be valid xxx-xx-xxxx',
};

export const PhonePattern: Pattern = {
  value: /^\d{3}-\d{3}-\d{4}$/g,
  message: 'The phone number must be valid xxx-xxx-xxxx',
};

export const AnyPhonePattern: Pattern = {
  value: /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g,
  message: 'The phone number must be valid',
};

export const EmailPattern: Pattern = {
  value: /^(.+)@(\S+)[.](\S+)$/g,
  message: 'The email must be valid',
};

export const EinPattern: Pattern = {
  value: /^(0[1-6]|1[0-6]|2[0-7]|[345]\d|[68][0-8]|7[1-7]|9[0-58-9])-?\d{7}$/g,
  message: 'Your EIN must be 9 digits long',
};

export const NaicsPattern: Pattern = {
  value: /^\d{6}$/g,
  message: 'Must be 6 digits',
};

export const NumberPattern: Pattern = {
  value: /^\d+$/g,
  message: 'Must be a number',
};

export const CharLimit100Pattern: Pattern = {
  value: /^[\w\W\d]{0,100}$/g,
  message: 'Must not exceed 100 characters',
};
