import { FieldError } from 'react-hook-form';
import { ValidationError } from '../types';

type ErrorMap = {
  [field: string]: FieldError;
};
export function transformServerFieldErrors(
  errors: Array<ValidationError>,
): ErrorMap {
  if (errors?.length > 0) {
    return errors.reduce<ErrorMap>((previous: ErrorMap, { loc, msg, type }) => {
      const fieldString = loc.join('.');
      return {
        ...previous,
        [fieldString]: {
          type,
          message: msg,
        },
      };
    }, {});
  } else {
    return {};
  }
}
