import { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Address, DateField, NumberField, UsStateSelectField } from '.';
import { CheckBoxField } from '@mosey/components/forms/CheckBoxField';
import { TextField } from '@mosey/components/forms/TextField';
import { BusinessIFormValues } from '../../types';
import { EinPattern } from '@mosey/utils/validation/patterns';

type FormBusinessProps = {
  isEdit?: boolean;
};

export const FormBusiness: FunctionComponent<FormBusinessProps> = ({
  isEdit,
}) => {
  const formMethods = useFormContext<BusinessIFormValues>();

  const {
    unregister,
    watch,
    formState: { errors },
  } = formMethods;

  const watchPhysicalAddressSameAsMailingAddress = watch(
    'is_physical_address_same_as_mailing_address',
  );

  useEffect(() => {
    if (watchPhysicalAddressSameAsMailingAddress) {
      unregister('mailing_address');
    }
  }, [watchPhysicalAddressSameAsMailingAddress]);

  const isBusinessAddressSameAsMailingAddress =
    watchPhysicalAddressSameAsMailingAddress || false;

  return (
    <div>
      <TextField
        name="name"
        label="Business Name"
        error={errors.name}
        reactFormConfig={{
          required: 'This is required',
        }}
      />
      <UsStateSelectField
        name="incorporation_region"
        label="State of the Business's Incorporation"
        description="Select the state where the business is incorporated."
        error={errors.incorporation_region}
        reactFormConfig={{
          required: 'This is required',
        }}
      />
      <DateField
        name="incorporation_date"
        label="Incorporation Date"
        error={errors.incorporation_date}
        reactFormConfig={{
          required: 'This is required',
        }}
      />
      {!isEdit && (
        <TextField
          name="business_identifier"
          label="EIN"
          error={errors.business_identifier}
          reactFormConfig={{
            pattern: EinPattern,
            required: 'This is required',
          }}
        />
      )}
      <div className="mt-8">
        <div>
          <Address
            error={errors.physical_address}
            name="physical_address"
            label="Business Address"
          />
          <CheckBoxField
            name="is_physical_address_same_as_mailing_address"
            label="This address is also the mailing address"
            error={errors.is_physical_address_same_as_mailing_address}
            className="my-3"
          />
        </div>
      </div>
      {!isBusinessAddressSameAsMailingAddress && (
        <div className="mt-8">
          <div>
            <Address
              label="Mailing Address"
              error={errors.mailing_address}
              name="mailing_address"
            />
          </div>
        </div>
      )}
      <DateField
        name="ownership_start_date"
        label="Date of Ownership"
        error={errors.ownership_start_date}
        reactFormConfig={{
          required: 'This is required',
        }}
      />
      <NumberField
        name="ownership_percentage"
        label="Percent Ownership"
        min="0"
        error={errors.ownership_percentage}
        reactFormConfig={{
          required: 'This is required',
        }}
      />
    </div>
  );
};
