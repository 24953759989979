import { clsx } from 'clsx';

interface SectionProgressProps {
  progress: number;
}

export const SectionProgress = ({ progress }: SectionProgressProps) => (
  <div className="flex items-center justify-center gap-x-5">
    <span className="text-xs font-semibold uppercase tracking-wide text-gray-400">
      Progress
    </span>
    <div className="flex h-1.5 w-64 overflow-hidden rounded bg-gray-200 text-xs">
      <div
        style={{ width: `${progress}%` }}
        className={clsx(
          'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none',
          {
            'bg-lime-500': progress === 100,
            'bg-teal-700': progress !== 100,
          },
        )}
      />
    </div>
  </div>
);
