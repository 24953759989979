import { useLegalEntityRegions } from '../../../../hooks/useUser';
import { RegionFilter } from './RegionFilter';
import { useTasksOverviewLocationId } from '../../utils/hooks';

export const SelectedRegionFilter = () => {
  const regions = useLegalEntityRegions();
  const locationId = useTasksOverviewLocationId();

  const selectedRegion = regions.find(
    ({ code }) => code === locationId?.toUpperCase(),
  );

  if (selectedRegion) {
    return (
      <li
        data-static-list-item="true"
        data-selected-region-code={selectedRegion.code}
        className="hidden sm:block"
      >
        <RegionFilter region={selectedRegion.region} />
      </li>
    );
  }

  return null;
};
