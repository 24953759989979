import { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Documents } from '../components';
import { LegalEntityDocument } from '../types';
import { NotFound } from './NotFound';
import { fetchApi } from '../utils/fetchApi';
import type { IApiData } from '../utils/types';

export const DocumentsView: FunctionComponent = () => {
  const { requestId } = useParams<Record<string, string | undefined>>();
  const [documents, setDocuments] = useState<LegalEntityDocument[] | null>(
    null,
  );

  if (!requestId) {
    return <NotFound />;
  }

  const documentsRequest = {
    url: `/api/documents/get_from_request/${requestId}`,
    method: 'GET',
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetchApi(documentsRequest).then((resp: IApiData) => {
      setDocuments(resp.data ? [resp.data] : []);
    });
  }, [requestId]);

  return <Documents documents={documents} />;
};
