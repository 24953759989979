import { useState } from 'react';
import { AuthlessPlatformNav } from '../../components/base/PlatformNav';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import {
  BatchApiStatusHandler,
  RedactableTextField,
  Section,
  SectionHeading,
} from '../../components';
import { TextField } from '@mosey/components/forms/TextField';
import { useBatchApi } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { Login, Platform } from '../../types';
import { fetchApi } from '../../utils/fetchApi';

interface PlatformAccountLoginProps {
  login: Login;
  platforms?: Platform[];
}

const PlatformAccountLoginError = () => {
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') as string;

  return (
    <AuthlessPlatformNav>
      <Section>
        <SectionHeading text="Something went wrong" />
        <p className="my-8">This page does not exist or is expired.</p>
        {callbackUrl && (
          <Button
            as="a"
            size="large"
            href={callbackUrl}
            rel="noopener noreferrer"
          >
            Return
          </Button>
        )}
      </Section>
    </AuthlessPlatformNav>
  );
};

const PlatformAccountLogin = ({
  login,
  platforms,
}: PlatformAccountLoginProps) => {
  const [isOtpError, setIsOtpError] = useState(false);
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') as string;
  const platformName = platforms?.[0]?.name;
  const buttonText = platformName ? `Return to ${platformName}` : 'Continue';
  const formMethods = useForm({
    defaultValues: {
      username: login.username,
      password: login.password,
    },
  });
  const { loginEntryToken } = useParams<{
    loginEntryToken: string;
  }>();

  const getOtp = async () => {
    try {
      const response = await fetchApi({
        url: `/logins/otp/${loginEntryToken}`,
        method: 'POST',
      });
      return response.data;
    } catch (err) {
      setIsOtpError(true);
      throw err;
    }
  };

  return (
    <AuthlessPlatformNav platform={platforms?.[0]}>
      <FormProvider {...formMethods}>
        <Section>
          <SectionHeading text={login.account_name} />
          <div className="mt-8">
            <TextField
              label="Username"
              name="username"
              isFullWidth
              disabled
              shouldRenderCopyButton
            />
            <RedactableTextField
              label="Password"
              name="password"
              disabled
              shouldRenderCopyButton
            />
          </div>
          {login.is_otp_required && (
            <RedactableTextField
              label="One-Time Passcode"
              name="otp"
              error={
                isOtpError
                  ? {
                      type: 'async',
                      message:
                        'Unable to fetch One-Time Passcode, this page is expired.',
                    }
                  : undefined
              }
              getExpiringValue={getOtp}
              disabled
              shouldRenderCopyButton
            />
          )}
          {login.security_questions &&
            login.security_questions.map(
              ({ question, answer }, questionIndex) => (
                <RedactableTextField
                  key={question}
                  name="answer"
                  label={`Security question #${questionIndex + 1}: ${question}`}
                  defaultValue={answer}
                  disabled
                  shouldRenderCopyButton
                />
              ),
            )}
          <div className="flex gap-4">
            <Button
              as="a"
              href={login.login_url}
              target="_blank"
              rel="noopener noreferrer"
              type="button"
              size="large"
            >
              Visit Website
            </Button>
            {callbackUrl && (
              <Button
                as="a"
                size="large"
                href={callbackUrl}
                rel="noopener noreferrer"
                variant="secondary"
              >
                {buttonText}
              </Button>
            )}
          </div>
        </Section>
      </FormProvider>
    </AuthlessPlatformNav>
  );
};

export const PlatformAccountLoginView = () => {
  const { loginEntryToken } = useParams<{
    loginEntryToken: string;
  }>();

  const batchResponse = useBatchApi([
    {
      url: `/logins/${loginEntryToken}`,
      method: 'POST',
    },
  ]);

  const componentPropsFn = ([
    loginsResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): PlatformAccountLoginProps => {
    return {
      login: loginsResponse.login,
      platforms: loginsResponse.platforms,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={PlatformAccountLogin}
      ErrorComponent={PlatformAccountLoginError}
      componentProps={componentPropsFn}
    />
  );
};
