import { FunctionComponent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { LegalEntityRegion } from '../types';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';

type CertificateOfGoodStandingFormProps = {
  legalEntityRegion: LegalEntityRegion;
};

const CertificateOfGoodStandingForm: FunctionComponent<
  CertificateOfGoodStandingFormProps
> = ({ legalEntityRegion }) => {
  const navigate = useNavigate();

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

  const formMethods = useForm();
  const {
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = formMethods;

  const regionName = legalEntityRegion.region.name;
  const regionCode = legalEntityRegion.region.code.toLowerCase();

  const onSubmit = () => {
    clearErrors();
    fetchApi({
      url: `/api/legal_entity/locations/${regionCode}/certificate_of_good_standing`,
      method: 'POST',
      body: {},
    }).then(({ status, error }: IApiData) => {
      if (status === ApiStatus.Error) {
        const err = {
          type: 'manual',
          message:
            error.detail || `Something went wrong, we're looking into it.`,
        };
        setError('submit', err);
      } else {
        setSubmitSuccess(true);
      }
    });
  };

  return (
    <div className="bg-gray-50 px-10 py-6">
      <div className="flex items-center justify-center border bg-white">
        {submitSuccess ? (
          <div className="flex h-64 flex-col items-center justify-center">
            <div className="size-10 rounded-full bg-green-600 p-1 text-white">
              <CheckIcon />
            </div>
            <div>
              <h3 className="text-2xl font-bold text-green-600">Success</h3>
            </div>
            <p className="ml-1 text-gray-500">
              Your Certificate of Good Standing request is on it&apos;s way!
            </p>
          </div>
        ) : (
          <FormProvider {...formMethods}>
            <form className="my-8 w-96 py-8" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4 border-b pb-4">
                <h2 className="text-2xl font-bold tracking-tight">
                  Request a {regionName} Certificate of Good Standing
                </h2>
              </div>
              <p className="mb-4 text-gray-600">
                You can use Mosey to request a Certificate of Good the state of{' '}
                {regionName}. We&apos;ll notify you once the request is complete
                with a copy of your certificate.
              </p>
              <div className="my-10">
                <Button type="submit" size="large">
                  Submit
                </Button>
                <Button
                  type="button"
                  size="large"
                  variant="secondary"
                  className="ml-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                {errors.submit && (
                  <div className="mt-2 flex items-center text-xs text-red-600">
                    <span>
                      <ErrorIcon className="mr-1 size-4" />
                    </span>
                    <div>
                      <p>{`${
                        errors.submit.message || 'Something went wrong.'
                      }`}</p>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};

type CertificateOfGoodStandingViewProps = {
  legalEntityRegion: LegalEntityRegion;
};

export const CertificateOfGoodStandingView: FunctionComponent<
  CertificateOfGoodStandingViewProps
> = ({ legalEntityRegion }) => {
  return (
    <CertificateOfGoodStandingForm legalEntityRegion={legalEntityRegion} />
  );
};
