import { AgencyAttribute, AgencyDetails, LoginWithAgencies } from '../types';

export function getLoginDataFromAgencies(
  agencies: Array<AgencyDetails>,
): Array<LoginWithAgencies> {
  const logins: Record<string, LoginWithAgencies> = {};
  agencies.forEach((agency) => {
    agency.agency_accounts.forEach((account) => {
      account.logins.forEach((login) => {
        if (!logins[login.public_id]) {
          logins[login.public_id] = {
            ...login,
            agencies: [],
          };
        }
        if (!logins[login.public_id].agencies.find((a) => a.id === agency.id)) {
          logins[login.public_id].agencies.push(agency);
        }
      });
    });
  });
  return Object.values(logins);
}

export function getRegistrationInfoFromAgencies(
  agencies: Array<AgencyDetails>,
): Array<AgencyAttribute> {
  const attributes: Record<string, AgencyAttribute> = {};
  agencies.forEach((agency) => {
    agency.agency_accounts.forEach((account) => {
      account.attributes.forEach((attr) => {
        if (attr.value) {
          attributes[attr.id] = attr;
        }
      });
    });
  });
  return Object.values(attributes);
}
