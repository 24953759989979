import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../utils/hooks';
import { TasksOverviewInterstitial } from './TasksOverviewInterstitial';

interface TasksOverviewSetupGateProps {
  children: React.ReactNode;
}

export const TasksOverviewSetupGate = ({
  children,
}: TasksOverviewSetupGateProps) => {
  const locationId = useTasksOverviewLocationId();
  const { summary } = useTasksOverviewData();
  let isRegionSetup = false;
  let regionSummary = null;

  if (locationId) {
    regionSummary = summary[locationId.toUpperCase()];
    isRegionSetup = regionSummary.is_setup || regionSummary.is_assessment;
  }

  if (locationId && isRegionSetup) {
    return <TasksOverviewInterstitial locationId={locationId} />;
  }

  return children;
};
