import { forwardRef } from 'react';
import { clsx } from 'clsx';

type SectionProps = {
  isFullscreen?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  function Section({ isFullscreen, className, children }, ref) {
    return (
      <section
        ref={ref}
        className={clsx(
          'text-gray-700',
          {
            'px-8 pt-8 pb-6': !isFullscreen,
          },
          className,
        )}
      >
        {children}
      </section>
    );
  },
);

export default Section;
