import { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { loginPlatformUser } from '../utils/auth';
import { NotFound } from './NotFound';
import { LoadingFullScreen } from './Loading';
import { FatalErrorFullScreen } from './FatalError';
import { ApiStatus } from '../utils/types';
import { useSafeLocationUpdate } from '../hooks/useSafeLocationUpdate';

export const PlatformAuth: FunctionComponent = () => {
  const updateLocation = useSafeLocationUpdate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const signupToken = queryParams.get('signup_token') || undefined;
  const destination = queryParams.get('destination') || undefined;

  useEffect(() => {
    if (signupToken) {
      loginPlatformUser(signupToken)
        .then((response) => {
          if (response.status === ApiStatus.Success) {
            setIsLoading(false);
          } else {
            setIsError(true);
          }
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [signupToken]);

  if (isError || !signupToken || !destination) {
    return <FatalErrorFullScreen />;
  }

  if (isLoading) {
    return <LoadingFullScreen />;
  }
  if (
    destination &&
    destination.startsWith('https://') &&
    window.location.host !== new URL(destination).host
  ) {
    updateLocation(destination);
    return <div />;
  }
  return destination ? <Navigate to={destination} replace /> : <NotFound />;
};
