import { Navigate } from 'react-router-dom';

export function withRedirect<P>(
  Component: React.ElementType,
  condition: () => boolean,
  redirectTo: string,
) {
  return function ConditionalComponent(props: P) {
    return condition() ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}
