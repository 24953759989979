import { Fragment } from 'react';
import { Transition, TransitionRootProps } from '@headlessui/react';

interface MenuTransitionProps {
  children: TransitionRootProps['children'];
}

export const MenuTransition = ({ children }: MenuTransitionProps) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  );
};
