import { PolicyMarkdownContent } from '@mosey/components/handbook/types';
import Handlebars from 'handlebars';
import {
  AttributeValues,
  CategoryItem,
  DetailedPolicy,
  Handbook,
  Policy,
  PolicyCategory,
  PolicyContent,
  PolicyGroup,
  PolicyItem,
  PolicyItemStatus,
  PolicyListItem,
  PolicySection,
  PublishableChange,
} from './types';
import { AutomationTypeEnum, Requirement } from '../../types';
import { EmployeeAcknowledgementStatus, components } from '@mosey/api-types';
import { TaskCardStatus } from '@mosey/components/layout/types';

type TemplateValue = string | number | boolean | undefined | null;
const HELPERS = {
  eq: (a: TemplateValue, b: TemplateValue) => a === b,
  neq: (a: TemplateValue, b: TemplateValue) => a !== b,
};

export function policyCompare(a: Policy, b: Policy) {
  const aRegionName = a.policy_scope?.region?.name;
  const bRegionName = b.policy_scope?.region?.name;
  if (aRegionName && !bRegionName) {
    return 1;
  } else if (!aRegionName && bRegionName) {
    return -1;
  } else if (aRegionName && bRegionName && aRegionName !== bRegionName) {
    return aRegionName.localeCompare(bRegionName);
  }
  return a.order - b.order;
}

export function fillContentItem(
  item: string,
  attributeValues: AttributeValues,
) {
  return Handlebars.compile(item, { noEscape: true })(
    { attribute: attributeValues },
    {
      helpers: HELPERS,
    },
  );
}

export function fillPolicyContent(
  policy: Policy,
  attributeValues: AttributeValues,
): PolicyContent {
  return {
    main: policy.content.main.map((item) => ({
      ...item,
      content: fillContentItem(
        (item as PolicyMarkdownContent).content,
        attributeValues,
      ),
    })),
    full_details: policy.content.full_details
      ? fillContentItem(policy.content.full_details, attributeValues)
      : null,
    resources: policy.content.resources,
  };
}

export function getHandbookChanges(draft: Handbook, published?: Handbook) {
  const changes: Array<PublishableChange> = [];
  for (const draftInstance of draft.policy_instances) {
    const publishedInstance = published?.policy_instances.find(
      (pi) => pi.policy_id === draftInstance.policy_id,
    );
    if (!publishedInstance) {
      changes.push({
        type: 'adopted',
        instance: draftInstance,
      });
    } else if (
      publishedInstance.policy_version !== draftInstance.policy_version
    ) {
      changes.push({
        type: 'updated',
        instance: draftInstance,
      });
    }
  }
  return changes;
}

export function isHandbookRequirement(requirement: Requirement) {
  return requirement.tags?.includes(AutomationTypeEnum.Handbook) || false;
}

export function findNextPolicy(
  relativeSlug: string,
  policies: PolicyListItem[],
) {
  const adoptedPolicyIndex = policies.findIndex(
    (policy) => policy.slug === relativeSlug,
  );

  if (adoptedPolicyIndex + 1 < policies.length) {
    return policies[adoptedPolicyIndex + 1];
  } else if (
    policies.length > 1 &&
    adoptedPolicyIndex + 1 === policies.length
  ) {
    return policies[0];
  } else {
    return undefined;
  }
}

export function aggregateAcknowledgements(
  acks: Array<components['schemas']['EmployeeAcknowledgementInfo']>,
) {
  return acks.reduce(
    (agg, curr) => ({
      signed:
        agg.signed +
        (curr.status === EmployeeAcknowledgementStatus.signed ? 1 : 0),
      pending:
        agg.pending +
        (curr.status === EmployeeAcknowledgementStatus.pending ? 1 : 0),
      outdated:
        agg.outdated +
        (curr.status === EmployeeAcknowledgementStatus.outdated ? 1 : 0),
    }),
    {
      signed: 0,
      pending: 0,
      outdated: 0,
    },
  );
}

export function getTaskCardStatusFromAcknowledgement(
  acknowledgement: components['schemas']['EmployeeAcknowledgementInfo'],
): TaskCardStatus {
  switch (acknowledgement.status) {
    case EmployeeAcknowledgementStatus.signed:
      return 'done';
    case EmployeeAcknowledgementStatus.outdated:
      return 'update-available';
    case EmployeeAcknowledgementStatus.pending:
    default:
      return 'todo';
  }
}

export function getLabelFromAcknowledgement(
  acknowledgement: components['schemas']['EmployeeAcknowledgementInfo'],
) {
  switch (acknowledgement.status) {
    case EmployeeAcknowledgementStatus.signed:
      return 'Signed';
    case EmployeeAcknowledgementStatus.outdated:
      return 'Out of date';
    case EmployeeAcknowledgementStatus.pending:
    default:
      return 'Pending';
  }
}

function getPolicyItemFromPolicy(policy: DetailedPolicy): PolicyItem {
  return {
    type: 'policy',
    slug: policy.slug,
    title: policy.title!,
    order: policy.order,
    status: policy.status,
    sectionId: policy.policy_section.id,
    policy,
    regionDescription: policy.policy_scope?.region?.name,
  };
}

function getAggregateStatus(policies: Array<DetailedPolicy>): PolicyItemStatus {
  if (policies.every((policy) => policy.status === 'adoptable')) {
    return 'adoptable';
  } else if (policies.every((policy) => policy.status === 'adopted')) {
    return 'adopted';
  } else if (policies.every((policy) => policy.status === 'published')) {
    return 'published';
  } else if (
    policies.some((policy) => policy.status === 'adoptable') ||
    policies.some((policy) => policy.status === 'updateable')
  ) {
    return 'updateable';
  } else {
    return 'updated';
  }
}

function getBaseCategoryItemFromPolicy(
  policy: Policy,
  category: PolicyCategory,
): CategoryItem {
  return {
    type: 'category',
    slug: category.id,
    title: category.name,
    order: policy.policy_category?.order || policy.order,
    policies: [],
    groups: [],
    sectionId: policy.policy_section.id,
    status: 'adoptable',
  };
}

function getRegionDescription(policies: Array<Policy>) {
  const regionPolicies = policies.filter(
    (policy) => policy.policy_scope?.region,
  );
  if (regionPolicies.length === 0) {
    return undefined;
  } else if (regionPolicies.length === 1) {
    return regionPolicies[0].policy_scope?.region?.name;
  } else {
    return `${regionPolicies.length} locations`;
  }
}

export function categorizeAndGroupPolicies(policies: Array<DetailedPolicy>) {
  const items: Array<PolicyListItem> = [];
  const categoryItems = new Map<string, CategoryItem>();

  policies.forEach((policy) => {
    if (policy.policy_category) {
      let categoryItem = categoryItems.get(policy.policy_category.id);
      if (!categoryItem) {
        categoryItem = getBaseCategoryItemFromPolicy(
          policy,
          policy.policy_category,
        );
        categoryItems.set(policy.policy_category.id, categoryItem);
        items.push(categoryItem);
      }
      categoryItem.policies.push(policy);
    } else {
      items.push(getPolicyItemFromPolicy(policy));
    }
  });

  items.forEach((item) => {
    if (item.type === 'policy') {
      return;
    }
    item.regionDescription = getRegionDescription(item.policies);
    const groups = Array<PolicyGroup>();
    const contentToGroup = new Map<string, PolicyGroup>();
    item.policies.forEach((policy) => {
      if (policy.is_custom_policy) {
        item.customPolicy = policy;
      } else {
        const mainContent = policy.content.main;
        const key = JSON.stringify(mainContent);
        let group = contentToGroup.get(key);
        if (!group) {
          group = {
            policies: [],
            regions: [],
          };
          contentToGroup.set(key, group);
          groups.push(group);
        }
        group.policies.push(policy);
        if (policy.policy_scope?.region?.name) {
          group.regions.push(policy.policy_scope.region.name);
        }
      }
    });
    item.groups = groups.sort((a, b) => b.policies.length - a.policies.length);
    item.groups.forEach((group) => group.regions.sort());
    item.status = getAggregateStatus(item.policies);
  });

  items.sort((a, b) => {
    return a.order - b.order;
  });

  return items;
}

export function getDetailedPolicy(
  policy: Policy,
  draft?: Handbook,
  published?: Handbook,
): DetailedPolicy {
  const draftInstance = draft?.policy_instances.find(
    (pi) => pi.policy_id === policy.id,
  );
  const publishedInstance = published?.policy_instances.find(
    (pi) => pi.policy_id === policy.id,
  );

  let status: PolicyItemStatus = 'published';
  if (!draftInstance) {
    status = 'adoptable';
  } else if (draftInstance.policy_version !== policy?.version) {
    status = 'updateable';
  } else if (
    publishedInstance &&
    draftInstance.policy_version !== publishedInstance.policy_version
  ) {
    status = 'updated';
  } else if (!publishedInstance) {
    status = 'adopted';
  }

  return {
    ...policy,
    status,
    draftInstance,
    publishedInstance,
  };
}

export function aggregatePoliciesBySection(
  policies: Array<DetailedPolicy>,
): Array<{ section: PolicySection; policies: DetailedPolicy[] }> {
  const sectionsById = policies.reduce(
    (all, instance) => {
      if (!all[instance.policy_section.id]) {
        all[instance.policy_section.id] = {
          section: instance.policy_section,
          policies: [],
        };
      }
      all[instance.policy_section.id].policies.push(instance);
      return all;
    },
    {} as Record<
      string,
      { section: PolicySection; policies: DetailedPolicy[] }
    >,
  );
  return Object.values(sectionsById)
    .map((section) => ({
      ...section,
      policies: section.policies.sort(policyCompare),
    }))
    .sort((a, b) => a.section.order - b.section.order);
}
