import { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import {
  BaseTextAreaField,
  BaseTextAreaFieldProps,
} from '@mosey/components/forms/BaseFormField';
import { FormField, FormFieldProps } from '@mosey/components/forms/FormField';

export type TextAreaFieldProps = {
  inputClassName?: string;
  reactFormConfig?: RegisterOptions;
} & BaseTextAreaFieldProps &
  FormFieldProps;

/**
 * Component to be used within a FormProvider to get the form context
 */
export const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({
  name,
  label,
  rightButton,
  error,
  className,
  description,
  descriptionLink,
  inputClassName,
  reactFormConfig,
}) => {
  const { register } = useFormContext();

  return (
    <FormField
      name={name}
      className={className}
      error={error}
      label={label}
      description={description}
      descriptionLink={descriptionLink}
      rightButton={rightButton}
    >
      <BaseTextAreaField
        className={inputClassName}
        error={error}
        id={name}
        {...register(name, { ...reactFormConfig })}
      />
    </FormField>
  );
};
