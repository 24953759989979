import { HTMLProps } from 'react';

type TableCellProps = Omit<HTMLProps<HTMLTableCellElement>, 'className'>;

export const TableCell = ({ children, ...rest }: TableCellProps) => {
  return (
    <td className="py-4 pr-6 first:pl-6" {...rest}>
      {children}
    </td>
  );
};
