import gustoLogo from '../assets/gusto.svg';
import remoteLogo from '../assets/remote.svg';

// TODO add icon to platform entity type
interface PlatformConfig {
  matcher: RegExp;
  name: string;
  iconPath: string;
  height?: string;
}

const PLATFORM_CONFIGS: Array<PlatformConfig> = [
  {
    matcher: /gusto/i,
    iconPath: gustoLogo,
    name: 'Gusto',
    height: 'h-7',
  },
  {
    matcher: /remote/i,
    iconPath: remoteLogo,
    name: 'Remote',
    height: 'h-7',
  },
];

export function getPlatform(platformName: string) {
  return PLATFORM_CONFIGS.find((config) => config.matcher.test(platformName));
}
