import { PlatformTaskResource } from '../../../types';
import { ExtLinkIcon } from '@mosey/components/Icons';

interface TaskDescriptionProps {
  description: string;
  resources: PlatformTaskResource[];
}

export const TaskDescription = ({
  description,
  resources,
}: TaskDescriptionProps) => {
  return (
    <div className="space-y-5">
      <p>{description}</p>

      <ul className="mx-6 list-disc">
        {resources?.map(({ url, name }) => {
          return (
            <li key={`${name}_${url}`}>
              <a
                href={url}
                target="_blank"
                className="text-teal-600 hover:underline"
                rel="noreferrer"
              >
                {name} <ExtLinkIcon className="mb-1 inline-block w-4" />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
