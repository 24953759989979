import { FunctionComponent, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type GateProps = {
  children: ReactNode;
  shouldRenderChildren: boolean;
  redirectUrl: string;
};

export const Gate: FunctionComponent<GateProps> = ({
  children,
  shouldRenderChildren,
  redirectUrl,
}) => {
  if (!shouldRenderChildren) return <Navigate to={redirectUrl} replace />;

  return <>{children}</>;
};
