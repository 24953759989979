import { PolicyOverviewCard } from '../components/PolicyOverviewCard';
import { PolicyListItem, PolicySection } from '../types';
import { SectionTitle } from '../components/SectionTitle';

type OverviewPolicySectionProps = {
  section: PolicySection;
  items: Array<PolicyListItem>;
};

export const OverviewPolicySection = ({
  section,
  items,
}: OverviewPolicySectionProps) => {
  const adoptedItems = items.filter((item) => item.status !== 'adoptable');
  return (
    <div key={section.id} className="flex flex-col gap-4">
      <SectionTitle
        title={section.title}
        to={`/handbook/section/${section.id}/policies`}
      />

      {adoptedItems.length > 0 ? (
        <ul className="w-full space-y-2">
          {adoptedItems.map((item) => {
            return (
              <PolicyOverviewCard key={item.slug} item={item} variant="card" />
            );
          })}
        </ul>
      ) : (
        <p className="text-zinc-700">No policies adopted yet</p>
      )}
    </div>
  );
};
