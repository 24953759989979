import { FunctionComponent, useEffect } from 'react';
import {
  RegisterOptions,
  useFieldArray,
  useFormContext,
  useFormState,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { formSpecArrayFieldToFormFieldProps } from '.';
import { FieldError } from '@mosey/components/forms/FieldError';
import { FormSpecField, Person } from '../../types';
import { Button } from '@mosey/components/buttons/Button';
import { TrashIcon } from '@mosey/components/Icons';
import { Renderer } from './Renderer';

type ArrayFieldProps = {
  name: string;
  label: string;
  description: string;
  error?: FieldErrorT | FieldErrorT[];
  reactFormConfig?: RegisterOptions;
  contents: FormSpecField[];
  addItemText?: string;
  peopleData?: Person[];
  hideAddAndDelete?: boolean;
};

// ArrayField validations have been configured to occur before the react-hook-form
// validation step to avoid race conditions with form submission. This means the
// validations will be run on page load to ensure that the errors are set in time
// to prevent a form submission.
export const ArrayField: FunctionComponent<ArrayFieldProps> = ({
  name,
  label,
  description,
  error,
  contents,
  reactFormConfig,
  addItemText,
  peopleData,
  hideAddAndDelete,
}) => {
  const {
    control,
    watch,
    clearErrors,
    setError,
    getValues,
    trigger: triggerValidation,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const watchFields = watch(name, []);
  const { submitCount } = useFormState({ control });

  useEffect(() => {
    const vals = getValues(name);
    if (reactFormConfig?.required && (!vals || vals.length === 0)) {
      append({}, { shouldFocus: false });
    }
  }, []);

  const runValidateArrayFunctions = () => {
    // remove any existing arrayField errors
    clearErrors(name);
    // run arrayValidation functions
    if (reactFormConfig?.validate) {
      const validationFunctions = Object.values(reactFormConfig.validate);
      for (const validateFn of validationFunctions) {
        const isValidOrErrorMsg = validateFn(watchFields);
        if (typeof isValidOrErrorMsg === 'string') {
          setError(name, {
            type: 'manual',
            message: isValidOrErrorMsg,
          });
          // break early to only apply one error for this field at a time
          break;
        }
      }
    }
  };

  useEffect(() => {
    const validateArrayField = async () => {
      if (reactFormConfig?.validate || reactFormConfig?.required) {
        if (watchFields.length > 0) {
          if (submitCount > 0) {
            const contentsAreValid = await triggerValidation(name);
            if (contentsAreValid) {
              runValidateArrayFunctions();
            }
          } else {
            runValidateArrayFunctions();
          }
        } else if (reactFormConfig?.required && watchFields.length === 0) {
          setError(name, {
            type: 'manual',
            message: 'You must add at least 1',
          });
        }
      }
    };
    validateArrayField();
  }, [
    // translated into json form for deep object comparison
    JSON.stringify(watchFields),
  ]);

  return (
    <div className="mb-8">
      <div>
        <div>
          <h2 className="block pb-2 text-xl font-semibold">{label}</h2>
          <p className="mb-4 text-sm text-gray-600">{description}</p>
          <ul>
            {fields.map((item, index) => {
              return (
                <li
                  key={item.id}
                  className="mb-4 rounded border bg-gray-100 p-4"
                >
                  <Renderer
                    config={formSpecArrayFieldToFormFieldProps(
                      contents,
                      name,
                      index,
                    )}
                    errors={Array.isArray(error) ? error[index] : undefined}
                    peopleData={peopleData}
                  />
                  {!hideAddAndDelete && (
                    <div className="flex flex-row-reverse">
                      <Button
                        type="button"
                        variant="secondary"
                        leftIcon={<TrashIcon className="size-4" />}
                        onClick={() => remove(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {!hideAddAndDelete && (
        <Button type="button" variant="secondary" onClick={() => append({})}>
          {addItemText || 'Add another'}
        </Button>
      )}
      {error && !Array.isArray(error) && (
        <div className="mb-2">
          <FieldError error={error} />
        </div>
      )}
    </div>
  );
};

export default ArrayField;
