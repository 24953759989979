import { FunctionComponent } from 'react';
import { PlatformNav } from './PlatformNav';
import { useUser } from '../../hooks/useUser';
import AppNav from './AppNav';
import { TasksFrameworkNav } from './TasksFrameworkNav';

type NavProps = {
  children?: React.ReactNode;
};

const Nav: FunctionComponent<NavProps> = ({ children }) => {
  const user = useUser();

  if (user.is_platform_user) {
    return <PlatformNav>{children}</PlatformNav>;
  }

  if (user.legal_entity.use_resolvers) {
    return <TasksFrameworkNav>{children}</TasksFrameworkNav>;
  }

  return <AppNav>{children}</AppNav>;
};

export default Nav;
