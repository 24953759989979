import dog500 from '../assets/500-dog.svg';
import {
  Navigate,
  isRouteErrorResponse,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';

export const FatalError = () => {
  const [searchParams] = useSearchParams();
  const error = useRouteError();
  const showError =
    isRouteErrorResponse(error) && typeof error.data?.detail === 'string';

  if (showError && error.data?.detail === 'Inactive user') {
    const plan = searchParams.get('plan');
    return (
      <Navigate
        to={`subscription/pricing${plan ? `?plan=${plan}` : ''}`}
        replace
      />
    );
  }

  if (import.meta.env.DEV) {
    console.error('\n\n<FatalError />\n\n', error);
  }

  return (
    <div className="flex h-full items-center justify-center">
      <img className="w-64" src={dog500} alt="Fatal error" />
      <div className="flex items-center">
        <div className="text-right">
          <h2 className="mt-6 max-w-xs text-4xl font-extrabold text-gray-900">
            {showError ? 'Error' : 'Something went wrong.'}
          </h2>
          <p className="mt-2 text-rose-700">
            {showError ? error.data.detail : "We're looking into it."}
          </p>
        </div>
      </div>
    </div>
  );
};

export const FatalErrorFullScreen = () => {
  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <FatalError />
    </div>
  );
};
