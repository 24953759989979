import { useMemo } from 'react';
import { useNavigation } from 'react-router-dom';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { usePendingSearchParamsValue } from '@mosey/components/hooks/navigation';
import { TaskRef } from '../utils/types';
import { useTasksOverviewData } from '../utils/hooks';
import {
  getTaskDueDate,
  isTaskAutomated,
  isTaskDone,
  isTaskManaged,
  isTaskOverdue,
  isTaskSetupRelated,
  isTaskTodo,
} from '../utils';
import { TasksOverviewZeroState } from './TasksOverviewZeroState';
import { TasksOverviewStatusFilter } from './TasksOverviewStatusFilter';
import { Loading } from '../../Loading';
import { TaskCardStatus } from '@mosey/components/layout/types';

export const TasksOverviewMainTasks = () => {
  const navigation = useNavigation();
  const status = usePendingSearchParamsValue('status');
  const { tasks, summary } = useTasksOverviewData();

  const filteredTasks = useMemo(() => {
    const overdueTasks: TaskRef[] = [];
    const notOverdueTasks: TaskRef[] = [];

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];

      if (task.source.type === 'requirement' && !isTaskSetupRelated(task)) {
        const { is_assessment: isAssessment, is_setup: isSetup } =
          summary[task.source.location.code];

        if (isAssessment || isSetup) {
          continue;
        }

        switch (status) {
          case 'done':
            if (isTaskDone(task) && !isTaskAutomated(task)) {
              notOverdueTasks.push(task);
            }

            break;

          case 'overdue':
            if (isTaskOverdue(task) && !isTaskManaged(task)) {
              overdueTasks.push(task);
            }

            break;

          case 'automated':
            if (isTaskAutomated(task)) {
              notOverdueTasks.push(task);
            }

            break;

          case 'managed':
            if (isTaskManaged(task)) {
              notOverdueTasks.push(task);
            }

            break;

          case 'todo':
          case null:
          default:
            if (isTaskTodo(task) && !isTaskManaged(task)) {
              if (isTaskOverdue(task)) {
                overdueTasks.push(task);
              } else {
                notOverdueTasks.push(task);
              }
            }

            break;
        }
      }
    }

    return [...overdueTasks, ...notOverdueTasks];
  }, [tasks, summary, status]);

  return (
    <section className="@5xl/tasks-overview:h-full">
      <TasksOverviewStatusFilter />

      <div aria-live="polite" className="flex h-full flex-col">
        {navigation.state === 'loading' &&
        navigation.location.pathname === location.pathname ? (
          /**
           * This ensures the spinner is centered in the visible area below the
           * filters. The height is calculated as follows:
           *
           * 100vh - header height - reguion filter height - status filter height
           */
          <div className="max-h-[calc(100vh-64px-90px-40px)] grow">
            <Loading />
          </div>
        ) : filteredTasks.length > 0 ? (
          <ul className="space-y-2">
            {filteredTasks.map((task) => {
              const { id, status, title, source } = task;
              const formattedDueDate = getTaskDueDate(task);
              let calculatedStatus: TaskCardStatus =
                status === 'deferred' ? 'todo' : status;

              if (isTaskManaged(task)) {
                calculatedStatus = 'managed';
              } else if (isTaskOverdue(task)) {
                calculatedStatus = 'overdue';
              } else if (isTaskAutomated(task)) {
                calculatedStatus = 'automated';
              }

              return (
                <li key={id}>
                  <TaskSummaryCard
                    status={calculatedStatus}
                    title={title}
                    size="medium"
                    description={
                      source.type === 'requirement'
                        ? source.description
                        : undefined
                    }
                    reason={
                      formattedDueDate
                        ? { title: formattedDueDate, type: 'due' }
                        : undefined
                    }
                    to={
                      source.type === 'question'
                        ? `/resolver/question/tasks/${id}`
                        : `/requirement/${id}`
                    }
                    region={
                      source.type === 'requirement'
                        ? source.location.name
                        : source.locations[0].name
                    }
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <TasksOverviewZeroState />
        )}
      </div>
    </section>
  );
};
