import { ProgressBar } from './ProgressBar';

type DescriptiveProgressProps = {
  label?: string;
  progress: number;
};

export const DescriptiveProgress = ({
  label = 'Progress',
  progress,
}: DescriptiveProgressProps) => {
  return (
    <div className="-mb-2 flex w-44 flex-col gap-2">
      <p className="flex items-center justify-between text-xs uppercase tracking-wide text-zinc-700">
        <span>{label}</span>
        <span>{progress}%</span>
      </p>
      <ProgressBar
        complete={progress}
        bgColor="bg-teal-350"
        bgHighlightColor="bg-teal-600"
      />
    </div>
  );
};
