import { RefObject, useState } from 'react';

export function useCopy(
  valueToCopy: string | RefObject<HTMLElement>,
  timeoutMs = 1000,
): [() => void, boolean] {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const onCopy = () => {
    if (typeof valueToCopy === 'string') {
      navigator.clipboard.writeText(valueToCopy);
    } else if (valueToCopy && valueToCopy.current) {
      if (window.ClipboardItem) {
        const htmlBlob = new Blob([valueToCopy.current.innerHTML], {
          type: 'text/html',
        });
        const textBlob = new Blob([valueToCopy.current.innerText], {
          type: 'text/plain',
        });
        navigator.clipboard.write([
          new ClipboardItem({
            'text/html': htmlBlob,
            'text/plain': textBlob,
          }),
        ]);
      } else {
        navigator.clipboard.writeText(valueToCopy.current.innerText);
      }
    }
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), timeoutMs);
  };

  return [onCopy, isCopied];
}
