import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { TaskSummaryCard } from '@mosey/components/layout/Card';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { isTaskSetupRelated } from '../../utils';
import { useLegalEntityRegions } from '../../../../hooks/useUser';

export const SetupCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const regions = useLegalEntityRegions();
  const { tasks, summary } = useTasksOverviewData();
  const setupAlerts = locationId
    ? []
    : regions.filter((region) => summary[region.code].is_setup);
  const setupTasks = locationId
    ? tasks.filter(
        (task) =>
          task.source.type === 'requirement' && isTaskSetupRelated(task),
      )
    : [];

  return (
    <TruncatedList
      title="Complete Setup"
      body="Setup tasks are based on your responses to questions"
      itemLabel={locationId ? 'tasks' : 'locations'}
      as="nav"
      aria-live="polite"
      isStacked={locationId !== null}
    >
      {locationId
        ? setupTasks.map(({ id, title, status }) => (
            <li key={id}>
              <TaskSummaryCard
                title={title}
                status={status === 'deferred' ? 'todo' : status}
                variant="nav"
                size="medium"
                to={`/requirement/${id}`}
              />
            </li>
          ))
        : setupAlerts.map((region) => (
            <li key={region.code}>
              <TaskSummaryCard
                title={region.name}
                status="setup-collection"
                variant="collection"
                size="medium"
                to={`/locations/usa/${region.code.toLowerCase()}/setup`}
                aria-label={`${region.name} setup`}
              />
            </li>
          ))}
    </TruncatedList>
  );
};
