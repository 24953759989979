import {
  components,
  TaskStatus,
  TaskType,
  LocationScope,
} from '@mosey/api-types';
import {
  AutomationTypeEnum,
  Fee,
  FormSpec,
  Region,
  RequirementEta,
  Resource,
} from '../../../types';

type TaskContextEmployee = {
  scope: 'employee';
  employee_id: string;
};

type TaskContextLegalEntity = {
  scope: 'legal-entity';
  legal_entity_id: string;
};

export enum TaskCategory {
  HR = 'hr',
  Insurance = 'insurance',
  Payroll = 'payroll',
  Registration = 'registration',
  RegistrationMaintenance = 'registration-maintenance',
  Tax = 'tax',
}

export type LocationRef = {
  id: string;
  code: string;
  name: string;
};

export type TaskPathType =
  | 'link'
  | 'mail'
  | 'phone'
  | 'fax'
  | 'email'
  | 'policy-generation'; // client side path type only

type TaskRemediationPath = {
  type: TaskPathType;
  value: string;
  text: string | null;
};

export type TaskRemediation = {
  id: string;
  title: string;
  description: string;
  order: number;
  paths: TaskRemediationPath[];
};

type TaskManagedProvider = {
  id: string;
  name: string;
};

export { TaskStatus, TaskType, LocationScope };

export type TaskSourceRequirement = {
  type: TaskType.requirement;
  id: string;
  context: TaskContextEmployee | TaskContextLegalEntity;
  requirement_id: string;
  title: string;
  summary: string | null;
  description: string;
  frequency_note: string | null;
  tax_rate_note: string | null;
  general_notes: string[] | null;
  category: TaskCategory;
  is_setup: boolean;
  is_automatable: boolean;
  resources: Resource[];
  tags: AutomationTypeEnum[];
  remediations: TaskRemediation[];
  managed_provider: TaskManagedProvider | null;
  period_start_date: string | null;
  period_end_date: string | null;
  due_date: string | null;
  fee: Fee | null;
  eta: RequirementEta | null;
  location: LocationRef;
};

export type TaskSourceQuestion = {
  type: TaskType.question;
  locations: LocationRef[];
  location_scope: LocationScope;
  description: string | null;
  form: FormSpec;
  answer: string | null;
  is_setup_related: boolean;
  is_editable: boolean;
};

export type UserRef = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
};

export interface BaseTask {
  id: string;
  title: string;
  status: TaskStatus;
}

export interface Task extends BaseTask {
  assigned_user: UserRef | null;
  source: TaskSourceQuestion | TaskSourceRequirement;
}

export interface TaskQuestion extends Task {
  source: TaskSourceQuestion;
}

export interface TaskRequirement extends Task {
  source: TaskSourceRequirement;
}

export type TaskRef = components['schemas']['TaskRef'];
export type TaskQuestionRef = components['schemas']['TaskQuestionRef'];
export type TaskRequirementRef = components['schemas']['TaskRequirementRef'];

export enum AlertType {
  Assessment = 'assessment',
  Setup = 'setup',
  NonSetup = 'non-setup',
}

export interface TasksAlert {
  region: Region | null;
  type: string;
  task_count: number;
}

export interface TaskSummary {
  assessment_alerts: TasksAlert[];
  setup_alerts: TasksAlert[];
  compliance_requirements_alert: TasksAlert | null;
  compliance_questions_alert: TasksAlert | null;
  in_progress_tasks: TaskRef[];
}

export enum ResolverType {
  Assessment = 'assessment',
  Setup = 'setup',
  Requirement = 'requirement',
  Question = 'question',
  Upcoming = 'upcoming',
  Overdue = 'overdue',
  Review = 'review',
}

export type ResolverRouteParams = {
  resolverType?: ResolverType;
  countryId?: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId?: string;
};

export type LocationResolverRouteParams = ResolverRouteParams & {
  countryId: 'usa';
  // TODO: Type this as USStateAbbrev
  locationId: string;
};

export type TaskRouteParams = ResolverRouteParams & {
  taskId: string;
};
