interface ManagedRequirementNoteProps {
  callout: React.ReactNode;
  details: React.ReactNode;
}

export const ManagedRequirementNote = ({
  callout,
  details,
}: ManagedRequirementNoteProps) => {
  return (
    <div className="flex border-x border-t p-4 first:rounded-t-lg last:rounded-b-lg last:border-b">
      <div className="flex w-40 flex-none flex-col items-center border-r pr-4">
        {callout}
      </div>
      <div className="flex items-center justify-center pl-4 text-left text-sm">
        {details}
      </div>
    </div>
  );
};
