import { FunctionComponent } from 'react';

import { clsx } from 'clsx';

type BadgeProps = {
  children: React.ReactNode;
  isActive?: boolean;
  className?: string;
};

export const Badge: FunctionComponent<BadgeProps> = ({
  children,
  isActive = false,
  className,
}) => {
  return (
    <div
      className={clsx(
        'inline-flex w-fit items-center rounded-full bg-sage-300 px-3 py-1.5 text-xs font-bold uppercase leading-5',
        className,
      )}
    >
      <span
        className={clsx('inline-flex size-1.5 rounded-full', {
          'bg-lime-500': isActive,
          'bg-rose-700': !isActive,
        })}
      />
      <span className="ml-2 uppercase">{children}</span>
    </div>
  );
};
