import { clsx } from 'clsx';

import { BaseButton, BaseButtonProps } from './BaseButton';
import { LoadingIcon } from '../Icons';

type IconButtonSizes = 'small' | 'medium' | 'large';
type IconButtonProps<T extends React.ElementType> = {
  icon: React.ReactNode;
  isFullRounded?: boolean;
  isLoading?: boolean;
  size?: IconButtonSizes;
  as?: T;
} & BaseButtonProps &
  React.ComponentPropsWithoutRef<T>;

const classes = {
  size: {
    small: 'p-1.5',
    medium: 'p-2',
    large: 'p-3',
  },
  loadingIcon: {
    size: {
      small: 'w-2 h-2',
      medium: 'w-3 h-3',
      large: 'w-4 h-4',
    },
  },
};

/**
 * Icon button component triggers an action or event-- it's used to render icon only buttons.
 */
export const IconButton = <T extends React.ElementType = 'button'>({
  icon,
  isFullRounded = false,
  isLoading = false,
  size = 'medium',
  className,
  ...props
}: IconButtonProps<T>) => (
  <BaseButton
    className={clsx(
      {
        [classes.size[size]]: !!size,
        'rounded-full': isFullRounded,
        rounded: !isFullRounded,
      },
      className,
    )}
    {...props}
  >
    <span>
      {isLoading ? (
        <LoadingIcon
          className={clsx({
            [classes.loadingIcon.size[size]]: !!isLoading,
          })}
        />
      ) : (
        icon
      )}
    </span>
  </BaseButton>
);
