import { captureException, captureMessage } from '@sentry/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../utils/auth';

export function useSafeLocationUpdate() {
  const navigate = useNavigate();
  return useCallback(
    (path: string, replace = false) => {
      try {
        const url = new URL(path);
        const isInternal = url.origin === window.location.origin;

        if (url.protocol !== 'https:' && !isInternal) {
          return;
        }

        // TODO: use an allow list of valid redirection targets as well
        captureMessage(`Safely redirecting user to: ${url.origin}`, 'debug');

        if (isInternal) {
          navigate(url.pathname + url.search, { replace });
        } else {
          logout().then(() => {
            if (replace) {
              window.location.replace(url);
            } else {
              window.location.href = url.toString();
            }
          });
        }
      } catch (err) {
        captureException(err);
        return;
      }
    },
    [navigate],
  );
}
