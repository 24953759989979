import { clsx } from 'clsx';

import { LoadingIcon } from '../Icons';
import { BaseButton, BaseButtonProps } from './BaseButton';

type ButtonSizes = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type ButtonProps<T extends React.ElementType> = {
  children: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  isFullWidth?: boolean;
  isLoading?: boolean;
  size?: ButtonSizes;
  as?: T;
} & BaseButtonProps &
  React.ComponentPropsWithoutRef<T>;

const classes = {
  size: {
    xsmall: 'py-1.5 px-2 text-xs',
    small: 'py-2 px-3 text-xs',
    medium: 'py-2 px-4 text-sm',
    large: 'py-2 px-4 text-base',
    xlarge: 'py-3 px-5 text-base',
  },
  loadingIcon: {
    size: {
      xsmall: 'w-4 h-4',
      small: 'w-4 h-4',
      medium: 'w-5 h-5',
      large: 'w-6 h-6',
      xlarge: 'w-6 h-6',
    },
  },
};

/**
 * Button component triggers an action or event.
 */
export const Button = <T extends React.ElementType = 'button'>({
  children,
  leftIcon,
  rightIcon,
  className,
  isFullWidth = false,
  isLoading = false,
  size = 'medium',
  ...props
}: ButtonProps<T>) => (
  <BaseButton
    className={clsx(
      'rounded',
      {
        [classes.size[size]]: !!size,
        'w-full': isFullWidth,
      },
      className,
    )}
    isDisabled={isLoading}
    {...props}
  >
    {isLoading ? (
      <LoadingIcon
        className={clsx({
          [classes.loadingIcon.size[size]]: !!isLoading,
        })}
      />
    ) : (
      <>
        {!!leftIcon && <span className="mr-2">{leftIcon}</span>}
        <span>{children}</span>
        {!!rightIcon && <span className="ml-2">{rightIcon}</span>}
      </>
    )}
  </BaseButton>
);
