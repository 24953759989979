import { Fragment } from 'react';
import { Form, Link, useNavigation } from 'react-router-dom';
import { clsx } from 'clsx';
import { TabGroup, TabList, TabPanels } from '@headlessui/react';
import {
  PencilAltIcon,
  CursorClickIcon,
  CheckCircleIcon,
  ClockIcon,
  BookOpenIcon,
  ReceiptTaxIcon,
  LightBulbIcon,
} from '@heroicons/react/outline';
import { RobotIcon } from '@mosey/components/Icons';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { StatusIcon } from '@mosey/components/layout/StatusIcon';
import { SkipButton } from '../common/SkipButton';
import {
  useIsNoticesAndPostersTask,
  useRemediations,
  useTaskActionData,
  useTaskRequirement,
  useTaskUrl,
} from '../utils/hooks';
import { TaskHeader } from '../common/TaskHeader';
import { SubmitButton } from '../common/SubmitButton';
import { Remediation } from '../common/Remediation';
import { TaskActions } from '../common/TaskActions';
import { TaskStatus } from '../utils/types';
import { NextButton } from '../common/NextButton';
import { TaskContainer } from './TaskContainer';
import { TaskTab } from '../common/TaskTab';
import {
  formatDateFromString,
  formatEta,
  formatFee,
} from '../../../utils/format';
import { LearnMoreBlock } from '../common/LearnMoreBlock';
import automatedImage from '../../../assets/automated.svg';
import {
  AutomationStep,
  AutomationStepStart,
  AutomationStepEnd,
} from '../common/AutomationStep';
import { TaskTabPanel } from '../common/TaskTabPanel';
import { NoticesCopyButton } from '../common/NoticesCopyButton';
import { AutomationStepSideEffects } from '../common/AutomationStepSideEffects';

export const TaskRequirement = () => {
  const { state } = useNavigation();
  const taskUrl = useTaskUrl();
  const {
    id,
    status,
    source: {
      description,
      frequency_note: frequencyNote,
      tax_rate_note: taxRateNote,
      general_notes: generalNotes,
      resources,
      is_automatable: isAutomatable,
      managed_provider: managedProvider,
      fee,
      eta,
    },
  } = useTaskRequirement();
  const remediations = useRemediations();
  const isNotices = useIsNoticesAndPostersTask();
  const actionData = useTaskActionData();
  const isTodo = status === TaskStatus.todo;
  const isDone = status === TaskStatus.done;
  const isInProgress = status === TaskStatus.in_progress;
  const formattedFee = fee ? formatFee(fee) : null;
  const formattedEta = eta?.estimation ? formatEta(eta.estimation) : null;
  const isAutomatedOrAutomatable =
    isAutomatable || managedProvider?.name === 'Mosey';

  return (
    <TaskContainer>
      <TaskHeader />
      <BlockAlert
        variant="error"
        message={actionData?.errors?.submit}
        scrollIntoView
        show={!!actionData?.errors?.submit && state === 'idle'}
        aria-live="assertive"
      />

      <p>{description}</p>

      <TabGroup
        key={id}
        defaultIndex={
          isAutomatedOrAutomatable && isDone && !managedProvider && !isNotices
            ? 1
            : 0
        }
        className="flex grow flex-col"
      >
        <TabList className="flex items-center gap-x-8 border-b-[3px] border-gray-100">
          {isAutomatedOrAutomatable && !isNotices && (
            <TaskTab Icon={RobotIcon}>Automate it</TaskTab>
          )}
          <TaskTab Icon={PencilAltIcon}>
            {isAutomatedOrAutomatable && !isNotices
              ? 'Do it manually'
              : 'What you need to do'}
          </TaskTab>
          <TaskTab Icon={LightBulbIcon}>Learn more</TaskTab>
        </TabList>

        <TabPanels as={Fragment}>
          {isAutomatedOrAutomatable && !isNotices && (
            <TaskTabPanel>
              {isInProgress ? (
                <div className="flex items-center gap-x-6 rounded-lg border border-indigo-100 bg-indigo-50 px-10 py-6">
                  <img src={automatedImage} alt="" className="w-36 shrink-0" />

                  <div className="space-y-1 text-indigo-900">
                    <h2 className="text-2xl font-bold">
                      Automation in progress...
                    </h2>
                    {eta?.earliest_date && eta?.latest_date && (
                      <p>
                        This task will be complete{' '}
                        <time dateTime={eta.earliest_date}>
                          {formatDateFromString(eta.earliest_date, {
                            month: 'long',
                            day: 'numeric',
                          })}
                        </time>{' '}
                        –{' '}
                        <time dateTime={eta.latest_date}>
                          {formatDateFromString(eta.latest_date, {
                            month: 'long',
                            day: 'numeric',
                          })}
                        </time>
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <p>
                    <strong>Automation Preview:</strong>
                  </p>

                  <div className="mt-2">
                    <AutomationStepStart Icon={CursorClickIcon}>
                      You automate this task
                    </AutomationStepStart>

                    <ol className="space-y-3">
                      {remediations.map((remediation) => {
                        return (
                          <AutomationStep
                            key={remediation.id}
                            sideEffects={
                              <AutomationStepSideEffects
                                remediation={remediation}
                              />
                            }
                          >
                            {remediation.title}
                          </AutomationStep>
                        );
                      })}
                    </ol>

                    <AutomationStepEnd
                      secondary={
                        formattedEta && (
                          <p className="flex items-center gap-x-2 text-teal-900">
                            <ClockIcon className="size-5 text-teal-800" />
                            {formattedEta.value} {formattedEta.unit}
                          </p>
                        )
                      }
                      Icon={CheckCircleIcon}
                    >
                      <p>
                        Task complete!
                        {formattedFee && (
                          <>
                            {' '}
                            {formattedFee.value}{' '}
                            {formattedFee.value === 'Variable' ? '' : 'in'} fees
                            passed through to your account
                          </>
                        )}
                      </p>
                    </AutomationStepEnd>
                  </div>
                </>
              )}

              <TaskActions>
                {!isTodo ? (
                  <>
                    <NoticesCopyButton />

                    <NextButton defaultToHome>Next</NextButton>
                  </>
                ) : (
                  <>
                    <SkipButton />

                    <NoticesCopyButton />

                    {isAutomatable && !isNotices && (
                      <Button as={Link} to="automation" size="xlarge">
                        Automate
                      </Button>
                    )}
                  </>
                )}
              </TaskActions>
            </TaskTabPanel>
          )}

          <TaskTabPanel>
            <ol className="space-y-7">
              {remediations.map((remediation, index) => {
                return (
                  <li key={remediation.id} className="flex gap-3">
                    {(remediations.length > 1 || isDone) && (
                      <StatusIcon
                        status={status === 'deferred' ? 'todo' : status}
                        size="large"
                      >
                        {!isDone && !isInProgress ? index + 1 : null}
                      </StatusIcon>
                    )}

                    <div className="w-full space-y-2">
                      <div className="font-semibold leading-6">
                        {remediation.title}
                      </div>

                      <Remediation remediation={remediation} />
                    </div>
                  </li>
                );
              })}
            </ol>

            <TaskActions>
              {!isTodo ? (
                <>
                  <NoticesCopyButton />

                  <NextButton defaultToHome>Next</NextButton>
                </>
              ) : (
                <>
                  <SkipButton />

                  <NoticesCopyButton />

                  <Form method="POST" action={taskUrl}>
                    <SubmitButton
                      key={`mark-as-done-button-${id}`}
                      variant="primary"
                      intent="mark-as-done"
                    >
                      Mark Complete
                    </SubmitButton>
                  </Form>
                </>
              )}
            </TaskActions>
          </TaskTabPanel>

          <TaskTabPanel>
            <div
              className={clsx(
                [
                  !!taxRateNote,
                  !!frequencyNote,
                  !!generalNotes,
                  resources.length > 0,
                ].filter(Boolean).length > 1 && 'columns-2 gap-6',
              )}
            >
              {taxRateNote && (
                <LearnMoreBlock Icon={ReceiptTaxIcon} title="Taxes & Fees">
                  <p>{taxRateNote}</p>
                </LearnMoreBlock>
              )}

              {frequencyNote && (
                <LearnMoreBlock Icon={ClockIcon} title="Frequency">
                  <p>{frequencyNote}</p>
                </LearnMoreBlock>
              )}

              {generalNotes && (
                <LearnMoreBlock Icon={LightBulbIcon} title="Insights">
                  <div className="space-y-4">
                    {generalNotes.map((note) => {
                      return <p key={note}>{note}</p>;
                    })}
                  </div>
                </LearnMoreBlock>
              )}

              {resources.length > 0 && (
                <LearnMoreBlock Icon={BookOpenIcon} title="Resources">
                  <ul className="space-y-2 leading-6">
                    {resources.map(({ name, url }) => {
                      return (
                        <li key={`${name}_${url}`}>
                          <TextLink
                            to={url}
                            target="_blank"
                            aria-label={`${name} – opens in new tab`}
                          >
                            {name}
                          </TextLink>
                        </li>
                      );
                    })}
                  </ul>
                </LearnMoreBlock>
              )}
            </div>
          </TaskTabPanel>
        </TabPanels>
      </TabGroup>
    </TaskContainer>
  );
};
