import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import * as types from '../types';
import {
  EmailIcon,
  FaxIcon,
  MailIcon,
  PhoneIcon,
  LinkIcon,
  ExtLinkIcon,
  ClipboardCheckIcon,
} from '@mosey/components/Icons';
import { hostnameFromURL } from '../utils/format';

interface DisplayProps extends React.ComponentProps<React.ElementType> {
  as?: React.ElementType;
  text: string;
  altText?: string;
  leftIcon: React.ReactNode;
  rightIcon?: React.ReactNode;
  hasBorder?: boolean;
}

const Display = ({
  as,
  text,
  altText,
  leftIcon,
  rightIcon = null,
  hasBorder = false,
  ...rest
}: DisplayProps) => {
  const Element = as || 'button';

  return (
    <Element
      className={clsx('flex items-start py-1', {
        'cursor-pointer hover:text-rose-800': !!rest.onClick,
        border: hasBorder,
      })}
      title={altText}
      aria-label={altText}
      {...rest}
    >
      <span className="mr-2 mt-1 size-4 items-center">{leftIcon}</span>
      <p className="text-sm text-teal-600">
        {text}
        {rightIcon && (
          <span className="ml-1 inline-block w-4 align-text-bottom">
            {rightIcon}
          </span>
        )}
      </p>
    </Element>
  );
};

type RemediationDisplayProps = {
  display: types.RemediationDisplay;
  hasBorder?: boolean;
};

export const RemediationDisplay: FunctionComponent<RemediationDisplayProps> = ({
  display,
  hasBorder = false,
}) => {
  switch (display.type) {
    case types.RemediationDisplayType.Default:
      return <></>;
    case types.RemediationDisplayType.Email:
      return (
        <Display
          hasBorder={hasBorder}
          text={display.email}
          leftIcon={<EmailIcon />}
          as="a"
          href={`mailto:${display.email}`}
        />
      );
    case types.RemediationDisplayType.Link:
      return (
        <Display
          hasBorder={hasBorder}
          text={display.text || hostnameFromURL(display.url)}
          leftIcon={<LinkIcon />}
          rightIcon={<ExtLinkIcon />}
          as="a"
          href={display.url}
          target="_blank"
          rel="noreferrer"
        />
      );
    case types.RemediationDisplayType.Mail:
      return (
        <Display
          hasBorder={hasBorder}
          text={display.address}
          leftIcon={<MailIcon />}
          rightIcon={<ClipboardCheckIcon />}
          as="p"
          onClick={() => {
            navigator.clipboard.writeText(display.address);
          }}
        />
      );
    case types.RemediationDisplayType.Fax:
      return (
        <Display
          hasBorder={hasBorder}
          text={display.number}
          leftIcon={<FaxIcon />}
        />
      );
    case types.RemediationDisplayType.Phone:
      return (
        <Display
          hasBorder={hasBorder}
          text={display.phone}
          leftIcon={<PhoneIcon />}
          as="p"
        />
      );
    default:
      return <></>;
  }
};

export default RemediationDisplay;
