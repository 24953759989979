import { useRouteLoaderData } from 'react-router-dom';
import { User } from '../types';
import { useMemo } from 'react';
import { getDecodedAccessToken } from '@mosey/utils/auth';
import { getImpersonatedUser } from '../utils/impersonation';

export const useUser = () => {
  return useRouteLoaderData('user') as User;
};

export const useLegalEntityRegions = () => {
  const {
    legal_entity: { regions },
  } = useUser();

  return useMemo(() => {
    return regions.sort((a, b) => a.name.localeCompare(b.name));
  }, [regions]);
};

export const useImpersonationUser = () => {
  const decodedAccessToken = getDecodedAccessToken();
  return getImpersonatedUser(decodedAccessToken);
};
