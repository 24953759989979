import { Link, LinkProps } from 'react-router-dom';
import { useNextResolverUrl } from '../utils/hooks';
import { Button } from '@mosey/components/buttons/Button';
import { getFinishRedirectUrl } from '../utils/session';

interface NextButtonProps extends Omit<LinkProps, 'to' | 'className'> {
  to?: LinkProps['to'];
  children?: React.ReactNode;
  defaultToHome?: boolean;
}

export const NextButton = ({
  to,
  children,
  defaultToHome,
  ...rest
}: NextButtonProps) => {
  const nextResolverUrl = useNextResolverUrl();
  const url = to || nextResolverUrl;
  const finishRedirectUrl = getFinishRedirectUrl();

  if (url) {
    return (
      <Button as={Link} to={url} size="xlarge" {...rest}>
        {children || 'Continue'}
      </Button>
    );
  }

  if (defaultToHome) {
    return (
      <Button
        as={Link}
        to={finishRedirectUrl ?? '/home'}
        size="xlarge"
        {...rest}
      >
        Finish
      </Button>
    );
  }

  return null;
};
