import { ElementType } from 'react';
import {
  Address,
  FranchiseTaxEstimate,
  ArrayField,
  DateField,
  FormSection,
  NonUSAddress,
  NumberField,
  RadioField,
  SelectField,
  TextAreaField,
} from '.';
import { CheckBoxField } from '@mosey/components/forms/CheckBoxField';
import { TextField } from '@mosey/components/forms/TextField';
import { SelectOptionType } from '../../types';
import {
  EmailPattern,
  NaicsPattern,
  PhonePattern,
  IdentifierPattern,
} from '@mosey/utils/validation/patterns';
import {
  withinNext90Days,
  withinNext60Days,
  withinPastYear,
  lastTenYearsSelectOptions,
  lastXYearsSelectOptions,
  sumEquals,
  relativeYearsSelectionOptions,
} from '../../utils/intake';
import { DynamicSelectField } from './DynamicSelectField';
import { MultiCheckboxField } from './MultiCheckboxField';
import {
  CountrySelectField,
  PersonSelectField,
  UsStateSelectField,
} from './SelectField';
import SupplementalPersonInfo from './SupplementalPersonInfo';

export const ComponentMapping: Record<string, ElementType> = {
  address: Address,
  deFranchiseTaxEstimate: FranchiseTaxEstimate,
  nonUsAddress: NonUSAddress,
  array: ArrayField,
  check: CheckBoxField,
  boolean: CheckBoxField,
  countrySelect: CountrySelectField,
  date: DateField,
  dynamicSelect: DynamicSelectField,
  multiCheckbox: MultiCheckboxField,
  multiSelect: MultiCheckboxField,
  number: NumberField,
  personSelect: PersonSelectField,
  radio: RadioField,
  section: FormSection,
  select: SelectField,
  stateSelect: UsStateSelectField,
  supplementalPersonInfo: SupplementalPersonInfo,
  text: TextField,
  textArea: TextAreaField,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ValidationMapping: Record<string, any> = {
  coDateRange: (v: Date) => {
    const today = new Date();
    return (
      withinPastYear(today, v) ||
      withinNext90Days(today, v) ||
      'Date should be within the last year or the next 90 days'
    );
  },
  okDateRange: (v: Date) => {
    const today = new Date();
    return (
      withinPastYear(today, v) ||
      withinNext60Days(today, v) ||
      'Date should be within the last year or the next 60 days'
    );
  },
  isChecked: (v: boolean) => v,
  withinNext90Days: (v: Date) => {
    const today = new Date();
    return (
      withinNext90Days(today, v) || 'Date should be within the next 90 days'
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scOfficers: (items: any[]) =>
    items.length >= 3 ||
    sumEquals(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      items.map((item: any) => parseInt(item.ownership_percentage) || 0),
      100,
    ) ||
    'Must have 3 owners or ownerships amounting to 100%',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasFilingDirector: (items: any[]) => {
    const filteredItems = items.filter((item) => item.is_filing_director);
    return (
      filteredItems.length === 1 ||
      'Must have one person responsible for filing'
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasOneFilingOfficer(items: any[]) {
    const filingOfficers = items.filter((item) => item.is_filing_officer);
    return (
      filingOfficers.length === 1 ||
      'Must have one officer responsible for filing'
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasOneOfficerForEachRole(items: any[]) {
    const ROLES = {
      president: 'President',
      cfo: 'CFO / Treasurer',
      secretary: 'Secretary',
    };
    for (const role of <(keyof typeof ROLES)[]>Object.keys(ROLES)) {
      const officersWithRole = items.filter((item) => item.roles[role]);
      if (officersWithRole.length !== 1) {
        return `Must have one officer designated as ${ROLES[role]}`;
      }
    }
    return true;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  areDirectorsUnique: (items: any[]) => {
    let isUnique = true;
    const refEmails: { [key: string]: boolean } = {};
    items.forEach((item) => {
      if (item.existing_person) {
        if (item.existing_person in refEmails) {
          isUnique = false;
        } else {
          refEmails[item.existing_person] = true;
        }
      }
    });
    return isUnique || 'All directors must be unique';
  },
};

export const DynamicSelectOptionsMapping: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]) => SelectOptionType[]
> = {
  lastTenYears: () => lastTenYearsSelectOptions(),
  lastXYears: ({ endYear, x }) => lastXYearsSelectOptions(x, endYear),
  relativeYears: ({ start, end }) => relativeYearsSelectionOptions(start, end),
};

export const PatternMapping: Record<
  string,
  { value: RegExp; message: string }
> = {
  phone: PhonePattern,
  email: EmailPattern,
  ssn: IdentifierPattern,
  identifier: IdentifierPattern,
  naics: NaicsPattern,
};
