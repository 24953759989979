import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { Link } from 'react-router-dom';
import welcomeImg from '../../../assets/handbook-welcome.png';

export const Welcome = () => {
  return (
    <section className="flex min-h-[calc(100vh-64px)] flex-col items-center justify-center p-8">
      <img src={welcomeImg} alt="" className="mb-10 w-full max-w-[625px]" />
      <h1 className="text-center text-2xl font-bold">
        Launch your multi-state employee handbook in minutes.
      </h1>
      <p className="mt-1 text-center text-zinc-800">
        Everything you need to make a compliant handbook for all 50 states.
      </p>
      <div className="mt-6 flex items-center gap-4">
        <Button as={Link} to="../form">
          Get started
        </Button>
        <p>
          or{' '}
          <TextLink
            to="https://mosey.com/handbook-beta/"
            target="_blank"
            variant="secondary"
            skipInk={false}
            skipIcon
          >
            learn more
          </TextLink>
        </p>
      </div>
    </section>
  );
};
