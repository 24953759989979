import { Field, Label, Switch as HeadlessSwitch } from '@headlessui/react';

interface SwtichProps {
  label: React.ReactNode;
}

type Props = SwtichProps & React.ComponentProps<typeof HeadlessSwitch>;

export const Switch = ({ label, ...switchProps }: Props) => {
  return (
    <Field as="div" className="flex items-center">
      <Label className="mr-2 cursor-pointer text-xs font-bold text-gray-500">
        {label}
      </Label>

      <HeadlessSwitch
        {...switchProps}
        className="group relative inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-sage-600 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-rose-600 focus-visible:ring-offset-2 data-[checked]:bg-lime-500"
      >
        <span
          aria-hidden="true"
          className="pointer-events-none inline-block size-[20px] translate-x-0 rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-[20px]"
        />
      </HeadlessSwitch>
    </Field>
  );
};
