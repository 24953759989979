import { FunctionComponent } from 'react';

import { Gate } from '../components/base/Gate';
import { useUser } from '../hooks/useUser';

type GateBasicPlanSetupProps = {
  children: React.ReactNode;
};

export const GateBasicPlanSetup: FunctionComponent<GateBasicPlanSetupProps> = ({
  children,
}) => {
  const {
    legal_entity: { should_catch_basic_gate },
  } = useUser();

  return (
    <Gate
      shouldRenderChildren={!should_catch_basic_gate}
      redirectUrl="/onboarding/basic"
    >
      {children}
    </Gate>
  );
};

export default GateBasicPlanSetup;
