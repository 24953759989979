import { useEffect, useState } from 'react';
import {
  ActionFunction,
  Link,
  LoaderFunction,
  useFetcher,
  useLoaderData,
} from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { CircleAddIcon, EditPencilIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { SectionHeading, SelectField } from '../../components';
import { Business, Person } from '../../types';
import { api, apiBatch } from '../../utils/fetchApi';
import { FormError } from '@mosey/components/forms/FormError';

type FormValues = {
  selectedSubmitter: string;
};

export const action: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());
  let response;

  try {
    response = await api({
      url: `/api/legal_entity/submitter/${data.selectedSubmitter}`,
      method: 'PUT',
    });
  } catch (error) {
    return {
      errors: {
        submit: 'Something went wrong, please try again.',
      },
    };
  }

  return {
    selectedSubmitter: (await response.json()).id,
  };
};

export const loader: LoaderFunction = async () => {
  return apiBatch({
    people: {
      url: '/api/legal_entity/people',
      method: 'GET',
    },
    businesses: {
      url: '/api/legal_entity/businesses',
      method: 'GET',
    },
    submitter: {
      url: '/api/legal_entity/submitter',
      method: 'GET',
      onError: (response) => {
        if (response.status === 404) {
          return null;
        }

        throw response;
      },
    },
  });
};

export const Component = () => {
  const { people, businesses, submitter } = useLoaderData() as {
    people: Person[];
    businesses: Business[];
    submitter: Person | null;
  };
  const fetcher = useFetcher<{
    errors?: Record<string, string>;
    selectedSubmitter: string;
  }>();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const formMethods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      selectedSubmitter: submitter?.id,
    },
  });

  const {
    reset,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    reset({ selectedSubmitter: submitter?.id });
  }, [submitter, reset]);

  useEffect(() => {
    if (fetcher?.data?.selectedSubmitter) {
      setSubmitSuccess(true);
      setTimeout(() => setSubmitSuccess(false), 2000);
    }
  }, [fetcher?.data?.selectedSubmitter]);

  const onCancel = () => {
    reset();
  };

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <SectionHeading className="flex-1 pb-4" text="Officers and Owners" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <h3 className="text-lg font-medium">Submitter</h3>

            <div className="mt-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
              <div className="flex space-x-2">
                <p className="flex-1 text-sm text-gray-700">
                  We use the selected submitter as the company&apos;s contact
                  and authorized person when submitting information to
                  government agencies.
                </p>
              </div>

              <div className="-mb-8 mt-4">
                <FormProvider {...formMethods}>
                  <fetcher.Form
                    method="PUT"
                    id="officers-and-owners-submitter-form"
                  >
                    <SelectField
                      options={people.map((person) => ({
                        name: `${person.first_name} ${person.last_name}`,
                        value: person.id,
                      }))}
                      reactFormConfig={{
                        required: 'This is required',
                      }}
                      label="Selected Submitter"
                      name="selectedSubmitter"
                    />
                  </fetcher.Form>
                </FormProvider>
              </div>
            </div>
            {fetcher?.data?.errors?.submit && <FormError margin="my-4" />}
            {submitSuccess && (
              <div>
                <div className="my-4 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                  Your selection has been updated!
                </div>
              </div>
            )}
          </section>

          <hr className="mb-6 mt-9" />

          <section className="mb-9">
            <div className="my-6 flex items-center justify-between">
              <h3 className="text-lg font-medium">Officers and Owners</h3>

              <Button
                as={Link}
                size="small"
                variant="secondary"
                leftIcon={<CircleAddIcon className="size-4 text-sage-600" />}
                to={'/settings/officers-and-owners/create'}
              >
                Add New
              </Button>
            </div>

            <table className="w-full border" cellPadding="0">
              <thead>
                <tr className="border-b text-sm uppercase text-gray-500">
                  <th className="px-6 py-3 text-left font-medium tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left font-medium tracking-wider">
                    Title
                  </th>
                  <th className="px-6 py-3 text-center font-medium tracking-wider">
                    Edit
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y">
                {people.length > 0 || businesses.length > 0 ? (
                  <>
                    {people.length > 0 &&
                      people.map((person) => {
                        return (
                          <tr key={person.id}>
                            <td>
                              <div className="w-full">
                                <p className="p-6 font-medium text-gray-900">
                                  {`${person.first_name} ${person.last_name}`}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="w-full">
                                <p className="p-6">{person.title}</p>
                              </div>
                            </td>
                            <td>
                              <div className="flex w-full justify-center">
                                <Link to={`/settings/people/edit/${person.id}`}>
                                  <EditPencilIcon className="size-6 text-gray-400" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {businesses.length > 0 &&
                      businesses.map((business) => {
                        return (
                          <tr key={business.id}>
                            <td>
                              <div className="w-full">
                                <p className="p-6 font-medium text-gray-900">
                                  {business.name}
                                </p>
                              </div>
                            </td>
                            <td />
                            <td>
                              <div className="flex w-full justify-center">
                                <Link
                                  to={`/settings/businesses/edit/${business.id}`}
                                >
                                  <EditPencilIcon className="size-6 text-gray-400" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div className="flex h-24 items-center justify-items-center">
                        <div className="m-auto">
                          <p className="italic text-gray-400">
                            No officers or owners found.
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      {isDirty && (
        <div className="sticky bottom-0 flex h-[72px] items-center justify-end space-x-2 border-t border-gray-200 bg-sage-50 px-6 py-4">
          <Button type="button" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>

          <div className="w-28">
            <Button
              type="submit"
              form="officers-and-owners-submitter-form"
              isFullWidth
            >
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
