import { Address } from '../../types';

interface AddressDisplayProps {
  address: Address;
}

export const AddressDisplay = ({ address }: AddressDisplayProps) => {
  return (
    <>
      <p>{address.address_line_1}</p>
      <p>{address.address_line_2}</p>
      <p>
        {address.city} {address.state} {address.postal_code}
      </p>
      <p>{address.country}</p>
    </>
  );
};
