import { FunctionComponent, useMemo, useState } from 'react';
import { Login } from '../../../types';
import { CredentialsSteps } from './CredentialsSteps';
import { GuidedCredentialsStep, GuidedStepContentProps } from './types';
import { VisitWebsiteStep } from './VisitWebsiteStep';
import { UsernameStep } from './UsernameStep';
import { PasswordStep } from './PasswordStep';
import { EmailOtpStep } from './EmailOtpStep';
import { OtpStep } from './OtpStep';
import { CompleteStep } from './CompleteStep';

function getStepsFromLogin(login: Login) {
  const steps = [
    GuidedCredentialsStep.VISIT_SITE,
    GuidedCredentialsStep.USERNAME,
    GuidedCredentialsStep.PASSWORD,
  ];
  if (login.is_otp_required) {
    steps.push(GuidedCredentialsStep.OTP);
  } else if (login.is_email_otp) {
    steps.push(GuidedCredentialsStep.EMAIL_OTP);
  }
  steps.push(GuidedCredentialsStep.COMPLETE);
  return steps;
}

const STEP_COMPONENTS: Record<
  string,
  FunctionComponent<GuidedStepContentProps>
> = {
  [GuidedCredentialsStep.VISIT_SITE]: VisitWebsiteStep,
  [GuidedCredentialsStep.USERNAME]: UsernameStep,
  [GuidedCredentialsStep.PASSWORD]: PasswordStep,
  [GuidedCredentialsStep.EMAIL_OTP]: EmailOtpStep,
  [GuidedCredentialsStep.OTP]: OtpStep,
  [GuidedCredentialsStep.COMPLETE]: CompleteStep,
};

type GuidedCredentialsProps = {
  login: Login;
};

export const GuidedCredentials = ({ login }: GuidedCredentialsProps) => {
  const steps = useMemo(() => getStepsFromLogin(login), [login]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];

  const onStepIndexChange = (newIndex: number) =>
    setCurrentStepIndex(Math.min(newIndex, steps.length - 1));

  const ContentComponent = STEP_COMPONENTS[currentStep];

  return (
    <div className="flex">
      <div className="mr-16 shrink-0 rounded border border-gray-300 bg-sage-50 px-8 pb-16 pt-8 md:pr-20">
        <CredentialsSteps
          steps={steps}
          currentStepIndex={currentStepIndex}
          onStepIndexChange={onStepIndexChange}
        />
      </div>
      <div>
        <ContentComponent
          login={login}
          nextStep={() => onStepIndexChange(currentStepIndex + 1)}
          reset={() => onStepIndexChange(0)}
        />
      </div>
    </div>
  );
};
