export const isValidDate = (value: unknown): value is Date => {
  return value instanceof Date && value.toString() !== 'Invalid Date';
};

export const convertDateToUTC = (date: Date): Date => {
  // for users in timezones behind GMT (e.g. GMT-7:00 for SF), this will create a Date in the future
  // for users in timezones ahead GMT (e.g. GMT+1:00 for London), this will create a Date in the past
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

// Given a date, convert the Date to UTC (see above) and then zero the hours relative to UTC
// Returns a new Date in the user's local timezone.
// ex: User in GMT+7:00: input 05/18/2023 at 19:00 (7PM) -> convert to UTC: 05/19/2023 at 02:00 (2AM)
// -> zeroing hours will be 05/19/2023 at 00:00 -> in user's TZ of GMT+7:00: 05/18/2023 at 17:00 (5PM)
export const toUTCMidnight = (d: Date): Date => {
  return new Date(convertDateToUTC(d).setUTCHours(0, 0, 0, 0));
};

// Get midnight of the current day in the user's local timezone
export const getLocalTodayMidnight = (): Date => {
  return new Date(new Date().setHours(0, 0, 0, 0));
};

// Given a string of format YYYY-MM-DD, return a Date object representing the current day at midnight
// in the user's local timezone
export const convertDateStringToLocalDate = (s: string): Date => {
  return new Date(`${s}T00:00`);
};

// Return the month name from the month number (not by index)
export const getMonthNameFromNumber = (n: number): string =>
  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][n - 1];

/*
Returns a date range of three months (previous, current, next) based
on `d`.
*/
export const threeMonthPeriod = (
  d: Date,
): { startDate: Date; endDate: Date } => {
  const month = d.getUTCMonth();
  const year = d.getUTCFullYear();

  const prevMonth = month === 0 ? 11 : month - 1;
  const prevMonthYear = month === 0 ? year - 1 : year;

  const nextMonth = month === 11 ? 0 : month + 1;
  const nextMonthYear = month === 11 ? year + 1 : year;

  const followingMonth = nextMonth === 11 ? 0 : nextMonth + 1;
  const followingMonthYear =
    nextMonth === 11 ? nextMonthYear + 1 : nextMonthYear;

  const startDate = new Date(prevMonthYear, prevMonth, 1);
  // NOTE: putting a zero for the date will be result in the last day
  // of the _previous_ month
  const endDate = new Date(followingMonthYear, followingMonth, 0);

  return { startDate, endDate };
};

export const nextMonth = (dateUTC: Date): Date => {
  const month = dateUTC.getUTCMonth();
  const year = dateUTC.getUTCFullYear();
  const day = dateUTC.getUTCDate();
  const hours = dateUTC.getUTCHours();
  const minutes = dateUTC.getUTCMinutes();
  const seconds = dateUTC.getUTCSeconds();

  const nextCalMonth = month === 11 ? 0 : month + 1;
  const nextMonthYear = month === 11 ? year + 1 : year;

  return new Date(
    Date.UTC(nextMonthYear, nextCalMonth, day, hours, minutes, seconds),
  );
};

export const prevMonth = (dateUTC: Date): Date => {
  const month = dateUTC.getUTCMonth();
  const year = dateUTC.getUTCFullYear();
  const day = dateUTC.getUTCDate();
  const hours = dateUTC.getUTCHours();
  const minutes = dateUTC.getUTCMinutes();
  const seconds = dateUTC.getUTCSeconds();

  const prevCalMonth = month === 0 ? 11 : month - 1;
  const prevMonthYear = month === 0 ? year - 1 : year;

  return new Date(
    Date.UTC(prevMonthYear, prevCalMonth, day, hours, minutes, seconds),
  );
};

export const lastDayOfMonth = (dateUTC: Date): Date => {
  const nextMonthDate = nextMonth(dateUTC);
  const year = nextMonthDate.getUTCFullYear();
  const month = nextMonthDate.getUTCMonth();
  const hours = dateUTC.getUTCHours();
  const minutes = dateUTC.getUTCMinutes();
  const seconds = dateUTC.getUTCSeconds();

  return new Date(Date.UTC(year, month, 0, hours, minutes, seconds));
};

export const firstDayOfMonth = (dateUTC: Date): Date => {
  const year = dateUTC.getUTCFullYear();
  const month = dateUTC.getUTCMonth();
  const hours = dateUTC.getUTCHours();
  const minutes = dateUTC.getUTCMinutes();
  const seconds = dateUTC.getUTCSeconds();

  return new Date(Date.UTC(year, month, 1, hours, minutes, seconds));
};

export const withinDateRange = (
  startDate: Date,
  endDate: Date,
  date: Date,
): boolean => {
  const utcStartDate = convertDateToUTC(startDate);
  const utcEndDate = convertDateToUTC(endDate);
  const utcDate = convertDateToUTC(date);

  return utcDate <= utcEndDate && utcDate >= utcStartDate;
};

export const getFormattedDate = (date: Date) => {
  const month = `0${(date.getUTCMonth() + 1).toString()}`.slice(-2);
  const day = `0${date.getUTCDate().toString()}`.slice(-2);
  const year = date.getUTCFullYear().toString();

  return `${month}/${day}/${year}`;
};

export const doesDateMatch = (d1: Date, d2: Date): boolean => {
  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth() &&
    d1.getUTCDate() === d2.getUTCDate()
  );
};
