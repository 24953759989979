import {
  redirect,
  useSearchParams,
  createSearchParams,
  LoaderFunction,
  useLoaderData,
  ActionFunction,
  useFetcher,
} from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isAuthenticated } from '@mosey/utils/auth';
import { FilledLockIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { signUp } from '../utils/auth';
import { acceptInvite } from '../utils/invite';
import { api } from '../utils/fetchApi';
import { VerifyInvite, Referral, ValidationErrorDetail } from '../types';
import { TextField } from '@mosey/components/forms/TextField';
import logo from '../assets/logo.svg';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { clsx } from 'clsx';

const errorIcon = <XIcon className="mt-0.5 size-5 shrink-0" />;
const successIcon = <CheckIcon className="mt-0.5 size-5 shrink-0" />;

type SignUpIFormValues = {
  email: string;
  password: string;
  companyName: string;
};

type LoaderData = null | VerifyInvite | Referral;

export const loader: LoaderFunction = async ({
  request,
}): Promise<LoaderData | Response> => {
  const url = new URL(request.url);
  const inviteToken = url.searchParams.get('invite');
  const referralCode = url.searchParams.get('code');

  if (isAuthenticated()) {
    return redirect('/');
  }

  if (inviteToken) {
    return api({
      url: `/api/invites/verify`,
      method: 'POST',
      body: { token: inviteToken },
    });
  } else if (referralCode) {
    return api({
      url: `/api/referrals/verify`,
      method: 'POST',
      body: { code: referralCode },
    });
  }

  return null;
};

export const action: ActionFunction = async ({ request }) => {
  const url = new URL(request.url);
  const inviteToken = url.searchParams.get('invite');
  const referralCode = url.searchParams.get('code');
  const selectedPlan = url.searchParams.get('plan');
  const { companyName, email, password } = await request.json();

  try {
    if (inviteToken) {
      await acceptInvite(inviteToken, password);
      return redirect('/home');
    } else if (referralCode) {
      await signUp(companyName, email, password, referralCode);
    } else {
      await signUp(companyName, email, password, null);
    }

    // Only create url search param if selectedPlan is truthy
    const selectedPlanParam =
      selectedPlan &&
      createSearchParams({
        plan: selectedPlan,
      });
    const redirectUrl = selectedPlanParam
      ? `/subscription/pricing?${selectedPlanParam.toString()}`
      : '/subscription/pricing';

    return redirect(redirectUrl);
  } catch (err) {
    if (err instanceof Error) {
      // Handle errors thrown from frontend
      return err.message;
    } else if (err === 'Invalid username domain') {
      return 'Please ensure you provided a valid company email address';
    } else if (err === 'Account already exists') {
      return 'An account already exists for this email address.';
    } else if (typeof err === 'string') {
      return err;
    } else {
      // Handle errors thrown from backend
      // Only show the first validation error from the API
      const valError = (err as ValidationErrorDetail)[0];
      if (valError.loc.indexOf('username') > -1) {
        return 'Please ensure you provided a valid company email address';
      } else if (valError.loc.indexOf('password') > -1) {
        return 'Please ensure your password has at least 11 characters';
      } else {
        return 'Please check the fields below to make sure they are valid';
      }
    }
  }
};

export const Component = () => {
  const loaderData = useLoaderData() as LoaderData;
  let invite: VerifyInvite | undefined = undefined;
  let referral: Referral | undefined = undefined;

  if (loaderData) {
    if ('legal_entity_name' in loaderData) {
      invite = loaderData;
    } else {
      referral = loaderData;
    }
  }

  const fetcher = useFetcher();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('invite');
  const email = searchParams.get('email');
  const companyName = searchParams.get('company_name');

  const formMethods = useForm<SignUpIFormValues>({
    defaultValues: {
      email: email || '',
      companyName: companyName || '',
    },
  });
  const {
    watch,
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const watchPassword = watch('password');

  const hasUpperCase = (str: string | null): boolean => {
    return str != null && /[A-Z]/.test(str);
  };
  const hasLowerCase = (str: string | null): boolean =>
    str != null && /[a-z]/.test(str);
  const hasNumber = (str: string | null): boolean =>
    str != null && /[0-9]/.test(str);
  const hasSpecialCharacter = (str: string | null): boolean =>
    str != null && /[^a-zA-Z0-9]/.test(str);
  const hasMinimumLength = (str: string | null): boolean =>
    str != null && str.length >= 11;

  const passwordUpperCaseMessage = 'One uppercase character';
  const passwordLowerCaseMessage = 'One lowercase character';
  const passwordNumberMessage = 'One number';
  const passwordSpecialCharacterMessage = 'One special character';
  const passwordMinimumLengthMessage = '11 character minimum';

  const passwordHasLowerCase: boolean = hasLowerCase(watchPassword);
  const passwordHasUpperCase: boolean = hasUpperCase(watchPassword);
  const passwordHasNumber: boolean = hasNumber(watchPassword);
  const passwordHasSpecialCharacters: boolean =
    hasSpecialCharacter(watchPassword);
  const passwordHasMinimumLength: boolean = hasMinimumLength(watchPassword);

  const onSubmit = async (data: SignUpIFormValues) => {
    const { companyName, email, password } = data;

    fetcher.submit(
      { companyName, email, password },
      { method: 'POST', encType: 'application/json' },
    );
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="mb-10 w-full max-w-md pb-4">
        <img src={logo} className="w-20" alt="Mosey" />
      </div>
      <div className="w-full max-w-md space-y-8">
        <div>
          <h1 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-700">
            Create your account
            {invite && ` and join ${invite.legal_entity_name}`}
          </h1>
          {referral && (
            <p className="mt-2 text-gray-600">
              {referral.partner_display_name && (
                <>
                  {referral.partner_display_name} and Mosey have joined forces
                  to help you manage employment and tax compliance.
                </>
              )}
              {referral.discount_percent && referral.discount_percent > 0 && (
                <span className="font-semibold text-lime-500">
                  {' '}
                  You save {referral.discount_percent}%
                  <span className="font-normal text-gray-600">.</span>
                </span>
              )}
            </p>
          )}
          {!referral && (
            <p className="mt-2 text-gray-600">
              <span className="mr-1">Or</span>
              <TextLink to="/login">log in</TextLink>
            </p>
          )}
        </div>
        <FormProvider {...formMethods}>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <BlockAlert
              show={!!fetcher.data}
              variant="error"
              message={fetcher.data}
            />
            <input type="hidden" name="remember" value="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              {!inviteToken && (
                <TextField
                  name="companyName"
                  inputClassName="relative focus:z-10 rounded-b-none"
                  hasMargin={false}
                  autoComplete="text"
                  placeholder="Company name"
                />
              )}
              {(!inviteToken || invite?.ask_for_email) && (
                <TextField
                  name="email"
                  inputClassName="relative focus:z-10 rounded-t-none rounded-b-none"
                  type="email"
                  autoComplete="email"
                  hasMargin={false}
                  placeholder="Email address"
                />
              )}
              <TextField
                name="password"
                inputClassName={`relative focus:z-10 ${
                  (!inviteToken || invite?.ask_for_email) && 'rounded-t-none'
                }`}
                type="password"
                autoComplete="current-password"
                hasMargin={false}
                placeholder="Set a password"
                aria-invalid={errors.password ? 'true' : 'false'}
                aria-errormessage="password-validation-error-message"
              />
            </div>
            <div
              className="flex flex-col gap-y-1"
              id="password-validation-error-message"
            >
              {watchPassword && (
                <>
                  <span
                    className={clsx(
                      'flex w-full gap-x-2 rounded-sm',
                      passwordHasLowerCase ? 'text-teal-800' : 'text-rose-800',
                    )}
                  >
                    {passwordHasLowerCase ? successIcon : errorIcon}
                    <span
                      aria-label={
                        (passwordHasLowerCase
                          ? 'Satisfies '
                          : 'Does not satisfy ') + passwordLowerCaseMessage
                      }
                    >
                      {passwordLowerCaseMessage}
                    </span>
                  </span>
                  <span
                    className={clsx(
                      'flex w-full gap-x-2 rounded-sm',
                      passwordHasUpperCase ? 'text-teal-800' : 'text-rose-800',
                    )}
                  >
                    {passwordHasUpperCase ? successIcon : errorIcon}
                    <span
                      aria-label={
                        (passwordHasUpperCase
                          ? 'Satisfies '
                          : 'Does not satisfy ') + passwordUpperCaseMessage
                      }
                    >
                      {passwordUpperCaseMessage}
                    </span>
                  </span>
                  <span
                    className={clsx(
                      'flex w-full gap-x-2 rounded-sm',
                      passwordHasNumber ? 'text-teal-800' : 'text-rose-800',
                    )}
                  >
                    {passwordHasNumber ? successIcon : errorIcon}
                    <span
                      aria-label={
                        (passwordHasNumber
                          ? 'Satisfies '
                          : 'Does not satisfy ') + passwordNumberMessage
                      }
                    >
                      {passwordNumberMessage}
                    </span>
                  </span>

                  <span
                    className={clsx(
                      'flex w-full gap-x-2 rounded-sm',
                      passwordHasSpecialCharacters
                        ? 'text-teal-800'
                        : 'text-rose-800',
                    )}
                  >
                    {passwordHasSpecialCharacters ? successIcon : errorIcon}
                    <span
                      aria-label={
                        (passwordHasSpecialCharacters
                          ? 'Satisfies '
                          : 'Does not satisfy ') +
                        passwordSpecialCharacterMessage
                      }
                    >
                      {passwordSpecialCharacterMessage}
                    </span>
                  </span>

                  <span
                    className={clsx(
                      'flex w-full gap-x-2 rounded-sm',
                      passwordHasMinimumLength
                        ? 'text-teal-800'
                        : 'text-rose-800',
                    )}
                  >
                    {passwordHasMinimumLength ? successIcon : errorIcon}
                    <span
                      aria-label={
                        (passwordHasMinimumLength
                          ? 'Satisfies '
                          : 'Does not satisfy ') + passwordMinimumLengthMessage
                      }
                    >
                      {passwordMinimumLengthMessage}
                    </span>
                  </span>
                </>
              )}
            </div>
            <div>
              <Button
                type="submit"
                size="large"
                leftIcon={<FilledLockIcon className="size-5" />}
                isFullWidth
                isLoading={fetcher.state !== 'idle'}
                disabled={fetcher.state !== 'idle'}
              >
                Submit
              </Button>
              <div className="mt-4 text-sm text-gray-500">
                By creating an account, you are agreeing to the Mosey{' '}
                <TextLink
                  to="https://mosey.com/terms-of-service"
                  target="_blank"
                >
                  terms of service
                </TextLink>{' '}
                and{' '}
                <TextLink to="https://mosey.com/privacy-policy" target="_blank">
                  privacy policy
                </TextLink>
                .
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
