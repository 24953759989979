import {
  FunctionComponent,
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  FocusEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@mosey/components/Icons';
import { SearchResult } from '../../types';
import { requirementPath } from '../../utils/paths';
import { fetchApi } from '../../utils/fetchApi';

export const Search: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const handleOnFocus = () => {
    setIsActive(true);
  };

  const handleResultClick = (e: MouseEvent) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('data-url');
    if (url) {
      setIsActive(false);
      navigate(url);
    }
  };

  useEffect(() => {
    // Don't search if there is no input. This also prevents making a
    // search request on page load.
    if (searchInput === '') {
      return;
    }

    fetchApi({
      url: `/api/search`,
      method: 'POST',
      body: { query: searchInput },
    }).then(({ data, error }) => {
      if (error) {
        console.log(error);
        return;
      }
      setSearchResults(data);
    });
  }, [searchInput]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const handleBlurSearchInput = (e: FocusEvent) => {
    e.preventDefault();
    // Check which element caused the blur by waiting for the next
    // event and checking the active element
    setTimeout(() => {
      // Don't blur if the user clicks a search result
      if (document.activeElement?.getAttribute('data-url')) {
        return;
      }
      setIsActive(false);
    }, 0);
  };

  return (
    <>
      <label
        htmlFor="search"
        className="relative block text-gray-400 focus-within:text-gray-600"
      >
        <SearchIcon className="pointer-events-none absolute left-4 top-1/2 size-4 -translate-y-1/2" />
        <input
          id="searchInput"
          type="text"
          name="search"
          placeholder="Search"
          className="w-full rounded border border-gray-300 pl-10 transition-shadow duration-150 focus:border-teal-500 focus:ring-teal-500"
          onChange={handleSearchInput}
          onBlur={handleBlurSearchInput}
          onFocus={handleOnFocus}
        />
      </label>
      {isActive && (
        <div>
          {/* Note: use the ref to auto focus the input element */}
          {searchResults.length > 0 && (
            <div
              className="origin-top-center absolute z-50 mt-4 max-h-96 w-96 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              role="menu"
            >
              <div className="py-1" role="none">
                {searchResults.map((r, idx) => (
                  <div
                    key={`${r.requirement_data_id}`}
                    tabIndex={idx}
                    role="menuitem"
                    className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    data-url={requirementPath(r.requirement_data_id)}
                    onClick={handleResultClick}
                  >
                    <p className="flex-1">{r.term}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Search;
