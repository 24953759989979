import { RequirementStatusMessage } from './RequirementStatusMessage';
import { CheckIcon } from '@heroicons/react/solid';
import { Requirement } from '../../types';
import { HandbookRequirementComplete } from './HandbookRequirementComplete';
import { isHandbookRequirement } from '../../views/handbook/utils';
import { USStateName } from '@mosey/utils/constants/us-states';

type RequirementCompleteProps = {
  requirement: Requirement;
};

export const RequirementComplete = ({
  requirement,
}: RequirementCompleteProps) => {
  if (requirement.is_managed && isHandbookRequirement(requirement)) {
    return (
      <HandbookRequirementComplete
        policyInstances={requirement.policy_instances || []}
        region={requirement.region[0] as keyof typeof USStateName}
      />
    );
  }

  return (
    <RequirementStatusMessage
      icon={<CheckIcon />}
      title="All done!"
      description={`${requirement.title} is complete.`}
    />
  );
};
