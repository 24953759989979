import { SparkleIcon } from '@mosey/components/Icons';
import { PlatformTaskStatus, PlatformTaskCriteria } from '../../../types';

interface TaskTriggeredByProps {
  criteriaMet: PlatformTaskCriteria[];
  status: PlatformTaskStatus;
}

export const TaskTriggeredBy = ({
  criteriaMet,
  status,
}: TaskTriggeredByProps) => {
  if (criteriaMet.length > 0 && status === PlatformTaskStatus.Todo) {
    return (
      <div className="bd-sage-400 flex gap-x-2 rounded border bg-sage-300 p-4 text-sm">
        <div aria-hidden="true" className="text-3xl">
          <SparkleIcon className="mt-1 size-5 text-rose-700" />
        </div>
        <div>
          <p className="font-bold">You&rsquo;re seeing this task because:</p>
          <ul className="ml-3 list-disc">
            {criteriaMet.map(({ key, reason }) => {
              return <li key={key}>{reason}</li>;
            })}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};
