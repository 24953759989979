import { FunctionComponent, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isAuthenticated } from '@mosey/utils/auth';
import { FilledLockIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { TextField } from '@mosey/components/forms/TextField';
import { requestPasswordReset } from '../utils/auth';
import palmTreeImage from '../assets/palm-tree.svg';

type PasswordResetRequestIFormValues = {
  email: string;
};

export const PasswordResetRequest: FunctionComponent = () => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const formMethods = useForm<PasswordResetRequestIFormValues>();
  const { handleSubmit } = formMethods;

  const onSubmit = async (data: PasswordResetRequestIFormValues) => {
    const { email } = data;

    if (email === '') {
      setError('Please provide your email address.');
      return;
    }

    setError('');

    try {
      setSubmitting(true);
      requestPasswordReset(email)
        .then(async (res) => {
          if (res.ok) {
            setSuccess(true);
          } else {
            const data: { detail: string } = await res.json();

            setError(data.detail);
          }
          setSubmitting(false);
        })
        .catch((err) => {
          setError(
            err.message ||
              'We were unable to request a password reset. Please, try again or contact our support.',
          );
        });
    } catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } else {
        // Handle errors thrown from backend
        // TODO: Show more descriptive errors per field
        setError('Please check the fields below to make sure they are valid.');
      }
    }
  };

  return isAuthenticated() ? (
    <Navigate to="/" replace />
  ) : (
    <div className="flex min-h-screen items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img alt="Palm tree" className="m-auto w-16" src={palmTreeImage} />
          <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
            Reset your password
          </h2>
          <p className="mt-2 text-center text-gray-600">
            <span className="mr-1">Or</span>
            <a
              href="/login"
              className="font-medium text-teal-600 hover:text-teal-700"
            >
              log in
            </a>
          </p>
        </div>
        <FormProvider {...formMethods}>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {error ? (
              <div>
                <div className="my-4 w-full rounded border border-red-200 bg-red-100 px-4 py-2 text-red-700">
                  {error}
                </div>
              </div>
            ) : (
              success && (
                <div>
                  <div className="my-4 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                    Please check your email to reset your password.
                  </div>
                </div>
              )
            )}

            <input type="hidden" name="remember" value="true" />
            <TextField
              name="email"
              type="email"
              placeholder="Email address"
              autoComplete="email"
            />

            <div>
              <Button
                type="submit"
                size="large"
                leftIcon={<FilledLockIcon className="size-5" />}
                isFullWidth
                isLoading={submitting}
                isDisabled={submitting}
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
