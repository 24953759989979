import { FunctionComponent } from 'react';
import { LoaderFunction, Link, useLoaderData } from 'react-router-dom';
import { clsx } from 'clsx';
import { components } from '@mosey/api-types';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import { TextLink } from '@mosey/components/navigation/TextLink';
import {
  LocationIcon,
  ActionIcon,
  SettingsIcon,
  OfficeIcon,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import {
  TaskNotice,
  Warning,
  StatusBarList,
  Section,
  SectionHeading,
  statusFromRequirement,
  StatusProgressBar,
} from '../components';
import {
  isInProgress,
  isTodo,
  ManagedPayrollReqsByState,
  ForeignQualificationReqsByState,
} from '../utils/requirement';
import { requirementStats } from '../utils/stats';
import { genRequirementPath } from '../utils/paths';
import {
  FilterRequirementsBy,
  filterRequirements,
  isActionableRequirement,
} from '../utils/filter';
import { sortRequirements } from '../utils/sort';
import {
  LegalEntityRegionRef,
  RegionStatus,
  Mail,
  Region,
  UpcomingLegislation,
  SubscriptionPlanEnum,
  RequirementOverview,
} from '../types';
import { RegionalNotice } from '../components/alerts/RegionalNotice';
import { api, apiBatch } from '../utils/fetchApi';
import { TaskSummary } from './tasks-framework/utils/types';
import { getStateSealImage } from '../utils/seals';
import { useUser } from '../hooks/useUser';
import tasksFrameworkBackground from '../assets/tasks-framework/background.svg';
import automatedImage from '../assets/automated.svg';

export const loader: LoaderFunction = async () => {
  let response;

  try {
    response = await api({
      url: '/api/compliance/tasks/summary',
      method: 'GET',
    });
  } catch (error) {
    if ((error as Response).status === 400) {
      return apiBatch({
        upcomingLegislation: {
          url: '/api/legislation',
          method: 'GET',
        },
        requirementsData: {
          url: '/api/requirements/overviews',
          method: 'GET',
        },
        legalEntityMail: {
          url: `/api/legal_entity/mail`,
          method: 'GET',
        },
        connections: {
          url: `/api/connections`,
          method: 'GET',
        },
      });
    }

    throw error;
  }

  return response;
};

export const Component = () => {
  const user = useUser();
  const data = useLoaderData() as TaskSummary;

  if (!user.legal_entity.use_resolvers) {
    return <NonTasksFrameworkHome />;
  }

  return (
    <Section>
      <SectionHeading text="Tasks" />

      {data.assessment_alerts.map(({ region }) => {
        if (region) {
          return (
            <TaskNotice
              key={region.name}
              icon={
                <img
                  src={getStateSealImage(region.code as USStateAbbrev)}
                  className="size-6"
                  alt={`${region.name} State seal`}
                />
              }
              heading={`Tell us about your business in ${region.name}`}
              cta="Start"
              ctaAriaLabel={`Start ${region.name} Assessment`}
              url={`/locations/usa/${region.code.toLowerCase()}/resolver/assessment/`}
            />
          );
        }
      })}
      {data.setup_alerts.map(({ region }) => {
        if (region) {
          return (
            <TaskNotice
              key={region.name}
              icon={
                <img
                  src={getStateSealImage(region.code as USStateAbbrev)}
                  className="size-6"
                  alt={`${region.name} State seal`}
                />
              }
              heading={`Finish setting up ${region.name}`}
              cta="Set up"
              ctaAriaLabel={`Set up ${region.name}`}
              url={`/locations/usa/${region.code.toLowerCase()}/resolver/setup/`}
            />
          );
        }
      })}
      {data.compliance_requirements_alert &&
        data.compliance_requirements_alert.task_count > 0 && (
          <TaskNotice
            count={data.compliance_requirements_alert.task_count}
            heading="Resolve compliance tasks"
            cta="Review"
            ctaAriaLabel="Review compliance tasks"
            url="/resolver/requirement"
          />
        )}
      {data.compliance_questions_alert && (
        <TaskNotice
          icon={<OfficeIcon className="size-5" />}
          heading="Tell us more about your business"
          cta="Start"
          ctaAriaLabel="Start answering questions about your business"
          url="/resolver/question"
        />
      )}

      {data.assessment_alerts.length === 0 &&
        data.setup_alerts.length === 0 &&
        !(
          data.compliance_requirements_alert &&
          data.compliance_requirements_alert.task_count > 0
        ) &&
        !data.compliance_questions_alert && (
          <div className="mx-auto mt-8 flex w-full max-w-3xl rounded-lg border border-teal-350 bg-teal-100">
            <div className="flex grow flex-col justify-center p-10">
              <h3 className="text-2xl font-bold leading-8">Inbox Zero</h3>
              <p className="text-zinc-800">You have no outstanding tasks!</p>
            </div>

            <img
              className="object-cover"
              src={tasksFrameworkBackground}
              alt=""
            />
          </div>
        )}

      <div className="mt-8">
        <SectionHeading text="Automated" level="h2" />

        {data.in_progress_tasks.length > 0 ? (
          <ul className="mt-4 space-y-2">
            {data.in_progress_tasks.map((task) => (
              <li key={task.id}>
                <TextLink to={`/tasks/${task.id}`}>{task.title}</TextLink>
              </li>
            ))}
          </ul>
        ) : (
          <div className="mx-auto mt-8 flex w-full max-w-3xl items-center gap-x-8">
            <img src={automatedImage} alt="" className="w-56 shrink-0" />
            <div>
              <h3 className="mb-2 text-2xl leading-8 text-zinc-700">
                Mosey saves you countless hours by automating time-consuming
                tasks
              </h3>
              <p className="leading-6 text-zinc-700">
                Your automated tasks will show up here
              </p>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

type InProgressProps = {
  requirements: RequirementOverview[];
};

const InProgress: FunctionComponent<InProgressProps> = ({ requirements }) => {
  return (
    <>
      {requirements && requirements.length > 0 ? (
        requirements.slice(0, 5).map((req) => {
          const progressStatus = statusFromRequirement(req);
          const complete = 0;
          return (
            <Link
              key={req.id}
              className="cursor-pointer rounded-lg py-2"
              to={genRequirementPath(req)}
            >
              <StatusProgressBar
                key={req.id}
                label={req.title}
                complete={complete}
                status={progressStatus}
              />
            </Link>
          );
        })
      ) : (
        <div className="flex h-44 items-center justify-items-center">
          <div className="m-auto">
            <p className="italic text-gray-400">No tasks in progress</p>
          </div>
        </div>
      )}
      {requirements.length > 5 && (
        <div className="text-sm">
          <TextLink to="/tasks">See all</TextLink>
        </div>
      )}
    </>
  );
};

type GettingStartedNoticeProps = {
  hqRegion: Region;
  isFQStarted: boolean;
  foreignQualificationReqId?: string;
  isPayrollSetupStarted: boolean;
};

const GettingStartedNotice: FunctionComponent<GettingStartedNoticeProps> = ({
  hqRegion,
  isFQStarted,
  foreignQualificationReqId,
  isPayrollSetupStarted,
}) => {
  return (
    <div className="mt-4 flex items-center rounded border bg-teal-400 px-4 py-3">
      <div className="stroke-current">
        <ActionIcon className="mr-3 size-10 rounded-3xl bg-rose-700 p-2 text-white" />
      </div>
      <div className="flex-1">
        <h2 className="font-medium">Get Started!</h2>
        <ul
          className={clsx('flex flex-col text-sm', {
            'ml-4 list-disc marker:text-slate-500':
              !isFQStarted && !isPayrollSetupStarted,
          })}
        >
          {!isFQStarted && foreignQualificationReqId && (
            <li>
              <a
                href={`/locations/usa/${hqRegion.code}/setup?section=registration`}
                className="text-slate-500 hover:text-slate-800"
              >
                Register locally in {hqRegion.name} (Foreign Qualification)
              </a>
            </li>
          )}
          {!isPayrollSetupStarted && (
            <li>
              <a
                href={`/locations/usa/${hqRegion.code}/setup?section=payroll`}
                className="text-slate-500 hover:text-slate-800"
              >
                Get the founders on payroll (Withholding Tax and Unemployment
                Insurance Accounts)
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="flex items-center">
        <Button as={Link} to="/tasks" variant="primary">
          See my tasks
        </Button>
      </div>
    </div>
  );
};

type RegionSetupNoticeProps = {
  legalEntityRegions: LegalEntityRegionRef[];
};

const RegionSetupNotice: FunctionComponent<RegionSetupNoticeProps> = ({
  legalEntityRegions,
}) => {
  const regionCount = legalEntityRegions.length;

  if (regionCount > 0) {
    return (
      <Warning
        heading="New state setup in progress"
        body=""
        url="/locations/usa"
        cta="See your locations"
      />
    );
  }
  return <></>;
};

type NoticesSectionProps = {
  requirements: RequirementOverview[];
  upcomingLegislation: UpcomingLegislation[];
  legalEntityRegions: LegalEntityRegionRef[];
  legalEntityMail: Mail[];
  connections: components['schemas']['Connection'][];
};

const NoticesSection: FunctionComponent<NoticesSectionProps> = ({
  requirements,
  upcomingLegislation,
  legalEntityRegions,
  legalEntityMail,
  connections,
}) => {
  const {
    legal_entity: { subscription_plan: subscriptionPlan, hq_region: hqRegion },
  } = useUser();

  const hasBasicPlan = subscriptionPlan === SubscriptionPlanEnum.basic;
  if (hasBasicPlan && hqRegion) {
    // find started status of FQ and Payroll in HQ region
    let isFQStarted = true;
    let isPayrollSetupStarted = true;

    let fqReqId;
    ForeignQualificationReqsByState[hqRegion.code.toUpperCase()].forEach(
      (dataId) => {
        // expect only 1 FQ requirement to be found based on entity type
        const fqReq = requirements.find((r) => r.data_id === dataId);
        if (fqReq) {
          isFQStarted = !isTodo(fqReq) || fqReq.is_managed;
          fqReqId = fqReq.id;
        }
      },
    );
    const payrollRequirementDataIds =
      ManagedPayrollReqsByState[hqRegion.code.toLowerCase()];
    const payrollReqs = requirements.filter(
      (r) => payrollRequirementDataIds.indexOf(r.data_id) > -1,
    );

    // if any payroll requirement is in-progress, consider the registration as started
    isPayrollSetupStarted = payrollReqs.some((r) => !isTodo(r) || r.is_managed);

    if (!isFQStarted || !isPayrollSetupStarted) {
      return (
        <div className="mt-4">
          <GettingStartedNotice
            hqRegion={hqRegion}
            isFQStarted={isFQStarted}
            foreignQualificationReqId={fqReqId}
            isPayrollSetupStarted={isPayrollSetupStarted}
          />
        </div>
      );
    }
  }

  // Only show task notices to actionable tasks
  // To keep in sync with the same data rendered in /views/Tasks.tsx
  const actionableRequirements = requirements.filter((req) =>
    isActionableRequirement(req),
  );

  const upcomingRequirementsCount = filterRequirements(
    actionableRequirements,
    FilterRequirementsBy.UpcomingNotManaged,
  ).length;
  const overdueRequirementsCount = filterRequirements(
    actionableRequirements,
    FilterRequirementsBy.Overdue,
  ).length;
  const upcomingLegislationCount = upcomingLegislation.length;
  const needsReauthConnectionsCount = connections.filter(
    (connection) => connection.needs_auth,
  ).length;
  const hasPayrollConnection = connections.some(
    (c) => c.integration?.type === 'payroll',
  );

  // Get the regions the user is currently setting up
  const setupRegions = legalEntityRegions.filter(
    (r) => r.status === RegionStatus.Entering,
  );

  let totalUnread = 0;
  const unreadMailByRegion: { [key: string]: Mail[] } = {};
  legalEntityMail
    .filter((mail: Mail) => {
      return !mail.is_read;
    })
    .forEach((mail: Mail) => {
      const lowerCaseCode = mail.region_code.toLowerCase();
      if (unreadMailByRegion[lowerCaseCode]) {
        unreadMailByRegion[lowerCaseCode].push(mail);
      } else {
        unreadMailByRegion[lowerCaseCode] = [mail];
      }
      return mail;
    });
  const regionsWithUnreadMail: Region[] = legalEntityRegions
    .filter((leRegion: LegalEntityRegionRef) => {
      return leRegion.region.code.toLowerCase() in unreadMailByRegion;
    })
    .map((leRegion: LegalEntityRegionRef) => {
      totalUnread +=
        unreadMailByRegion[leRegion.region.code.toLowerCase()].length;
      return leRegion.region;
    });
  return (
    <>
      <div className="mt-4">
        {!hasPayrollConnection && !hasBasicPlan && (
          <TaskNotice
            heading="Payroll setup"
            cta="Add a payroll connection"
            url="/onboarding/setup"
            icon={<SettingsIcon className="size-6" />}
          />
        )}
        <RegionSetupNotice legalEntityRegions={setupRegions} />
        {needsReauthConnectionsCount > 0 && (
          <TaskNotice
            heading="Payroll integration issue to resolve"
            count={needsReauthConnectionsCount}
            cta="View integrations"
            url="/settings/integrations"
          />
        )}
        {upcomingLegislationCount > 0 && (
          <TaskNotice
            heading="Upcoming legislation"
            count={upcomingLegislationCount}
            cta="View legislation"
            url="/legislation"
          />
        )}
        {overdueRequirementsCount > 0 && (
          <TaskNotice
            heading="Overdue tasks"
            count={overdueRequirementsCount}
            cta="View tasks"
            url="/tasks/all?filters=overdue"
          />
        )}
        {upcomingRequirementsCount > 0 && (
          <TaskNotice
            heading="Upcoming tasks"
            count={upcomingRequirementsCount}
            cta="View tasks"
            url="/tasks/all?filters=upcoming"
          />
        )}
        {regionsWithUnreadMail.length > 0 && (
          <RegionalNotice
            heading="Unread Mail"
            totalCount={totalUnread}
            regions={regionsWithUnreadMail}
          />
        )}
      </div>
    </>
  );
};

const NewUserNotice: FunctionComponent = () => (
  <div className="flex h-96 flex-col items-center justify-center border border-t-0 bg-white">
    <div className="max-w-md">
      <div className="mb-6 size-10 rounded bg-gray-100 p-2 text-gray-500">
        <LocationIcon />
      </div>
      <div className="mb-6">
        <h3 className="text-xl font-bold">Welcome to Mosey!</h3>
        <p className="text-gray-500">
          Get started by registering in a new location or importing an existing
          one.
        </p>
      </div>

      <Button as={Link} size="xlarge" to="/locations/usa/setup">
        Add location
      </Button>
    </div>
  </div>
);

export const NonTasksFrameworkHome = () => {
  const {
    legal_entity: { regions: legalEntityRegions },
  } = useUser();
  const {
    requirementsData,
    upcomingLegislation,
    legalEntityMail,
    connections,
  } = useLoaderData() as {
    upcomingLegislation: UpcomingLegislation[];
    requirementsData: {
      results: RequirementOverview[];
    };
    legalEntityMail: Mail[];
    connections: components['schemas']['Connection'][];
  };
  const requirements = sortRequirements(requirementsData.results);

  const locationStats = legalEntityRegions
    .filter(
      (r) =>
        r.status !== RegionStatus.Pending && r.status !== RegionStatus.Exiting,
    )
    .map((r) => {
      const regionCode = r.region.code.toLowerCase();
      const regionLabel = r.region.name;
      const reqs = requirements.filter(
        (i) => i.region[0].toLowerCase() === regionCode,
      );

      let stats;
      // Don't count stats for regions that are just being set up
      if (r.status === RegionStatus.Entering) {
        stats = {
          complete: 0,
          incomplete: 0,
          inProgress: 0,
        };
      } else {
        stats = requirementStats(reqs);
      }

      return {
        key: regionLabel,
        label: regionLabel,
        url: `/locations/usa/${regionCode}`,
        ...stats,
      };
    });
  // Sort in-place by percent complete in descending order
  locationStats.sort((a, b) => b.complete - a.complete);

  /* Calculate bounds for location stats */
  // show up to 10 locations
  const MAX_SHOWN_LOCATION_STATS = 10;
  const numShownLocationStats =
    locationStats.length > MAX_SHOWN_LOCATION_STATS
      ? MAX_SHOWN_LOCATION_STATS
      : locationStats.length;
  let middleBound = Math.floor(numShownLocationStats / 2);
  // if the number of locations to show is odd, show more in the left column
  if (numShownLocationStats % 2 !== 0) {
    middleBound += 1;
  }

  return (
    <Section>
      <SectionHeading text="Home" />
      {legalEntityRegions.length === 0 ? (
        <NewUserNotice />
      ) : (
        <>
          <div>
            <NoticesSection
              requirements={requirements}
              upcomingLegislation={upcomingLegislation}
              legalEntityRegions={legalEntityRegions}
              legalEntityMail={legalEntityMail}
              connections={connections}
            />
            <div className="mt-8 border-b pb-4">
              <div className="border-b py-2">
                <h2 className="flex-1 text-xl font-bold">Status</h2>
              </div>
              <div className="flex items-start">
                <div className="mt-4 w-1/2 pr-20">
                  <StatusBarList stats={locationStats.slice(0, middleBound)} />
                </div>
                <div className="mt-4 w-1/2 pr-20">
                  <StatusBarList
                    stats={locationStats.slice(
                      middleBound,
                      MAX_SHOWN_LOCATION_STATS,
                    )}
                  />
                </div>
              </div>
              {locationStats.length > MAX_SHOWN_LOCATION_STATS && (
                <div className="mt-2 flex items-center">
                  <a
                    className="text-sm text-teal-700 hover:text-teal-800"
                    href="/locations/usa"
                  >
                    See all ➞
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 pb-4">
            <div className="mb-2 border-b py-2">
              <h2 className="flex-1 text-xl font-bold">In-progress</h2>
            </div>
            <InProgress requirements={requirements.filter(isInProgress)} />
          </div>
        </>
      )}
    </Section>
  );
};
