import { components } from '@mosey/api-types';
import { Badge } from '../alerts/Badge';
import { ReactivateConnectionButton } from './ReactivateConnectionButton';

interface IntegrationsProps {
  connections: components['schemas']['Connection'][];
}

export const Integrations = ({ connections }: IntegrationsProps) => {
  return (
    <ul className="mt-6 divide-y rounded-lg border border-gray-200 text-gray-700">
      {connections.map((connection) => (
        <li
          key={connection.id}
          className="flex items-center justify-between px-4 py-5"
        >
          <p>
            {connection.provider_name
              ? `${connection.provider_name} (${connection.integration.name})`
              : connection.integration.name}
          </p>

          <div className="flex items-center gap-x-4">
            {connection.needs_auth && (
              <ReactivateConnectionButton connection={connection} />
            )}

            <Badge
              isActive={!(connection.is_disabled || connection.needs_auth)}
            >
              {connection.is_disabled || connection.needs_auth
                ? 'Inactive'
                : 'Active'}
            </Badge>
          </div>
        </li>
      ))}
    </ul>
  );
};
