import { Button } from '@mosey/components/buttons/Button';
import { TextField } from '@mosey/components/forms/TextField';
import { GuidedStepContentProps } from './types';

export const UsernameStep = ({ nextStep }: GuidedStepContentProps) => {
  return (
    <div className="mt-8 flex flex-col items-start gap-8">
      <h5 className="text-2xl font-semibold ">Enter your username</h5>
      <div className="flex flex-col gap-4">
        <p className="max-w-xl">
          Once you are prompted for your username, copy it from the field below.
        </p>
        <TextField
          label="Username"
          name="username"
          disabled
          shouldRenderCopyButton
          hasMargin={false}
        />
      </div>
      <Button onClick={nextStep}>Next</Button>
    </div>
  );
};
