import clsx from 'clsx';
import { ThHTMLAttributes } from 'react';

type HeaderCellProps = Omit<
  ThHTMLAttributes<HTMLTableCellElement>,
  'className'
> & {
  width?: string;
};

export const HeaderCell = ({ children, width, ...rest }: HeaderCellProps) => {
  return (
    <th
      className={clsx(
        'group/headerCell text-nowrap py-3 pr-6  text-left text-sm font-medium uppercase tracking-wider text-gray-500 first:pl-6',
        {
          'cursor-pointer': rest.onClick,
        },
        width,
      )}
      {...rest}
    >
      {children}
    </th>
  );
};
