import { FunctionComponent } from 'react';
import {
  RegisterOptions,
  useFormContext,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { clsx } from 'clsx';
import { FieldError } from '@mosey/components/forms/FieldError';

type FieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  className?: string;
  labelClassName?: string;
  optionClassName?: string;
  placeholder?: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: { name?: string; value: any }[];
  reactFormConfig?: RegisterOptions;
  defaultValue?: string;
};

/**
 * Component to be used within a FormProvider to get the form context
 */
export const RadioField: FunctionComponent<FieldProps> = ({
  name,
  label,
  error,
  className,
  labelClassName,
  optionClassName,
  placeholder,
  description,
  options,
  reactFormConfig,
  defaultValue,
}) => {
  const { register } = useFormContext();
  return (
    <div className="mb-7">
      <div>
        <label
          className={clsx(
            {
              'font-semibold block mb-2': !labelClassName,
            },
            labelClassName,
          )}
          htmlFor={name}
        >
          {label}
        </label>
        {description && (
          <p className="mb-4 text-sm text-gray-600">{description}</p>
        )}
        <div className={className}>
          {options?.map((option) => (
            <label
              key={`opt-label-${option.name}`}
              className={clsx(
                {
                  'flex items-center mb-1': !optionClassName,
                },
                optionClassName,
              )}
            >
              <input
                type="radio"
                className={clsx('mr-4', {
                  'border-red-500': error,
                })}
                placeholder={placeholder}
                id={`${name}-${option.value}`}
                value={option.value}
                key={option.value}
                defaultChecked={option.value === defaultValue}
                {...register(name, { ...reactFormConfig })}
              />
              {option.name}
            </label>
          ))}
        </div>
      </div>
      <FieldError error={error} />
    </div>
  );
};
