import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useKeyedBatchApi } from '../../hooks';
import { KeyedBatchApiStatusHandler } from '../../components';
import { IFetchApi } from '../../utils/types';
import { PlatformLocationsTasks } from './PlatformLocationsTasks';

export const PlatformLocationsTasksView = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');

  const calls: {
    [key: string]: IFetchApi;
  } = {
    locations: {
      url: '/locations',
      method: 'GET',
      isPlatform: true,
    },
    legalEntity: {
      url: '/legal_entity',
      method: 'GET',
      isPlatform: true,
    },
  };

  if (locationId) {
    calls.tasks = {
      url: `/locations/${locationId}/tasks`,
      method: 'GET',
      isPlatform: true,
    };
  } else {
    calls.tasks = {
      url: '/tasks',
      method: 'GET',
      isPlatform: true,
    };
  }

  const batchResponse = useKeyedBatchApi(calls, [
    JSON.stringify(calls),
    locationId,
    shouldRefresh,
  ]);

  const toggleShouldRefresh = useCallback(
    () => setShouldRefresh((s) => !s),
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ({ locations, tasks }: { [key: string]: any }) => {
    return {
      refreshView: toggleShouldRefresh,
      locations,
      tasks,
    };
  };

  return (
    <KeyedBatchApiStatusHandler
      batchResponse={batchResponse}
      component={PlatformLocationsTasks}
      componentProps={componentPropsFn}
    />
  );
};
