import * as Sentry from '@sentry/react';
import { LoaderFunction, json, redirect } from 'react-router-dom';
import { api } from './fetchApi';
import { User } from '../types';
import { getDecodedAccessToken, RefreshTokenError } from '@mosey/utils/auth';
import { getImpersonatedUser } from './impersonation';
import { switchLegalEntity, switchLegalEntityForImpersonator } from './auth';

export const userLoader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const targetLegalEntityId = url.searchParams.get('legal_entity_id');

  if (targetLegalEntityId) {
    const decodedAccessToken = getDecodedAccessToken();
    const impersonatedUser = getImpersonatedUser(decodedAccessToken);

    try {
      // If its an impersonator, they won't have a refresh token in cookie, so we get a new access token same way
      if (impersonatedUser) {
        await switchLegalEntityForImpersonator(targetLegalEntityId);
      } else {
        await switchLegalEntity(targetLegalEntityId);
      }
    } catch (error) {
      if (
        Object.values(RefreshTokenError).includes(error as RefreshTokenError)
      ) {
        const redirectUrl = encodeURIComponent(
          `${url.pathname}${url.search}${url.hash}`,
        );

        throw redirect(
          `/logout${redirectUrl !== '%2F' ? `?redirect=${redirectUrl}` : ''}`,
        );
      }

      throw error;
    }
  }

  const response = await api({
    url: '/api/users/me',
    method: 'POST',
  });

  const user: User = await response.json();

  Sentry.setUser({ id: user.id });

  return json(user);
};
