import { FunctionComponent } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import { Gate } from '../components/base/Gate';
import { useUser } from '../hooks/useUser';

type GateActiveSubscriptionProps = {
  children: React.ReactNode;
};

export const GateActiveSubscription: FunctionComponent<
  GateActiveSubscriptionProps
> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const selectedPlan = searchParams.get('plan');

  const {
    legal_entity: { is_subscribed: isSubscribed },
  } = useUser();

  const selectedPlanParam =
    selectedPlan &&
    createSearchParams({
      plan: selectedPlan,
    });

  const redirectUrl = selectedPlanParam
    ? `/subscription/pricing?${selectedPlanParam.toString()}`
    : '/subscription/pricing';

  return (
    <Gate shouldRenderChildren={isSubscribed} redirectUrl={redirectUrl}>
      {children}
    </Gate>
  );
};
