import { Button } from '@mosey/components/buttons/Button';
import logo from '../../../assets/logo.svg';
import { useSearchParams } from 'react-router-dom';
import { useSafeLocationUpdate } from '../../../hooks/useSafeLocationUpdate';

interface PlatformTasksHeadingProps {
  name?: string;
}

export const PlatformTasksHeading = ({ name }: PlatformTasksHeadingProps) => {
  const updateLocation = useSafeLocationUpdate();
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback_url') as string;

  const onClick = () => {
    updateLocation(callbackUrl, true);
  };
  return (
    <header className="my-6 flex items-center gap-x-4 border-b pb-6">
      <img className="mt-2 h-5" src={logo} alt="Mosey Logo" />
      <h1 className="col-span-8 col-start-4 ml-auto text-2xl font-bold leading-none">
        Tasks
        {!!name && ` for ${name}`}
      </h1>
      <Button size="xsmall" variant="secondary" onClick={onClick}>
        Go back to Remote
      </Button>
    </header>
  );
};
