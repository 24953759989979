import { components } from '@mosey/api-types';

export enum PolicyContentType {
  Markdown = 'markdown',
  MultiColumnList = 'multicolumnlist',
}

export type PolicyMarkdownContent = components['schemas']['PolicyMainContent'];

export type PolicyMultiColumnListContent = {
  type: PolicyContentType.MultiColumnList;
  items: Array<string>;
  columns: number;
};

export type PolicyMainContent = components['schemas']['PolicyMainContent'];

export type Policy = components['schemas']['Policy'];

export type PolicyContentResource = components['schemas']['PolicyResource'];
