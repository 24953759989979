export enum USStateAbbrev {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  DistrictOfColumbia = 'DC',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}

export enum USStateName {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export enum USStateId {
  AL = 'e5aa6d3222d22de2',
  AK = '86e384ec4456dd93',
  AZ = '43c2b058fdc38bd0',
  AR = '8a3248de4ff636d4',
  CA = '99be5e8f54c4680d',
  CO = 'e8239e013ff4cde4',
  CT = 'e69817cba25a7fa6',
  DE = '1f1cc147c009b409',
  DC = 'afb903b3dcbe23fc',
  FL = '33cf440c464d38f5',
  GA = '5c392fde8d78eb3c',
  HI = 'dcb44d856176eb43',
  ID = 'a71f627b1843088a',
  IL = '30c18f24ba96c1e2',
  IN = 'fbe3a2db6d46f2fe',
  IA = '81fb4925ba83f81b',
  KS = 'f60f094906bf6e61',
  KY = '0fced19468cca680',
  LA = 'b51b14eb685c802f',
  ME = '50b6e06268188bff',
  MD = '784c3796c1f6931f',
  MA = 'd26e531db2dcbb50',
  MI = 'b07e8933d62889e9',
  MN = '2577a77a916b01bd',
  MS = '4641d29a7416dae8',
  MO = '4eecb1031e0ecc97',
  MT = '3c6de0a85af1153b',
  NE = '47b790d36c9135fe',
  NV = 'c2473d37f8547551',
  NH = 'e4656a4c283b7c15',
  NJ = 'a3cc7e50a63fb347',
  NM = '43ca15ae2aa526e9',
  NY = '5894aa7b4729ed13',
  NC = 'aa79248e404b74d2',
  ND = '5528e71a2a626676',
  OH = 'ff6fec03fcfaa7d1',
  OK = '36552f81875623ad',
  OR = 'e3fd17f3f015b908',
  PA = '1c96f4e30d829772',
  PR = 'b89488821f4f68bb',
  RI = '1e58e23ec4a87036',
  SC = 'd2bbb1305f460a69',
  SD = '4e4a5bc7fcf5abd2',
  TN = '13f00f61a16fdabd',
  TX = '7dac61e5b25e151a',
  UT = '5da0217cd400f37a',
  VT = '3683a6d2eec6b45c',
  VA = 'f568e8571666ccd6',
  WA = '05a2dfcf81d37e7d',
  WV = 'aa5a1dfcbdd37d25',
  WI = '920abf5751681231',
  WY = '0132461d340cded0',
}

export const isUSStateCode = (
  code: string,
): code is keyof typeof USStateName => {
  return code in USStateName;
};
