type FilterCountProps = {
  count: number;
};

export const FilterCount = ({ count }: FilterCountProps) => {
  return (
    <p className="flex h-5 min-w-5 items-center justify-center rounded-full bg-gray-200 px-1.5 text-xs font-semibold text-sage-700 shadow-sm">
      {count > 100 ? '99+' : count}
    </p>
  );
};
