import { DecodedToken, setAccessTokenCommon } from '@mosey/utils/auth';
import * as config from '../settings/config';

const saveImpersonatorToken = (token: DecodedToken) => {
  if (!token.impersonator) {
    throw new Error('No impersonator set');
  }

  localStorage.setItem('token', token.token);
  localStorage.setItem('impersonator', token.impersonator);
  setAccessTokenCommon(token);
};

export const getImpersonatedUser = (token: DecodedToken | null) => {
  if (!token || !token.impersonator) {
    return null;
  }

  return token.sub;
};

export const impersonationListener = (event: MessageEvent) => {
  if (event.origin !== config.ADMIN_BASE_URL) {
    return;
  }
  saveImpersonatorToken(event.data as DecodedToken);
};
