interface TaskContainerProps {
  children: React.ReactNode;
}

export const TaskContainer = ({ children }: TaskContainerProps) => {
  return (
    <article className="flex h-full grow flex-col gap-y-4 overflow-y-auto p-8">
      {children}
    </article>
  );
};
