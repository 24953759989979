import { FunctionComponent } from 'react';

import { Gate } from '../components/base/Gate';
import { useUser } from '../hooks/useUser';

type GateBusinessProfileProps = {
  children: React.ReactNode;
};

export const GateBusinessProfile: FunctionComponent<
  GateBusinessProfileProps
> = ({ children }) => {
  const {
    legal_entity: { business_profile_submitted: isBusinessProfileSubmitted },
  } = useUser();

  return (
    <Gate
      shouldRenderChildren={isBusinessProfileSubmitted}
      redirectUrl="/onboarding/business-information"
    >
      {children}
    </Gate>
  );
};

export default GateBusinessProfile;
