import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, useEffect } from 'react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import { useScrollIntoView } from '../hooks';

interface BlockAlertProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'className'> {
  message: React.ReactNode;
  children?: React.ReactNode;
  variant: 'error' | 'warning' | 'info' | 'success';
  show?: boolean;
  scrollIntoView?: boolean;
}

export const BlockAlert = ({
  message,
  children,
  variant,
  show = false,
  scrollIntoView = false,
  ...containerProps
}: BlockAlertProps) => {
  let Icon;
  const [scrollIntoViewRef, triggerScroll] = useScrollIntoView(scrollIntoView, {
    block: 'center',
  });

  useEffect(() => {
    if (show && scrollIntoView) {
      triggerScroll();
    }
  }, [show, scrollIntoView, triggerScroll]);

  switch (variant) {
    case 'error':
      Icon = ExclamationIcon;
      break;

    case 'warning':
      Icon = ExclamationCircleIcon;
      break;

    case 'info':
      Icon = InformationCircleIcon;
      break;

    case 'success':
      Icon = CheckCircleIcon;
      break;
  }

  return (
    <div
      className="contents"
      aria-live={containerProps['aria-live'] || 'polite'}
    >
      {show && (
        <div
          {...containerProps}
          ref={scrollIntoViewRef}
          className={clsx(
            'flex w-full gap-x-2 rounded-sm border p-4 shadow-sm',
            {
              'bg-rose-50 border-rose-200 text-rose-900': variant === 'error',
              'bg-amber-50 border-amber-200 text-amber-900':
                variant === 'warning',
              'bg-teal-50 border-teal-400 text-teal-900': variant === 'info',
              'bg-lime-50 border-lime-200 text-lime-900': variant === 'success',
            },
          )}
        >
          <Icon className="mt-0.5 size-5 shrink-0" />

          <p>
            <span className="font-bold">{message}</span>
            {children && ' – '}
            {children}
          </p>
        </div>
      )}
    </div>
  );
};
