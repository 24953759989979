import { useMemo, useState } from 'react';
import { clsx } from 'clsx';
import {
  CalendarIcon,
  SelectIcon,
  LeftIcon,
  RightIcon,
} from '@mosey/components/Icons';
import { Popover } from '@mosey/components/menus/Popover';
import { Button } from '@mosey/components/buttons/Button';
import { formatDateFromString } from '../../utils/format';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const daysInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

type CalendarMonthData = {
  name: string;
  year: number;
  days: string[];
};

const getCalendarMonth = (year: number, month: number): CalendarMonthData => {
  const result: CalendarMonthData = {
    name: monthNames[month - 1],
    year,
    days: [],
  };
  let totalDays = daysInMonths[month - 1];

  if ((month === 2 && year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    totalDays += 1;
  }

  for (let i = 1; i <= totalDays; i += 1) {
    result.days.push(
      `${year}-${month.toString().padStart(2, '0')}-${i
        .toString()
        .padStart(2, '0')}`,
    );
  }

  return result;
};

interface DateRangerPickerProps {
  defaultStartDate?: string | null;
  defaultEndDate?: string | null;
  defaultButtonText?: string;
  ariaButtonTextPrefix?: string;
  onRangeChange: (startDate: string | null, endDate: string | null) => void;
}

export const DateRangePicker = ({
  defaultStartDate = null,
  defaultEndDate = null,
  defaultButtonText = 'Date range not selected',
  ariaButtonTextPrefix = 'Change date range:',
  onRangeChange,
}: DateRangerPickerProps) => {
  const today = new Date();
  const todaysYear = today.getFullYear();
  const todaysMonth = today.getMonth() + 1;

  const [startDate, setStartDate] = useState<string | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<string | null>(defaultEndDate);

  let initialDisplayYear = todaysYear;
  let initialDisplayMonth = todaysMonth;

  if (defaultEndDate) {
    [initialDisplayYear, initialDisplayMonth] = defaultEndDate
      .split('-')
      .map(Number);
  } else if (defaultStartDate) {
    [initialDisplayYear, initialDisplayMonth] = defaultStartDate
      .split('-')
      .map(Number);
  }

  const [displayCalendar, setDisplayCalendar] = useState<[number, number]>([
    initialDisplayYear,
    initialDisplayMonth,
  ]);

  const handleDayClick = (date: string) => {
    if (startDate && !endDate) {
      /**
       * If the second date the user picks is before the first, flip the order
       * so the range is valid.
       */
      if (Date.parse(startDate) > Date.parse(date)) {
        setStartDate(date);
        setEndDate(startDate);
      } else {
        setEndDate(date);
      }
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    onRangeChange(null, null);
  };

  const advanceMonths = (forward = true) => {
    let [newYear, newMonth] = displayCalendar;

    newMonth += forward ? 1 : -1;

    if (newMonth < 1) {
      newMonth = 12;
      newYear -= 1;
    } else if (newMonth > 12) {
      newMonth = 1;
      newYear += 1;
    }

    setDisplayCalendar([newYear, newMonth]);
  };

  const handlePreviousMonth = () => advanceMonths(false);
  const handleNextMonth = () => advanceMonths();

  const months = useMemo(() => {
    const [rightYear, rightMonth] = displayCalendar;
    let leftMonth = rightMonth - 1;
    let leftYear = rightYear;

    if (leftMonth < 1) {
      leftMonth = 12;
      leftYear -= 1;
    }

    return [
      getCalendarMonth(leftYear, leftMonth),
      getCalendarMonth(rightYear, rightMonth),
    ];
  }, [displayCalendar]);

  let buttonText = defaultButtonText;
  let accessibleButtonText = defaultButtonText;
  const shortDateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  if (startDate) {
    const formattedStartDate = formatDateFromString(startDate);
    const formattedShortStartDate = formatDateFromString(
      startDate,
      shortDateOptions,
    );

    if (!endDate) {
      buttonText = `${formattedShortStartDate} to \u{2026}`;
      accessibleButtonText = `${formattedStartDate} to \u{2026}`;
    } else {
      const formattedEndDate = formatDateFromString(endDate);
      const formattedShortEndDate = formatDateFromString(
        endDate,
        shortDateOptions,
      );

      if (startDate === endDate) {
        buttonText = formattedShortStartDate;
        accessibleButtonText = formattedStartDate;
      } else {
        buttonText = `${formattedShortStartDate} – ${formattedShortEndDate}`;
        accessibleButtonText = `${formattedStartDate} to ${formattedEndDate}`;
      }
    }
  }

  return (
    <Popover
      buttonText={buttonText}
      ariaButtonText={`${ariaButtonTextPrefix} ${accessibleButtonText}`}
      ButtonLeftIcon={CalendarIcon}
      ButtonRightIcon={SelectIcon}
    >
      {({ close }) => (
        <>
          <div className="relative my-6 grid grid-cols-1 gap-x-14 md:grid-cols-2 lg:w-[650px]">
            <button
              type="button"
              className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={handlePreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <LeftIcon className="size-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={handleNextMonth}
            >
              <span className="sr-only">Next month</span>
              <RightIcon className="size-5" aria-hidden="true" />
            </button>
            {months.map((month, monthIdx) => (
              <section
                key={month.name}
                className={clsx('text-center', {
                  'hidden md:block': monthIdx === months.length - 1,
                })}
              >
                <h2 className="text-sm font-semibold text-gray-900">
                  {month.name} {month.year}
                </h2>
                <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                  <div>Sun</div>
                  <div>Mon</div>
                  <div>Tue</div>
                  <div>Wed</div>
                  <div>Thu</div>
                  <div>Fri</div>
                  <div>Sat</div>
                </div>
                <div className="isolate mt-2 grid grid-cols-7 gap-y-0.5 text-sm">
                  {month.days.map((day, dayIdx) => {
                    const currentDay = new Date(day);
                    const dayTimestamp = currentDay.getTime();
                    const dayOfWeek = currentDay.getDay();
                    const isWithinRange =
                      startDate !== null &&
                      endDate !== null &&
                      day !== startDate &&
                      day !== endDate &&
                      dayTimestamp >= Date.parse(startDate) &&
                      dayTimestamp <= Date.parse(endDate);
                    const isToday =
                      day ===
                      `${todaysYear}-${todaysMonth
                        .toString()
                        .padStart(2, '0')}-${today
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`;
                    const buttonClasses = clsx(
                      'group relative py-1.5 font-bold text-gray-700 hover:bg-gray-100 focus:z-10 ',
                      {
                        'bg-rose-700 hover:bg-rose-800 text-white':
                          day === startDate || day === endDate,
                        'rounded-l-md': day === startDate,
                        'rounded-r-md': day === endDate,
                        'bg-teal-400 hover:bg-teal-500': isWithinRange,
                        'col-start-1': dayIdx === 0 && dayOfWeek === 0,
                        'col-start-2': dayIdx === 0 && dayOfWeek === 1,
                        'col-start-3': dayIdx === 0 && dayOfWeek === 2,
                        'col-start-4': dayIdx === 0 && dayOfWeek === 3,
                        'col-start-5': dayIdx === 0 && dayOfWeek === 4,
                        'col-start-6': dayIdx === 0 && dayOfWeek === 5,
                        'col-start-7': dayIdx === 0 && dayOfWeek === 6,
                      },
                    );

                    return (
                      <button
                        key={day}
                        type="button"
                        className={buttonClasses}
                        aria-pressed={day === startDate || day === endDate}
                        aria-current={isToday ? 'date' : undefined}
                        onClick={() => handleDayClick(day)}
                      >
                        <time
                          dateTime={day}
                          className={clsx(
                            'mx-auto flex size-7 items-center justify-center',
                            {
                              'rounded-full bg-teal-550 group-hover:bg-teal-600':
                                isToday,
                            },
                          )}
                          aria-label={formatDateFromString(day)}
                        >
                          {day.split('-').pop()?.replace(/^0/, '')}
                        </time>
                      </button>
                    );
                  })}
                </div>
              </section>
            ))}
          </div>
          <div className="flex gap-x-6">
            <Button
              isFullWidth
              variant="secondary"
              onClick={() => {
                handleClear();
                close();
              }}
              aria-label={
                startDate && endDate
                  ? `Clear date filter from ${formatDateFromString(
                      startDate,
                    )} to ${formatDateFromString(endDate)}`
                  : undefined
              }
            >
              Clear
            </Button>
            <Button
              disabled={!endDate}
              isFullWidth
              onClick={() => {
                onRangeChange(startDate, endDate);
                close();
              }}
              aria-label={
                startDate && endDate
                  ? `Apply date filter from ${formatDateFromString(
                      startDate,
                    )} to ${formatDateFromString(endDate)}`
                  : undefined
              }
            >
              Apply
            </Button>
          </div>
        </>
      )}
    </Popover>
  );
};
