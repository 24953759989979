import { FunctionComponent } from 'react';
import { CheckIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';

type ConfirmationProps = {
  heading: string;
  description: string;
  buttonText?: string;
  onButtonClick?: (e: React.MouseEvent) => void;
};

export const Confirmation: FunctionComponent<ConfirmationProps> = ({
  heading,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div className="flex h-64 flex-col items-center justify-center">
      <div className="size-10 rounded-full bg-green-600 p-1 text-white">
        <CheckIcon />
      </div>
      <div>
        <h3 className="text-2xl font-bold text-green-600">{heading}</h3>
      </div>
      <p className="ml-1 text-gray-500">{description}</p>
      {onButtonClick && buttonText && (
        <Button
          type="button"
          size="large"
          className="mt-4"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
