import { FunctionComponent } from 'react';
import { Region } from '../../types';
import { ArrowRightIcon } from '@mosey/components/Icons';
import { Link, NavLink } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';

type RegionalNoticeProps = {
  heading: string;
  totalCount: number;
  regions: Region[];
  extraContent?: React.ReactNode;
};

export const RegionalNotice: FunctionComponent<RegionalNoticeProps> = ({
  heading,
  totalCount,
  regions,
  extraContent,
}) => {
  return (
    <div className="mt-4 flex flex-col rounded border bg-gray-50 px-4 py-3 pb-5">
      <div className="flex items-center">
        {totalCount && (
          <div>
            <span className="rounded-lg bg-red-500 px-2 py-1 text-xs font-bold text-white">
              {totalCount}
            </span>
          </div>
        )}
        <div className="ml-2 flex-1">
          <h2 className="flex-1 font-medium">{heading}</h2>
        </div>
        <Button as={NavLink} to={`/mail`} variant={`secondary`}>
          {`Read all mail`}
        </Button>
      </div>
      <ul className="mt-2 px-8 text-gray-500">
        {regions.map((region) => (
          <li key={region.code}>
            <Link
              to={`/locations/usa/${region.code.toLowerCase()}/mail`}
              className="flex items-center text-sm"
            >
              {region.name}
              <ArrowRightIcon className="ml-1 size-4" />
            </Link>
          </li>
        ))}
      </ul>
      {extraContent}
    </div>
  );
};
