import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { TaskCardStatus } from './types';
import { CheckCircleIcon } from '@heroicons/react/solid';
import {
  LightningBoltListIcon,
  QuestionListIcon,
  RobotWithCheckIcon,
  RobotWithClockIcon,
  RobotWithRefreshIcon,
  StopwatchIcon,
  TodoCircleIcon,
  UpdateAvailableIcon,
  UpdatedIcon,
} from '../Icons';

type StatusIconProps = {
  status: TaskCardStatus;
  size: 'medium' | 'large';
  children?: ReactNode;
};

function getBackgroundColor(status: TaskCardStatus) {
  switch (status) {
    case 'in-progress':
    case 'automated':
    case 'managed':
      return 'bg-violet-100';
    case 'overdue':
      return 'bg-rose-100';
    case 'todo':
    case 'new':
    case 'done':
    case 'setup-collection':
    case 'question-collection':
    default:
      return 'bg-teal-350';
  }
}

export const getStatusIconData = (status: TaskCardStatus) => {
  switch (status) {
    case 'done':
      return {
        Component: CheckCircleIcon,
        baseColor: 'text-teal-700',
      };
    case 'in-progress':
      return {
        Component: RobotWithClockIcon,
        baseColor: 'text-violet-600',
      };
    case 'automated':
      return {
        Component: RobotWithCheckIcon,
        baseColor: 'text-violet-600',
      };
    case 'managed':
      return {
        Component: RobotWithRefreshIcon,
        baseColor: 'text-violet-600',
      };
    case 'overdue':
      return {
        Component: StopwatchIcon,
        baseColor: 'text-rose-900',
      };
    case 'setup-collection':
      return {
        Component: LightningBoltListIcon,
        baseColor: 'text-teal-700',
      };
    case 'question-collection':
      return {
        Component: QuestionListIcon,
        baseColor: 'text-teal-700',
      };
    case 'update-available':
      return {
        Component: UpdateAvailableIcon,
        baseColor: 'text-teal-700',
      };
    case 'updated':
      return {
        Component: UpdatedIcon,
        baseColor: 'text-teal-700',
      };
    case 'new':
    default:
      return {
        Component: TodoCircleIcon,
        baseColor: 'text-teal-700',
      };
  }
};

export const StandaloneStatusIcon = ({
  status,
}: {
  status: TaskCardStatus;
}) => {
  const { Component, baseColor } = getStatusIconData(status);
  return <Component className={clsx(baseColor, 'size-[18px]')} />;
};

export const StatusIcon = ({
  status,
  size = 'large',
  children,
}: StatusIconProps) => {
  const sizeClass = size === 'medium' ? 'size-7' : 'size-8';
  const bgColor = getBackgroundColor(status);

  return (
    <div
      className={clsx(
        'relative flex shrink-0 items-center justify-center rounded-full',
        sizeClass,
        bgColor,
      )}
    >
      {children ? (
        <span className="text-sm font-medium text-teal-800">{children}</span>
      ) : (
        <StandaloneStatusIcon status={status} />
      )}
      {status === 'new' && (
        <p className="absolute -bottom-1 rounded-lg bg-teal-800 px-1 py-[2px] text-[7px] font-medium leading-[7px] text-rose-50">
          NEW
        </p>
      )}
    </div>
  );
};
