import { clsx } from 'clsx';
import { capitalize } from '../utils/format';
import { FilterPlatformTasksBy, FilterRequirementsBy } from '../utils/filter';

type QuickFiltersProps<T> = {
  items: {
    label: T;
    count: number;
    isSelected: boolean;
  }[];
  onClick: (label: T) => void;
};

export const QuickFilters = <
  T extends FilterRequirementsBy | FilterPlatformTasksBy,
>({
  items,
  onClick,
}: QuickFiltersProps<T>) => {
  return (
    <div className="flex items-center">
      {items.map((item) => {
        return (
          <div
            key={`${item.label}`}
            className="ml-8 flex cursor-pointer items-center font-semibold text-gray-400 hover:text-gray-600"
            onClick={() => onClick(item.label)}
          >
            <div
              className={clsx('pb-2 text-sm', {
                'text-rose-700 border-b-2 border-rose-700': item.isSelected,
              })}
            >
              {capitalize(item.label.toString())}
            </div>
            <span
              className={clsx(
                'mb-2 ml-2 rounded-xl bg-gray-200 px-2 py-1 text-xs font-semibold',
                {
                  'text-rose-700': item.isSelected,
                },
              )}
            >
              {item.count}
            </span>
          </div>
        );
      })}
    </div>
  );
};
