import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateField, NonUSAddress, NumberField, SelectField } from '.';
import { TextField } from '@mosey/components/forms/TextField';
import { EntityTypeEnum, PersonIFormValues } from '../../types';
import {
  AnyPhonePattern,
  EmailPattern,
  IdentifierPattern,
} from '@mosey/utils/validation/patterns';
import { CountryCode } from '../../constants';
import { useUser } from '../../hooks/useUser';

type FormPeopleProps = {
  personType: string | null;
  isEdit?: boolean;
};

export const FormPeople = ({ personType, isEdit = false }: FormPeopleProps) => {
  const formMethods = useFormContext<PersonIFormValues>();
  const {
    legal_entity: { entity_type: entityType },
  } = useUser();

  const {
    watch,
    unregister,
    formState: { errors },
  } = formMethods;

  const watchIdentifierType = watch('identifier_type');

  useEffect(() => {
    if (watchIdentifierType === 'does_not_have') {
      unregister(`identifier`);
    }
  }, [watchIdentifierType]);

  if (entityType === EntityTypeEnum.llc) {
    return (
      <div>
        <div>
          <TextField
            name="first_name"
            label="First name"
            error={errors.first_name}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="last_name"
            label="Last name"
            error={errors.last_name}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <DateField
            name="birth_date"
            label="Birth Date"
            error={errors.birth_date}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="title"
            label="Title"
            error={errors.title}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="email"
            label="Email"
            error={errors.email}
            reactFormConfig={{
              required: 'This is required',
              pattern: EmailPattern,
            }}
          />
          <TextField
            name="phone_number"
            label="Phone Number"
            error={errors.phone_number}
            reactFormConfig={{
              required: 'This is required',
              pattern: AnyPhonePattern,
            }}
          />
          {!isEdit && (
            <>
              <SelectField
                name="identifier_type"
                label="Identifier Type"
                error={errors.identifier_type}
                description="Choose either SSN or ITIN as a form of identification."
                reactFormConfig={{
                  required: 'This is required',
                }}
                options={[
                  { name: 'Choose one', value: '' },
                  {
                    name: 'Does not have SSN or ITIN',
                    value: 'does_not_have',
                  },
                  { name: 'SSN', value: 'ssn' },
                  { name: 'ITIN', value: 'itin' },
                ]}
              />
              {(watchIdentifierType === 'ssn' ||
                watchIdentifierType === 'itin') && (
                <TextField
                  name="identifier"
                  label="SSN or ITIN"
                  error={errors.identifier}
                  reactFormConfig={{
                    pattern: IdentifierPattern,
                    required: 'This is required',
                  }}
                />
              )}
            </>
          )}
        </div>
        <DateField
          name="ownership_start_date"
          label="Date of Ownership"
          error={errors.ownership_start_date}
          reactFormConfig={{
            required: 'This is required',
          }}
        />
        <NumberField
          name="ownership_percentage"
          label="Percent Ownership"
          min="0"
          error={errors.ownership_percentage}
          reactFormConfig={{
            required: 'This is required',
          }}
        />
        <div className="mt-8">
          <div>
            <NonUSAddress
              label="Home Address"
              description="Some government agencies require personal addresses, but Mosey will use the business address wherever possible for privacy."
              error={errors.personal_address}
              name="personal_address"
              defaultCountry={CountryCode.US}
            />
          </div>
        </div>
      </div>
    );
  }

  if (entityType === EntityTypeEnum.c_corp) {
    const showOfficerFields =
      personType === 'is_officer' || personType === 'is_both_officer_and_owner';
    const showOwnerFields =
      personType === 'is_owner' || personType === 'is_both_officer_and_owner';

    return (
      <div>
        <div>
          <TextField
            name="first_name"
            label="First name"
            error={errors.first_name}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="last_name"
            label="Last name"
            error={errors.last_name}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <DateField
            name="birth_date"
            label="Birth Date"
            error={errors.birth_date}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="title"
            label="Title"
            error={errors.title}
            reactFormConfig={{
              required: 'This is required',
            }}
          />
          <TextField
            name="email"
            label="Email"
            error={errors.email}
            reactFormConfig={{
              required: 'This is required',
              pattern: EmailPattern,
            }}
          />
          <TextField
            name="phone_number"
            label="Phone Number"
            error={errors.phone_number}
            reactFormConfig={{
              required: 'This is required',
              pattern: AnyPhonePattern,
            }}
          />
          {!isEdit && (
            <>
              <SelectField
                name="identifier_type"
                label="Identifier Type"
                error={errors.identifier_type}
                description="Choose either SSN or ITIN as a form of identification."
                reactFormConfig={{
                  required: 'This is required',
                }}
                options={[
                  { name: 'Choose one', value: '' },
                  {
                    name: 'Does not have SSN or ITIN',
                    value: 'does_not_have',
                  },
                  { name: 'SSN', value: 'ssn' },
                  { name: 'ITIN', value: 'itin' },
                ]}
              />
              {(watchIdentifierType === 'ssn' ||
                watchIdentifierType === 'itin') && (
                <TextField
                  name="identifier"
                  label="SSN or ITIN"
                  error={errors.identifier}
                  reactFormConfig={{
                    pattern: IdentifierPattern,
                    required: 'This is required',
                  }}
                />
              )}
            </>
          )}
          {showOfficerFields && (
            <DateField
              name="start_date"
              label="Start Date"
              error={errors.start_date}
              reactFormConfig={{
                required: 'This is required',
              }}
            />
          )}
          <div className="mt-8">
            <div>
              <NonUSAddress
                label="Home Address"
                description="Some government agencies require personal addresses, but Mosey will use the business address wherever possible for privacy."
                error={errors.personal_address}
                name="personal_address"
                defaultCountry={CountryCode.US}
              />
            </div>
          </div>
        </div>
        {showOwnerFields && (
          <div>
            <DateField
              name="ownership_start_date"
              label="Date of Ownership"
              error={errors.ownership_start_date}
              reactFormConfig={{
                required: 'This is required',
              }}
            />
            <NumberField
              name="ownership_percentage"
              label="Percent Ownership"
              min="0"
              error={errors.ownership_percentage}
              reactFormConfig={{
                required: 'This is required',
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return <></>;
};
