import { FunctionComponent, useState, useCallback, Fragment } from 'react';
import { clsx } from 'clsx';
import {
  Requirement,
  RequirementStatus,
  Remediation,
  RemediationDisplayType,
} from '../types';
import { fetchApi } from '../utils/fetchApi';
import { CheckIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { RemediationDisplay } from '.';

type RemediationStatusProps = {
  isFirst: boolean;
  isLast: boolean;
  isDone: boolean;
  isDisabled: boolean;
};

const RemediationStatus: FunctionComponent<RemediationStatusProps> = ({
  isFirst,
  isLast,
  isDone,
  isDisabled,
}) => {
  const renderThroughLine = () => {
    // Handle single item
    if (isFirst && isLast) {
      return <></>;
    }

    if (isFirst) {
      return (
        <div className="flex h-full items-end justify-center">
          <div className="pointer-events-none h-1/2 border-l" />
        </div>
      );
    }

    if (isLast) {
      return (
        <div className="flex h-full items-start justify-center">
          <div className="pointer-events-none h-1/2 border-l" />
        </div>
      );
    }

    return (
      <>
        <div className="flex h-full items-start justify-center">
          <div className="pointer-events-none h-full border-l" />
        </div>
      </>
    );
  };

  return (
    <div className="relative z-10 w-8 shrink-0">
      {renderThroughLine()}
      <div
        className={clsx(
          'absolute left-1/2 top-1/2 z-40 flex size-9 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-2 p-1',
          {
            'border-rose-600 bg-white': !isDone && !isDisabled,
            'border-lime-500 bg-lime-500': isDone,
            'border-gray-200 bg-white': isDisabled,
          },
        )}
      >
        <span
          className={clsx('size-6 fill-current', {
            'text-rose-500': !isDone && !isDisabled,
            'text-white': isDone,
            'text-gray-300': isDisabled,
          })}
        >
          {isDone && <CheckIcon />}
        </span>
      </div>
    </div>
  );
};

type RemediationsListRowProps = {
  requirement: Requirement;
  onChange: () => void;
  remediation: Remediation;
  isFirst: boolean;
  isLast: boolean;
  isDisabled: boolean;
};

const RemediationsListRow: FunctionComponent<RemediationsListRowProps> = ({
  requirement,
  onChange,
  remediation,
  isFirst,
  isLast,
  isDisabled,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleRemediationIsDone = useCallback(() => {
    setIsLoading(true);

    const isDone = !remediation.is_done;

    // In order to call async functions in a hook you need to wrap it
    // in a function then call it
    // https://github.com/facebook/react/issues/14326#issuecomment-441680293
    async function updateRemediation() {
      const _requirement = requirement;
      const { remediations } = _requirement;
      const rem = remediations.find((i) => i.id === remediation.id);

      // Make the type checker happy
      if (!rem) {
        return;
      }
      rem.is_done = isDone;

      // Update the requirement status
      // NOTE: This is exactly what the backend server does when a
      // remediation is updated. We are duplicating the logic here to
      // avoid doing another API call and slowing down the UI
      const completedRemediationsCount = remediations.filter(
        (r) => r.is_done,
      ).length;
      if (completedRemediationsCount === remediations.length) {
        _requirement.status = RequirementStatus.Done;
      } else {
        _requirement.status = RequirementStatus.Todo;
      }

      await fetchApi({
        url: `/api/requirements/${requirement.id}/remediation/${remediation.id}`,
        method: 'PUT',
        body: { is_done: isDone },
      });

      onChange();

      // Add a timeout here so it never flashes on the screen
      setTimeout(() => setIsLoading(false), 400);
    }
    updateRemediation();
  }, [requirement, remediation]);

  return (
    <div className="relative grid grid-cols-12 justify-between gap-x-12 px-8">
      <div className="col-span-9 flex w-full">
        <RemediationStatus
          isFirst={isFirst}
          isLast={isLast}
          isDone={remediation.is_done}
          isDisabled={isDisabled}
        />
        <div className="flex w-full flex-col justify-center p-6">
          <p
            className={clsx('text-sm font-semibold', {
              'text-gray-400': isDisabled,
              'text-gray-900': !isDisabled,
            })}
          >
            {remediation.title}
          </p>
          <p
            className={clsx('mt-1 text-sm', {
              'text-gray-400': isDisabled,
              'text-gray-500': !isDisabled,
            })}
          >
            {remediation.description}
          </p>
          {Array.isArray(remediation.display) ? (
            <div>
              {remediation.display.map((d) => (
                <Fragment key={`${remediation.id}-${d.type}`}>
                  <RemediationDisplay display={d} />
                </Fragment>
              ))}
            </div>
          ) : (
            remediation.display.type !== RemediationDisplayType.Default && (
              <div className="flex">
                <RemediationDisplay hasBorder display={remediation.display} />
              </div>
            )
          )}
        </div>
      </div>
      <div className="col-span-3 flex items-center justify-end">
        <Button
          size="small"
          className="w-32"
          variant={remediation.is_done ? 'actionSuccess' : 'secondary'}
          leftIcon={<CheckIcon className="size-4" />}
          onClick={toggleRemediationIsDone}
          isDisabled={isDisabled}
          isLoading={isLoading}
        >
          {remediation.is_done ? 'Mark To Do' : 'Mark Done'}
        </Button>
      </div>
    </div>
  );
};

type RemediationsListProps = {
  // Parent requirement, this is needed for updating when a
  // remediation changes
  requirement: Requirement;
  // A callback when the requirement changes
  onChange: () => void;
  isDisabled: boolean;
};

export const RemediationsList: FunctionComponent<RemediationsListProps> = ({
  requirement,
  onChange,
  isDisabled,
}) => (
  <article className="grid w-full overflow-x-auto border">
    <h3 className="border-b px-6 py-3 text-left text-sm font-bold tracking-wider">
      Steps
    </h3>
    <main>
      <ul className="grid w-full divide-y">
        {requirement.remediations.length > 0 ? (
          requirement.remediations.map((r: Remediation, idx: number) => (
            <RemediationsListRow
              key={r.id}
              requirement={requirement}
              onChange={onChange}
              remediation={r}
              isFirst={idx === 0}
              isLast={idx === requirement.remediations.length - 1}
              isDisabled={isDisabled}
            />
          ))
        ) : (
          <div className="flex h-64 w-full items-center justify-center">
            <p className="italic text-gray-400">No requirements found</p>
          </div>
        )}
      </ul>
    </main>
  </article>
);

export default RemediationsList;
