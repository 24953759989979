import { clsx } from 'clsx';
import { NavLink, NavLinkProps } from 'react-router-dom';

type PageNavLinkProps = Omit<NavLinkProps, 'className'> & {
  children: React.ReactNode;
  count?: number;
};

export const PageNavLink = ({ children, count, ...rest }: PageNavLinkProps) => {
  return (
    <NavLink
      className="group flex cursor-pointer items-center gap-1 pt-1.5 outline-none"
      {...rest}
    >
      {({ isActive }) => (
        <>
          <div
            className={clsx('border-b-[3px] pb-[3px]', {
              'border-rose-700 hover:border-rose-900': isActive,
              'border-transparent group-hover:border-teal-500': !isActive,
            })}
          >
            <div
              className={clsx(
                'min-h-5 rounded border-2 border-transparent px-1 text-sm font-medium text-gray-500 group-focus-visible:border-rose-700',
                {
                  'text-rose-700 group-hover:text-rose-900 group-hover:group-focus-visible:border-rose-900':
                    isActive,
                },
              )}
            >
              {children}
            </div>
          </div>
          {count !== undefined && (
            <div
              className={clsx(
                'mb-1.5 flex size-6 items-center justify-center rounded-full bg-gray-200 text-center text-sm font-medium text-gray-500',
                {
                  'text-rose-700': isActive,
                },
              )}
              aria-label={`(${count} items)`}
            >
              {count}
            </div>
          )}
        </>
      )}
    </NavLink>
  );
};
