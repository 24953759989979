import { FC, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { isAuthenticated } from '@mosey/utils/auth';
import { FilledLockIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import logo from '../assets/logo.svg';
import { login } from '../utils/auth';
import { TextField } from '@mosey/components/forms/TextField';
import { ApiStatus } from '../utils/types';

type LoginIFormValue = {
  email: string;
  password: string;
};

export const Login: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>('');

  const formMethods = useForm<LoginIFormValue>();
  const { handleSubmit } = formMethods;
  const redirect = searchParams.get('redirect') || '/';

  const onSubmit = async (data: LoginIFormValue) => {
    setError('');
    try {
      const { email, password } = data;
      const response = await login(email, password);

      if (response.status === ApiStatus.Error) {
        setError(response.error.message);
      } else {
        navigate(redirect, { replace: true });
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        setError("Something went wrong, we're looking into it.");
      }
    }
  };

  return isAuthenticated() ? (
    <Navigate to={redirect} replace />
  ) : (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="mb-10 w-full max-w-md pb-4">
        <div className="w-20">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <FormProvider {...formMethods}>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <BlockAlert show={!!error} variant="error" message={error} />
            <input type="hidden" name="remember" value="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <TextField
                hasMargin={false}
                inputClassName="relative focus:z-10 rounded-b-none"
                name="email"
                placeholder="Email address"
                type="email"
              />
              <TextField
                hasMargin={false}
                inputClassName="relative focus:z-10 rounded-t-none"
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>

            <div className="flex items-center justify-end">
              <a
                href="/password-reset"
                className="font-medium text-teal-600 hover:text-teal-700"
              >
                Forgot your password?
              </a>
            </div>

            <div>
              <Button
                type="submit"
                size="large"
                leftIcon={<FilledLockIcon className="size-5" />}
                isFullWidth
              >
                Sign in
              </Button>
            </div>
            <div className="block text-center">
              <Link
                to={`/login/sso?${searchParams.toString()}`}
                className="mx-auto w-full font-medium text-teal-600 hover:text-teal-700"
              >
                Single sign-on
              </Link>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
