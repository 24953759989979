import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import {
  BatchApiStatusHandler,
  SectionHeading,
  FormPeople,
  BackButton,
  SelectField,
} from '../../components';
import { useBatchApi } from '../../hooks';
import { EntityTypeEnum, Person, PersonIFormValues } from '../../types';
import { FatalError } from '../FatalError';
import { fetchApi } from '../../utils/fetchApi';
import { ApiStatus } from '../../utils/types';
import { cleanPeopleValues } from './OfficersAndOwnersCreate';
import { cleanIntakeDates, unregisterByPersonType } from '../../utils/intake';
import { useUser } from '../../hooks/useUser';

type PeopleAndOwnersEditProps = {
  peopleData: Person[];
  personId?: string;
};

const getPersonType = (person: Person) => {
  if (person.is_owner && person.is_officer) {
    return 'is_both_officer_and_owner';
  }

  if (person.is_owner) {
    return 'is_owner';
  }
  return 'is_officer';
};

export const PeopleAndOwnersEdit: FunctionComponent<
  PeopleAndOwnersEditProps
> = ({ peopleData, personId }) => {
  const {
    legal_entity: { entity_type: entityType },
  } = useUser();
  const currentPerson = peopleData.find((person) => person.id === personId);

  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (!currentPerson) {
    return <FatalError />;
  }

  const isOwner = currentPerson.is_owner;
  const isOfficer = currentPerson.is_officer;

  const personDefaultValues = {
    first_name: currentPerson.first_name,
    last_name: currentPerson.last_name,
    title: currentPerson.title,
    email: currentPerson.email,
    phone_number: currentPerson.phone,
    birth_date: currentPerson.birth_date,
    personal_address: {
      city: currentPerson.address?.city || null,
      state: currentPerson.address?.state || null,
      postal_code: currentPerson.address?.postal_code || null,
      address_line_1: currentPerson.address?.address_line_1 || null,
      address_line_2: currentPerson.address?.address_line_2 || '',
      country: currentPerson.address?.country,
    },
  };

  const ownerDefaultValues = {
    ownership_start_date: currentPerson.ownership_start_date,
    ownership_percentage: currentPerson.ownership_percentage,
  };

  const officerDefaultValues = {
    start_date: currentPerson.start_date,
  };

  const defaultValuesMapping: Record<EntityTypeEnum, object> = {
    [EntityTypeEnum.llc]: {
      person_type: 'is_member',
      ...personDefaultValues,
      ...ownerDefaultValues,
    },
    [EntityTypeEnum.c_corp]: {
      is_owner: currentPerson.is_owner,
      is_officer: currentPerson.is_officer,
      person_type: getPersonType(currentPerson),
      ...personDefaultValues,
      ...(isOwner && ownerDefaultValues),
      ...(isOfficer && officerDefaultValues),
    },
    // TODO: Add default values for other entity types
    [EntityTypeEnum.gp]: {},
    [EntityTypeEnum.llp]: {},
    [EntityTypeEnum.lp]: {},
    [EntityTypeEnum.unset]: {},
  };
  const defaultVals = defaultValuesMapping[entityType];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formMethods = useForm<PersonIFormValues>({
    mode: 'onBlur',
    defaultValues: defaultVals,
  });

  const {
    watch,
    handleSubmit,
    clearErrors,
    reset,
    unregister,
    setValue,
    getFieldState,
    formState: { isDirty, isValid, errors },
  } = formMethods;

  // ~HACKY~ The next 2 useEffects are needed for personal address form behavior --
  // For some reason react-hook-form isn't deeply registering the address values;
  // without manually saving and watching those values, editing these fields don't
  // trigger the isDirty state needed for saving the form
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setValue('personal_address', personDefaultValues.personal_address);
  }, []);
  const watchPersonalAddress = watch('personal_address');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getFieldState('personal_address');
  }, [JSON.stringify(watchPersonalAddress)]);

  const onCancel = () => {
    reset(defaultVals);
  };

  const onSubmit = async (formData: PersonIFormValues) => {
    if (!isValid) return;

    setHasSubmitError(false);
    clearErrors();
    setIsLoading(true);

    let cleanedData = cleanIntakeDates(formData);
    cleanedData = cleanPeopleValues(cleanedData);

    try {
      const { status } = await fetchApi({
        url: `/api/legal_entity/person/${personId}`,
        method: 'PUT',
        body: cleanedData,
      });

      if (
        status === ApiStatus.Error ||
        status === ApiStatus.ErrorUnauthorized
      ) {
        setHasSubmitError(true);
      } else {
        reset(cleanedData);
        setSubmitSuccess(true);
        setTimeout(() => setSubmitSuccess(false), 1500);
      }
    } catch (error) {
      setHasSubmitError(true);
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!isDirty) return;

    // Reset validation messages if needed when the user touchs form inputs
    if (hasSubmitError) {
      setHasSubmitError(false);
    } else if (submitSuccess) {
      setSubmitSuccess(false);
    }
  }, [isDirty]);

  const watchPersonType = watch('person_type');

  // Unregister fields when the form structure changes
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    unregisterByPersonType(watchPersonType, unregister);
  }, [watchPersonType]);

  const personTypeOptions =
    entityType === EntityTypeEnum.llc
      ? [{ name: 'A Member', value: 'is_member' }]
      : [
          { name: 'An Officer', value: 'is_officer' },
          { name: 'A Business Owner', value: 'is_owner' },
          {
            name: 'Both an Officer and Business Owner',
            value: 'is_both_officer_and_owner',
          },
        ];

  return (
    <div className="flex flex-col">
      <div className="p-16">
        <div className="mb-6">
          <BackButton />
        </div>

        <SectionHeading className="flex-1 pb-4" text="Edit Profile" />

        <div className="flex flex-col py-9 xl:max-w-xl">
          <section>
            <FormProvider {...formMethods}>
              <form>
                <SelectField
                  name="person_type"
                  label="They are..."
                  error={errors.person_type}
                  options={personTypeOptions}
                  reactFormConfig={{
                    required: 'This is required',
                  }}
                />
                <FormPeople personType={watchPersonType} isEdit />
              </form>
            </FormProvider>
          </section>
        </div>
      </div>
      {(isDirty || submitSuccess) && (
        <div className="b-0 l-0 sticky bottom-0 flex h-[72px] items-center justify-end border-t border-gray-200 bg-sage-50 px-6 py-4">
          {submitSuccess && (
            <div className="ml-3 flex items-center text-lg text-lime-500">
              <span>
                <CheckCircleIcon className="mr-1 size-5" />
              </span>
              <div>Person information has been updated!</div>
            </div>
          )}

          {hasSubmitError && (
            <div className="ml-3 flex items-center text-lg text-red-600">
              <span>
                <ErrorIcon className="mr-1 size-5" />
              </span>
              <div>Something went wrong, please try again.</div>
            </div>
          )}

          {!submitSuccess && (
            <div className="flex gap-x-2">
              <Button
                type="button"
                variant="secondary"
                onClick={onCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>

              <div className="w-28">
                <Button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  isFullWidth
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const PeopleEditView: FunctionComponent = () => {
  const { personId } = useParams<Record<string, string>>();
  const batchResponse = useBatchApi([
    {
      url: '/api/legal_entity/people',
      method: 'GET',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([peopleData]: any[]): PeopleAndOwnersEditProps => {
    return {
      peopleData,
      personId,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={PeopleAndOwnersEdit}
      componentProps={componentPropsFn}
    />
  );
};
