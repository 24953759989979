import { Donut, DonutDatum } from '@mosey/components/charts/Donut';
import { useMemo } from 'react';
import { HandbookDataLoader } from '../types';
import { Link, LinkProps, useRouteLoaderData } from 'react-router-dom';

type SignaturesDonutProps = {
  to?: LinkProps['to'];
};

export const SignaturesDonut = ({ to }: SignaturesDonutProps) => {
  const {
    stats: {
      acknowledgements: { outdated, pending, signed },
      handbooks: { published },
    },
  } = useRouteLoaderData('handbook') as HandbookDataLoader;
  const { data, percentage } = useMemo(() => {
    const total = signed + pending + outdated;
    const result = {
      data: [
        {
          name: 'Signed',
          value: signed,
          theme: 'primary',
        },
        {
          name: 'Pending',
          value: pending,
          theme: 'secondary',
        },
      ] as Array<DonutDatum>,
      percentage: total > 0 ? signed / total : 0,
    };
    if (published > 1) {
      result.data.push({
        name: 'Out of date',
        value: outdated,
        theme: 'amber',
      });
    }
    return result;
  }, [signed, outdated, pending, published]);
  const donut = (
    <Donut data={data} summaryPercentage={percentage} isClickable={!!to} />
  );

  if (to) {
    return (
      <Link
        to={to}
        className="cursor-pointer rounded focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-600"
      >
        {donut}
      </Link>
    );
  } else {
    return donut;
  }
};
