import { Interstitial, InterstitialProps } from '../common/Interstitial';
import { SetupGraphic } from './SetupGraphic';

type SetupInterstitialProps = Omit<
  InterstitialProps,
  'children' | 'title' | 'description'
> &
  Partial<Pick<InterstitialProps, 'title' | 'description'>>;

export const SetupInterstitial = ({
  title = 'Complete setup tasks',
  description = 'Automate many tedious processes. For everything else, follow step-by-step instructions with resources to help you along the way.',
  ...props
}: SetupInterstitialProps) => {
  return (
    <Interstitial title={title} description={description} {...props}>
      <div className="-mb-10 max-w-[860px]">{SetupGraphic}</div>
    </Interstitial>
  );
};
