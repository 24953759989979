import { HTMLProps } from 'react';
import clsx from 'clsx';

type DataRowProps = Omit<HTMLProps<HTMLTableRowElement>, 'className'>;

export const TableRow = ({ children, ...rest }: DataRowProps) => {
  return (
    <tr
      {...rest}
      className={clsx('align-top', {
        'cursor-pointer hover:bg-gray-50': rest.onClick,
      })}
    >
      {children}
    </tr>
  );
};
