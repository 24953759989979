import { FunctionComponent, useState, useCallback } from 'react';
import { clsx } from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@mosey/components/Icons';

type TextTruncateProps = {
  text: string;
  className?: string;
};

/* Note: The number of lines to truncate to needs to be static
otherwise the css classes won't be generated by tailwind. If you need
a different number of lines, create a new component */
export const TextTruncateThree: FunctionComponent<TextTruncateProps> = ({
  text,
  className,
}) => {
  // Measure the element to determine the number of lines and use a
  // clamp to truncate it. Show a toggle to expand the truncated text.

  const [shouldTruncate, setShouldTruncate] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      // Before the component mounts the node ref will be null
      if (node?.parentElement) {
        // Calculate the number of lines based on height
        const elHeight = node.offsetHeight;
        const styles = window.getComputedStyle(node);
        const lineHeight = styles
          .getPropertyValue('line-height')
          .replace('px', '');
        const elLineCount = elHeight / parseInt(lineHeight, 10);

        setShouldTruncate(elLineCount > 3);
      }
    },
    [text],
  );

  const shouldClamp = shouldTruncate && !readMore;

  return (
    <div>
      <p
        ref={measuredRef}
        className={clsx(
          'leading-6',
          {
            'line-clamp-3': shouldClamp,
            'line-clamp-none': !shouldClamp,
          },
          className,
        )}
      >
        {text}
      </p>
      {shouldTruncate && (
        <span
          className="cursor-pointer leading-6 text-rose-700"
          onClick={() => setReadMore((prevState) => !prevState)}
        >
          {readMore ? 'Show less' : 'Read more'}
        </span>
      )}
    </div>
  );
};

export const TextTruncateOne: FunctionComponent<TextTruncateProps> = ({
  text,
  className,
}) => {
  // Measure the element to determine the number of lines and use a
  // clamp to truncate it. Show a toggle to expand the truncated text.
  const [shouldTruncate, setShouldTruncate] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);

  const measuredRef = useCallback(
    (node: HTMLElement | null) => {
      // Before the component mounts the node ref will be null
      if (node?.parentElement) {
        // Calculate the number of lines based on height
        const elHeight = node.offsetHeight;
        const styles = window.getComputedStyle(node);
        const lineHeight = styles
          .getPropertyValue('line-height')
          .replace('px', '');
        const elLineCount = elHeight / parseInt(lineHeight, 10);

        setShouldTruncate(elLineCount > 1);
      }
    },
    [text],
  );

  const shouldClamp = shouldTruncate && !readMore;

  return (
    <div className="flex w-full">
      <div className="flex-1">
        <p
          ref={measuredRef}
          className={clsx(
            'leading-6',
            {
              'line-clamp-1': shouldClamp,
              'line-clamp-none': !shouldClamp,
            },
            className,
          )}
        >
          {text}
        </p>
        {shouldTruncate && (
          <span
            className="cursor-pointer leading-6 text-rose-700"
            onClick={() => setReadMore((prevState) => !prevState)}
          >
            {readMore ? 'Show less' : 'Read more'}
          </span>
        )}
      </div>
      {shouldTruncate && (
        <button
          className="ml-10 flex self-start text-zinc-600 hover:text-zinc-700"
          type="button"
          onClick={() => setReadMore((prevState) => !prevState)}
        >
          {readMore ? (
            <ChevronUpIcon className="size-6" />
          ) : (
            <ChevronDownIcon className="size-6" />
          )}
        </button>
      )}
    </div>
  );
};
