import { FieldError } from './FieldError';
import { TaskQuestionFieldBaseProps } from './types';

export const TaskDateField = ({
  field: {
    name,
    label,
    description,
    required,
    component: {
      params: { min, max, step },
    },
  },
  value,
  errors = {},
}: TaskQuestionFieldBaseProps) => {
  const error = errors[name];
  const descriptionId = `description-${name}`;
  const errorMessageId = `form-field-error-message-${name}`;

  return (
    <>
      <div className="mb-6 flex flex-col gap-y-2">
        <label htmlFor={name} className="block max-w-3xl cursor-pointer">
          {label}
        </label>
        {description && <p id={descriptionId}>{description}</p>}
      </div>

      <input
        type="date"
        name={name}
        id={name}
        min={min}
        max={max}
        value={value === null ? undefined : value}
        required={Boolean(required)}
        step={step}
        className="h-20 w-fit cursor-text rounded border border-stone-200 px-6 text-center text-2xl font-bold leading-[48px] text-zinc-800 transition-shadow duration-150 hover:border-gray-300 focus:border-teal-500 focus:ring-teal-500"
        aria-describedby={[
          description ? descriptionId : '',
          errorMessageId,
        ].join(' ')}
      />

      <FieldError id={errorMessageId} error={Boolean(error)}>
        {error}
      </FieldError>
    </>
  );
};
