// Renderer.js
import { FunctionComponent, createElement } from 'react';
import * as Sentry from '@sentry/react';
import { FormSpecField, Person } from '../../types';
import { VisibilityWrapper } from './VisibilityWrapper';

export type RendererConfig = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  children?: RendererConfig[] | FormSpecField[];
};

export type RendererProps = {
  config: RendererConfig[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  // TODO: This feels awkward to put something so specific like this
  // in a generic abstraction for rendering forms. We should make a
  // more generic `data` prop that can be typed for the form.
  peopleData?: Person[];
};

export const Renderer: FunctionComponent<RendererProps> = ({
  config,
  errors,
  peopleData,
}) => {
  if (!config) {
    throw new Error('You are calling Renderer with no config.');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderer = (items: any) => (
    <>
      {items.map(
        (
          /* eslint-disable @typescript-eslint/no-explicit-any */ item: any /* eslint-enable @typescript-eslint/no-explicit-any */,
        ) => {
          const error = errors?.[item.props.fieldName];
          const el = createElement(
            item.component,
            {
              ...item.props,
              key: `wrap-${item.props.name}`,
              error,
              peopleData,
            },
            item.children &&
              (typeof item.children === 'string'
                ? item.children
                : renderer(item.children)),
          );
          if (error && item.props.hidden) {
            Sentry.captureException(
              new Error(`Hidden error on form field: ${error?.ref?.id}`),
            );
          }
          if (
            item.props.fieldDependencies &&
            item.props.fieldDependencies.length > 0
          ) {
            return createElement(
              VisibilityWrapper,
              {
                key: `wrap-${item.props.name}`,
                name: item.props.name,
                fieldDependencies: item.props.fieldDependencies,
              },
              el,
            );
          }
          if (item.props.fieldRules && item.props.fieldRules.length > 0) {
            return createElement(
              VisibilityWrapper,
              {
                key: `wrap-${item.props.name}`,
                name: item.props.name,
                fieldRules: item.props.fieldRules,
              },
              el,
            );
          }
          return el;
        },
      )}
    </>
  );

  return renderer(config);
};
