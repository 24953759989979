import { useMemo } from 'react';
import { usePolicyList } from './usePolicyList';

export function usePolicySection(id: string) {
  const { sections } = usePolicyList();
  const { currentSection, nextSection } = useMemo(() => {
    for (let index = 0; index < sections.length; index++) {
      if (sections[index].section.id === id) {
        return {
          currentSection: sections[index],
          nextSection: sections[index + 1],
        };
      }
    }
    return {
      currentSection: sections[0],
      nextSection: sections[1],
    };
  }, [sections, id]);
  const actionableItems = currentSection.items.filter(
    (item) => item.status === 'adoptable' || item.status === 'updateable',
  );
  return {
    currentSection,
    nextSection,
    actionableItems,
    hasActionableItems: actionableItems.length > 0,
  };
}
