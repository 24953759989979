import { useEffect, useState } from 'react';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IFetchApi, IApiData } from '../utils/types';

/*
Hook for fetching data via `fetchApi` from the backend API. Returns an
`IApiData` object. See `ApiStatus` for which states need to be
handled.

Args:
- IFetchApi
  - url: the URL to query
  - method: the request method
  - body: HTTP body to send, defaults to `{}`, does not send a body
    for `GET` requests
- extraDeps: an array of hook dependencies (when changed the hook runs
  again). Defaults to `[]` (no dependencies).
*/
export const useApi = (
  { url, body = {}, method, isPlatform }: IFetchApi,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraDeps: any[] = [],
): IApiData => {
  const [data, setData] = useState<IApiData>({
    status: ApiStatus.Loading,
    error: null,
    data: null,
  });

  useEffect(() => {
    fetchApi({ url, method, body, isPlatform }).then((apiData) => {
      setData(apiData);
    });
  }, [...extraDeps]); // eslint-disable-line react-hooks/exhaustive-deps

  return data;
};

/*
  The same as `useApi`, but operates on an array API requests to call
  concurrently. Responses are returned in the order they are
  requested which allows the caller to map requests to responses.
*/
export const useBatchApi = (
  requests: IFetchApi[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraDeps: any[] = [],
): IApiData[] => {
  const initData = requests.map(() => {
    return {
      status: ApiStatus.Loading,
      error: null,
      data: null,
    };
  });
  const [data, setData] = useState<IApiData[]>(initData);

  useEffect(() => {
    requests.map(({ url, body = {}, method }, idx) => {
      return fetchApi({ url, method, body }).then((apiData) => {
        setData((prev) => {
          const next = [...prev];
          // This is important to preserve ordering such that request
          // order equals the returned response data order.
          next[idx] = apiData;
          return next;
        });
      });
    });
  }, [...extraDeps]); // eslint-disable-line react-hooks/exhaustive-deps

  return data;
};

/**
 * This Hook allows you to make API requests by structuring your calls as an
 * object with named keys.
 *
 * This allows routes to make calls conditionally since an array of responses
 * (like with useBatchApi) requires the number of calls and their order to be
 * deterministic.
 */
export const useKeyedBatchApi = (
  requests: { [key: string]: IFetchApi },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraDeps: any[] = [],
): { [key: string]: IApiData } => {
  const initData = Object.entries(requests).reduce(
    (acc, [key]) => ({
      ...acc,
      [key]: {
        status: ApiStatus.Loading,
        error: null,
        data: null,
      },
    }),
    {},
  );
  const [data, setData] = useState<{ [key: string]: IApiData }>(initData);

  useEffect(() => {
    Object.entries(requests).map(
      ([key, { url, body = {}, method, isPlatform }]) => {
        return fetchApi({ url, method, body, isPlatform }).then((apiData) => {
          setData((d) => ({ ...d, [key]: apiData }));
        });
      },
    );
  }, extraDeps);

  return data;
};
