import {
  convertDateStringToLocalDate,
  getLocalTodayMidnight,
} from '@mosey/utils/dates';
import {
  BaseTask,
  ResolverType,
  Task,
  TaskRef,
  TaskStatus,
  TaskType,
} from './types';
import { formatDateFromString } from '../../../utils/format';

export const isTaskSetupRelated = ({
  source: { is_setup_related: isSetupRelated },
}: TaskRef) => {
  return isSetupRelated;
};

export const isTaskTodo = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.todo;
};

export const isTaskDone = ({ status }: Task | TaskRef) => {
  return status === TaskStatus.done;
};

export const isTaskOverdue = ({ status, source }: Task | TaskRef) => {
  if (source.type === TaskType.requirement && 'due_date' in source) {
    const { due_date: dueDate, managed_provider: managedProvider } = source;

    if (managedProvider) {
      return false;
    }

    if (dueDate && status === TaskStatus.todo) {
      const localToday = getLocalTodayMidnight();
      const localDueDate = convertDateStringToLocalDate(dueDate);

      return localDueDate < localToday;
    }
  }

  return false;
};

/**
 * Automated here is past tense, so the task must have status of done. This is
 * distinct from managed or in progress tasks.
 */
export const isTaskAutomated = (task: TaskRef) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'done' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export const isTaskManaged = (task: TaskRef) => {
  return (
    task.source.type === 'requirement' &&
    task.source.managed_provider &&
    task.source.managed_provider.name !== 'Mosey'
  );
};

export const isTaskInProgress = (task: TaskRef) => {
  return (
    task.source.type === 'requirement' &&
    task.status === 'in-progress' &&
    task.source.managed_provider?.name === 'Mosey'
  );
};

export const getNextTask = (
  resolverType: string | undefined,
  tasks: TaskRef[],
  taskId?: BaseTask['id'],
) => {
  if (taskId) {
    const currentIndex = tasks.findIndex(({ id }) => id === taskId) + 1;

    if (resolverType === ResolverType.Review) {
      return tasks.at(currentIndex);
    }

    return tasks
      .slice(currentIndex)
      .concat(tasks.slice(0, currentIndex))
      .find(isTaskTodo);
  }

  return tasks.find(isTaskTodo);
};

export const getTaskDueDate = ({ source }: Task | TaskRef) => {
  return source.type === TaskType.requirement &&
    'due_date' in source &&
    source.due_date
    ? formatDateFromString(source.due_date, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : null;
};
