import { ComponentType } from 'react';

interface LearnMoreBlockProps {
  Icon: ComponentType<
    Pick<React.ButtonHTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
  >;
  title: React.ReactNode;
  children: React.ReactNode;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const LearnMoreBlock = ({
  Icon,
  title,
  children,
  headingLevel = 'h2',
}: LearnMoreBlockProps) => {
  const Heading = headingLevel;

  return (
    <div className="break-inside-avoid space-y-3">
      <Heading className="flex items-center gap-x-2 font-medium leading-6">
        <Icon className="size-6" aria-hidden="true" />
        {title}
      </Heading>

      {children}
    </div>
  );
};
