import { useState, useRef, useEffect } from 'react';
import { PolicyContentType } from '@mosey/components/handbook/types';
import { PolicyMarkdown } from './PolicyMarkdown';

type ExpandablePolicyMarkdownProps = {
  content: string;
};

export const ExpandablePolicyMarkdown = ({
  content,
}: ExpandablePolicyMarkdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight,
      );
    }
  }, [content]);

  return (
    <>
      <div
        ref={contentRef}
        className={`relative mt-0.5 rounded text-sm text-amber-900 ${isExpanded ? '' : 'max-h-16'} ${
          isExpanded || !isOverflowing
            ? ''
            : 'overflow-hidden [mask-image:linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0))]'
        }`}
      >
        <PolicyMarkdown
          content={{
            type: PolicyContentType.Markdown,
            content: content,
          }}
        />
      </div>
      {isOverflowing && (
        <button
          onClick={toggleExpand}
          type="button"
          className="text-teal-800 hover:text-teal-900 font-semibold text-sm pt-2"
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </button>
      )}
    </>
  );
};
