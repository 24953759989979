import { FunctionComponent, useState } from 'react';
import { clsx } from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  GovernmentIcon,
} from '@mosey/components/Icons';

import { RedactableTextField } from '.';
import { Badge } from './alerts/Badge';
import { LoginWithAgencies } from '../types';
import { GuidedCredentials } from './accounts/guided_credentials/GuidedCredentials';

enum AccountsTableTabsEnum {
  Credentials = 'credentials',
  Questions = 'questions',
}

type AccountsTableRowProps = {
  login: LoginWithAgencies;
  isExpanded: boolean;
  shouldRenderCredentials: boolean;
  onExpand: () => void;
};

const AccountsTableRow: FunctionComponent<AccountsTableRowProps> = ({
  login,
  isExpanded,
  shouldRenderCredentials,
  onExpand,
}) => {
  const formMethods = useForm({
    defaultValues: {
      username: login.username,
      password: login.password,
    },
  });

  const [activeTab, setActiveTab] = useState<AccountsTableTabsEnum>(
    AccountsTableTabsEnum.Credentials,
  );

  return (
    <>
      <tr
        role="button"
        onClick={onExpand}
        className={clsx({
          'bg-teal-350': isExpanded,
        })}
      >
        <td className="py-6 pl-8">
          <p className="flex-1 font-semibold">{login.account_name}</p>
        </td>
        <td className="py-6 pr-6">
          <ul>
            {login.agencies.map((agency) => (
              <li key={agency.id} className="flex [&:not(:first-child)]:mt-1.5">
                <GovernmentIcon className="mr-2 size-5 min-w-[20px]" />
                {agency.name}
              </li>
            ))}
          </ul>
        </td>
        <td className="py-6 pr-6">
          <Badge isActive={login.is_active}>
            {login.is_active ? 'Active' : 'Inactive'}
          </Badge>
        </td>
        <td className="py-6 pr-6">
          {isExpanded ? (
            <ChevronUpIcon className="ml-auto size-6 text-sage-600" />
          ) : (
            <ChevronDownIcon className="ml-auto size-6 text-sage-700" />
          )}
        </td>
      </tr>
      {isExpanded && (
        <tr className="bg-sage-100">
          <td colSpan={6}>
            {shouldRenderCredentials ? (
              <div>
                <div className="flex gap-x-9 border-b px-6">
                  <button
                    type="button"
                    onClick={() =>
                      setActiveTab(AccountsTableTabsEnum.Credentials)
                    }
                    className={clsx('relative py-5 font-bold text-gray-500', {
                      "text-rose-700 after:content-[''] after:bg-rose-700 after:w-full after:h-0.5 after:absolute after:left-0 after:bottom-0":
                        activeTab === AccountsTableTabsEnum.Credentials,
                    })}
                  >
                    Credentials
                  </button>
                  {login.security_questions &&
                    login.security_questions.length > 0 && (
                      <button
                        type="button"
                        onClick={() =>
                          setActiveTab(AccountsTableTabsEnum.Questions)
                        }
                        className={clsx(
                          'relative py-5 font-bold text-gray-500',
                          {
                            "text-rose-700 after:content-[''] after:bg-rose-700 after:w-full after:h-0.5 after:absolute after:left-0 after:bottom-0":
                              activeTab === AccountsTableTabsEnum.Questions,
                          },
                        )}
                      >
                        Security Questions
                      </button>
                    )}
                </div>
                <div>
                  <FormProvider {...formMethods}>
                    {activeTab === AccountsTableTabsEnum.Credentials ? (
                      <div className="px-6 pb-16 pt-12">
                        <GuidedCredentials login={login} />
                      </div>
                    ) : (
                      login.security_questions &&
                      login.security_questions.length > 0 &&
                      activeTab === AccountsTableTabsEnum.Questions && (
                        <div className="px-6 py-4">
                          <table className="w-full">
                            <thead>
                              <th className="w-6/12 py-4 text-left">
                                Question
                              </th>
                              <th className="w-6/12 py-4 text-left">Answer</th>
                            </thead>
                            <tbody className="divide-y">
                              {login.security_questions.map((item) => (
                                <tr key={item.question}>
                                  <td className="py-4 font-bold">
                                    <div className="mb-8">{item.question}</div>
                                  </td>
                                  <td className="-mb-8 py-4">
                                    <RedactableTextField
                                      name="answer"
                                      defaultValue={item.answer}
                                      disabled
                                      shouldRenderCopyButton
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )
                    )}
                  </FormProvider>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center bg-sage-100 p-6">
                <h3 className="text-xl font-bold">Contact Mosey Support</h3>
                <p className="text-gray-500">
                  This account type requires help from Mosey Support and cannot
                  be displayed. Please email us at{' '}
                  <a
                    href="mailto:support@mosey.com"
                    className="transition-text font-medium text-teal-600 duration-150 hover:text-teal-700"
                  >
                    support@mosey.com
                  </a>{' '}
                  if you need access.
                </p>
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

type AccountsTableProps = {
  logins: LoginWithAgencies[];
};

export const AccountsTable: FunctionComponent<AccountsTableProps> = ({
  logins,
}) => {
  const [expandedTableRow, setExpandedTableRow] = useState<number | null>(null);

  return (
    <table className="w-full border">
      <thead>
        <tr className="text-sm uppercase text-gray-500">
          <th className="border-b py-3 pl-8 pr-6 text-left font-medium tracking-wider">
            Name
          </th>
          <th className="border-b py-3 pr-6 text-left font-medium tracking-wider">
            Agencies
          </th>
          <th className="border-b py-3 pr-6 text-left font-medium tracking-wider">
            Status
          </th>
          <th className="border-b py-3 pr-6 text-left font-medium tracking-wider">
            {' '}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {logins?.length ? (
          logins.map((login, index) => (
            <AccountsTableRow
              key={login.account_name}
              shouldRenderCredentials={login.is_supported}
              login={login}
              isExpanded={expandedTableRow === index}
              onExpand={() =>
                setExpandedTableRow((prevState) =>
                  prevState === index ? null : index,
                )
              }
            />
          ))
        ) : (
          <tr>
            <td colSpan={6}>
              <div className="flex h-64 items-center justify-items-center">
                <div className="m-auto">
                  <p className="text-gray-400">No accounts found</p>
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
