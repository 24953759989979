import { CheckCircleIcon } from '@heroicons/react/solid';
import { getStatusIconData } from '@mosey/components/layout/StatusIcon';
import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import { RobotWithRefreshIcon } from '@mosey/components/Icons';
import { useLegalEntityRegions } from '../../../hooks/useUser';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../utils/hooks';

export const TasksOverviewStatusFilter = () => {
  const regions = useLegalEntityRegions();
  const { summary } = useTasksOverviewData();
  const locationId = useTasksOverviewLocationId();
  let todoCount = 0;
  let overdueCount = 0;
  let doneCount = 0;
  let automatedCount = 0;
  let managedCount = 0;

  for (let i = 0; i < regions.length; i++) {
    const region = regions[i];

    if (locationId && region.code !== locationId.toUpperCase()) {
      continue;
    }

    const locationSummary = summary[region.code];

    todoCount += locationSummary.todo_count;
    overdueCount += locationSummary.overdue_count;
    doneCount += locationSummary.done_count;
    automatedCount += locationSummary.automated_count;
    managedCount += locationSummary.managed_count;
  }

  return (
    <QueryFilter
      queryKey="status"
      options={[
        {
          label: 'To do',
          value: 'todo',
          count: todoCount,
          icon: getStatusIconData('new'),
        },
        {
          label: 'Overdue',
          value: 'overdue',
          count: overdueCount,
          icon: getStatusIconData('overdue'),
        },
        {
          label: 'Done',
          value: 'done',
          count: doneCount,
          icon: {
            Component: CheckCircleIcon,
            baseColor: 'text-teal-700',
          },
        },
        {
          label: 'Automated',
          value: 'automated',
          count: automatedCount,
          icon: getStatusIconData('automated'),
        },
        {
          label: 'Managed',
          value: 'managed',
          count: managedCount,
          icon: {
            Component: RobotWithRefreshIcon,
            baseColor: 'text-violet-600',
          },
        },
      ]}
    />
  );
};
