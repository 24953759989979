import { Fee } from '../types';
import { formatFee } from '../utils/format';

type RequirementFeeTextProps = {
  fee: Fee;
};

export const RequirementFeeText = ({ fee }: RequirementFeeTextProps) => {
  const { description, explanation } = formatFee(fee);

  return (
    <div className="text-xs">
      <span className="font-bold">{description}</span> {explanation}
    </div>
  );
};
