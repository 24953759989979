import { PlatformLegalEntityLocation } from '../../../types';
import { useSearchParams } from 'react-router-dom';
import { LocationIconFilled } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';

interface LocationFilterProps {
  locations: PlatformLegalEntityLocation[];
}

export const LocationFilter = ({ locations }: LocationFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedLocationId = searchParams.get('location_id');
  const selectedLocation = locations.find(
    ({ location: { id } }) => id === selectedLocationId,
  );

  return (
    <DropdownMenu
      buttonText={selectedLocation?.location.name ?? 'All Locations'}
      ariaButtonText="Change mail location filter:"
      ButtonLeftIcon={LocationIconFilled}
    >
      <MenuItem
        key="All Locations"
        as="button"
        aria-label="Filter mail to All Locations"
        LeftIcon={LocationIconFilled}
        aria-pressed={!selectedLocation}
        selected={!selectedLocation}
        onClick={() => {
          searchParams.delete('location_id');
          setSearchParams(searchParams);
        }}
      >
        All Locations
      </MenuItem>
      {locations.map(({ location: { id, name } }) => (
        <MenuItem
          key={id}
          as="button"
          aria-label={`Filter mail to ${name}`}
          LeftIcon={LocationIconFilled}
          aria-pressed={id === selectedLocationId}
          selected={id === selectedLocationId}
          onClick={() => {
            searchParams.set('location_id', id);
            setSearchParams(searchParams);
          }}
        >
          {name}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};
