import { useNextTaskUrl, useTodoTasks } from '../utils/hooks';
import { TextLink } from '@mosey/components/navigation/TextLink';

export const SkipButton = () => {
  const todoTasks = useTodoTasks();
  const nextTaskUrl = useNextTaskUrl();

  if (nextTaskUrl && todoTasks.length > 1) {
    return (
      <div className="mr-auto shrink-0">
        <TextLink to={nextTaskUrl} variant="secondary" skipInk={false}>
          Skip for now
        </TextLink>
      </div>
    );
  }

  return null;
};
