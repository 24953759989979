import { LoaderFunction } from 'react-router-dom';
import { safelyUpdateLocation } from '../utils/redirect';
import { api } from '../utils/fetchApi';

export const onboardingLoader: LoaderFunction = async ({
  request: { url },
}) => {
  const callbackUrl = new URL(url).searchParams.get('callback_url');

  if (!callbackUrl) {
    throw Error('Missing callback url');
  }

  const response = await api({
    url: `/api/connect/gusto/onboard`,
    method: 'POST',
    body: { callback_url: callbackUrl },
  });

  safelyUpdateLocation((await response.json()).redirect_url);

  return null;
};

export const beginAuthorizationLoader: LoaderFunction = async ({
  request: { url },
}) => {
  const token = new URL(url).searchParams.get('token');

  if (!token) {
    throw Error('Missing token');
  }

  const response = await api({
    url: `/api/connect/gusto/authorize`,
    method: 'POST',
    body: { authorization_token: token },
  });

  safelyUpdateLocation((await response.json()).redirect_url);

  return null;
};

export const validateAuthorizationLoader: LoaderFunction = async ({
  request: { url },
}) => {
  const searchParams = new URL(url).searchParams;
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  if (!code) {
    throw Error('Missing token');
  }

  if (!state) {
    throw Error('Missing state');
  }

  const response = await api({
    url: '/api/connect/gusto/authorize/confirm',
    method: 'POST',
    body: { authorization_code: code, validation_state: state },
  });

  safelyUpdateLocation((await response.json()).redirect_url);

  return null;
};
