import { ReactNode, useState } from 'react';
import {
  Tooltip as AriaTooltip,
  TooltipTrigger,
  OverlayArrow,
} from 'react-aria-components';

type TooltipProps = {
  children: ReactNode;
  label: string;
  forceOpen?: boolean;
  offset?: number;
};

export const Tooltip = ({
  label,
  children,
  forceOpen = false,
  offset = 5,
}: TooltipProps) => {
  const [open, setOpen] = useState(false);

  return (
    <TooltipTrigger
      delay={0}
      closeDelay={0}
      onOpenChange={setOpen}
      isOpen={open || forceOpen}
    >
      {children}
      <AriaTooltip
        className="rounded bg-black px-4 py-3 text-sm text-white"
        offset={offset}
      >
        <OverlayArrow className="pointer-events-none">
          <svg width={16} height={16} viewBox="0 0 8 8">
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>
        {label}
      </AriaTooltip>
    </TooltipTrigger>
  );
};
