import { clsx } from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { useTasksOverviewLocationId } from '../../utils/hooks';

export const AllRegionsFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = useTasksOverviewLocationId();

  return (
    <li data-static-list-item="true">
      <button
        aria-pressed={locationId ? 'false' : 'true'}
        className={clsx(
          'flex shrink-0 grow items-center gap-x-2 rounded border px-4 py-2 text-sm font-medium focus-visible:outline-2 focus-visible:outline-teal-800',
          !locationId
            ? 'border-teal-700 bg-teal-700 text-teal-50'
            : 'border-teal-350 bg-teal-300 text-zinc-800 hover:border-teal-500 hover:bg-teal-400 hover:text-black',
        )}
        onClick={() => {
          searchParams.delete('location_id');
          setSearchParams(searchParams);
        }}
        aria-label="Show tasks from all states"
      >
        <div className="leading-6">All</div>
      </button>
    </li>
  );
};
