import { FunctionComponent, MouseEvent, createElement } from 'react';
import { clsx } from 'clsx';

type CategoryTagProps = {
  text: string;
  icon?: React.ElementType;
  className?: string;
  altText?: string;
  onClick?: (event: MouseEvent) => void;
};

export const CategoryTag: FunctionComponent<CategoryTagProps> = ({
  text,
  altText,
  icon,
  className,
  onClick,
}) => {
  return (
    <div
      title={altText}
      className={clsx(
        'mr-2 flex w-max items-center gap-x-2 rounded-lg border-1 border-gray-300 bg-white px-3 py-1 text-xs font-medium',
        className,
      )}
      onClick={onClick}
    >
      {icon && (
        <span
          aria-label="Category tag icon"
          className="size-5 shrink-0 text-zinc-600"
        >
          {createElement(icon)}
        </span>
      )}
      <span className="uppercase text-gray-900">{text}</span>
    </div>
  );
};
