import { FunctionComponent, useState } from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  Routes,
  Outlet,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  RequirementsTable,
  ApiStatusHandler,
  Section,
  SectionHeading,
  QuickFilters,
} from '../components';
import { useApi } from '../hooks';
import {
  FilterRequirementsBy,
  filterRequirements,
  getCountForFilter,
  isActionableRequirement,
} from '../utils/filter';
import { computedRequirementCounts } from '../utils/stats';
import { sortRequirements } from '../utils/sort';
import { RequirementOverview } from '../types';
import { useUser } from '../hooks/useUser';
import { useIsQuestionUrl } from './tasks-framework/utils/hooks';

export enum TasksCategory {
  AssignedToMe = 'me',
  All = 'all',
}

const categoryToName: Record<TasksCategory, string> = {
  [TasksCategory.All]: 'Tasks - All',
  [TasksCategory.AssignedToMe]: 'Tasks - Assigned to me',
};

type TasksProps = {
  results: RequirementOverview[];
  filters: string[];
  category: TasksCategory;
};

export const Tasks: FunctionComponent<TasksProps> = ({
  results,
  filters,
  category,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useUser();
  const viewName = categoryToName[category];

  let requirements = results.filter((req) => {
    // Always hide deferred, blocked, virtual instances from the tasks view
    // because we only want items they can take action on
    if (!isActionableRequirement(req)) return false;

    switch (category) {
      case TasksCategory.All:
        return true;
      case TasksCategory.AssignedToMe:
        // Only show tasks assigned to the current user
        return req.assigned_user?.id === user.id;
      default:
        return false;
    }
  });

  let filter = FilterRequirementsBy.All;
  if (filters && filters.length > 0) {
    // Note: Only supporting one filter for now, but don't want to
    // rename later since it's in the query params and we don't want
    // to break user links
    filter = filters[0] as FilterRequirementsBy;
  }
  const [reqFilter, setFilter] = useState(filter);
  const computedReqCounts = computedRequirementCounts(requirements);

  requirements = sortRequirements(requirements);
  requirements = filterRequirements(requirements, reqFilter);

  const handleStatusFilterClick = (f: FilterRequirementsBy): void => {
    setFilter(f);
    navigate(`${pathname}?filters=${f.toLowerCase()}`);
  };

  const allowedFilters = [
    FilterRequirementsBy.All,
    FilterRequirementsBy.New,
    FilterRequirementsBy.Upcoming,
    FilterRequirementsBy.Overdue,
    FilterRequirementsBy.Done,
  ];

  return (
    <Section>
      <SectionHeading
        borderless
        text={viewName}
        rightActionElement={
          <QuickFilters
            items={allowedFilters.map((f) => {
              return {
                label: f,
                count: getCountForFilter(computedReqCounts, f),
                isSelected: reqFilter === f,
              };
            })}
            onClick={handleStatusFilterClick}
          />
        }
      />
      <div className="min-h-full">
        <RequirementsTable requirements={requirements} />
      </div>
    </Section>
  );
};

export const TasksView: FunctionComponent = () => {
  const { category } = useParams<Record<string, string>>();

  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const filters = params.get('filters')?.split(',') || [];
  const extraProps = { filters, category };

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 60);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);
  const { status, error, data } = useApi({
    url: '/api/requirements/overviews',
    method: 'GET',
  });

  return (
    <ApiStatusHandler
      status={status}
      error={error}
      data={data}
      component={Tasks}
      extraProps={extraProps}
    />
  );
};

export const TasksGate = () => {
  const user = useUser();
  const isQuestionUrl = useIsQuestionUrl();

  if (user.legal_entity.use_resolvers || isQuestionUrl) {
    return <Outlet />;
  }

  return (
    <Routes>
      <Route index element={<Navigate to="me" replace />} />
      <Route path=":category" element={<TasksView />} />
    </Routes>
  );
};
