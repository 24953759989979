import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import { Address } from '../../types';
import { BatchApiStatusHandler, SectionHeading } from '../../components';
import { useBatchApi } from '../../hooks';
import { AddressDisplay } from '../../components/mailroom/AddressDisplay';
import { withRedirect } from '../../utils/routing';
import { useUser } from '../../hooks/useUser';
import { Feature } from '../../types/features';
import { planHasAccessToFeature } from '../../utils/plans';

interface MailroomAddresses {
  primary: Address;
  state_overrides: {
    [key: string]: Address;
  };
}

interface MailRoomSettingsProps {
  mailroomAddresses: MailroomAddresses;
}

const MailroomSettings = ({ mailroomAddresses }: MailRoomSettingsProps) => {
  return (
    <div className="flex flex-col p-16">
      <SectionHeading
        className="flex flex-1 items-center pb-4"
        text="Mailroom"
      />
      <h3 className="mb-4 mt-9 text-lg font-medium">Mailroom address</h3>
      <AddressDisplay address={mailroomAddresses.primary} />
      <h3 className="mb-4 mt-9 text-lg font-medium">
        Alternate address for Oregon agencies
      </h3>
      <AddressDisplay
        address={mailroomAddresses.state_overrides[USStateAbbrev.Oregon]}
      />
    </div>
  );
};

export const MailroomSettingsView = () => {
  const batchResponse = useBatchApi([
    {
      url: '/api/legal_entity/mailroom_address',
      method: 'GET',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([mailroomAddresses]: any[]) => {
    return {
      mailroomAddresses,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={MailroomSettings}
      componentProps={componentPropsFn}
    />
  );
};

export const ConditionalMailroomSettingsView = withRedirect(
  MailroomSettingsView,
  () => {
    const user = useUser();
    return planHasAccessToFeature(
      user.legal_entity.subscription_plan,
      Feature.MailroomAddress,
    );
  },
  '/settings/account',
);
