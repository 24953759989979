import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from '@mosey/components/buttons/Button';
import { ButtonVariants } from '@mosey/components/buttons/BaseButton';

type TaskNoticeProps = {
  className?: string;
  heading: string;
  body?: string;
  count?: number;
  icon?: React.ReactNode;
  cta?: string;
  ctaAriaLabel?: string;
  url?: string;
  buttonVariant?: ButtonVariants;
};

export const TaskNotice: FunctionComponent<TaskNoticeProps> = ({
  className,
  heading,
  body,
  count,
  icon,
  cta,
  ctaAriaLabel,
  url,
  buttonVariant = 'secondary',
}) => {
  return (
    <article
      className={clsx(
        'mt-4 flex items-center rounded border px-4 py-3',
        {
          'bg-gray-50': !className,
        },
        className,
      )}
    >
      {count !== undefined ? (
        <div
          aria-label={`${count} outstanding items`}
          className="rounded-lg bg-red-500 px-2 py-1 text-xs font-bold text-white"
        >
          {count}
        </div>
      ) : (
        icon
      )}

      <div className="ml-2 flex-1">
        <h2 className="font-medium">{heading}</h2>
        <p className="text-sm">{body}</p>
      </div>

      {url && (
        <Button
          as={NavLink}
          to={url}
          variant={buttonVariant}
          aria-label={ctaAriaLabel}
        >
          {cta}
        </Button>
      )}
    </article>
  );
};

type WarningProps = {
  heading: string;
  body: string;
  cta?: string;
  url?: string;
  children?: React.ReactNode;
};

export const Warning: FunctionComponent<WarningProps> = ({
  heading,
  body,
  cta,
  url,
  children,
}) => {
  return (
    <div className="mt-4 flex items-center rounded border border-yellow-100 bg-yellow-50 px-4 py-3">
      <div className="stroke-current text-yellow-500">
        <svg
          className="mr-2 size-6"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 10V3L4 14h7v7l9-11h-7z"
          />
        </svg>
      </div>
      <div className="flex-1">
        <h2 className="font-medium text-yellow-900">{heading}</h2>
        <p className="text-sm text-yellow-800">{body}</p>
        {children}
      </div>
      {url && (
        <div className="flex items-center">
          <Button as={NavLink} to={url} variant="secondary">
            {cta}
          </Button>
        </div>
      )}
    </div>
  );
};

type ErrorProps = {
  heading: string;
  body: string;
};

export const Error: FunctionComponent<ErrorProps> = ({ heading, body }) => {
  return (
    <div className="mt-4 flex max-w-6xl rounded border border-red-100 bg-red-50 px-4 py-3">
      <div className="stroke-current text-red-500">
        <svg
          className="mr-2 size-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div className="flex-1">
        <h2 className="font-medium text-red-900">{heading}</h2>
        <p className="text-sm text-red-800">{body}</p>
      </div>
    </div>
  );
};
