import { clsx } from 'clsx';

export type IconType = React.ComponentType<
  Pick<React.HTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
>;

export type PillProps<T extends React.ElementType = 'span'> = {
  as?: T;
  Icon?: IconType;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'inactive' | 'automation' | 'success';
  isCircle?: boolean;
} & Omit<React.ComponentPropsWithoutRef<T>, 'className'>;

type NonCirclePillProps<T extends React.ElementType> = PillProps<T> & {
  isCircle?: false;
  size?: 'small' | 'large';
};

type CirclePillProps<T extends React.ElementType> = PillProps<T> & {
  isCircle: true;
  Icon: IconType;
};

export const Pill = <T extends React.ElementType = 'span'>({
  as,
  Icon,
  children,
  size = 'large',
  variant = 'primary',
  isCircle = false,
  ...rest
}: NonCirclePillProps<T> | CirclePillProps<T>) => {
  const Component = as || 'span';

  return (
    <Component
      {...rest}
      className={clsx(
        'inline-flex flex-nowrap items-center self-center whitespace-nowrap text-nowrap rounded-full',
        isCircle
          ? 'size-8 justify-center'
          : 'font-bold uppercase leading-none tracking-wide',
        !isCircle && size === 'small' && 'h-6 gap-x-1 px-3 text-[11px]',
        !isCircle && size === 'large' && 'h-8 gap-x-2 px-4 text-xs',
        variant === 'primary' && 'bg-rose-200 text-rose-900',
        variant === 'secondary' && 'bg-teal-350 text-teal-800',
        variant === 'inactive' && 'bg-gray-200 text-zinc-600',
        variant === 'automation' && 'bg-violet-100 text-violet-800',
        variant === 'success' && 'bg-teal-700 text-teal-50',
      )}
    >
      {Icon && (
        <Icon
          aria-hidden
          className={clsx(
            'h-[18px] max-w-[18x] shrink-0',
            !isCircle && '-ml-1',
            variant === 'secondary' && 'text-teal-700',
            variant === 'automation' && 'text-violet-600',
          )}
        />
      )}

      <div className={clsx(isCircle && 'sr-only')}>{children}</div>
    </Component>
  );
};
