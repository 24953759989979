import { RefObject } from 'react';
import { useCopy } from '../hooks/useCopy';
import { Button } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import { CopyIcon } from '../Icons';

type CopyButtonProps = {
  valueToCopy: string | RefObject<HTMLElement>;
  label: string;
};

export const CopyButton = ({ label, valueToCopy }: CopyButtonProps) => {
  const [onCopy, isCopied] = useCopy(valueToCopy);
  const Icon = isCopied ? CheckIcon : CopyIcon;
  return (
    <Button
      className="group flex items-center gap-1.5 whitespace-nowrap"
      onClick={onCopy}
    >
      <span className="font-semibold text-teal-800 group-hover:text-teal-900">
        {isCopied ? 'Copied!' : `Copy ${label}`}
      </span>
      <Icon className="size-4 text-teal-700 group-hover:text-teal-800" />
    </Button>
  );
};
