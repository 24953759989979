import { FC } from 'react';
import { clsx } from 'clsx';
import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { ButtonVariants } from '@mosey/components/buttons/BaseButton';
import { useScrollIntoView } from '@mosey/components/hooks';
import { RequirementFee } from '../../components';
import { Requirement } from '../../types';
import { getStateSealImage } from '../../utils/seals';
import autopilotImage from '../../assets/autopilot.png';
import answeringQuestionsImage from '../../assets/answering-questions.png';
import constructionPageImage from '../../assets/construction-page.svg';
import successCloudImage from '../../assets/success-cloud.svg';
import smallCloudImage from '../../assets/small-cloud.svg';
import { useRequirementFee } from '../../hooks/useRequirementFee';
import { RequirementEta } from '../../components/requirement/RequirementEta';

export enum PromptMessageTypeEnum {
  Automation = 'automation',
  EnterLater = 'enterLater',
  Defer = 'defer',
  Complete = 'complete',
}

type PromptMessageProps = {
  title: string;
  description: string;
  requirementData: Requirement;
  forwardButtonLabel: string;
  variant: PromptMessageTypeEnum;
  onForward: () => void;
  onBack: () => void;
};

const promptMessageButtonVariant: Record<PromptMessageTypeEnum, string> = {
  [PromptMessageTypeEnum.Automation]: 'indigoPrimary',
  [PromptMessageTypeEnum.EnterLater]: 'primary',
  [PromptMessageTypeEnum.Defer]: 'primary',
  [PromptMessageTypeEnum.Complete]: 'primary',
};

const promptMessageImageMapping: Record<
  PromptMessageTypeEnum,
  { src: string; alt: string }
> = {
  [PromptMessageTypeEnum.Automation]: {
    src: autopilotImage,
    alt: 'Automate with Mosey',
  },
  [PromptMessageTypeEnum.EnterLater]: {
    src: answeringQuestionsImage,
    alt: 'Automate with Mosey',
  },
  [PromptMessageTypeEnum.Defer]: {
    src: constructionPageImage,
    alt: 'Crane holding a browser',
  },
  [PromptMessageTypeEnum.Complete]: {
    src: successCloudImage,
    alt: 'Badge holder with details around',
  },
};

export const PromptMessage: FC<PromptMessageProps> = ({
  title,
  description,
  requirementData,
  forwardButtonLabel,
  variant,
  onForward,
  onBack,
}) => {
  const fee = useRequirementFee(requirementData);
  const eta = requirementData.eta?.estimation;
  const [scrollIntoViewRef] = useScrollIntoView();
  const isCompleteVariant = variant === PromptMessageTypeEnum.Complete;
  const regionCode = requirementData.region[0];

  return (
    <div className="flex flex-col items-center p-16" ref={scrollIntoViewRef}>
      <div className="relative">
        <img
          className={clsx({
            'w-96': isCompleteVariant,
          })}
          src={promptMessageImageMapping[variant].src}
          alt={promptMessageImageMapping[variant].alt}
        />

        {isCompleteVariant && (
          <>
            <div className="absolute right-28 top-24 z-20 -translate-x-2 -translate-y-2 rounded-full border border-lime-500 bg-lime-500 p-1 text-white">
              <div className="size-5">
                <CheckIcon />
              </div>
            </div>

            <img
              className="absolute left-1/2 top-1/2 size-36 -translate-x-1/2 -translate-y-1/2"
              src={getStateSealImage(regionCode as USStateAbbrev)}
              alt="State seal"
            />

            <img
              className="absolute right-20 top-24 w-16 -translate-y-2 translate-x-6"
              src={smallCloudImage}
              alt="Small cloud"
            />

            <img
              className="absolute bottom-24 left-20 w-16 -translate-x-2 translate-y-4"
              src={smallCloudImage}
              alt="Small cloud"
            />
          </>
        )}
      </div>

      <div className="mt-11 max-w-xl text-center">
        <div className="mb-16">
          <h2 className="text-5xl font-black">{title}</h2>
          <p className="mt-6 text-lg font-medium">{description}</p>
        </div>

        {variant === PromptMessageTypeEnum.Automation && (fee || eta) && (
          <div className="mb-16">
            {eta && <RequirementEta eta={eta} />}
            {fee && <RequirementFee fee={fee} />}
          </div>
        )}

        <div
          className={clsx('flex gap-x-5', {
            'w-64 mx-auto': isCompleteVariant,
          })}
        >
          {!isCompleteVariant && (
            <Button
              isFullWidth
              variant="secondary"
              size="xlarge"
              leftIcon={<ArrowLeftIcon className="size-5" />}
              onClick={onBack}
            >
              Back to questions
            </Button>
          )}
          <Button
            isFullWidth
            rightIcon={<ArrowRightIcon className="size-5" />}
            variant={promptMessageButtonVariant[variant] as ButtonVariants}
            size="xlarge"
            onClick={onForward}
          >
            {forwardButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
