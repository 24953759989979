import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useKeyedBatchApi } from '../../hooks';
import { KeyedBatchApiStatusHandler } from '../../components';
import { IFetchApi } from '../../utils/types';
import { PlatformTaskDetail } from './PlatformTaskDetail';

export const PlatformTaskDetailView = () => {
  const { taskId } = useParams();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const calls: {
    [key: string]: IFetchApi;
  } = {
    task: {
      url: `/tasks/${taskId}`,
      method: 'GET',
      isPlatform: true,
    },
    legalEntity: {
      url: '/legal_entity',
      method: 'GET',
      isPlatform: true,
    },
  };

  const batchResponse = useKeyedBatchApi(calls, [
    JSON.stringify(calls),
    taskId,
    shouldRefresh,
  ]);

  const toggleShouldRefresh = useCallback(
    () => setShouldRefresh((s) => !s),
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ({ task }: { [key: string]: any }) => {
    return {
      refreshView: toggleShouldRefresh,
      task,
    };
  };

  return (
    <KeyedBatchApiStatusHandler
      batchResponse={batchResponse}
      component={PlatformTaskDetail}
      componentProps={componentPropsFn}
    />
  );
};
