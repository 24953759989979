import { clsx } from 'clsx';

interface TaskActionsProps {
  children: React.ReactNode;
  isHidden?: boolean;
}

export const TaskActions = ({
  children,
  isHidden = false,
}: TaskActionsProps) => {
  return (
    <div
      className={clsx(
        'mt-auto flex w-full items-center justify-end gap-x-6 pt-4 empty:hidden',
        {
          invisible: isHidden,
        },
      )}
    >
      {children}
    </div>
  );
};
