import { useRef, useEffect, useState, useCallback } from 'react';

export const useScrollIntoView = (
  initialState = true,
  options?: Parameters<HTMLDivElement['scrollIntoView']>[0],
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldScroll, setShouldScroll] = useState<boolean>(initialState);
  const triggerScroll = useCallback(() => {
    setShouldScroll(true);
  }, []);

  useEffect(() => {
    if (containerRef.current && shouldScroll) {
      setShouldScroll(false);
      containerRef.current.scrollIntoView(options);
    }
  }, [shouldScroll, options]);

  return [containerRef, triggerScroll] as const;
};
