import { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@mosey/components/buttons/Button';
import { fetchApi } from '../../utils/fetchApi';
import { FormError } from '@mosey/components/forms/FormError';
import { FormFieldProps } from '@mosey/components/forms/FormField';
import { FieldError } from '@mosey/components/forms/FieldError';

type FranchiseTaxEstimateProps = {
  description: string;
  label: string;
  name: string;
} & FormFieldProps;

export const FranchiseTaxEstimate: FunctionComponent<
  FranchiseTaxEstimateProps
> = ({ label, description, name, error }) => {
  const formMethods = useFormContext();

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [estimateTaxValue, setEstimateTaxValue] = useState<null | string>(null);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const {
    watch,
    register,
    unregister,
    getValues,
    setValue,
    trigger: triggerValidation,
  } = formMethods;

  const watchIdentifierType = watch('identifier_type');

  useEffect(() => {
    if (watchIdentifierType === 'does_not_have') {
      unregister(`identifier`);
    }
  }, [watchIdentifierType]);

  const calculateEstimate = async () => {
    setErrorMessage(null);
    setIsCalculating(true);

    const isTriggerValid = await triggerValidation();
    if (!isTriggerValid) {
      setIsCalculating(false);
      return;
    }

    const commonAuthorizedShares = parseInt(
      getValues('de-common-authorized-shares'),
    );
    const commonIssuedShares = parseInt(getValues('de-common-issued-shares'));
    const commonParValue = parseFloat(getValues('de-common-par-value'));

    const preferredAuthorizedShares = parseInt(
      getValues('de-preferred-authorized-shares'),
    );
    const preferredIssuedShares = parseInt(
      getValues('de-preferred-issued-shares'),
    );
    const preferredParValue = parseFloat(getValues('de-preferred-par-value'));

    const grossAssets = parseInt(getValues('gross_assets'));

    if (!commonAuthorizedShares || !commonIssuedShares || !commonParValue) {
      setErrorMessage('Please complete common stock before estimating');
      setIsCalculating(false);
      return;
    }
    if (
      (preferredAuthorizedShares ||
        preferredIssuedShares ||
        preferredParValue) &&
      !(preferredAuthorizedShares && preferredIssuedShares && preferredParValue)
    ) {
      setErrorMessage('Please complete preferred stock before estimating');
      setIsCalculating(false);
      return;
    }
    if (isNaN(grossAssets)) {
      setErrorMessage('Please enter gross assets before estimating');
      setIsCalculating(false);
      return;
    }

    const authorizedShares =
      commonAuthorizedShares + (preferredAuthorizedShares || 0);
    const issuedShares = commonIssuedShares + (preferredIssuedShares || 0);
    const totalValue =
      commonParValue * commonAuthorizedShares +
      (preferredParValue * preferredAuthorizedShares || 0);

    // call legalinc proxied endpoint
    const estimateResponse = await fetchApi({
      url: `/api/legal_entity/locations/de/annual_report/estimate`,
      method: 'POST',
      body: {
        authorized_shares: authorizedShares,
        issued_shares: issuedShares,
        par_value: totalValue / authorizedShares,
        gross_assets: grossAssets,
      },
    });

    if (estimateResponse.error) {
      setErrorMessage('Something went wrong, please try again.');
      setIsCalculating(false);
      return;
    }

    // if success:
    const taxValue = estimateResponse.data.estimate;
    setValue(name, taxValue);
    setEstimateTaxValue(
      // format with commas 1000 -> 1,000
      taxValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    );
    setIsCalculating(false);
  };

  return (
    <div className="mb-8 border bg-gray-50 px-8 py-4">
      <fieldset>
        <legend>
          <span className="my-2 block font-semibold">{label}</span>
          <p className="mb-4 text-sm text-gray-600">{description}</p>
        </legend>
        <input type="hidden" id={name} {...register(name)} />
        {estimateTaxValue && (
          <p className="text-lg font-semibold">${estimateTaxValue}</p>
        )}
        <p className="my-4 flex w-full flex-col">
          <Button
            type="button"
            onClick={calculateEstimate}
            isLoading={isCalculating}
            variant={estimateTaxValue ? 'secondary' : 'primary'}
          >
            {estimateTaxValue ? 'Recalculate Estimate' : 'Calculate Estimate'}
          </Button>
          {errorMessage && (
            <FormError margin="mt-2" errorMessage={errorMessage} />
          )}
          {error && <FieldError error={error} />}
        </p>
      </fieldset>
    </div>
  );
};
