export enum ApiStatus {
  // API request is being made
  Loading,
  // API call was successful
  Success,
  // API call resulted in an unauthorized error even after attempting
  // a token refresh
  ErrorUnauthorized,
  // API resulted in an error
  Error,
  // The initial request failed and we are attempting to refresh an
  // access token
  RefreshingToken,
  // We have new access token and will attempt to make a request
  // again. Note: if the retry fails the status will be `Error`.
  Retrying,
}
export type IApiPydanticError = {
  body: unknown;
  detail: Array<{
    loc: string[];
    msg: string;
    type: string;
  }>;
  log_key: string;
  message: string;
};

export type IApiGenericError = {
  detail: string;
};

export type IApiRateLimitError = {
  error: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IApiData<T = any, E = any> {
  status: ApiStatus;
  statusCode?: Response['status'];
  error: E;
  data: T | null;
}

export interface IFetchApi {
  url: string;
  method: string;
  credentials?: RequestCredentials;
  body?: unknown;
  isPlatform?: boolean;
  contentType?: string;
}
