import {
  ComponentType,
  FunctionComponent,
  MouseEvent,
  createElement,
} from 'react';
import { clsx } from 'clsx';
import { CheckIcon, LockIcon } from '@mosey/components/Icons';
import { Badge } from './alerts/Badge';

type NavIndicatorProps = {
  isDone?: boolean;
  isSelected?: boolean;
  isSectionSelected?: boolean;
  isLocked?: boolean;
};

const NavIndicator = ({
  isDone,
  isSelected,
  isSectionSelected,
  isLocked,
}: NavIndicatorProps) => {
  if (isDone) {
    return (
      <div className="size-5 rounded-full bg-lime-500 p-0.5 text-white">
        <CheckIcon />
      </div>
    );
  }

  if (isSelected && !isDone && !isLocked) {
    return <div className="size-4 rounded-full bg-teal-550 text-white" />;
  }

  if (isSectionSelected && !isSelected && !isDone && !isLocked) {
    return <div className="size-4 rounded-full bg-teal-550/40 text-white" />;
  }

  if (isLocked) {
    return <LockIcon className="size-4 text-gray-300" />;
  }

  return <div className="size-4 rounded-full border border-sage-600" />;
};

type NavigatorSectionItemProps = {
  text: string;
  sectionHasBlockedItem?: boolean;
  isSectionSelected?: boolean;
  isDone?: boolean;
  isSelected?: boolean;
  isLocked?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  onClick?: (e: MouseEvent) => void;
};

export enum NavigationSectionItemStatusNew {
  SectionLocked,
  SectionSelected,
  Selected,
  Locked,
  Done,
  Default,
}

export const NavigatorSectionItemNew: FunctionComponent<
  NavigatorSectionItemProps
> = ({
  text,
  sectionHasBlockedItem,
  isSectionSelected,
  isDone,
  isSelected,
  isLocked,
  isFirst,
  isLast,
  onClick,
}) => {
  const isUnique = isFirst && isLast;

  const icon = (
    <NavIndicator
      isDone={isDone}
      isLocked={isLocked}
      isSectionSelected={isSectionSelected}
      isSelected={isSelected}
    />
  );

  return (
    <div className="flex w-full">
      <div className="relative w-8">
        <div
          className={clsx('flex justify-center', {
            'h-1/2 items-end': isLast && !isUnique,
            'h-[calc(100%+1.5rem)] -translate-y-6': !isLast && !isUnique,
            'h-full -translate-y-1/2': isUnique,
          })}
        >
          <div
            className={clsx('pointer-events-none h-full border-l-1', {
              'border-teal-550': !isLocked && !sectionHasBlockedItem,
              'bg-sage-600': isLocked || sectionHasBlockedItem,
            })}
          />
        </div>
        <div
          className={clsx(
            'absolute left-1/2 top-1/2 z-40 -translate-x-1/2 -translate-y-1/2 rounded-full text-white',
            {
              'w-2 h-2': !isSectionSelected,
              'bg-teal-550 border-teal-550':
                !isLocked && !isSectionSelected && !sectionHasBlockedItem,
              'w-3 h-3 border-teal-800 bg-teal-800':
                isSectionSelected && !isLocked,

              'w-2 h-2 border-sage-600 bg-sage-600':
                isLocked ||
                (!isLocked && sectionHasBlockedItem && !isSectionSelected),
            },
          )}
        />
      </div>
      <button
        className={clsx(
          'my-2 ml-2 flex-1 cursor-pointer text-left',
          !onClick && 'pointer-events-none cursor-default',
        )}
        type="button"
        onClick={onClick}
        aria-current={isSelected ? 'step' : false}
        aria-label={`${text}${isDone ? ' is marked as Done' : ''}`}
      >
        <div
          className={clsx(
            'flex items-center rounded-md px-3.5 py-2 text-sm font-semibold leading-5',
            {
              'text-sage-600': isLocked && !isSelected,
              'text-sage-600 bg-gray-100': isLocked && isSelected,
              'text-teal-700 bg-teal-400/60':
                (isSelected || (isSelected && isDone)) && !isLocked,
            },
          )}
        >
          <div className="flex-1 pr-1">{text}</div>
          {icon && (
            <div className="flex size-5 items-center justify-center">
              {icon}
            </div>
          )}
        </div>
      </button>
    </div>
  );
};

type NavigatorSectionProps = {
  sectionIcon: ComponentType<{
    className?: string;
  }>;
  heading: string;
  isPartiallyDone: boolean;
  isSelected?: boolean;
  isFirst?: boolean;
  isDone?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
};

export const NavigatorSectionNew: FunctionComponent<NavigatorSectionProps> = ({
  sectionIcon,
  heading,
  isPartiallyDone,
  isSelected,
  isFirst,
  isDone,
  children,
  onClick,
  className,
}) => {
  return (
    <div className="relative w-full">
      <div
        className={clsx({
          'bg-sage-100': isDone && isSelected,
          'cursor-pointer': onClick,
        })}
        onClick={onClick}
      >
        <div
          className={clsx(
            className ? className : 'py-8 pl-4 pr-7',
            !isFirst && 'border-t',
          )}
        >
          <div className="flex items-center pb-6">
            <div
              className={clsx('relative z-40 rounded-full border p-2', {
                'border-rose-700 bg-rose-700 text-white': isSelected,
                'border-lime-500 bg-lime-500 text-white': isDone,
                'bg-white': !isSelected && !isDone,
              })}
            >
              <div className="size-4">
                {isDone ? (
                  <CheckIcon />
                ) : (
                  createElement(sectionIcon, {
                    className: clsx(!isSelected && 'text-sage-600'),
                  })
                )}
              </div>
            </div>
            <div className="ml-3 flex-1 text-lg font-bold leading-7 text-zinc-700">
              {heading}
            </div>

            <div>
              <Badge isActive={isPartiallyDone}>
                {isPartiallyDone ? 'Active' : 'Inactive'}
              </Badge>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
