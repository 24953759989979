import { Navigate, useSearchParams } from 'react-router-dom';
import { BusinessInformationFormView } from './BusinessInformationForm';
import { useUser } from '../hooks/useUser';

export const AutomationWrapper = () => {
  const { is_platform_user: isPlatformUser } = useUser();
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination') || undefined;

  // TODO: when we make this component generic:
  //  - remove check for platform user
  //  - hit API forms endpoint here, iterate through all of the 'stacked' forms
  //  - render each form using generic individual form component (internal component shouldn't navigate anywhere)

  if (!isPlatformUser && destination) {
    return <Navigate to={destination} replace />;
  } else if (isPlatformUser) {
    return <BusinessInformationFormView destination={destination} />;
  }
  return <Navigate to="/" replace />;
};
