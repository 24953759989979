import { isValidDate, withinDateRange } from '@mosey/utils/dates';
import { isObject } from '@mosey/utils/types';
import { UseFormUnregister } from 'react-hook-form';
import {
  PeopleOptionsType,
  Person,
  PersonIFormValues,
  SelectOptionType,
} from '../types';
import { isoDate } from './format';

type cleanIntakeDatesReturnType<T> = T extends Date ? string : T;

export const cleanIntakeDates = <T = unknown>(
  data: T,
): cleanIntakeDatesReturnType<T> => {
  if (data instanceof Date) {
    return isoDate(data) as cleanIntakeDatesReturnType<T>;
  } else if (Array.isArray(data)) {
    return data.map(cleanIntakeDates) as cleanIntakeDatesReturnType<T>;
  } else if (isObject(data)) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      return { ...acc, [key]: cleanIntakeDates(value) };
    }, {}) as cleanIntakeDatesReturnType<T>;
  }

  return data as cleanIntakeDatesReturnType<T>;
};

export const cleanBusinessInformationFormValues = (data: unknown) => {
  /* eslint-disable camelcase */
  if (isObject(data)) {
    return {
      ...data,
      is_non_stock: data.is_non_stock === null ? false : data.is_non_stock,
    };
  }
  /* eslint-enable camelcase */

  return data;
};

export const withinPastYear = (endDate: Date, date: Date): boolean => {
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 365);

  return withinDateRange(startDate, endDate, date);
};

export const withinNext90Days = (startDate: Date, date: Date): boolean => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 90);

  return withinDateRange(startDate, endDate, date);
};

export const withinNext60Days = (startDate: Date, date: Date): boolean => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 60);

  return withinDateRange(startDate, endDate, date);
};

export const validateDateProvided = (value: Date) => {
  return isValidDate(value) ? true : 'This is required';
};

export const lastTenYearsSelectOptions = (): SelectOptionType[] => {
  const thisYear = new Date().getFullYear();
  const yearOptions = [];
  for (let y = thisYear; y >= thisYear - 10; y -= 1) {
    yearOptions.push({ value: y });
  }
  return yearOptions;
};

export const lastXYearsSelectOptions = (
  x: number,
  endYear?: number,
): SelectOptionType[] => {
  const start = endYear || new Date().getFullYear();
  const yearOptions = [];
  for (let y = start; y >= start - x; y -= 1) {
    yearOptions.push({ value: y });
  }
  return yearOptions;
};

export const relativeYearsSelectionOptions = (
  start: number,
  end: number,
): SelectOptionType[] => {
  const increment = start < end ? 1 : -1;
  const startYear = new Date().getFullYear() + start;
  const endYear = new Date().getFullYear() + end;

  const yearOptions = [];
  for (let a = startYear; a !== endYear; a += increment) {
    yearOptions.push({ value: a });
  }
  return yearOptions;
};

export const filterPeople = (
  peopleType: PeopleOptionsType,
  peopleData: Person[],
): Person[] => {
  switch (peopleType) {
    case PeopleOptionsType.businessOwners:
      return peopleData.filter((person) => person.is_owner || person.is_member);
    case PeopleOptionsType.officers:
      return peopleData.filter((person) => person.is_officer);
    case PeopleOptionsType.all:
    default:
      return peopleData;
  }
};

export const sumEquals = (values: number[], target: number) => {
  const sum = values.reduce((partialSum, a) => partialSum + a, 0);
  return sum === target;
};

export const unregisterByPersonType = (
  personType: string | null,
  unregisterFn: UseFormUnregister<PersonIFormValues>,
) => {
  if (personType !== 'is_both_officer_and_owner') {
    if (personType !== 'is_officer') {
      // Remove officer-only fields
      unregisterFn(['start_date']);
    }
    if (personType !== 'is_owner') {
      // Remove owner-only fields
      unregisterFn(['ownership_start_date', 'ownership_percentage']);
    }
    if (
      personType !== 'is_officer' &&
      personType !== 'is_owner' &&
      personType !== 'is_member'
    ) {
      // Remove people-only fields
      unregisterFn(`first_name`);
      unregisterFn(`last_name`);
      unregisterFn(`title`);
      unregisterFn(`email`);
      unregisterFn(`phone_number`);
      unregisterFn(`personal_address`);
      unregisterFn(`identifier_type`);
      unregisterFn(`identifier`);
      unregisterFn(`birth_date`);
    }
  }
};
