import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import './tailwind.css';
import { SENTRY_DSN } from './settings/config';
import App from './Routes';
import { impersonationListener } from './utils/impersonation';

if (import.meta.env.PROD) {
  Sentry.init({
    release: import.meta.env.VITE_COMMIT_HASH,
    environment:
      window.location.host === 'sandbox-app.mosey.com'
        ? 'sandbox'
        : 'production',
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.5,
    tracePropagationTargets: ['localhost', /^https:\/\/api\.mosey\.com\/api/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Listen for setting of impersonation token by admins
window.addEventListener('message', impersonationListener);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
