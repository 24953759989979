import { KeyboardEvent, useState } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { clsx } from 'clsx';
import { CheckIcon, ChevronDownIcon } from '@mosey/components/Icons';
import { FieldError } from '@mosey/components/forms/FieldError';
import { Pill } from '@mosey/components/badges/Pill';

export type MultiSelectOption = {
  value: string | number;
  name: string;
};
export type MultiSelectProps = {
  name: string;
  options: MultiSelectOption[];
  rules?: RegisterOptions;
};

export const MultiSelect = ({ options, name, rules }: MultiSelectProps) => {
  const [query, setQuery] = useState('');
  const filteredOptions = query
    ? options.filter((option) => {
        return option.name.toLowerCase().includes(query.toLowerCase());
      })
    : options;

  return (
    <Controller
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <Combobox
          onChange={(value: MultiSelectOption[]) => {
            onChange({ target: { name, value } });
            onBlur();
            setQuery('');
          }}
          by="value"
          value={value || []}
          multiple
        >
          <div className="relative">
            <div
              className={clsx(
                'flex min-h-12 flex-wrap gap-x-1 gap-y-2 rounded-md border-none bg-white py-3 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600',
                error ? 'ring-red-500' : 'ring-gray-300',
              )}
            >
              {value?.map((option: MultiSelectOption) => (
                <Pill key={option.value} variant="inactive" size="small">
                  {option.name}
                </Pill>
              ))}
              <ComboboxInput
                className="border-0 p-0 text-gray-900 shadow-none outline-none placeholder:text-sage-600 focus:ring-0"
                autoComplete="off"
                placeholder={value?.length > 0 ? '' : 'Select states'}
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Backspace' && !query && value.length > 0) {
                    onChange({
                      target: {
                        name,
                        value: value.slice(0, value.length - 1),
                      },
                    });
                  }
                }}
                value={query}
              />
            </div>
            <FieldError error={error} />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
              {filteredOptions.map((option) => (
                <ComboboxOption
                  key={option.value}
                  value={option}
                  className={({ focus }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      focus ? 'bg-blue-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span
                        className={clsx(
                          'block truncate',
                          selected && 'font-semibold',
                        )}
                      >
                        {option.name}
                      </span>

                      {selected && (
                        <span
                          className={clsx(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            focus ? 'text-white' : 'text-blue-600',
                          )}
                        >
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          </div>
        </Combobox>
      )}
    />
  );
};
