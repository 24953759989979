import { PolicyDetailsContainer } from './PolicyDetailsContainer';
import { ExpandablePolicyMarkdown } from './ExpandablePolicyMarkdown';

type PolicyFullDetailsProps = {
  fullDetails: string;
};

export const PolicyFullDetails = ({ fullDetails }: PolicyFullDetailsProps) => {
  return (
    <PolicyDetailsContainer
      header={<div className="py-2">Full policy details</div>}
      content={<ExpandablePolicyMarkdown content={fullDetails} />}
    />
  );
};
