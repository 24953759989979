import { CategoryItem } from '../types';
import { usePolicySection } from './usePolicySection';

export function usePolicyCategory(sectionId: string, categoryId: string) {
  const { currentSection } = usePolicySection(sectionId);

  const category = currentSection.items.find(
    (item) => item.type === 'category' && item.slug === categoryId,
  ) as CategoryItem | undefined;

  return {
    category,
  };
}
