import { IconButton } from '@mosey/components/buttons/IconButton';
import { clsx } from 'clsx';
import { CheckIcon, CopyIcon } from '@mosey/components/Icons';
import { useCopy } from '@mosey/components/hooks/useCopy';

interface CopyButtonProps {
  textToCopy: string;
}

export const CopyButton = ({ textToCopy }: CopyButtonProps) => {
  const [onCopy, isCopied] = useCopy(textToCopy, 2000);

  return (
    <div className="group relative">
      <IconButton
        aria-label="Copy input value"
        aria-describedby="text-field-state"
        type="button"
        size="small"
        variant={isCopied ? 'actionSuccess' : 'secondary'}
        onClick={onCopy}
        icon={
          isCopied ? (
            <CheckIcon className="size-4" />
          ) : (
            <CopyIcon className="size-4" />
          )
        }
      />
      <span
        role="tooltip"
        id="text-field-state"
        className={clsx(
          'pointer-events-none absolute left-full top-1.5 ml-4 text-xs font-bold uppercase tracking-wider text-sage-600 transition-opacity',
          {
            'opacity-0 group-hover:opacity-100': !isCopied,
          },
        )}
      >
        {isCopied ? 'Copied' : 'Copy'}
      </span>
    </div>
  );
};
