import { ReactNode } from 'react';

type HeaderRowProps = {
  children: ReactNode;
};

export const HeaderRow = ({ children }: HeaderRowProps) => {
  return (
    <thead>
      <tr className="border-b">{children}</tr>
    </thead>
  );
};
