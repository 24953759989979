import { ExpandablePolicyMarkdown } from '@mosey/components/handbook/ExpandablePolicyMarkdown';
import { Filter, FilterOption } from '@mosey/components/menus/Filter';
import { useState, useMemo } from 'react';
import { fillContentItem } from '../utils';
import { AttributeValues, DetailedPolicy } from '../types';
import { useRouteLoaderData } from 'react-router-dom';
import { PolicyDetailsContainer } from '@mosey/components/handbook/PolicyDetailsContainer';

export type PolicyCategoryFullDetailsProps = {
  policies: DetailedPolicy[];
};

export const PolicyCategoryFullDetails = ({
  policies,
}: PolicyCategoryFullDetailsProps) => {
  const attributeValues = useRouteLoaderData(
    'policy-section-details',
  ) as AttributeValues;
  const filterOptions = useMemo(
    () =>
      policies.map((policy) => ({
        label: policy.policy_scope?.region?.name || 'Global',
        value: policy.id,
      })),
    [policies],
  );
  const [selectedOption, setSelectedOption] = useState<FilterOption>(
    filterOptions[0],
  );
  const selectedPolicy = policies.find(
    (policy) => policy.id === selectedOption.value,
  );

  const details = useMemo(
    () =>
      fillContentItem(
        selectedPolicy?.content.full_details || '',
        attributeValues,
      ),
    [selectedPolicy?.content.full_details, attributeValues],
  );

  if (filterOptions.length === 0) {
    return null;
  }

  return (
    <PolicyDetailsContainer
      header={
        <>
          <div>Full policy details for</div>
          <Filter
            options={filterOptions}
            selectedOption={selectedOption || filterOptions[0]}
            setSelectedOption={setSelectedOption}
          />
        </>
      }
      content={<ExpandablePolicyMarkdown content={details} />}
    />
  );
};
