import { ErrorIcon } from '../Icons';
import { clsx } from 'clsx';

interface FormErrorProps {
  errorMessage?: string;
  margin?: string;
}

// TODO: These styles can be moved into FieldError directly and this
//  component removed once all forms have been migrated to use BlockAlert

// Deprecated: New form wide errors should be displayed using the <BlockAlert/> component
export const FormError = ({
  errorMessage = 'Something went wrong, please try again.',
  margin = 'mt-4',
}: FormErrorProps) => {
  return (
    <div className={clsx(margin, 'flex items-center text-xs text-red-600')}>
      <ErrorIcon className="mr-1 size-4" />
      <p>{errorMessage}</p>
    </div>
  );
};
