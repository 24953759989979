import { USStateName } from '@mosey/utils/constants/us-states';
import { HandbookRequirementComplete } from '../../../components/requirement/HandbookRequirementComplete';
import {
  useIsPolicyGenTask,
  useTaskPolicyInstances,
  useTaskRequirement,
} from '../utils/hooks';

export const AutomationSuccess = () => {
  const { source } = useTaskRequirement();
  const isPolicyGenTask = useIsPolicyGenTask();
  const taskPolicyInstances = useTaskPolicyInstances();

  if (isPolicyGenTask) {
    return (
      <HandbookRequirementComplete
        policyInstances={taskPolicyInstances}
        region={source.location.code as keyof typeof USStateName}
      />
    );
  }

  return null;
};
