import { RequirementOverview } from '../types';
import { CategoryToStringMapping } from './category';
import { nameFromUser, requirementComputedStatus } from './format';

const sortStrings = (a: string, b: string): number => {
  const lowA = a.toLowerCase();
  const lowB = b.toLowerCase();
  if (lowA < lowB) {
    return -1;
  }
  if (lowA > lowB) {
    return 1;
  }
  return 0;
};

const sortByDate = (a: RequirementOverview, b: RequirementOverview): number => {
  if (!a.due_date && !b.due_date) {
    return 0;
  }
  if (!a.due_date) {
    return -1;
  }
  if (!b.due_date) {
    return 1;
  }

  const dueDateA = new Date(a.due_date);
  const dueDateB = new Date(b.due_date);
  if (dueDateA < dueDateB) {
    return -1;
  }
  if (dueDateA > dueDateB) {
    return 1;
  }
  return 0;
};

const sortByName = (a: RequirementOverview, b: RequirementOverview): number => {
  return sortStrings(a.title, b.title);
};

const sortByCategory = (
  a: RequirementOverview,
  b: RequirementOverview,
): number => {
  const catA = CategoryToStringMapping[a.category];
  const catB = CategoryToStringMapping[b.category];
  return sortStrings(catA, catB);
};

const sortByStatus = (
  a: RequirementOverview,
  b: RequirementOverview,
): number => {
  const statusA = requirementComputedStatus(a);
  const statusB = requirementComputedStatus(b);
  return sortStrings(statusA, statusB);
};

export function sortRequirements<T extends RequirementOverview>(
  reqs: T[],
): T[] {
  return reqs.sort(sortByDate);
}

const sortByAssignment = (
  a: RequirementOverview,
  b: RequirementOverview,
): number => {
  if (!a.assigned_user && !b.assigned_user) {
    return 0;
  }
  if (!a.assigned_user) {
    return -1;
  }
  if (!b.assigned_user) {
    return 1;
  }

  const nameA = nameFromUser(a.assigned_user)!;
  const nameB = nameFromUser(b.assigned_user)!;

  return sortStrings(nameA, nameB);
};

export enum SortDir {
  Asc,
  Desc,
}

export enum ColumnType {
  ReqName,
  Category,
  DueDate,
  Status,
  AssignedTo,
}

type ComparisonFn = (a: RequirementOverview, b: RequirementOverview) => number;

const sortingFunctionsByColumn: Record<ColumnType, ComparisonFn> = {
  [ColumnType.ReqName]: sortByName,
  [ColumnType.Category]: sortByCategory,
  [ColumnType.DueDate]: sortByDate,
  [ColumnType.Status]: sortByStatus,
  [ColumnType.AssignedTo]: sortByAssignment,
};

export type SortTableBy = {
  column: ColumnType;
  sortDir: SortDir;
};

export const sortTable = (
  reqs: RequirementOverview[],
  sortBy: SortTableBy,
): RequirementOverview[] => {
  const shallowCopy = [...reqs];
  // use secondary column sorting to ensure a more determinate ordering
  const secondarySortBy =
    sortBy.column === ColumnType.ReqName
      ? ColumnType.DueDate
      : ColumnType.ReqName;
  shallowCopy.sort(
    (a, b) =>
      sortingFunctionsByColumn[sortBy.column](a, b) ||
      sortingFunctionsByColumn[secondarySortBy](a, b),
  );
  return sortBy.sortDir === SortDir.Asc ? shallowCopy : shallowCopy.reverse();
};
