import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import { LoadingIcon } from '@mosey/components/Icons';

type LoadingProps = {
  className?: string;
};

export const Loading: FunctionComponent<LoadingProps> = ({ className }) => {
  return (
    <div
      role="status"
      className={clsx('flex h-full items-center justify-center', className)}
    >
      <p className="sr-only">Loading...</p>
      <LoadingIcon className="size-8 text-gray-500" />
    </div>
  );
};

export const LoadingFullScreen: FunctionComponent = () => {
  return (
    <div className="absolute inset-0 z-20 flex items-center justify-center bg-white">
      <Loading />
    </div>
  );
};
