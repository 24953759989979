import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorIcon } from '@mosey/components/Icons';
import {
  Attribute,
  AttributeType,
  RequirementAttribute,
  RequirementStatus,
} from '../types';
import { cleanIntakeDates } from '../utils/intake';
import { fetchApi } from '../utils/fetchApi';
import { Button } from '@mosey/components/buttons/Button';
import { formSpecToRendererConfig, Renderer } from './forms';

type RequirementImportDatumFormProps = {
  requirementId: string;
  regionCode: string;
  onSubmitSuccess: () => void;
  onCancel: () => void;
  attributes: RequirementAttribute[];
};

export const RequirementImportDatumForm: FunctionComponent<
  RequirementImportDatumFormProps
> = ({ requirementId, regionCode, onSubmitSuccess, onCancel, attributes }) => {
  const formMethods = useForm();
  const {
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data: unknown) => {
    clearErrors();

    const cleanedData = cleanIntakeDates(data) as object;

    const body = Object.entries(cleanedData).map(([k, v]) => {
      return { attribute_id: k, value: v, region_code: regionCode };
    });

    const datumResponse = await fetchApi({
      url: '/api/datums',
      method: 'POST',
      body,
    });

    if (datumResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('submit', err);
      return;
    }

    const reqPatchResponse = await fetchApi({
      url: `/api/requirements/${requirementId}`,
      method: 'PUT',
      body: { status: RequirementStatus.Done },
    });

    if (reqPatchResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('submit', err);
      return;
    }

    const generateReqsResponse = await fetchApi({
      url: `/api/legal_entity/locations/${regionCode}/requirements`,
      method: 'POST',
    });

    if (generateReqsResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('submit', err);
      return;
    }

    onSubmitSuccess();
  };

  const mapAttributeType = (a: Attribute): string => {
    if (a.type === AttributeType.Boolean) {
      return 'check';
    }
    return a.type;
  };

  const formFields = attributes.map((ra: RequirementAttribute) => {
    const a = ra.attribute;
    return {
      name: `${a.id}`,
      label: a.required ? (
        <>
          {a.name}{' '}
          <span className="font-bold text-red-600" aria-hidden="true">
            *
          </span>
        </>
      ) : (
        a.name
      ),
      description: a.description,
      required: a.required ? 'This is required' : undefined,
      component: {
        type: mapAttributeType(a),
        params: {
          placeholder: a.placeholder,
        },
      },
    };
  });

  const loadedSpec = {
    title: 'Setup',
    sections: [{ form_fields: formFields }],
  };
  return (
    <FormProvider {...formMethods}>
      <form className="my-8 w-96" onSubmit={handleSubmit(onSubmit)}>
        <Renderer
          config={formSpecToRendererConfig(loadedSpec)}
          errors={errors}
        />
        <p className="mt-4 flex">
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button type="submit" size="large" className="ml-4">
            Submit
          </Button>
        </p>
        {errors.submit && (
          <div className="mt-2 flex items-center text-xs text-red-600">
            <span>
              <ErrorIcon className="mr-1 size-4" />
            </span>
            <div>
              <p>{`${errors.submit.message || 'Something went wrong.'}`}</p>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
