import { HTMLProps } from 'react';

type TableCellProps = Omit<HTMLProps<HTMLTableCellElement>, 'className'>;

export const EmptyStateCell = ({ children, ...rest }: TableCellProps) => {
  return (
    <td
      className="px-6 py-20 text-center text-lg font-semibold text-zinc-700"
      {...rest}
    >
      {children}
    </td>
  );
};
