import { FunctionComponent } from 'react';
import { clsx } from 'clsx';

type MenuListProps = {
  children: React.ReactNode;
};

export const MenuList: FunctionComponent<MenuListProps> = ({ children }) => (
  <ul className="h-full border-r">{children}</ul>
);

export enum MenuItemVariants {
  Small = 'small',
  Medium = 'medium',
}

type MenuItemProps = {
  children: React.ReactNode;
  isActive?: boolean;
  variant?: MenuItemVariants;
};

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  children,
  isActive,
  variant = MenuItemVariants.Medium,
}) => (
  <li
    className={clsx(
      'flex items-center justify-between border-b py-4 pl-6 pr-3 font-semibold',
      {
        'h-16': variant === MenuItemVariants.Small,
        'h-24': variant === MenuItemVariants.Medium,
        'text-teal-700 bg-teal-300': isActive,
        'hover:bg-gray-50': !isActive,
      },
    )}
  >
    {children}
  </li>
);
