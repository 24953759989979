import { Fee } from './api';

export enum PlatformTaskStatus {
  Todo = 'todo',
  Dismissed = 'dismissed',
  InProgress = 'in-progress',
  Error = 'error',
  Done = 'done',
}

export enum PlatformTaskPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface TaskContextEmployee {
  scope: 'employee';
  employee_id: string;
}

export interface TaskContextLegalEntity {
  scope: 'legal-entity';
  legal_entity_id: string;
}

export interface PlatformLegalEntityLocation {
  location: PlatformLocation;
  config: {
    has_employees: boolean;
    has_physical_location: boolean;
  };
}

export interface PlatformLocation {
  id: string;
  name: string;
  code: string;
}

export type PlatformTaskPathType = 'link' | 'mail' | 'phone' | 'fax' | 'email';

export interface PlatformTaskPath {
  type: PlatformTaskPathType;
  value: string;
  text: string | null;
}

export enum PlatformTaskResolutionType {
  Custom = 'custom',
  Hosted = 'hosted',
  Manual = 'manual',
  Action = 'action',
}

export interface PlatformTaskResolutionStep {
  id: string;
  order: number;
  title: string;
  description: string;
  paths: PlatformTaskPath[];
}

export interface PlatformTaskRedirectResolution {
  type: 'redirect';
  url: string;
}

export interface PlatformTaskHostedResolutionField {
  description: string;
  name: string;
  required: boolean;
  type: string;
}

export interface ActionField {
  name: string;
  description: string;
  required: boolean;
  type: string;
}

export interface PlatformTaskActionResolution {
  type: 'action';
  description: string;
  method: string;
  url: string;
  content_type: string;
  fields: ActionField[];
}

export interface PlatformTaskManualResolution {
  type: 'manual';
  steps: PlatformTaskResolutionStep[];
}

export interface PlatformTaskHostedResolution {
  type: 'hosted';
  content_type: string;
  description: string;
  fields: PlatformTaskHostedResolutionField[];
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'OPTIONS';
  url: string;
}

export interface PlatformTaskResolutions {
  custom?: PlatformTaskRedirectResolution;
  hosted?: PlatformTaskHostedResolution;
  action?: PlatformTaskActionResolution;
  manual?: PlatformTaskManualResolution;
}

export interface PlatformTaskResource {
  url: string;
  name: string;
}

export interface PlatformTaskManagedProvider {
  id: string;
  name: string;
}

export enum PlatformTaskCriteriaType {
  EmployeeCount = 'employee-count',
  PayrollAmount = 'payroll-amount',
  RegionHasEmployees = 'region-has-employees',
  RegionHasPhysicalLocation = 'region-has-physical-location',
  RequirementDone = 'requirement-done',
}

export interface PlatformTaskCriteria {
  key: PlatformTaskCriteriaType;
  reason: string;
  data: { [key: string]: unknown };
}

export enum TaskDefinitionType {
  Requirement = 'requirement',
}

export interface PlatformTaskDefinition {
  id: string;
  title: string;
  description: string;
  summary: string;
  location: PlatformLocation;
  resources: PlatformTaskResource[];
  type: TaskDefinitionType;
}

export interface PlatformTask {
  id: string;
  context: TaskContextEmployee | TaskContextLegalEntity;
  default_resolution_name: PlatformTaskResolutionType;
  period_start_date: string | null;
  period_end_date: string | null;
  due_date: string | null;
  managed_provider: PlatformTaskManagedProvider | null;
  definition: PlatformTaskDefinition;
  resolutions: PlatformTaskResolutions;
  status: PlatformTaskStatus;
  criteria_met: PlatformTaskCriteria[];
  unblocks: PlatformTaskDefinition[];
  priority: PlatformTaskPriority;
  fee: Fee | null;
  tags: string[] | null;
}

export interface PlatformLegalEntity {
  id: string;
  name: string;
}
