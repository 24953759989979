import { CheckCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import { Button } from '../buttons/Button';
import { Link, LinkProps } from 'react-router-dom';

type ActionBannerProps = {
  children: ReactNode;
  isLoading?: boolean;
  to: LinkProps['to'];
};

export const ActionBanner = ({
  children,
  to,
  isLoading,
}: ActionBannerProps) => {
  return (
    <div className="flex w-full items-center gap-3 rounded-lg border border-teal-900 bg-teal-800 px-4 py-3">
      <CheckCircleIcon className="size-6 text-white" />
      <p className="flex-1 font-semibold text-white">{children}</p>
      <Button as={Link} to={to} variant="secondary" isLoading={isLoading}>
        Continue
      </Button>
    </div>
  );
};
