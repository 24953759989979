import { Suspense } from 'react';
import {
  Await,
  defer,
  LoaderFunction,
  Navigate,
  redirect,
  useNavigation,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { isUSStateCode, USStateId } from '@mosey/utils/constants/us-states';
import { toUTCMidnight } from '@mosey/utils/dates';
import { TaskStatus } from '@mosey/api-types';
import { apiBatch } from '../../../utils/fetchApi';
import { useImpersonationUser, useUser } from '../../../hooks/useUser';
import { Loading } from '../../Loading';
import { TasksOverviewMainTasks } from './TasksOverviewMainTasks';
import { TasksOverviewSetupGate } from './TasksOverviewSetupGate';
import { TasksOverviewSidebar } from './sidebar/TasksOverviewSidebar';
import { TaskOverviewRegionFilters } from './region-filters/TaskOverviewRegionFilters';
import { useNextTasksOverviewLocationId } from '../utils/hooks';
import { isoDate } from '../../../utils/format';

export const loader: LoaderFunction = async ({ request }) => {
  const { searchParams } = new URL(request.url);
  const locationId = searchParams.get('location_id');
  const tasksSearchParams = new URLSearchParams();
  const inProgressSearchParams = new URLSearchParams();
  const tasksUrl = '/api/compliance/tasks';
  let mainTasksUrl = tasksUrl;
  let inProgressTasksUrl = tasksUrl;

  const endDate = toUTCMidnight(new Date());
  endDate.setDate(endDate.getDate() + 90);

  tasksSearchParams.set('end_date', isoDate(endDate));

  inProgressSearchParams.set('statuses', TaskStatus.in_progress);
  inProgressSearchParams.set('task_type', 'requirement');

  if (locationId) {
    const code = locationId.toUpperCase();

    if (!isUSStateCode(code)) {
      return redirect(`/home`);
    }

    tasksSearchParams.set('region_id', USStateId[code]);
    inProgressSearchParams.set('region_id', USStateId[code]);
  } else {
    const status = searchParams.get('status');

    switch (status) {
      case 'automated':
      case 'done':
        tasksSearchParams.set('statuses', TaskStatus.done);
        break;

      case 'managed':
        tasksSearchParams.delete('statuses');
        break;

      case 'todo':
      case 'overdue':
      default:
        tasksSearchParams.set('statuses', TaskStatus.todo);
        break;
    }

    tasksSearchParams.set('task_type', 'requirement');
    tasksSearchParams.set('is_setup', 'false');
  }

  if (tasksSearchParams.size > 0) {
    mainTasksUrl = `${tasksUrl}?${decodeURIComponent(tasksSearchParams.toString())}`;
  }

  if (inProgressSearchParams.size > 0) {
    inProgressTasksUrl = `${tasksUrl}?${decodeURIComponent(inProgressSearchParams.toString())}`;
  }

  return defer({
    data: apiBatch({
      tasks: {
        url: mainTasksUrl,
        method: 'GET',
      },
      legislation: {
        url: '/api/legislation',
        method: 'GET',
      },
      connections: {
        url: `/api/connections`,
        method: 'GET',
      },
      summary: {
        url: `/api/legal_entity/locations/summary?end_date=${isoDate(endDate)}`,
        method: 'GET',
      },
      inProgress: {
        url: inProgressTasksUrl,
        method: 'GET',
      },
    }).then((response) => response.json()),
  });
};

export const Component = () => {
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get('location_id');
  const nextLocationId = useNextTasksOverviewLocationId();
  const navigation = useNavigation();
  const { role } = useUser();
  const impersonatedUser = useImpersonationUser();
  const data = useRouteLoaderData('tasks-overview') as
    | {
        data: Promise<Response>;
      }
    | undefined;

  if (!data) {
    throw new Error('Attempted to render Tasks Overview without API data!');
  }

  if (role !== 'admin' && !impersonatedUser) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="flex size-full max-w-full flex-col @container/tasks-overview">
      <Suspense
        fallback={
          <div className="grow">
            <Loading />
          </div>
        }
      >
        <Await resolve={data.data}>
          <TaskOverviewRegionFilters />

          {navigation.state === 'loading' &&
          locationId !== nextLocationId &&
          navigation.location.pathname === location.pathname ? (
            <div className="grow">
              <Loading />
            </div>
          ) : (
            <TasksOverviewSetupGate>
              <div className="items-start space-y-10 px-8 pb-6 @5xl/tasks-overview:grid @5xl/tasks-overview:grid-cols-[minmax(0,_1fr)_374px] @5xl/tasks-overview:gap-x-10 @5xl/tasks-overview:space-y-0">
                <TasksOverviewMainTasks />
                <TasksOverviewSidebar />
              </div>
            </TasksOverviewSetupGate>
          )}
        </Await>
      </Suspense>
    </div>
  );
};
