import { captureMessage } from '@sentry/react';
import { EmailOtp } from '../types';
import { fetchApi } from './fetchApi';
import { ApiStatus } from './types';

const POLL_WAIT_MS = 10000;
const MAX_POLL_COUNT = 12;

async function fetchOtp(accountLoginId: string): Promise<EmailOtp | undefined> {
  const response = await fetchApi({
    url: `/api/accounts/otp/${accountLoginId}/email`,
    method: 'GET',
  });
  if (response.status === ApiStatus.Success && response.data) {
    return {
      code: response.data.email_otp_code,
      timestamp: response.data.email_timestamp,
      date: new Date(response.data.email_timestamp),
    };
  } else {
    return undefined;
  }
}

export function pollForEmailOtp(
  accountLoginId: string,
  afterDate?: Date,
): Promise<EmailOtp> {
  const fifteenMinsAgo = new Date();
  fifteenMinsAgo.setMinutes(fifteenMinsAgo.getMinutes() - 15);
  const minimumDate = afterDate || fifteenMinsAgo;
  let pollCount = 0;
  return new Promise((resolve, reject) => {
    const fetch = async () => {
      try {
        pollCount++;
        if (pollCount > MAX_POLL_COUNT) {
          captureMessage('Failed to find email OTP', 'debug');
          return reject('Failed to find email OTP');
        }

        const emailOtp = await fetchOtp(accountLoginId);

        if (emailOtp && emailOtp.date > minimumDate) {
          captureMessage(`Found email OTP`, (scope) => {
            scope.setLevel('debug');
            scope.setExtra('Number of attempts', pollCount);
            return scope;
          });
          return resolve(emailOtp);
        }
        setTimeout(fetch, POLL_WAIT_MS);
      } catch (err) {
        setTimeout(fetch, POLL_WAIT_MS);
      }
    };
    fetch();
  });
}
