import { FunctionComponent } from 'react';
import { FieldError as FieldErrorT } from 'react-hook-form';
import { clsx } from 'clsx';
import { FieldError } from '@mosey/components/forms/FieldError';

export type CheckBoxProps = {
  id: string;
  label: string;
  labelClassName?: string;
  error?: FieldErrorT;
  className?: string;
  description?: string;
  hidden?: boolean;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
};

export const CheckBox: FunctionComponent<CheckBoxProps> = ({
  id,
  label,
  labelClassName,
  error,
  className,
  description,
  hidden,
  inputProps,
}) => {
  return (
    <div
      className={clsx(
        {
          'mb-8': !className && !hidden,
          hidden,
        },
        className,
      )}
    >
      <div>
        <div className="flex">
          <input
            type="checkbox"
            className={clsx('mr-2 mt-1 w-4 rounded', {
              'border-red-500': error,
            })}
            id={id}
            {...inputProps}
          />
          <label
            className={clsx(
              'block',
              {
                'font-semibold': !labelClassName,
              },
              labelClassName,
            )}
            htmlFor={id}
          >
            {label}
          </label>
        </div>
        <div className="ml-6 text-sm text-gray-500">{description}</div>
      </div>
      <FieldError error={error} />
    </div>
  );
};
