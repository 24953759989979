import { useLocation, useNavigation, useSearchParams } from 'react-router-dom';

export const useNextSearchParams = () => {
  const location = useLocation();
  const navigation = useNavigation();

  if (
    navigation.state === 'loading' &&
    navigation.location.pathname === location.pathname
  ) {
    const nextSearchParams = new URLSearchParams(navigation.location.search);
    return nextSearchParams;
  }
};

export const useNextSearchParamsValue = (key: string) => {
  const nextSearchParams = useNextSearchParams();

  if (nextSearchParams) {
    return nextSearchParams.get(key);
  }
};

export const usePendingSearchParamsValue = (key: string) => {
  const [searchParams] = useSearchParams();
  const nextValue = useNextSearchParamsValue(key);

  return nextValue === undefined ? searchParams.get(key) : nextValue;
};
