import { useEffect, useState } from 'react';
import { Button } from '@mosey/components/buttons/Button';
import logo from '../../assets/logo.svg';

const TestHostedSignup = () => {
  const [callbackUrl, setCallbackUrl] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cu = urlParams.get('callback_url') || '';
    setCallbackUrl(cu);
  }, []);

  const handleClick = () => {
    window.open(callbackUrl, '_blank');
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="mb-10 w-full max-w-md pb-4">
        <div className="w-20">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-4xl font-extrabold tracking-tight text-gray-900">
            Test Hosted Signup
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className="-space-y-px rounded-md">
            <p>
              This is a test page simulating hosted sign up. When the user is
              finished signing up, they will click a button that will redirect
              them to the callback URL.
            </p>
          </div>
          <div className="-space-y-px rounded-md">
            <p>You can test this by clicking the button below.</p>
          </div>

          <div>
            <Button
              type="submit"
              size="large"
              isFullWidth
              onClick={handleClick}
            >
              Sign up
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TestHostedSignup;
