import { Link, LoaderFunction } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { api } from '../utils/fetchApi';
import { CheckIcon } from '@mosey/components/Icons';

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');

  return api({
    url: '/api/verify/email',
    method: 'POST',
    body: { token },
  });
};

export const Component = () => {
  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="flex h-64 flex-col items-center justify-center space-y-4">
        <div className="size-12 rounded-full bg-green-600 p-1 text-white">
          <CheckIcon />
        </div>

        <h3 className="text-3xl font-bold text-green-600">Success</h3>
        <p className="ml-1 text-lg text-gray-500">
          Your email address was verified!
        </p>

        <Button as={Link} to="/home" size="large" className="mt-6">
          Get started
        </Button>
      </div>
    </div>
  );
};
