import { Navigate, useParams } from 'react-router-dom';
import {
  useNextTaskUrl,
  useResolverLocation,
  useTasks,
  useTodoTasks,
} from '../utils/hooks';
import { ResolverType, LocationResolverRouteParams } from '../utils/types';
import { FullHeightWrapper } from '../common/Interstitial';
import { useUser } from '../../../hooks/useUser';
import { AssessmentInterstitial } from './AssessmentInterstitial';
import { SetupInterstitial } from './SetupInterstitial';

export const ResolverIntro = () => {
  const { resolverType = ResolverType.Assessment } =
    useParams<LocationResolverRouteParams>();
  const tasks = useTasks();
  const todoTasks = useTodoTasks();
  const nextTaskUrl = useNextTaskUrl();
  const location = useResolverLocation();

  const {
    legal_entity: { use_resolvers: useResolvers },
  } = useUser();

  if (tasks.length === 0) {
    return <Navigate to="/home" replace />;
  }

  const nextReviewUrl = `tasks/${tasks[0].id}`;
  if (resolverType === ResolverType.Review) {
    return <Navigate to={nextReviewUrl} replace />;
  }

  if (
    !location ||
    (resolverType !== ResolverType.Assessment &&
      resolverType !== ResolverType.Setup) ||
    todoTasks.length < tasks.length ||
    !useResolvers
  ) {
    return <Navigate to={nextTaskUrl || '/home'} replace />;
  }

  if (todoTasks.length === 0) {
    return <Navigate to="complete" replace />;
  }

  if (tasks.length === 1) {
    return <Navigate to={`tasks/${tasks[0].id}`} replace />;
  }

  const percentComplete =
    (resolverType === ResolverType.Assessment ? 1 / 3 : 2 / 3) * 100;

  return (
    <FullHeightWrapper>
      {resolverType === ResolverType.Assessment ? (
        <AssessmentInterstitial percentComplete={percentComplete} />
      ) : (
        <SetupInterstitial percentComplete={percentComplete} />
      )}
    </FullHeightWrapper>
  );
};
