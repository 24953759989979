import { Link, NavLink, NavLinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { HamburgerIcon, UserIcon } from '@mosey/components/Icons';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { Button } from '@mosey/components/buttons/Button';
import { PageNavLink } from '@mosey/components/page-header/PageNavLink';
import logo from '../../assets/logo.svg';
import { ImpersonationAlert } from './ImpersonationAlert';

const MenuItemNavLink = (props: NavLinkProps) => {
  return (
    <MenuItem as="div">
      <NavLink
        {...props}
        className={({ isActive }) => {
          return clsx(
            'w-full',
            isActive && 'font-bold text-rose-800 hover:text-rose-900',
          );
        }}
      />
    </MenuItem>
  );
};

interface TasksFrameworkNavProps {
  children: React.ReactNode;
}

export const TasksFrameworkNav = ({ children }: TasksFrameworkNavProps) => {
  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <header className="flex h-20 w-full items-center border-b border-b-gray-200 px-8">
        <Link
          to="/home"
          className="mt-1 block rounded-sm pt-[2px] outline-offset-4 outline-rose-700 focus-visible:outline-2"
        >
          <img className="w-20" src={logo} alt="Mosey – Home" />
        </Link>

        <nav className="ml-auto flex items-center gap-x-6">
          <ul className="hidden items-center gap-x-6 sm:flex">
            <li>
              <PageNavLink to="/home">Home</PageNavLink>
            </li>
            <li>
              <PageNavLink to="/mail">Mail</PageNavLink>
            </li>
            <li>
              <Button
                as={NavLink}
                to="/locations/usa/setup"
                variant="secondary"
                className="text-sm"
              >
                Add another state
              </Button>
            </li>
          </ul>

          <DropdownMenu
            isIconButton
            buttonContent={({ active }) => (
              <>
                <HamburgerIcon
                  className={clsx(
                    'size-8 sm:hidden',
                    active
                      ? 'text-teal-700 hover:text-teal-800'
                      : 'text-zinc-700 hover:text-zinc-800',
                  )}
                />
                <UserIcon className="hidden size-8 rounded-full border p-1 text-gray-500 sm:block" />
              </>
            )}
            ariaButtonText="User menu"
          >
            <div className="sm:hidden">
              <MenuItemNavLink to="/home">Home</MenuItemNavLink>
              <MenuItemNavLink to="/mail">Mail</MenuItemNavLink>
            </div>

            <MenuItemNavLink to="/settings">Settings</MenuItemNavLink>
            <MenuItemNavLink to="/logout">Sign out</MenuItemNavLink>
          </DropdownMenu>
        </nav>
      </header>

      <ImpersonationAlert />

      <main className="w-full max-w-screen-2xl flex-1 overflow-auto">
        {children}
      </main>
    </div>
  );
};
