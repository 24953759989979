import { CalendarIcon, StopwatchIcon } from '@mosey/components/Icons';
import { Pill } from '@mosey/components/badges/Pill';
import { isOverDue } from './utils';
import { formatDateFromString } from '../../../utils/format';

interface TaskDueDateProps {
  dueDate: string | null;
  onlyShowOverdue?: boolean;
}

export const TaskDueDate = ({
  dueDate,
  onlyShowOverdue = false,
}: TaskDueDateProps) => {
  if (dueDate) {
    return (
      <div className="flex items-center gap-x-2">
        {isOverDue(dueDate) ? (
          <Pill Icon={StopwatchIcon}>Overdue</Pill>
        ) : (
          !onlyShowOverdue && (
            <p className="flex items-center text-sm font-semibold">
              <CalendarIcon className="mr-2 w-4" />
              Due Date:{' '}
              <time dateTime={dueDate}>{formatDateFromString(dueDate)}</time>
            </p>
          )
        )}
      </div>
    );
  }

  return null;
};
