import { RequirementOverview, RequirementStatus } from '../types';
import { RequirementCounts } from '../utils/stats';
import {
  isAll,
  isDone,
  isHR,
  isInsurance,
  isNew,
  isOverdue,
  isPayroll,
  isRegistration,
  isRegistrationMaintenance,
  isTax,
  isUpcoming,
} from './requirement';

export enum FilterRequirementsBy {
  Upcoming = 'upcoming',
  UpcomingNotManaged = 'upcoming-not-managed',
  Overdue = 'overdue',
  Complete = 'complete',
  Done = 'done',
  Locked = 'locked',
  All = 'all',
  Tax = 'tax',
  Payroll = 'payroll',
  HR = 'hr',
  Registration = 'registration',
  RegistrationMaintenance = 'registration-maintenance',
  Insurance = 'insurance',
  New = 'new',
}

export enum FilterPlatformTasksBy {
  All = 'all',
  TimeSensitive = 'time-sensitive',
  InProgress = 'in-progress',
  Archived = 'archived',
}

export const filterRequirements = (
  reqs: RequirementOverview[],
  filterBy: FilterRequirementsBy,
): RequirementOverview[] => {
  return reqs.filter((req) => {
    switch (filterBy) {
      case FilterRequirementsBy.Upcoming:
        return isUpcoming(req);
      case FilterRequirementsBy.UpcomingNotManaged:
        return isUpcoming(req) && !req.is_managed;
      case FilterRequirementsBy.Overdue:
        return isOverdue(req);
      case FilterRequirementsBy.Complete:
        return isDone(req);
      case FilterRequirementsBy.Done:
        return isDone(req);
      case FilterRequirementsBy.Locked:
        return req.is_blocked;
      case FilterRequirementsBy.Tax:
        return isTax(req);
      case FilterRequirementsBy.Payroll:
        return isPayroll(req);
      case FilterRequirementsBy.Registration:
        return isRegistration(req);
      case FilterRequirementsBy.RegistrationMaintenance:
        return isRegistrationMaintenance(req);
      case FilterRequirementsBy.HR:
        return isHR(req);
      case FilterRequirementsBy.Insurance:
        return isInsurance(req);
      case FilterRequirementsBy.All:
        return isAll();
      case FilterRequirementsBy.New:
        return isNew(req);
      default:
        return ((unknownStatus: never) => {
          throw new Error(`${unknownStatus} was unhandled.`);
        })(filterBy);
    }
  });
};

export const getCountForFilter = (
  counts: RequirementCounts,
  filterBy: FilterRequirementsBy,
): number => {
  switch (filterBy) {
    case FilterRequirementsBy.All:
      return counts.allCount;
    case FilterRequirementsBy.Upcoming:
      return counts.upcomingCount;
    case FilterRequirementsBy.Overdue:
      return counts.overdueCount;
    case FilterRequirementsBy.Done:
      return counts.doneCount;
    case FilterRequirementsBy.Locked:
      return counts.lockedCount;
    case FilterRequirementsBy.New:
      return counts.newCount;
    default:
      return 0;
  }
};

export const isActionableRequirement = (req: RequirementOverview) => {
  if (
    req.status === RequirementStatus.Deferred ||
    req.is_virtual ||
    req.is_blocked
  ) {
    return false;
  }

  return true;
};
