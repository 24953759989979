import { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import {
  BaseTextField,
  BaseTextFieldProps,
  TextFieldSizes,
} from './BaseFormField';
import { FormField, FormFieldProps } from './FormField';
import { clsx } from 'clsx';
import { IconButton } from '../buttons/IconButton';
import { CheckIcon, CopyIcon } from '../Icons';
import { useCopy } from '../hooks/useCopy';

export type TextFieldProps = Omit<
  {
    size?: TextFieldSizes;
    inputClassName?: string;
    isFullWidth?: boolean;
    reactFormConfig?: RegisterOptions;
    shouldRenderCopyButton?: boolean;
    copyConfig?: {
      valueToCopy?: string;
      disabled?: boolean;
    };
  } & BaseTextFieldProps &
    FormFieldProps,
  'className'
>;

/**
 * Component to be used within a FormProvider to get the form context
 */
export const TextField: FunctionComponent<TextFieldProps> = ({
  name,
  label,
  rightButton,
  leftButton,
  overlay,
  error,
  description,
  descriptionLink,
  size = 'medium',
  inputClassName,
  reactFormConfig,
  shouldRenderCopyButton,
  copyConfig,
  isFullWidth = false,
  hasMargin,
  ...props
}) => {
  const { register } = useFormContext();
  const textFieldValue = useWatch({ name: name });
  const [onCopy, isCopied] = useCopy(
    copyConfig?.valueToCopy || textFieldValue || props.defaultValue,
    2000,
  );

  return (
    <div
      className={clsx('flex items-center', {
        'w-full': isFullWidth,
      })}
    >
      <FormField
        name={name}
        className="flex-1 focus-within:z-10"
        hasMargin={hasMargin}
        error={error}
        label={label}
        description={description}
        descriptionLink={descriptionLink}
        rightButton={rightButton}
        leftButton={leftButton}
        overlay={overlay}
      >
        <BaseTextField
          type="text"
          size={size}
          error={error}
          className={clsx(inputClassName, {
            'rounded-r-none': shouldRenderCopyButton,
            'border-lime-500': isCopied,
          })}
          id={name}
          {...register(name, { ...reactFormConfig })}
          {...props}
        />
      </FormField>
      {shouldRenderCopyButton && (
        <div
          className={clsx('relative self-start', {
            'mt-8': label,
          })}
        >
          <IconButton
            aria-label="Copy input value"
            aria-describedby="text-field-state"
            type="button"
            size="large"
            className={clsx('peer rounded-l-none border-l-0', {
              'cursor-default pointer-events-none': isCopied,
            })}
            variant={isCopied ? 'actionSuccess' : 'graySecondary'}
            onClick={onCopy}
            icon={
              isCopied ? (
                <CheckIcon className="size-4" />
              ) : (
                <CopyIcon className="size-4" />
              )
            }
            disabled={copyConfig?.disabled}
          />
          <span
            role="tooltip"
            id="text-field-state"
            className={clsx(
              'text-sage-600 absolute inset-y-px right-full mr-0.5 inline-flex w-max items-center bg-white px-4 text-xs font-bold uppercase tracking-wider transition-opacity duration-150 hover:ease-in peer-hover:pointer-events-auto peer-hover:visible peer-hover:opacity-100',
              {
                'opacity-0 invisible pointer-events-none': !isCopied,
              },
            )}
          >
            {isCopied ? 'Copied' : 'Copy'}
          </span>
        </div>
      )}
    </div>
  );
};
