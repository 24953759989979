import { ReactNode } from 'react';

type TableProps = {
  children: ReactNode;
};

export const Table = ({ children }: TableProps) => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full border">{children}</table>
    </div>
  );
};
