import { clsx } from 'clsx';
import { CheckCircleIcon } from '@mosey/components/Icons';
import { GuidedCredentialsStep } from './types';

const STEP_TITLE = {
  [GuidedCredentialsStep.VISIT_SITE]: 'Visit agency site',
  [GuidedCredentialsStep.USERNAME]: 'Input username',
  [GuidedCredentialsStep.PASSWORD]: 'Input password',
  [GuidedCredentialsStep.OTP]: 'Fetch passcode',
  [GuidedCredentialsStep.EMAIL_OTP]: 'Fetch passcode',
  [GuidedCredentialsStep.COMPLETE]: 'Complete',
};

type StepIconProps = {
  stepIndex: number;
  currentStepIndex: number;
};
const StepIcon = ({ currentStepIndex, stepIndex }: StepIconProps) => {
  if (stepIndex < currentStepIndex) {
    return (
      <span className="relative flex size-6 shrink-0 items-center justify-center">
        <CheckCircleIcon
          className="size-full text-lime-600 group-hover:text-lime-700"
          aria-hidden="true"
        />
      </span>
    );
  } else if (stepIndex === currentStepIndex) {
    return (
      <span
        className="relative flex size-6 shrink-0 items-center justify-center"
        aria-hidden="true"
      >
        <span className="absolute size-4 rounded-full border-2 border-gray-400" />
        <span className="relative block size-2 rounded-full bg-gray-400" />
      </span>
    );
  } else {
    return (
      <div
        className="relative flex size-6 items-center justify-center"
        aria-hidden="true"
      >
        <div className="size-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
      </div>
    );
  }
};

type StepNavEntryProps = {
  stepIndex: number;
  currentStepIndex: number;
  step: GuidedCredentialsStep;
  onStepIndexChange: (stepIndex: number) => void;
};

export const StepNavEntry = ({
  currentStepIndex,
  onStepIndexChange,
  step,
  stepIndex,
}: StepNavEntryProps) => {
  return (
    <li>
      <button
        onClick={() => onStepIndexChange(stepIndex)}
        type="button"
        className="group flex cursor-pointer items-center"
        {...(currentStepIndex === stepIndex
          ? {
              'aria-current': 'step',
            }
          : {})}
      >
        <StepIcon currentStepIndex={currentStepIndex} stepIndex={stepIndex} />
        <p
          className={clsx(
            'ml-3',
            stepIndex === currentStepIndex
              ? 'font-bold text-gray-900'
              : 'font-semibold text-gray-500 group-hover:text-gray-900',
          )}
        >
          {STEP_TITLE[step]}
        </p>
      </button>
    </li>
  );
};
