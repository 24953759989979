import { FunctionComponent, useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { EyeIcon, EyeOffIcon } from '@mosey/components/Icons';
import { TextField, TextFieldProps } from '@mosey/components/forms/TextField';

type RedactButtonProps = {
  isTextVisible: boolean;
  onClick: () => void;
  label?: string;
};

const RedactButton: FunctionComponent<RedactButtonProps> = ({
  isTextVisible,
  onClick,
  label = 'input',
}) => (
  <button
    aria-label={`${isTextVisible ? 'Hide' : 'Show'} ${label} value`}
    type="button"
    onClick={onClick}
    className="transition-text text-sage-600 duration-150 hover:text-sage-700 hover:ease-in"
  >
    {isTextVisible ? (
      <EyeOffIcon className="size-5" />
    ) : (
      <EyeIcon className="size-5" />
    )}
  </button>
);

type RedactableTextFieldProps = {
  shouldRenderCopyButton?: boolean;
  getExpiringValue?: () => Promise<string | null>;
} & TextFieldProps;

export const RedactableTextField: FunctionComponent<
  RedactableTextFieldProps
> = ({ shouldRenderCopyButton = false, ...props }) => {
  const { getExpiringValue } = props;
  const [expiringValue, setExpiringValue] = useState<string | null>(null);

  const [isTextVisible, setIsTextVisible] = useState(false);

  const onClickRightButton = async () => {
    setIsTextVisible((prevState) => !prevState);
  };

  const updateExpiringValue = async () => {
    if (!getExpiringValue) {
      return;
    }
    const value = await getExpiringValue();
    setExpiringValue(value);
  };

  useEffect(() => {
    if (!getExpiringValue) {
      return;
    }
    updateExpiringValue();
    const interval = setInterval(async () => {
      updateExpiringValue();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <TextField
      shouldRenderCopyButton
      copyConfig={{
        valueToCopy: expiringValue || '',
        disabled: getExpiringValue && expiringValue === null,
      }}
      isFullWidth
      type={isTextVisible ? 'text' : 'password'}
      rightButton={
        <RedactButton
          label={props.label}
          isTextVisible={isTextVisible}
          onClick={onClickRightButton}
        />
      }
      leftButton={
        expiringValue && (
          <div className="mr-2">
            <CountdownCircleTimer
              key={expiringValue}
              isPlaying
              duration={30}
              colors="#20BCC2" // teal-600
              strokeWidth={3}
              size={20}
            />
          </div>
        )
      }
      {...props}
      {...(expiringValue ? { value: expiringValue } : {})}
      {...(!expiringValue && getExpiringValue != null
        ? { value: '123456' }
        : {})}
    />
  );
};
