import { components } from '@mosey/api-types';

type LegalEntityRegionRef = components['schemas']['LegalEntityRegionRef'];

const LOCATION_CARD_GAP = 8;
const CAROUSEL_X_PADDING = 4;

export const areRegionArraysEqual = (
  arr1: LegalEntityRegionRef[],
  arr2: LegalEntityRegionRef[],
) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].code !== arr2[i].code) {
      return false;
    }
  }

  return true;
};

export const getFittingRegions = (
  regions: LegalEntityRegionRef[],
  container: HTMLElement,
) => {
  if (window.innerWidth < 640) {
    return [];
  }

  const { clientWidth: containerWidth, children } = container;
  let totalWidth = CAROUSEL_X_PADDING;
  let selectedRegionCode = null;
  const fittingRegionCodes: string[] = [];

  for (let i = 0; i < children.length; i++) {
    const child = children[i];

    if (child instanceof HTMLElement) {
      // Need to account for the gap between elements
      const additionalWidth = child.offsetWidth + LOCATION_CARD_GAP;

      if (child.dataset.selectedRegionCode) {
        selectedRegionCode = child.dataset.selectedRegionCode;
      }

      if (
        !(
          !child.dataset.staticListItem &&
          selectedRegionCode === child.dataset.regionCode
        )
      ) {
        if (totalWidth + additionalWidth > containerWidth) {
          break;
        }

        totalWidth += additionalWidth;
      }

      if (!child.dataset.staticListItem && child.dataset.regionCode) {
        fittingRegionCodes.push(child.dataset.regionCode);
      }
    }
  }

  return regions.filter(({ code }) => fittingRegionCodes.includes(code));
};
