import { ArrowLeftIcon } from '@mosey/components/Icons';
import { PlatformTask } from '../../types';
import { IconButton } from '@mosey/components/buttons/IconButton';
import { Link } from 'react-router-dom';
import { ManagedByTag } from './common/ManagedByTag';
import { TaskDescription } from './common/TaskDescription';
import { TaskDueDate } from './common/TaskDueDate';
import { TaskResolutions } from './common/TaskResolutions';
import { TaskStatus } from './common/TaskStatus';
import { TaskTriggeredBy } from './common/TaskTriggeredBy';

interface PlatformTaskDetailProps {
  refreshView: () => void;
  task: PlatformTask;
}

export const PlatformTaskDetail = ({
  refreshView,
  task,
}: PlatformTaskDetailProps) => {
  const {
    managed_provider: managedProvider,
    definition: { title, description, resources },
    resolutions,
    due_date: dueDate,
    status,
    criteria_met: criteriaMet,
  } = task;

  return (
    <>
      <header className="mb-5 flex items-center gap-x-4">
        <IconButton
          isFullRounded
          variant="secondary"
          icon={<ArrowLeftIcon className="w-4" />}
          as={Link}
          to="/compliance-tasks"
          aria-label="See all tasks"
        />

        <h1 className="text-2xl font-bold leading-6 text-gray-900">{title}</h1>

        <div className="ml-auto">
          <ManagedByTag managedProvider={managedProvider} />
        </div>
      </header>

      <div className="space-y-5 text-sm">
        <TaskDueDate dueDate={dueDate} />
        <TaskTriggeredBy criteriaMet={criteriaMet} status={status} />
        <TaskDescription description={description} resources={resources} />

        <div className="border-t py-6">
          <TaskStatus task={task} refreshView={refreshView} />
        </div>
      </div>

      {resolutions.manual && (
        <TaskResolutions steps={resolutions.manual.steps} />
      )}
    </>
  );
};
