import { TabPanel } from '@headlessui/react';

interface TaskTabPanelProps {
  children: React.ReactNode;
}

export const TaskTabPanel = ({ children }: TaskTabPanelProps) => {
  return (
    <TabPanel className="mt-7 flex h-full grow flex-col gap-y-3 outline-2 outline-offset-[16px] focus:outline-rose-700">
      {children}
    </TabPanel>
  );
};
