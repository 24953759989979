import { USStateAbbrev } from '@mosey/utils/constants/us-states';
import akSeal from '../assets/ak.png';
import alSeal from '../assets/al.png';
import arSeal from '../assets/ar.png';
import azSeal from '../assets/az.png';
import caSeal from '../assets/ca.png';
import coSeal from '../assets/co.png';
import ctSeal from '../assets/ct.png';
import dcSeal from '../assets/dc.png';
import deSeal from '../assets/de.png';
import flSeal from '../assets/fl.png';
import gaSeal from '../assets/ga.png';
import hiSeal from '../assets/hi.png';
import iaSeal from '../assets/ia.png';
import idSeal from '../assets/id.png';
import ilSeal from '../assets/il.png';
import inSeal from '../assets/in.png';
import ksSeal from '../assets/ks.png';
import kySeal from '../assets/ky.png';
import laSeal from '../assets/la.png';
import maSeal from '../assets/ma.png';
import mdSeal from '../assets/md.png';
import meSeal from '../assets/me.png';
import miSeal from '../assets/mi.png';
import mnSeal from '../assets/mn.png';
import moSeal from '../assets/mo.png';
import msSeal from '../assets/ms.png';
import mtSeal from '../assets/mt.png';
import ncSeal from '../assets/nc.png';
import ndSeal from '../assets/nd.png';
import neSeal from '../assets/ne.png';
import nhSeal from '../assets/nh.png';
import njSeal from '../assets/nj.png';
import nmSeal from '../assets/nm.png';
import nySeal from '../assets/ny.png';
import nvSeal from '../assets/nv.png';
import ohSeal from '../assets/oh.png';
import okSeal from '../assets/ok.png';
import orSeal from '../assets/or.png';
import paSeal from '../assets/pa.png';
import riSeal from '../assets/ri.png';
import scSeal from '../assets/sc.png';
import sdSeal from '../assets/sd.png';
import tnSeal from '../assets/tn.png';
import txSeal from '../assets/tx.png';
import utSeal from '../assets/ut.png';
import vaSeal from '../assets/va.png';
import vtSeal from '../assets/vt.png';
import waSeal from '../assets/wa.png';
import wiSeal from '../assets/wi.png';
import wvSeal from '../assets/wv.png';
import wySeal from '../assets/wy.png';

const logoMap: Record<string, string> = {
  [USStateAbbrev.Alabama]: alSeal,
  [USStateAbbrev.Alaska]: akSeal,
  [USStateAbbrev.Arizona]: azSeal,
  [USStateAbbrev.Arkansas]: arSeal,
  [USStateAbbrev.California]: caSeal,
  [USStateAbbrev.Colorado]: coSeal,
  [USStateAbbrev.Connecticut]: ctSeal,
  [USStateAbbrev.Delaware]: deSeal,
  [USStateAbbrev.DistrictOfColumbia]: dcSeal,
  [USStateAbbrev.Florida]: flSeal,
  [USStateAbbrev.Georgia]: gaSeal,
  [USStateAbbrev.Hawaii]: hiSeal,
  [USStateAbbrev.Idaho]: idSeal,
  [USStateAbbrev.Illinois]: ilSeal,
  [USStateAbbrev.Indiana]: inSeal,
  [USStateAbbrev.Iowa]: iaSeal,
  [USStateAbbrev.Kansas]: ksSeal,
  [USStateAbbrev.Kentucky]: kySeal,
  [USStateAbbrev.Louisiana]: laSeal,
  [USStateAbbrev.Maine]: meSeal,
  [USStateAbbrev.Maryland]: mdSeal,
  [USStateAbbrev.Massachusetts]: maSeal,
  [USStateAbbrev.Michigan]: miSeal,
  [USStateAbbrev.Minnesota]: mnSeal,
  [USStateAbbrev.Mississippi]: msSeal,
  [USStateAbbrev.Missouri]: moSeal,
  [USStateAbbrev.Montana]: mtSeal,
  [USStateAbbrev.Nebraska]: neSeal,
  [USStateAbbrev.Nevada]: nvSeal,
  [USStateAbbrev.NewHampshire]: nhSeal,
  [USStateAbbrev.NewJersey]: njSeal,
  [USStateAbbrev.NewMexico]: nmSeal,
  [USStateAbbrev.NewYork]: nySeal,
  [USStateAbbrev.NorthCarolina]: ncSeal,
  [USStateAbbrev.NorthDakota]: ndSeal,
  [USStateAbbrev.Ohio]: ohSeal,
  [USStateAbbrev.Oklahoma]: okSeal,
  [USStateAbbrev.Oregon]: orSeal,
  [USStateAbbrev.Pennsylvania]: paSeal,
  [USStateAbbrev.RhodeIsland]: riSeal,
  [USStateAbbrev.SouthCarolina]: scSeal,
  [USStateAbbrev.SouthDakota]: sdSeal,
  [USStateAbbrev.Tennessee]: tnSeal,
  [USStateAbbrev.Texas]: txSeal,
  [USStateAbbrev.Utah]: utSeal,
  [USStateAbbrev.Vermont]: vtSeal,
  [USStateAbbrev.Virginia]: vaSeal,
  [USStateAbbrev.Washington]: waSeal,
  [USStateAbbrev.WestVirginia]: wvSeal,
  [USStateAbbrev.Wisconsin]: wiSeal,
  [USStateAbbrev.Wyoming]: wySeal,
};

export function getStateSealImage(state: USStateAbbrev) {
  return logoMap[state?.toUpperCase()];
}
