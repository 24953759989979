import { Outlet } from 'react-router-dom';
import { PlatformTasksHeading } from './common/PlatformTasksHeading';
import { useKeyedBatchApi } from '../../hooks';
import { KeyedBatchApiStatusHandler } from '../../components';
import { PlatformLegalEntity } from '../../types';

interface PlatformTasksRootProps {
  legalEntity: PlatformLegalEntity;
}

export const PlatformTasksRoot = ({ legalEntity }: PlatformTasksRootProps) => {
  return (
    <>
      <PlatformTasksHeading name={legalEntity.name} />
      <Outlet />
    </>
  );
};

export const PlatformTasksRootView = () => {
  const calls = {
    legalEntity: {
      url: '/legal_entity',
      method: 'GET',
      isPlatform: true,
    },
  };

  const batchResponse = useKeyedBatchApi(calls, [JSON.stringify(calls)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ({ legalEntity }: { [key: string]: any }) => {
    return {
      legalEntity,
    };
  };

  return (
    <div className="mx-auto h-screen max-w-4xl">
      <KeyedBatchApiStatusHandler
        batchResponse={batchResponse}
        component={PlatformTasksRoot}
        componentProps={componentPropsFn}
      />
    </div>
  );
};
