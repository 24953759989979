import { Requirement } from '../types';
import { isDomestic } from '../utils/legal-entity';
import { useUser } from './useUser';

export const useRequirementFee = (requirement?: Requirement) => {
  const { legal_entity: legalEntity } = useUser();
  if (!requirement) {
    return undefined;
  }
  return isDomestic(legalEntity, requirement.region[0])
    ? requirement.domestic_fee
    : requirement.foreign_fee;
};
