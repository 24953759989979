import {
  FunctionComponent,
  useState,
  useCallback,
  MouseEvent,
  useEffect,
  useMemo,
  createElement,
  Suspense,
} from 'react';
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
  useAsyncValue,
  useRouteLoaderData,
  Await,
} from 'react-router-dom';
import { clsx } from 'clsx';
import {
  CheckIcon,
  ClipboardCheckIcon,
  GovernmentIcon,
  HumanResourcesIcon,
  LoadingIcon,
  LockClosedFilledIcon,
  MoreIcon,
  PayrollIcon,
  RefreshIcon,
  RobotIcon,
  SparkleIcon,
  StopwatchIcon,
  TaxIcon,
} from '@mosey/components/Icons';
import { TextLink } from '@mosey/components/navigation/TextLink';
import { DropdownMenu, MenuItem } from '@mosey/components/menus/DropdownMenu';
import { icon as iconStyle } from '@mosey/components/menus/styles';
import { Button } from '@mosey/components/buttons/Button';
import { Pill } from '@mosey/components/badges/Pill';
import {
  AssignSelect,
  BatchApiStatusHandler,
  RequirementProgress,
  RequirementFee,
  TextTruncateThree,
} from '../components';
import {
  NavigatorSection,
  NavigatorSectionItem,
} from '../components/Navigator';
import { useBatchApi } from '../hooks';
import {
  AutomationTypeEnum,
  Requirement,
  RequirementStatus,
  LegalEntityRegion,
  User,
  RegionNavigator,
  RequirementComputedStatus,
  TaxClassEnum,
  RequirementPatchUpdate,
} from '../types';
import {
  isDone as requirementIsDone,
  isManagedByMosey as requirementIsManagedByMosey,
  shouldRequirementSkipCompletionScreen,
  statusColorMapping,
} from '../utils/requirement';
import {
  formatDateFromString,
  requirementComputedStatus,
} from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { SectionProgress } from './location_detail_setup_new';
import { useRequirementFee } from '../hooks/useRequirementFee';
import { useUser } from '../hooks/useUser';
import {
  findNextRequirementFromSection,
  findNextSectionIdAndRequirementId,
  findSectionFromRequirementId,
  findSectionFromSetupSectionId,
} from './LocationDetailSetup_NEW';
import { NoticeUrlDisplay } from './Notices_NEW';
import {
  TaskQuestionRef,
  TaskRef,
  TaskType,
} from './tasks-framework/utils/types';
import { LocationDetailViewAssessmentInterstitial } from './LocationDetailInterstitials';
import { QuestionTasksBanner } from '../components/QuestionTasksBanner';
import { Loading } from './Loading';

enum SetupSectionId {
  Payroll = 'payroll',
  SalesTax = 'sales-tax',
  CorpTax = 'corp-tax',
  HR = 'hr',
  HRAndBenefits = 'hr-benefits',
  Registration = 'registration',
}

const SetupSectionIdToName: Record<SetupSectionId, string> = {
  [SetupSectionId.Payroll]: 'Payroll',
  [SetupSectionId.SalesTax]: 'Sales Tax',
  [SetupSectionId.CorpTax]: 'Corporate Tax',
  [SetupSectionId.HR]: 'HR',
  [SetupSectionId.HRAndBenefits]: 'HR & Benefits',
  [SetupSectionId.Registration]: 'Registration',
};

const SetupSectionIdToIcon: Record<SetupSectionId, React.FunctionComponent> = {
  [SetupSectionId.Payroll]: PayrollIcon,
  [SetupSectionId.SalesTax]: TaxIcon,
  [SetupSectionId.CorpTax]: TaxIcon,
  [SetupSectionId.HR]: HumanResourcesIcon,
  [SetupSectionId.HRAndBenefits]: HumanResourcesIcon,
  [SetupSectionId.Registration]: GovernmentIcon,
};

export const statusIconMapping: Record<
  RequirementComputedStatus,
  FunctionComponent
> = {
  [RequirementComputedStatus.Todo]: ClipboardCheckIcon,
  [RequirementComputedStatus.Done]: CheckIcon,
  [RequirementComputedStatus.Deferred]: StopwatchIcon,
  [RequirementComputedStatus.Locked]: LockClosedFilledIcon,
  [RequirementComputedStatus.Overdue]: StopwatchIcon,
  [RequirementComputedStatus.InProgress]: RefreshIcon,
  [RequirementComputedStatus.Managed]: RobotIcon,
};

type Section = {
  id: SetupSectionId;
  requirements: string[];
};

type SetupNavigatorProps = {
  // A list of groups to display in the navigator
  sections: Section[];
  // Currently selected section
  setupSectionId: SetupSectionId;
  // Currently selected requirement within the section
  requirementId: string;
  // Requirements data to look up if a full `Requirement` instance is needed
  requirements: Requirement[];
};

export const SetupNavigator: FunctionComponent<SetupNavigatorProps> = ({
  sections,
  setupSectionId,
  requirementId,
  requirements,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigatorSectionClick = (sid: SetupSectionId) => {
    return () => navigate(`${pathname}?section=${sid}`);
  };

  const handleNavigatorSectionItemClick = (
    sid: SetupSectionId,
    rid: string,
  ) => {
    return (e: MouseEvent) => {
      // The parent `NavigatorSection` has a click handler also so this
      // prevents the parent from getting the click event too
      e.stopPropagation();
      navigate(`${pathname}?section=${sid}&requirement=${rid}`);
    };
  };

  // Returns true if all requirements with a data ID in
  // `requirementDataIds` have a status of `RequirementStatus.Done` or
  // they are managed.  NOTE: There can be multiple requirements with
  // the same data ID (recurring requirements) so this check looks at
  // the status of the first instance and ignores the rest. Otherwise
  // we could end up in a situation where the user completed all the
  // requirements but the section is not marked as complete
  const sectionRequirementsDone = (requirementDataIds: string[]): boolean => {
    const reqs = [];
    const visitedDataIds = new Set();
    for (const r of requirements) {
      const dataId = r.data_id;
      if (requirementDataIds.indexOf(dataId) === -1) {
        continue;
      }

      // Ignore requirements that have a data ID we've already visited
      if (visitedDataIds.has(dataId)) {
        continue;
      }

      visitedDataIds.add(dataId);
      reqs.push(r);
    }

    return reqs.every((r) => requirementIsDone(r));
  };

  const requirementsDictionary = useMemo(() => {
    const dictionary: { [key: string]: Requirement } = {};

    sections.map((section) =>
      section.requirements.forEach((reqId) => {
        const req = requirements.find((r) => r.data_id === reqId);

        if (req) {
          dictionary[reqId] = req;
        }
      }),
    );

    return dictionary;
  }, [requirements]);

  return (
    <div>
      <div className="relative">
        {sections.map((section, sectionIdx) => {
          const sectionHasBlockedItem = section.requirements.some(
            (reqId) =>
              requirementsDictionary[reqId] &&
              requirementsDictionary[reqId].is_blocked,
          );

          return (
            <NavigatorSection
              key={section.id}
              sectionIcon={SetupSectionIdToIcon[section.id]}
              heading={SetupSectionIdToName[section.id]}
              isSelected={setupSectionId === section.id}
              isFirst={sectionIdx === 0}
              isDone={
                section.requirements.length > 0 &&
                sectionRequirementsDone(section.requirements)
              }
              onClick={handleNavigatorSectionClick(section.id)}
            >
              {section.requirements.map((reqId: string, reqIdx: number) => {
                const req = requirementsDictionary[reqId];

                return (
                  <NavigatorSectionItem
                    key={reqId}
                    text={req?.title || ''}
                    sectionHasBlockedItem={sectionHasBlockedItem}
                    isSectionSelected={setupSectionId === section.id}
                    isLocked={req && req.is_blocked}
                    isFirst={reqIdx === 0}
                    isDone={req && requirementIsDone(req)}
                    isSelected={requirementId === reqId}
                    isLast={reqIdx === section.requirements.length - 1}
                    onClick={handleNavigatorSectionItemClick(section.id, reqId)}
                  />
                );
              })}
            </NavigatorSection>
          );
        })}
      </div>
    </div>
  );
};

type LocationDetailSetupProps = {
  legalEntityRegion: LegalEntityRegion;
  requirements: Requirement[];
  users: User[];
  sections: Section[];
  setupSectionId: SetupSectionId;
  requirementId: string | null;
  locationId: string;
  toggleReloadView: () => void;
  triggerParentRefresh: () => void;
};

export const LocationDetailSetup: FunctionComponent<
  LocationDetailSetupProps
> = ({
  legalEntityRegion,
  requirements,
  users,
  sections,
  setupSectionId,
  requirementId,
  locationId,
  toggleReloadView,
  triggerParentRefresh,
}) => {
  const questionTasks = useAsyncValue() as TaskRef[];
  const { legal_entity: legalEntity } = useUser();
  const [isDeferLoading, setIsDeferLoading] = useState<boolean>(false);
  const [isAssignmentLoading, setIsAssignmentLoading] =
    useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isImported, setIsImported] = useState<boolean>(false);

  // Reset the component's internal state whenever the requirement and
  // location changes, otherwise you will have stale state when
  // switching between requirements
  useEffect(() => {
    setIsDeferLoading(false);
    setIsAssignmentLoading(false);
    setIsImporting(false);
  }, [locationId, requirementId]);

  // Aggregate question tasks by requirement ID
  const questionTasksByRequirementId: { [key: string]: TaskQuestionRef[] } = {};

  for (let i = 0; i < questionTasks.length; i++) {
    const { source } = questionTasks[i];

    if (
      source.type === TaskType.question &&
      'related_requirement_ids' in source &&
      source.related_requirement_ids
    ) {
      for (let j = 0; j < source.related_requirement_ids.length; j++) {
        const reqId = source.related_requirement_ids[j];

        if (reqId in questionTasksByRequirementId) {
          questionTasksByRequirementId[reqId].push(source);
        } else {
          questionTasksByRequirementId[reqId] = [source];
        }
      }
    }
  }

  const sectionInfo = sections.find((s) => s.id === setupSectionId);

  if (!sectionInfo) {
    throw Error(`Could not find section matching ${setupSectionId}`);
  }

  // Default to the first requirement in the section
  const defaultReqId = sectionInfo.requirements[0];

  const findRequirement = (reqId: string | null): Requirement => {
    let req: Requirement;
    if (reqId) {
      const maybeReq = requirements.find((r) => r.data_id === reqId);
      if (!maybeReq) {
        throw Error(`Requirement not found ${reqId}`);
      }
      req = maybeReq;
    } else if (defaultReqId) {
      req = findRequirement(defaultReqId);
    } else {
      throw Error('No default requirement found');
    }
    return req;
  };

  const requirementData = findRequirement(requirementId);
  const fee = useRequirementFee(requirementData);
  const isAttributeProducer = requirementData.produces.length > 0;
  const isManaged = requirementData.is_managed;
  const isManagedByMosey = requirementIsManagedByMosey(requirementData);
  const isDone = requirementIsDone(requirementData);
  const isImportable =
    !isImporting &&
    !isDone &&
    !isManaged &&
    !isManagedByMosey &&
    isAttributeProducer;
  const isLocked = requirementData.is_blocked;
  const isAutomatable =
    requirementData.is_automatable &&
    !isManaged &&
    !isDone &&
    !isLocked &&
    requirementData.id &&
    requirementData.status !== RequirementStatus.Deferred;

  const handleImportSuccess = () => {
    setIsImported(true);
    // Make sure the status updates to done
    toggleReloadView();
    // Make sure the registration info in the parent view updates
    triggerParentRefresh();
  };

  const handleRequirementChange = () => {
    toggleReloadView();
    triggerParentRefresh();
  };

  const handleAssignmentChange = (userId: string | null) => {
    setIsAssignmentLoading(true);

    const requirementCopy = { ...requirementData };

    let body: RequirementPatchUpdate;
    if (userId) {
      body = {
        operation: 'assign',
        // eslint-disable-next-line
        user_id: userId,
      };
      const user = users.find((u) => u.id === userId);
      requirementCopy.assigned_user = user;
    } else {
      body = {
        operation: 'unassign',
      };
      requirementCopy.assigned_user = undefined;
    }

    fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PATCH',
      body,
    }).then(() => {
      toggleReloadView();
    });

    // Add a timeout here so it never flashes on the screen
    setTimeout(() => setIsAssignmentLoading(false), 400);
  };

  const deferHandler = useCallback(
    async (e?: MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      setIsDeferLoading(true);

      async function updateRequirement() {
        const status =
          requirementData.status === RequirementStatus.Deferred
            ? RequirementStatus.Todo
            : RequirementStatus.Deferred;

        await fetchApi({
          url: `/api/requirements/${requirementData.id}`,
          method: 'PUT',
          body: { status },
        });

        // Add a timeout here so it never flashes on the screen
        setTimeout(() => {
          toggleReloadView();
          triggerParentRefresh();
          setIsDeferLoading(false);
        }, 400);
      }

      await updateRequirement();
    },
    [requirementData],
  );

  const selectedSectionRequirements = [];
  const visitedDataIds = new Set();
  for (const r of requirements) {
    if (!sectionInfo.requirements.includes(r.data_id)) {
      continue;
    }

    // Ignore requirements that have a data ID we've already visited
    if (visitedDataIds.has(r.data_id)) {
      continue;
    }

    visitedDataIds.add(r.data_id);
    selectedSectionRequirements.push(r);
  }

  const selectedSectionPercentComplete =
    (selectedSectionRequirements.filter(requirementIsDone).length /
      selectedSectionRequirements.length) *
    100;
  const selectedSectionIsComplete = selectedSectionPercentComplete === 100;

  const hasUnsatisfiedCriteria =
    requirementData.has_criteria && !requirementData.is_criteria_satisfied;

  const setupQuestionsComplete =
    questionTasks &&
    questionTasks.filter(
      (qt) =>
        qt.source.type === TaskType.question &&
        'is_setup_related' in qt.source &&
        qt.source.is_setup_related,
    ).length == 0;

  const allQuestionsComplete = questionTasks && questionTasks.length === 0;

  if (
    !legalEntityRegion.is_setup_complete &&
    !setupQuestionsComplete &&
    requirements.every((r) => r.status === 'todo')
  ) {
    return (
      <LocationDetailViewAssessmentInterstitial
        resolverUrl={`/locations/usa/${legalEntityRegion.region.code.toLowerCase()}/resolver/assessment`}
      />
    );
  }

  return (
    <>
      <QuestionTasksBanner
        regionCode={locationId}
        isSetup={!setupQuestionsComplete}
        isNested={false}
        show={
          !setupQuestionsComplete ||
          (legalEntityRegion.is_setup_complete && !allQuestionsComplete)
        }
      />
      <div className="h-[calc(100%-60px)] p-6">
        <div className="flex items-center border-t">
          <div className="w-96 shrink-0 border-l bg-white px-6 py-4 text-lg font-bold text-zinc-700">
            Navigator
          </div>
          <div className="flex flex-1 flex-wrap items-center border-x bg-white px-6 py-4">
            <div className="flex flex-1 items-center justify-between gap-x-4">
              <div className="flex">
                <div
                  className={clsx('size-6 fill-current', {
                    'text-lime-500': selectedSectionIsComplete,
                    'text-rose-700': !selectedSectionIsComplete,
                  })}
                >
                  {createElement(SetupSectionIdToIcon[setupSectionId])}
                </div>
                <h2 className="ml-2 flex-1 text-lg font-bold text-zinc-700">
                  {SetupSectionIdToName[setupSectionId]}
                </h2>
              </div>
              <SectionProgress progress={selectedSectionPercentComplete} />
            </div>
          </div>
        </div>
        <div className="flex h-full flex-col border">
          <div className="flex h-full flex-1 items-start">
            <div className="h-full w-96 overflow-y-auto border-r bg-white">
              <div>
                <SetupNavigator
                  sections={sections}
                  setupSectionId={setupSectionId}
                  requirementId={requirementData.data_id}
                  requirements={requirements}
                />
              </div>
            </div>
            <div className="no-scrollbar flex h-full flex-1 flex-col overflow-y-auto bg-white pb-6">
              <div className="grid pt-10 2xl:grid-cols-12">
                <div className="col-span-12 2xl:flex">
                  <div className="flex items-start lg:col-span-11 2xl:col-span-7">
                    <div className="px-9">
                      <div className="flex items-start justify-between">
                        <h3 className="text-2xl font-bold tracking-tight text-zinc-700">
                          {requirementData.title}
                        </h3>
                        <div className="flex gap-x-4">
                          {(!isManaged || !isDone) && (
                            <div className="col-span-1 grid justify-items-end">
                              <div className="ml-1 mt-1">
                                {isDeferLoading ? (
                                  <div className="flex items-center">
                                    <span className="mr-2 text-sm text-gray-600">
                                      Saving
                                    </span>
                                    <LoadingIcon className="size-6 cursor-pointer text-gray-400" />
                                  </div>
                                ) : (
                                  <DropdownMenu
                                    isIconButton
                                    ariaButtonText="Change requirement status"
                                    buttonContent={
                                      <MoreIcon
                                        className={iconStyle}
                                        aria-hidden="true"
                                      />
                                    }
                                  >
                                    <MenuItem
                                      as="button"
                                      onClick={deferHandler}
                                    >
                                      {requirementData.status ===
                                      RequirementStatus.Deferred
                                        ? 'Mark as Todo'
                                        : 'Defer'}
                                    </MenuItem>
                                  </DropdownMenu>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 text-sm text-zinc-700 xl:grid xl:grid-cols-12">
                        <div className="xl:col-span-8">
                          <TextTruncateThree
                            text={requirementData.description}
                          />
                          {requirementData.resources && (
                            <ul className="mt-2 list-disc">
                              {requirementData.resources.map((resource) => (
                                <li
                                  key={`${resource.url}`}
                                  className="flex items-center text-sm"
                                >
                                  <TextLink to={resource.url} target="_blank">
                                    {resource.name}
                                  </TextLink>
                                </li>
                              ))}
                            </ul>
                          )}

                          {isAutomatable && (
                            <>
                              {fee && (
                                <div className="my-9">
                                  <RequirementFee fee={fee} />
                                </div>
                              )}
                              <div className="mt-6 flex whitespace-nowrap">
                                <Button
                                  as={Link}
                                  // need to use APP_BASE_URL to match hosts to prevent logout in CallbackInterstitial
                                  to={`/automation/${
                                    requirementData.id
                                  }?callback_url=${encodeURIComponent(location.toString())}&skip_success_screen=${shouldRequirementSkipCompletionScreen(requirementData)}`}
                                >
                                  Automate with Mosey
                                </Button>
                              </div>
                            </>
                          )}

                          {legalEntity.notice_url &&
                            requirementData?.tags?.includes(
                              AutomationTypeEnum.NoticeBoard,
                            ) &&
                            isManagedByMosey &&
                            isDone && (
                              <div className="mt-4 space-y-4">
                                <p>
                                  Copy the link below to an internal wiki or
                                  document that is readibly accessible to all
                                  remote employees and notify them of where to
                                  find it. Updates will happen automatically for
                                  all regions you add.
                                </p>

                                <NoticeUrlDisplay
                                  region={legalEntityRegion.region}
                                />
                              </div>
                            )}
                        </div>
                        {isImportable &&
                          !hasUnsatisfiedCriteria &&
                          !requirementData.is_blocked && (
                            <div className="mt-4 flex items-start xl:col-span-4 xl:mt-0 xl:justify-end xl:pl-4">
                              <div className="bd-sage-400 rounded border bg-sage-200 p-4 text-base leading-6 text-rose-600 xl:max-w-xs">
                                <div className="flex items-center justify-between">
                                  <SparkleIcon className="mr-2 mt-2 size-5 self-start rounded-3xl text-rose-700" />
                                  <div className="flex-1">
                                    <div className="text-base font-bold leading-7 text-zinc-700">
                                      Already did this?
                                    </div>
                                    <div className="text-sm text-zinc-700">
                                      Import your information to mark this
                                      requirement as done and set up tracking.
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  type="button"
                                  className="ml-6 mt-2"
                                  variant="secondary"
                                  onClick={() => setIsImporting(true)}
                                >
                                  Import
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8 border-y py-3 text-sm">
                <div className="flex flex-wrap items-center gap-y-4 px-8">
                  {requirementData.due_date && (
                    <>
                      <span className="font-semibold text-zinc-800">
                        Due Date
                      </span>
                      <span className="ml-2 mr-4 inline-block border-r pr-4">
                        {formatDateFromString(requirementData.due_date)}
                      </span>
                    </>
                  )}
                  <span className="font-semibold text-zinc-800">Status</span>
                  <span className="ml-2 mr-4 inline-block border-r pr-4">
                    <Pill
                      Icon={
                        statusIconMapping[
                          requirementComputedStatus(requirementData)
                        ]
                      }
                      size="small"
                      variant={
                        statusColorMapping[
                          requirementComputedStatus(requirementData)
                        ]
                      }
                    >
                      {requirementComputedStatus(requirementData)}
                    </Pill>
                  </span>
                  <span className="inline font-semibold text-zinc-800">
                    Assigned to
                  </span>
                  <span className="ml-2">
                    <LoadingIcon
                      className={clsx(
                        'mr-1 inline size-5 cursor-pointer text-gray-400',
                        {
                          hidden: !isAssignmentLoading,
                        },
                      )}
                    />
                    <AssignSelect
                      users={users}
                      assignedTo={requirementData.assigned_user}
                      onChange={handleAssignmentChange}
                      alignRight
                    />
                  </span>
                </div>
              </div>
              <div className="mt-7 flex grow flex-col px-8">
                <RequirementProgress
                  requirement={requirementData}
                  questionTasks={
                    questionTasksByRequirementId[requirementData.data_id]
                  }
                  isImporting={isImporting}
                  isImported={isImported}
                  isManaged={isManaged}
                  isManagedByMosey={isManagedByMosey}
                  onImportSuccess={handleImportSuccess}
                  onImportCancel={() => setIsImporting(false)}
                  onRequirementChange={handleRequirementChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type LocationDetailSetupViewProps = {
  legalEntityRegion: LegalEntityRegion;
  requirements: Requirement[];
  triggerParentRefresh: () => void;
};

const buildNavigatorSections = (
  regionNavigator: RegionNavigator,
  requirements: Requirement[],
  taxClass: TaxClassEnum,
  hasUniqueNavigator: boolean,
  locationId: string,
  incorporationRegionCode: string = '',
) => {
  const sections: Section[] = [];
  const isHomeState =
    locationId.toLowerCase() === incorporationRegionCode.toLowerCase();

  const filterRequirements = (reqDataIds: string[]) => {
    // return all requirements when we don't know if location is foreign/home
    if (incorporationRegionCode === '') {
      return reqDataIds;
    } else {
      const tag: AutomationTypeEnum = isHomeState
        ? AutomationTypeEnum.ForeignStateOnly
        : AutomationTypeEnum.HomeStateOnly;

      const filteredReqIds = reqDataIds.filter((reqId) => {
        const req = requirements.find((req) => req.data_id === reqId);
        return !req?.tags?.includes(tag);
      });
      return filteredReqIds;
    }
  };

  if (regionNavigator.payroll_requirements.length) {
    const filteredPayrollRequirements = filterRequirements(
      regionNavigator.payroll_requirements,
    );
    sections.push({
      id: SetupSectionId.Payroll,
      requirements: filteredPayrollRequirements,
    });
  }
  if (regionNavigator.hr_requirements.length) {
    const filteredHRRequirements = filterRequirements(
      regionNavigator.hr_requirements,
    );
    sections.push({
      id: SetupSectionId.HR,
      requirements: filteredHRRequirements,
    });
  }
  if (regionNavigator.hr_benefits_requirements.length) {
    const filteredHRBenefitsRequirements = filterRequirements(
      regionNavigator.hr_benefits_requirements,
    );
    sections.push({
      id: SetupSectionId.HRAndBenefits,
      requirements: filteredHRBenefitsRequirements,
    });
  }
  // TODO: remove check once all S Corp navigators are configured
  if (hasUniqueNavigator || taxClass !== TaxClassEnum.s_corp) {
    if (regionNavigator.registration_requirements.length) {
      const filteredRegistrationRequirements = filterRequirements(
        regionNavigator.registration_requirements,
      );
      sections.push({
        id: SetupSectionId.Registration,
        requirements: filteredRegistrationRequirements,
      });
    }
    if (regionNavigator.entity_tax_requirements.length) {
      const filteredEntityTaxRequirements = filterRequirements(
        regionNavigator.entity_tax_requirements,
      );
      sections.push({
        id: SetupSectionId.CorpTax,
        requirements: filteredEntityTaxRequirements,
      });
    }
  }
  if (regionNavigator.sales_tax_requirements.length) {
    const filteredSalesTaxRequirements = filterRequirements(
      regionNavigator.sales_tax_requirements,
    );
    sections.push({
      id: SetupSectionId.SalesTax,
      requirements: filteredSalesTaxRequirements,
    });
  }
  return sections;
};

export const LocationDetailSetupView: FunctionComponent<
  LocationDetailSetupViewProps
> = ({ legalEntityRegion, requirements, triggerParentRefresh }) => {
  const { questionTasksTodo } = useRouteLoaderData('question-tasks-jm') as {
    questionTasksTodo: Promise<TaskRef[]>;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = legalEntityRegion.region.code.toLowerCase();
  const { tax_class: taxClass, incorporation_region: incorporationRegion } =
    useUser().legal_entity;

  const sections = buildNavigatorSections(
    legalEntityRegion.region_navigator,
    requirements,
    taxClass,
    legalEntityRegion.has_unique_navigator,
    locationId,
    incorporationRegion?.code,
  );

  if (sections.length === 0) {
    throw Error(`Unsupported location with location id: ${locationId}`);
  }

  const filteredSections = sections.filter((section) => {
    return section.requirements.length > 0;
  });

  let setupSectionId = searchParams.get('section') as SetupSectionId;
  let requirementId = searchParams.get('requirement');
  let shouldUpdateSearchParams = false;

  let section: Section | null = null;

  if (setupSectionId) {
    section = findSectionFromSetupSectionId(filteredSections, setupSectionId);

    if (!section) {
      shouldUpdateSearchParams = true;
      searchParams.delete('section');
    }
  }

  if (requirementId) {
    const requirementSection = findSectionFromRequirementId(
      filteredSections,
      requirementId,
    );

    if (requirementSection) {
      /**
       * This is a special case where both search parameters were provided but
       * they correspond to two different sections. In this case, use the section
       * associated with the requirement, and update the URL search parameters.
       */
      if (setupSectionId && requirementSection !== section) {
        shouldUpdateSearchParams = true;
        searchParams.set('section', requirementSection.id);
      }

      section = requirementSection;
      setupSectionId = section.id;
    } else {
      shouldUpdateSearchParams = true;
      searchParams.delete('requirement');
    }
  }

  /**
   * If we are not given a requirement ID via query parameters, we can only
   * determine which requirement needs attention first using requirements data
   * response from the API.
   */
  if (!requirementId && setupSectionId) {
    requirementId = findNextRequirementFromSection(section, requirements);
    if (requirementId) {
      shouldUpdateSearchParams = true;
      searchParams.set('requirement', requirementId);
    }
  } else if (!requirementId && !setupSectionId) {
    [setupSectionId, requirementId] = findNextSectionIdAndRequirementId(
      filteredSections,
      requirements,
    );
    shouldUpdateSearchParams = true;
    searchParams.set('section', setupSectionId);
    if (requirementId) {
      searchParams.set('requirement', requirementId);
    }
  }

  /**
   * Default to the first section if section ID was not provided as a query
   * parameter, or could not be determined via the requirement ID.
   */
  if (setupSectionId === null) {
    setupSectionId = filteredSections[0].id;
  }

  useEffect(() => {
    if (shouldUpdateSearchParams) {
      setSearchParams(searchParams, { replace: true });
    }
  }, [shouldUpdateSearchParams]);

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 90);

  // Sentinel so the view can be reloaded if requirements change
  const [shouldRefreshData, toggleShouldRefreshData] = useState<boolean>(false);
  const batchResponse = useBatchApi(
    [
      {
        url: '/api/users',
        method: 'GET',
      },
    ],
    [setupSectionId, shouldRefreshData],
  );

  const componentPropsFn = ([
    usersResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ]: any[]): LocationDetailSetupProps => {
    return {
      legalEntityRegion,
      requirements: requirements,
      users: usersResponse,
      toggleReloadView: () => toggleShouldRefreshData((prev) => !prev),
      sections: filteredSections,
      setupSectionId,
      requirementId,
      locationId,
      triggerParentRefresh,
    };
  };

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={questionTasksTodo}>
        <BatchApiStatusHandler
          batchResponse={batchResponse}
          component={LocationDetailSetup}
          componentProps={componentPropsFn}
        />
      </Await>
    </Suspense>
  );
};
