import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { StatusIcon } from './StatusIcon';
import { TaskCardVariant, TaskCardReason, TaskCardStatus } from './types';
import { ReasonIcon } from './ReasonIcon';
import { ChevronRightIcon } from '@heroicons/react/outline';

export type TaskSummaryCardProps = {
  variant?: TaskCardVariant;
  size?: 'medium' | 'large';
  title: ReactNode;
  description?: ReactNode;
  status?: TaskCardStatus;
  reason?: {
    title: ReactNode;
    type?: TaskCardReason;
  };
  region?: ReactNode;
  // whether to match the hash as well
  isExactMatch?: boolean;
} & NavLinkProps;

export const TaskSummaryCard = ({
  status,
  title,
  description,
  reason,
  region,
  variant = 'card',
  size = 'large',
  isExactMatch = false,
  ['aria-label']: ariaLabel,
  ...props
}: TaskSummaryCardProps) => {
  const location = useLocation();
  const fullPath = location.pathname + location.hash;
  const showFooter = region || reason;
  const isMediumCollection = variant === 'collection' && size === 'medium';

  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        clsx(
          'block border border-gray-200 outline-none hover:border-teal-350 hover:bg-teal-200 focus-visible:border-rose-700 focus-visible:ring-1 focus-visible:ring-rose-700',
          isMediumCollection && status && 'py-1.5 pl-1.5 pr-3',
          isMediumCollection && 'px-3 py-1.5',
          !isMediumCollection && 'px-3 py-[10px]',
          variant === 'nav' &&
            'relative z-0 -mt-px hover:z-10 hover:border-b focus-visible:z-20',
          variant === 'card' && 'rounded shadow-sm hover:shadow',
          variant === 'collection' && 'rounded-full shadow-sm hover:shadow',
          isActive &&
            (!isExactMatch || fullPath === props.to) &&
            variant === 'nav' &&
            'bg-teal-100 text-black',
        )
      }
      rel={props.target === '_blank' ? 'noreferrer' : undefined}
      aria-label={`${ariaLabel || title}${status === 'done' ? ' (Complete)' : status === 'overdue' ? ' (Overdue)' : ''}`}
    >
      <div className="flex w-full gap-3">
        {status && (
          <StatusIcon
            status={status}
            size={variant === 'nav' && size === 'medium' ? 'medium' : 'large'}
          />
        )}

        <div className="flex w-full flex-1 flex-col items-start justify-center gap-0.5 self-center overflow-hidden text-zinc-800">
          <p
            className={clsx(
              'w-full font-semibold',
              size === 'medium' && 'text-sm',
            )}
          >
            {title}
          </p>

          {description && (
            <p className="w-full truncate text-sm">{description}</p>
          )}

          {showFooter && (
            <div className="flex w-full max-w-full gap-x-1 text-sm">
              {reason && (
                <div className="flex items-center gap-1 truncate">
                  {reason.type && <ReasonIcon reason={reason.type} />}
                  <p className="truncate text-zinc-600">{reason.title}</p>
                </div>
              )}

              {region && <p className="ml-auto text-zinc-600">{region}</p>}
            </div>
          )}
        </div>

        {variant === 'collection' && (
          <ChevronRightIcon className="size-4 shrink-0 self-center text-zinc-600" />
        )}
      </div>
    </NavLink>
  );
};
