import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { PageTitle } from '@mosey/components/page-header/PageTitle';
import {
  redirect,
  useFetcher,
  useLoaderData,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import { SubmitTarget } from 'react-router-dom/dist/dom';
import { api } from '../../../utils/fetchApi';
import { Renderer, formSpecToRendererConfig } from '../../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { FormSpec } from '../../../types';
import { Button } from '@mosey/components/buttons/Button';
import { TextLink } from '@mosey/components/navigation/TextLink';

export const SetupForm = () => {
  const submit = useSubmit();
  const { state } = useNavigation();
  const { Form } = useFetcher();
  const formSpec = useLoaderData() as FormSpec;
  const formMethods = useForm({ defaultValues: formSpec.default_values });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data: SubmitTarget) => {
    submit(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { ...(data as any), intent: 'handbook-setup' },
      {
        method: 'POST',
        action: '/handbook/overview',
        encType: 'application/json',
        navigate: true,
      },
    );
  };
  return (
    <section>
      <PageHeader>
        <PageTitle>Handbook Setup</PageTitle>
      </PageHeader>
      <main className="p-8">
        <FormProvider {...formMethods}>
          <Form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <p>
              Provide some additional information about your business to start
              building your handbook.
            </p>
            <div className="mt-8 max-w-96">
              <Renderer
                config={formSpecToRendererConfig(formSpec)}
                errors={errors}
              />
            </div>
            <div className="-mt-8 flex items-center gap-6">
              <Button type="submit" isLoading={state !== 'idle'}>
                Save & Continue
              </Button>
              <TextLink
                to="/handbook/setup/welcome"
                variant="secondary"
                skipInk={false}
              >
                Cancel
              </TextLink>
            </div>
          </Form>
        </FormProvider>
      </main>
    </section>
  );
};

SetupForm.loader = async () => {
  const formResponse = await api({
    url: '/api/form_captures/view/handbook_setup',
    method: 'POST',
  });
  const [form] = (await formResponse.json()) as Array<FormSpec>;
  if (form?.sections[0].form_fields.length > 0) {
    return form;
  } else {
    // there is no data to collect, create their draft handbook and move them to the overview
    try {
      await api({
        method: 'POST',
        url: '/api/handbook/draft',
      });
    } catch (err) {
      if ((err as Response).status !== 409) {
        throw err;
      }
    }
    return redirect('/handbook/overview');
  }
};
