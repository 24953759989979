import { RobotIcon } from '@mosey/components/Icons';
import { ComponentType } from 'react';

interface AutomationStepStartEndProps {
  children: React.ReactNode;
  Icon: ComponentType<
    Pick<React.ButtonHTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
  >;
}

interface AutomationStepEndProps extends AutomationStepStartEndProps {
  secondary?: React.ReactNode;
}

export const AutomationStepStart = ({
  Icon,
  children,
}: AutomationStepStartEndProps) => {
  return (
    <>
      <div className="relative flex items-center gap-x-2 rounded bg-teal-350 px-6 py-4 leading-6 text-teal-900">
        <Icon className="size-5 text-teal-800" aria-hidden="true" />
        {children}
      </div>

      <div
        aria-hidden="true"
        className="ml-[30px] h-7 w-1.5 bg-gradient-to-b from-teal-350 to-indigo-50"
      />
    </>
  );
};

export const AutomationStepEnd = ({
  Icon,
  secondary,
  children,
}: AutomationStepEndProps) => {
  return (
    <>
      <div className="relative min-h-7">
        <div
          aria-hidden="true"
          className="absolute inset-y-0 ml-[30px] w-1.5 bg-gradient-to-b from-indigo-50 to-teal-350"
        />

        {secondary && <div className="isolate ml-12 py-5">{secondary}</div>}
      </div>

      <div className="relative flex items-center gap-x-2 rounded bg-teal-350 px-6 py-4 leading-6 text-teal-900">
        <Icon className="size-5 text-teal-800" aria-hidden="true" />
        {children}
      </div>
    </>
  );
};

interface AutomationStepProps {
  children: React.ReactNode;
  sideEffects?: React.ReactNode;
}

export const AutomationStep = ({
  children,
  sideEffects,
}: AutomationStepProps) => {
  return (
    <li className="group relative">
      <div className="flex items-center gap-x-2 rounded bg-indigo-50 px-6 py-4 text-indigo-800">
        <div
          aria-hidden="true"
          className="absolute top-full ml-1.5 block h-3 w-1.5 bg-indigo-50 group-last:hidden"
        />

        <RobotIcon className="size-5 text-indigo-800" aria-hidden="true" />

        {children}

        <span className="ml-auto text-xs font-medium uppercase text-indigo-700">
          Automated
        </span>
      </div>
      {sideEffects}
    </li>
  );
};
