import { FunctionComponent } from 'react';
import { FieldError as ReactHookFieldError } from 'react-hook-form';
import { FormError } from './FormError';

type FieldErrorProps = {
  error?: ReactHookFieldError;
};

export const FieldError: FunctionComponent<FieldErrorProps> = ({ error }) => {
  return error ? (
    <FormError errorMessage={error?.message} margin="mt-2" />
  ) : null;
};
