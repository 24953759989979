import { FunctionComponent, useState } from 'react';

import { TextField, TextFieldProps } from '@mosey/components/forms/TextField';
import { EmailOtp, Login } from '../../types';
import { pollForEmailOtp } from '../../utils/otp';
import { LoadingIcon, RefreshIcon } from '@mosey/components/Icons';

type EmailOtpInputProps = {
  login: Login;
} & TextFieldProps;

export const EmailOtpInput: FunctionComponent<EmailOtpInputProps> = ({
  login,
  ...props
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState<EmailOtp | null>(null);

  const getEmailOtp = async () => {
    try {
      setLoading(true);
      const newOtp = await pollForEmailOtp(login.public_id, emailOtp?.date);
      setEmailOtp(newOtp);
      setError(null);
    } catch (err) {
      setError(
        `Request timed out, please request a new code from ${login.account_name}`,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <TextField
      shouldRenderCopyButton
      copyConfig={{
        valueToCopy: emailOtp?.code || '',
        disabled: loading || !emailOtp?.code,
      }}
      isFullWidth
      type={emailOtp?.code ? 'text' : 'password'}
      rightButton={
        emailOtp &&
        !loading && (
          <button
            aria-label={`Fetch ${emailOtp?.code ? 'new ' : ''} one-time passcode`}
            type="button"
            onClick={getEmailOtp}
            className="text-sage-600 duration-150 hover:text-sage-700 hover:ease-in"
          >
            <RefreshIcon className="size-5" />
          </button>
        )
      }
      overlay={
        <>
          {!emailOtp && !loading && (
            <button
              type="button"
              onClick={getEmailOtp}
              className="flex size-full cursor-pointer items-center rounded-l pl-3 text-sm font-bold uppercase tracking-wider text-sage-600 duration-150 hover:bg-gray-50 hover:text-sage-700 hover:ease-in"
            >
              Click to fetch OTP
            </button>
          )}
          {loading && (
            <div className="flex size-full items-center pl-2">
              <LoadingIcon className="mr-2 size-8 text-gray-500" />
              <span
                role="tooltip"
                id="text-field-state"
                className="pointer-events-none mr-2 text-sm font-bold uppercase tracking-wider text-sage-600"
              >
                Waiting for email
              </span>
            </div>
          )}
        </>
      }
      {...props}
      value={loading ? '' : emailOtp?.code}
      disabled
      error={
        error
          ? {
              type: 'custom',
              message: error,
            }
          : undefined
      }
    />
  );
};
