import { DownloadIcon, PDFIcon, PrintIcon } from '@mosey/components/Icons';
import { NoticeRow } from './NoticeRow';
import { Link, useLocation } from 'react-router-dom';
import { components } from '@mosey/api-types';

type EmbeddedNoticeEntryProps = {
  notice: components['schemas']['Notice'];
};

export const EmbeddedNoticeEntry = ({ notice }: EmbeddedNoticeEntryProps) => {
  const { pathname } = useLocation();
  return (
    <li>
      <NoticeRow>
        <Link
          to={`${pathname}?view=${notice.id}`}
          className="mr-auto py-6 font-bold"
          target="_top"
        >
          <PDFIcon className="mr-4 inline w-6" />
          {notice.title}
        </Link>

        <Link
          to={`${pathname}?view=${notice.id}`}
          className="text-teal-700"
          target="_top"
        >
          View
        </Link>

        <Link
          to={`${pathname}?print=${notice.id}`}
          className="border-l pl-2"
          target="_top"
        >
          <PrintIcon className="w-6 text-gray-400" />
        </Link>

        <Link to={`${pathname}?download=${notice.id}`} target="_top">
          <DownloadIcon className="w-6 text-gray-400" />
        </Link>
      </NoticeRow>
    </li>
  );
};
