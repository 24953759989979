import { PolicyContentRenderer } from '@mosey/components/handbook/PolicyContentRenderer';
import { ReactNode } from 'react';
import { PolicyMainContent } from './types';

type CompanyWidePolicyContentProps = {
  action?: ReactNode;
  mainContent: Array<PolicyMainContent>;
};

export const CompanyWidePolicyContent = ({
  action,
  mainContent,
}: CompanyWidePolicyContentProps) => {
  return (
    <div className="flex flex-col gap-2 bg-teal-200 p-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-semibold text-teal-800">
          Company-wide Policy
        </h3>
        {action}
      </div>
      <div className="text-zinc-700">
        {mainContent.map((item, index: number) => (
          <PolicyContentRenderer key={index} content={item} />
        ))}
      </div>
    </div>
  );
};
