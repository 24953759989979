import { FunctionComponent } from 'react';
import { Link, Outlet } from 'react-router-dom';

import * as config from '../settings/config';
import gustoLogo from '../assets/gusto-logo.svg';
import paylocityLogo from '../assets/paylocity-logo.svg';
import adpLogo from '../assets/adp-logo.svg';
import zenefitsLogo from '../assets/zenefits-logo.svg';
import { clsx } from 'clsx';

const PayrollProviderLogo = {
  Gusto: gustoLogo,
  Paylocity: paylocityLogo,
  ADP: adpLogo,
  Zenefits: zenefitsLogo,
};

type PayrollProviderProps = {
  id: string;
  name: string;
  logo: string;
  to?: string;
};

const BASE_OPTION_CLASSES =
  'border rounded mb-2 text-center py-4 m-2 shadow-sm hover:bg-gray-50 cursor-pointer flex justify-center items-center min-h-[78px]';

const PayrollProvider: FunctionComponent<PayrollProviderProps> = ({
  id,
  name,
  logo,
  to,
}) => {
  let finchURL = config.FINCH_CONNECT_URL;
  if (import.meta.env.PROD) {
    finchURL = `${finchURL}&payroll_provider=${id}`;
  }
  if (to) {
    return (
      <Link className={clsx(BASE_OPTION_CLASSES, 'w-40')} to={to}>
        <img src={`${logo}`} alt={`Connect ${name} payroll`} />
      </Link>
    );
  } else {
    return (
      <a href={finchURL} className={clsx(BASE_OPTION_CLASSES, 'w-40')}>
        <img src={`${logo}`} alt={`Connect ${name} payroll using Finch`} />
      </a>
    );
  }
};

export const PayrollSetup: FunctionComponent = () => {
  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md p-8 py-12">
        <div>
          <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
            Connect payroll
          </h2>
          <p className="mt-2 text-center text-gray-600">
            We will use this read-only access to improve compliance monitoring
            and send timely alerts.
          </p>
          <div className="mt-10 flex">
            <div className="-m-2 flex flex-1 flex-wrap justify-center">
              <PayrollProvider
                id="gusto"
                name="Gusto"
                logo={PayrollProviderLogo.Gusto}
                to={`/platform/payroll/onboard?callback_url=${encodeURIComponent(
                  config.APP_BASE_URL + '/settings/integrations',
                )}`}
              />
              <PayrollProvider
                id="zenefits"
                name="Zenefits"
                logo={PayrollProviderLogo.Zenefits}
              />
              <PayrollProvider
                id="paylocity"
                name="Paylocity"
                logo={PayrollProviderLogo.Paylocity}
              />
              <PayrollProvider
                id="adp_workforce_now"
                name="ADP"
                logo={PayrollProviderLogo.ADP}
              />
              <a
                className={clsx(
                  BASE_OPTION_CLASSES,
                  'w-[21rem] p-4 font-semibold',
                )}
                href={config.FINCH_CONNECT_URL}
              >
                See more providers...
              </a>
            </div>
          </div>
          <p className="mt-6 text-center">
            <Link
              className="text-teal-700 hover:text-teal-800"
              to="/onboarding/rippling"
            >
              Instructions for Rippling »
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export const Onboarding: FunctionComponent = () => {
  return (
    <section className="max-w-6xl py-4">
      <Outlet />
    </section>
  );
};

export default Onboarding;
