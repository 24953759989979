import {
  CalendarIcon,
  EmailIcon,
  GovernmentIcon,
  IconProps,
  RadarIcon,
  RobotWithCheckIcon,
  RobotWithClockIcon,
  SettingsIcon,
} from '@mosey/components/Icons';
import { NotificationTopicEnum, paths } from '@mosey/api-types';
import { FunctionComponent } from 'react';

export const NOTIFICATION_TOPIC_CODE_TO_ICON: Record<
  NotificationTopicEnum,
  FunctionComponent<IconProps>
> = {
  [NotificationTopicEnum.personal_account_maintenance_and_updates]:
    SettingsIcon,
  [NotificationTopicEnum.first_time_confirmations]: SettingsIcon,
  [NotificationTopicEnum.new_assignments_to_user]: SettingsIcon,
  [NotificationTopicEnum.company_account_maintenance_and_updates]: SettingsIcon,
  [NotificationTopicEnum.automation_action_required]: RobotWithClockIcon,
  [NotificationTopicEnum.automation_status_updates]: RobotWithCheckIcon,
  [NotificationTopicEnum.weekly_summary]: CalendarIcon,
  [NotificationTopicEnum.new_mail]: EmailIcon,
  [NotificationTopicEnum.new_email]: GovernmentIcon,
  [NotificationTopicEnum.new_and_upcoming_updates]: RadarIcon,
};

export type NotificationTopic =
  paths['/api/notifications/topics']['get']['responses']['200']['content']['application/json'];
