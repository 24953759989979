import { Pill } from '@mosey/components/badges/Pill';
import { PlatformTaskManagedProvider } from '../../../types';

interface ManagedByTagProps {
  managedProvider: PlatformTaskManagedProvider | null;
}

export const ManagedByTag = ({ managedProvider }: ManagedByTagProps) => {
  if (managedProvider) {
    return (
      <Pill variant="automation">✨ Managed by ${managedProvider.name}</Pill>
    );
  }

  return null;
};
