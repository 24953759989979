import {
  FieldDependency,
  FormSpec,
  FormSpecField,
  FormSpecSection,
  FieldRule,
} from '../../types';
import { RendererConfig } from './Renderer';
import { ComponentMapping, PatternMapping, ValidationMapping } from './mapping';
import FormSection from './FormSection';

export const translateFormFieldProps = (
  field: FormSpecField,
  arrayNamePrefix = '',
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateObject: { [key: string]: any } = {};
  if (field.validate) {
    field.validate.forEach((v: string) => {
      validateObject[v] = ValidationMapping[v];
    });
  }
  let patternObject: { value: RegExp; message: string } | undefined;
  if (field.pattern) {
    patternObject = PatternMapping[field.pattern];
  }
  const fieldDependencies: FieldDependency[] = [];
  if (field.field_dependencies) {
    field.field_dependencies.forEach((dependency) => {
      if (dependency.target_is_in_array_field) {
        fieldDependencies.push({
          targetField: `${arrayNamePrefix}.${dependency.target_field}`,
          targetIsInArrayField: dependency.target_is_in_array_field,
          visibilityFunction: dependency.visibility_function,
        });
      } else {
        fieldDependencies.push({
          targetField: dependency.target_field,
          targetIsInArrayField: dependency.target_is_in_array_field,
          visibilityFunction: dependency.visibility_function,
        });
      }
    });
  }
  const fieldRules: FieldRule[] = [];
  if (field.field_rules) {
    field.field_rules.forEach((rule) => {
      if (rule.target_is_in_array_field) {
        fieldRules.push({
          targetField: `${arrayNamePrefix}.${rule.target_field}`,
          targetIsInArrayField: rule.target_is_in_array_field,
          condition: rule.condition,
        });
      } else {
        fieldRules.push({
          targetField: rule.target_field,
          targetIsInArrayField: rule.target_is_in_array_field,
          condition: rule.condition,
        });
      }
    });
  }

  const name =
    arrayNamePrefix !== '' ? `${arrayNamePrefix}.${field.name}` : field.name;
  return {
    name,
    label: field.label,
    description: field.description,
    reactFormConfig: {
      required: field.required,
      validate: validateObject,
      pattern: patternObject,
      valueAsDate: field.value_as && field.value_as === 'date',
      valueAsNumber: field.value_as && field.value_as === 'number',
    },
    fieldName: field.name,
    fieldDependencies,
    fieldRules,
    ...field.component.params,
  };
};

export const formSpecFieldToConfig = (field: FormSpecField): RendererConfig => {
  const props = translateFormFieldProps(field);
  return {
    component: ComponentMapping[field.component.type],
    props,
    children: field.children,
  };
};

export const formSpecArrayFieldToFormFieldProps = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contents: any[],
  name: string,
  index: number,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return contents.map((field: any) => {
    const prefix = `${name}.${index}`;
    const props = translateFormFieldProps(field, prefix);
    return {
      component: ComponentMapping[field.component.type],
      props,
      children: field.children,
    };
  });
};

export const formSpecToRendererConfig = (obj: FormSpec): RendererConfig[] => {
  const sections: RendererConfig[] = obj.sections.map(
    (section: FormSpecSection) => {
      const children = section.form_fields.map(formSpecFieldToConfig);
      return {
        component: FormSection,
        props: {
          label: section.label,
          description: section.description,
        },
        children,
      };
    },
  );
  return sections;
};
