import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ArrowRightIcon, ErrorIcon } from '@mosey/components/Icons';
import { useScrollIntoView } from '@mosey/components/hooks';
import { Requirement, RequirementStatus } from '../../types';
import { fetchApi } from '../../utils/fetchApi';
import { Button } from '@mosey/components/buttons/Button';
import { RequirementContentWrapper } from '../LocationDetailSetup_NEW';

enum DeterminationAnswerEnum {
  Yes = 'yes',
  No = 'no',
  Skip = 'skip',
}

type DeterminationQuestionOptionsForm = {
  determinationAnswer: DeterminationAnswerEnum;
};

type DeterminationQuestionProps = {
  requirementData: Requirement;
  attributeId: string;
  regionCode: string;
  onNotApplicable: (event?: MouseEvent) => void;
  onSubmitSuccess: () => void;
  onSkip: () => void;
};

export const DeterminationDatumQuestion: FC<DeterminationQuestionProps> = ({
  requirementData,
  attributeId,
  regionCode,
  onNotApplicable,
  onSubmitSuccess,
  onSkip,
}) => {
  const [scrollIntoViewRef] = useScrollIntoView();
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);
  const isAnyButtonLoading = isNextLoading || isSkipLoading;

  const formMethods = useForm<DeterminationQuestionOptionsForm>();
  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    formState: { errors },
  } = formMethods;

  const handleSkip = () => {
    setIsSkipLoading(true);
    onSkip();
    setIsSkipLoading(false);
  };

  const onSubmit = async (data: DeterminationQuestionOptionsForm) => {
    clearErrors();
    setIsNextLoading(true);

    if (data.determinationAnswer === DeterminationAnswerEnum.Yes) {
      const datumResponse = await fetchApi({
        url: '/api/datums',
        method: 'POST',
        body: [
          { attribute_id: attributeId, value: true, region_code: regionCode },
        ],
      });

      if (datumResponse.error) {
        const err = {
          type: 'manual',
          message: `Something went wrong, we're looking into it.`,
        };
        setError('determinationAnswer', err);
        setIsNextLoading(false);
        return;
      }

      const reqPatchResponse = await fetchApi({
        url: `/api/requirements/${requirementData.id}`,
        method: 'PUT',
        body: { status: RequirementStatus.Done },
      });

      if (reqPatchResponse.error) {
        const err = {
          type: 'manual',
          message: `Something went wrong, we're looking into it.`,
        };
        setError('determinationAnswer', err);
        setIsNextLoading(false);
        return;
      }

      const generateReqsResponse = await fetchApi({
        url: `/api/legal_entity/locations/${regionCode}/requirements`,
        method: 'POST',
      });

      if (generateReqsResponse.error) {
        const err = {
          type: 'manual',
          message: `Something went wrong, we're looking into it.`,
        };
        setError('determinationAnswer', err);
        setIsNextLoading(false);
        return;
      }
      onSubmitSuccess();
    } else {
      onNotApplicable();
    }
    setIsNextLoading(false);
  };

  return (
    <RequirementContentWrapper
      title={requirementData.title}
      description={requirementData.description}
      resources={requirementData.resources}
      ref={scrollIntoViewRef}
    >
      <div className="bg-sage-100 p-9">
        <h3 className="mb-6 text-lg font-bold">
          Have you or will you meet this threshold?
        </h3>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="divide-y-2 divide-sage-100 bg-white">
              <label
                className="block cursor-pointer text-lg font-bold"
                htmlFor="yes"
              >
                <div className="flex items-center gap-x-4 px-6 py-3">
                  <input
                    type="radio"
                    value="yes"
                    id="yes"
                    disabled={isAnyButtonLoading}
                    {...register('determinationAnswer')}
                  />
                  Yes
                </div>
              </label>
              <label
                className="block cursor-pointer text-lg font-bold"
                htmlFor="no"
              >
                <div className="flex items-center gap-x-4 px-6 py-3">
                  <input
                    type="radio"
                    value="no"
                    id="no"
                    disabled={isAnyButtonLoading}
                    {...register('determinationAnswer')}
                  />
                  No
                </div>
              </label>
            </div>

            <div className="mt-6 flex gap-x-6">
              <Button
                type="button"
                isFullWidth
                disabled={isAnyButtonLoading}
                isLoading={isSkipLoading}
                onClick={handleSkip}
                variant="secondary"
                size="xlarge"
              >
                Skip for now
              </Button>
              <Button
                type="submit"
                disabled={isAnyButtonLoading}
                isLoading={isNextLoading}
                isFullWidth
                rightIcon={<ArrowRightIcon className="w-4" />}
                size="xlarge"
              >
                Next
              </Button>
            </div>
          </form>
        </FormProvider>
        {errors.determinationAnswer && (
          <div className="mt-2 flex items-center text-xs text-red-600">
            <span>
              <ErrorIcon className="mr-1 size-4" />
            </span>
            <div>
              <p>{`${
                errors.determinationAnswer.message || 'Something went wrong.'
              }`}</p>
            </div>
          </div>
        )}
      </div>
    </RequirementContentWrapper>
  );
};
