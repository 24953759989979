import { FunctionComponent, useState, useCallback } from 'react';
import { CheckIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';

import { fetchApi } from '../utils/fetchApi';
import { withRedirect } from '../utils/routing';
import { useUser } from '../hooks/useUser';

export const OnboardingVerifyEmail: FunctionComponent = () => {
  const [hasResent, setHasResent] = useState<boolean>(false);

  const resendEmail = useCallback(() => {
    fetchApi({ url: '/api/verify/resend', method: 'POST' }).then(() => {
      setHasResent(true);
    });
  }, []);

  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="flex h-64 flex-col items-center justify-center space-y-4">
        <div className="size-12 rounded-full bg-green-600 p-1 leading-6 text-white">
          <CheckIcon />
        </div>
        <div>
          <h3 className="text-3xl font-bold text-green-600">
            Welcome to Mosey!
          </h3>
        </div>
        <p className="ml-1 max-w-sm text-center text-lg text-gray-500">
          Please check your email for a link to verify your account and get
          started.
        </p>
        <Button
          type="button"
          onClick={resendEmail}
          size="medium"
          className="mt-6"
          disabled={hasResent}
        >
          {hasResent ? 'Email sent' : 'Resend email'}
        </Button>
      </div>
    </div>
  );
};

export const ConditionalOnboardingVerifyEmail = withRedirect(
  OnboardingVerifyEmail,
  () => !useUser().is_active,
  '/home',
);
