import { cloneElement, FunctionComponent, ReactElement } from 'react';
import { clsx } from 'clsx';

type TooltipProps = {
  name: string;
  children: ReactElement;
  label?: React.ReactNode | null;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  name,
  label,
  children,
}: TooltipProps) => {
  const mergedPropsChildren = cloneElement(children, {
    'aria-describedby': name,
  });

  return (
    <div className="group relative">
      {mergedPropsChildren}
      {label && (
        <div
          role="tooltip"
          className={clsx(
            'whitespace-no-wrap absolute left-0 top-0 z-50 -mt-8 mb-6 hidden w-48 flex-col items-center rounded bg-black p-2 text-xs font-normal leading-none text-white shadow-lg group-hover:flex',
          )}
          id={name}
        >
          {label}
        </div>
      )}
    </div>
  );
};
