import { Button } from '@mosey/components/buttons/Button';
import { APP_BASE_URL, FINCH_CONNECT_URL } from '../../settings/config';
import { Link } from 'react-router-dom';
import { components } from '@mosey/api-types';

interface ReactivateConnectionButtonProps {
  connection: components['schemas']['Connection'];
}

export const ReactivateConnectionButton = ({
  connection,
}: ReactivateConnectionButtonProps) => {
  switch (connection.integration.slug) {
    case 'gusto':
      return (
        <Button
          as={Link}
          size="small"
          variant="primary"
          to={`/platform/payroll/onboard?callback_url=${encodeURIComponent(
            APP_BASE_URL + '/settings/integrations',
          )}`}
        >
          Reactivate
        </Button>
      );
    case 'finch':
    default:
      return (
        <Button
          as="a"
          size="small"
          variant="primary"
          href={`${FINCH_CONNECT_URL}&payroll_provider=${connection.provider}`}
        >
          Reactivate
        </Button>
      );
  }
};
