import { FC } from 'react';
import { AutomationView } from '../../views/AutomationView';
import { LocalPayrollSetup } from '../../views/LocalPayrollSetup';
import { NextStepDataProps } from '../../views/LocationDetailSetup_NEW';
import { NoticeEnrollmentViewNew } from '../../views/Notices_NEW';
import { Requirement, AutomationTypeEnum } from '../../types';

type FormAutomationProps = {
  formType: AutomationTypeEnum | null;
  locationId: string;
  requirement: Requirement;
  nextStepData: NextStepDataProps | null;
  onNext: () => void;
  onEnterLater: () => void;
  onSubmitSuccess: () => void;
};

export const FormAutomation: FC<FormAutomationProps> = ({
  formType,
  locationId,
  requirement,
  nextStepData,
  onNext,
  onEnterLater,
  onSubmitSuccess,
}) => {
  switch (formType) {
    case AutomationTypeEnum.DynamicForm:
      return (
        <AutomationView
          requirement={requirement}
          onAutomationComplete={onSubmitSuccess}
          onEnterLater={onEnterLater}
        />
      );
    case AutomationTypeEnum.NoticeBoard:
      return (
        <NoticeEnrollmentViewNew
          nextStepData={nextStepData}
          onNext={onNext}
          locationId={locationId}
          requirementId={requirement.id!}
        />
      );
    case AutomationTypeEnum.LocalPayrollSetup:
      return (
        <LocalPayrollSetup
          onSubmitSuccess={onSubmitSuccess}
          onEnterLater={onEnterLater}
          locationId={locationId}
          requirement={requirement}
        />
      );

    default:
      return <></>;
  }
};
