import { clsx } from 'clsx';
import { ExclamationIcon } from '@heroicons/react/outline';

interface FieldErrorProps {
  id: string;
  error?: boolean;
  children: React.ReactNode;
}

export const FieldError = ({
  id,
  error = false,
  children,
}: FieldErrorProps) => {
  return (
    <p
      id={id}
      className={clsx('flex items-center gap-x-2 text-rose-800', {
        block: error,
        hidden: !error,
      })}
    >
      <ExclamationIcon className="size-5" />
      {children}
    </p>
  );
};
