import { NextButton } from '../common/NextButton';
import { useTasksOverviewData } from '../utils/hooks';
import { AssessmentInterstitial } from '../resolver/AssessmentInterstitial';
import { SetupInterstitial } from '../resolver/SetupInterstitial';

interface TasksOverviewInterstitialProps {
  locationId: string;
}

export const TasksOverviewInterstitial = ({
  locationId,
}: TasksOverviewInterstitialProps) => {
  const { summary } = useTasksOverviewData();
  const { is_assessment: isAssessment } = summary[locationId.toUpperCase()];

  return (
    <div className="mx-8 mb-8 overflow-hidden rounded-lg border border-teal-350">
      {isAssessment ? (
        <AssessmentInterstitial
          actions={
            <NextButton
              to={`/locations/usa/${locationId}/resolver/assessment`}
            />
          }
        />
      ) : (
        <SetupInterstitial
          actions={<NextButton to={`/locations/usa/${locationId}/setup`} />}
        />
      )}
    </div>
  );
};
