import { FunctionComponent, useState } from 'react';
import { clsx } from 'clsx';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router';
import { Button } from '@mosey/components/buttons/Button';
import {
  Section,
  SectionHeading,
  Confirmation,
  BatchApiStatusHandler,
} from '../components';
import { Requirement } from '../types';
import { cleanIntakeDates } from '../utils/intake';
import { getRegionNameByCode } from '../utils/format';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus, IApiData } from '../utils/types';
import { Renderer, formSpecToRendererConfig } from '../components/forms';
import DefaultLocalPayrollSetupSpec from '../components/forms/specs/default_local_payroll_setup.json';
import { useBatchApi } from '../hooks';
import { FormError } from '@mosey/components/forms/FormError';

type LocalPayrollSetupProps = {
  locationId: string;
  requirement: Requirement;
  onSubmitSuccess?: () => void;
  onEnterLater?: () => void;
  isFullWidth?: boolean;
};

export const LocalPayrollSetup: FunctionComponent<LocalPayrollSetupProps> = ({
  requirement,
  locationId,
  onSubmitSuccess,
  onEnterLater,
  isFullWidth,
}) => {
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false);

  const loadedSpec = DefaultLocalPayrollSetupSpec;
  const formMethods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = (data: unknown) => {
    setHasSubmitError(false);

    const payload = cleanIntakeDates(data);

    // Workflow instance automation
    fetchApi({
      url: `/api/form_captures/local_payroll_${requirement.id}`,
      method: 'POST',
      body: {
        data: payload,
      },
    }).then(({ status }: IApiData) => {
      if (status !== ApiStatus.Success) {
        setHasSubmitError(true);
      } else {
        // Create and start workflow for requirements
        fetchApi({
          url: `/api/workflows`,
          method: 'POST',
          body: {
            data_ids: [requirement.data_id],
            region_code: locationId,
          },
        }).then(({ status: status2 }: IApiData) => {
          if (status2 !== ApiStatus.Success) {
            setHasSubmitError(true);
          } else if (onSubmitSuccess) {
            onSubmitSuccess();
          } else {
            setSubmitSuccessful(true);
          }
        });
      }
    });
  };
  return (
    <Section className="flex flex-col items-center">
      {isSubmitSuccessful ? (
        <Confirmation
          heading="Success"
          description="Your request is on its way"
        />
      ) : (
        <>
          <SectionHeading
            className={clsx('pb-4', {
              'w-full': isFullWidth,
              'w-96': !isFullWidth,
            })}
            text={
              requirement.title ||
              getRegionNameByCode(locationId) + ' Local Employer Setup'
            }
          />
          <FormProvider {...formMethods}>
            <form
              className={clsx('my-8 ', {
                'w-full': isFullWidth,
                'w-96': !isFullWidth,
              })}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Renderer
                config={formSpecToRendererConfig(loadedSpec)}
                errors={errors}
              />
              <p className="mt-4 flex flex-col">
                {onEnterLater && (
                  <Button
                    type="button"
                    variant="grayTertiary"
                    size="large"
                    isFullWidth
                    onClick={onEnterLater}
                  >
                    Enter later
                  </Button>
                )}

                <Button type="submit" size="large">
                  Submit
                </Button>
                {hasSubmitError && <FormError />}
              </p>
            </form>
          </FormProvider>
        </>
      )}
    </Section>
  );
};

export const LocalPayrollSetupView: FunctionComponent = () => {
  const { locationId, requirementId } = useParams<Record<string, string>>();
  if (!locationId || !requirementId) {
    throw Error('Location or requirement missing');
  }

  const batchResponse = useBatchApi([
    {
      url: `/api/requirements/${requirementId}`,
      method: 'POST',
    },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([reqResponse]: any[]): LocalPayrollSetupProps => {
    return {
      requirement: reqResponse,
      locationId,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={LocalPayrollSetup}
      componentProps={componentPropsFn}
    />
  );
};

export default LocalPayrollSetupView;
