import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
  useFetcher,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { ActionBanner } from '@mosey/components/layout/ActionBanner';
import { useScrollToElement } from '@mosey/components/hooks/useScrollToElement';
import { api } from '../../../utils/fetchApi';
import { PolicyDetailSection } from '../components/PolicyDetailSection';
import { PolicyOverviewCard } from '../components/PolicyOverviewCard';
import { useEffect } from 'react';
import { HandbookHeader } from '../components/HandbookHeader';
import { HandbookDataLoader } from '../types';
import { findNextPolicy } from '../utils';
import { PolicyCategoryDetailSection } from '../components/PolicyCategoryDetailSection';
import { usePolicySection } from '../hooks/usePolicySection';

export const PolicySectionDetailView = () => {
  const { state: navigationState } = useNavigation();
  const { sectionId } = useParams();
  const { hash, pathname } = useLocation();
  const { published } = useRouteLoaderData('handbook') as HandbookDataLoader;
  const { submit, state } = useFetcher();
  const navigate = useNavigate();
  const { currentSection, nextSection, actionableItems, hasActionableItems } =
    usePolicySection(sectionId!);

  const { section } = currentSection;
  const showContinueBanner = !hasActionableItems && !published;

  const [scrollContainerRef, retriggerScroll] =
    useScrollToElement<HTMLDivElement>({
      elementId: hash.slice(1),
      // leave room for sticky banner if no actionable policies
      bufferSpacing: showContinueBanner ? 120 : 40,
    });

  useEffect(() => {
    retriggerScroll();
  }, [sectionId]);

  const onAdopted = (adoptedSlug: string) => {
    const nextPolicy = findNextPolicy(adoptedSlug, actionableItems);
    navigate(
      `/handbook/section/${sectionId}/policies${nextPolicy ? `#${nextPolicy.slug}` : ''}`,
      {
        replace: true,
      },
    );
  };

  return (
    <>
      <HandbookHeader
        action={
          hasActionableItems && (
            <Button
              disabled={state !== 'idle'}
              isLoading={state !== 'idle'}
              onClick={() =>
                submit(
                  {
                    intent: 'adopt-all-policies',
                    nextPage: nextSection
                      ? `/handbook/section/${nextSection.section.id}`
                      : `/handbook`,
                  },
                  {
                    method: 'POST',
                    action: pathname,
                  },
                )
              }
            >
              Adopt all & continue
            </Button>
          )
        }
      />
      <div className="relative flex max-h-[calc(100vh-152px)] min-h-[calc(100vh-152px)]">
        <div className="min-w-[425px] overflow-y-auto py-8 pl-8 pr-2">
          <h2 className="text-xl font-semibold">{section?.title} Policies</h2>
          {section?.description && (
            <p className="mt-2">{section.description}</p>
          )}
          <ul className="mt-4 max-w-[425px] border-t border-gray-200">
            {currentSection.items.map((item) => (
              <PolicyOverviewCard key={item.slug} item={item} variant="nav" />
            ))}
          </ul>
        </div>
        <div
          className="relative flex-1 overflow-auto py-6 pl-6 pr-8"
          ref={scrollContainerRef}
        >
          {showContinueBanner && (
            <div className="sticky top-0 mb-10">
              <ActionBanner
                to={
                  nextSection
                    ? `/handbook/section/${nextSection.section.id}`
                    : `/handbook`
                }
                isLoading={navigationState !== 'idle'}
              >
                You&apos;re done with {section.title} Policies
              </ActionBanner>
            </div>
          )}
          {currentSection.items.map((item) =>
            item.type === 'category' ? (
              <PolicyCategoryDetailSection
                key={item.slug}
                item={item}
                onAdopted={() => onAdopted(item.slug)}
              />
            ) : (
              <PolicyDetailSection
                key={item.slug}
                item={item}
                onAdopted={() => onAdopted(item.slug)}
              />
            ),
          )}
        </div>
      </div>
    </>
  );
};

PolicySectionDetailView.loader = async ({ params }: LoaderFunctionArgs) => {
  return api({
    url: `/api/handbook/policy_section/${params.sectionId}/attribute_values`,
    method: 'GET',
  });
};

PolicySectionDetailView.action = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const formData = await request.formData();
  const intent = formData.get('intent') as string;

  if (intent === 'adopt-all-policies') {
    await api({
      url: `/api/handbook/policy_section/${params.sectionId}/adopt`,
      method: 'POST',
    });
    return redirect(formData.get('nextPage') as string);
  } else if (intent === 'adopt-policy') {
    await api({
      url: `/api/handbook/policies/${formData.get('policy_id')}/adopt`,
      method: 'POST',
    });
    return {
      success: true,
    };
  } else if (intent === 'adopt-policy-category') {
    await api({
      url: `/api/handbook/policies/adopt`,
      method: 'POST',
      body: {
        policies: (formData.get('policy_ids') as string).split(','),
      },
    });
    return {
      success: true,
    };
  }
};
