import { FunctionComponent, ReactNode } from 'react';

type FormSectionProps = {
  children: ReactNode;
  label?: string;
  description?: string;
};

export const FormSection: FunctionComponent<FormSectionProps> = ({
  children,
  label,
  description,
}) => {
  return (
    <div className="mb-8">
      {label && (
        <label className="mb-2 block text-xl font-semibold">{label}</label>
      )}
      {description && (
        <p className="mb-4 text-sm text-gray-600">{description}</p>
      )}
      {children}
    </div>
  );
};

export default FormSection;
