import { FunctionComponent } from 'react';
import { RegisterOptions, FieldError as FieldErrorT } from 'react-hook-form';
import { DynamicSelectOptionsMapping } from './mapping';
import { SelectField } from './SelectField';

type FieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  className?: string;
  description?: string;
  descriptionLink?: { url: string; text: string };
  reactFormConfig?: RegisterOptions;
  optionsType: string;
  optionsParams: { [key: string]: number };
};

export const DynamicSelectField: FunctionComponent<FieldProps> = ({
  name,
  label,
  error,
  className,
  description,
  descriptionLink,
  reactFormConfig,
  optionsType,
  optionsParams,
}) => {
  const optionsFunction = DynamicSelectOptionsMapping[optionsType];
  const options = optionsFunction(optionsParams);

  return (
    <SelectField
      name={name}
      label={label}
      error={error}
      className={className}
      description={description}
      descriptionLink={descriptionLink}
      reactFormConfig={reactFormConfig}
      options={options}
    />
  );
};
