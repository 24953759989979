import {
  LibraryIcon,
  OfficeBuildingIcon,
  SparklesIcon,
} from '@heroicons/react/outline';
import { LightningBoltIcon, StopwatchIcon } from '../Icons';
import { TaskCardReason } from './types';

type ReasonIconProps = {
  reason: TaskCardReason;
};

const BASE_STYLES = 'size-4 min-w-4 text-zinc-600';

export const ReasonIcon = ({ reason }: ReasonIconProps) => {
  switch (reason) {
    case 'setup':
      return <LightningBoltIcon className={BASE_STYLES} />;
    case 'new-requirement':
      return <LibraryIcon className={BASE_STYLES} />;
    case 'due':
      return <StopwatchIcon className={BASE_STYLES} />;
    case 'account':
      return <OfficeBuildingIcon className={BASE_STYLES} />;
    case 'suggestion':
    default:
      return <SparklesIcon className={BASE_STYLES} />;
  }
};
