import { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { clsx } from 'clsx';
import {
  BaseTextField,
  BaseTextFieldProps,
} from '@mosey/components/forms/BaseFormField';
import { FormField, FormFieldProps } from '@mosey/components/forms/FormField';

export type DateFieldProps = {
  inputClassName?: string;
  placeholder?: string;
  reactFormConfig?: RegisterOptions;
} & BaseTextFieldProps &
  FormFieldProps;

/**
 * Component to be used within a FormProvider to get the form context
 */
export const DateField: FunctionComponent<DateFieldProps> = ({
  name,
  label,
  size = 'medium',
  error,
  rightButton,
  className,
  inputClassName,
  placeholder,
  description,
  descriptionLink,
  reactFormConfig,
  ...props
}) => {
  const { register } = useFormContext();
  const combinedValidation = reactFormConfig?.required
    ? {
        ...reactFormConfig?.validate,
      }
    : reactFormConfig?.validate;
  return (
    <FormField
      name={name}
      className={className}
      error={error}
      label={label}
      description={description}
      descriptionLink={descriptionLink}
      rightButton={rightButton}
    >
      <BaseTextField
        type="date"
        size={size}
        error={error}
        className={clsx(inputClassName)}
        placeholder={placeholder}
        max={'9999-12-31'}
        id={name}
        {...props}
        {...register(name, {
          ...reactFormConfig,
          // use combined default and provided validations
          validate: combinedValidation,
          valueAsDate: false,
        } as RegisterOptions)}
      />
    </FormField>
  );
};
