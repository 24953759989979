import { ReactNode } from 'react';

type NoticeRowProps = {
  children: ReactNode;
};

export const NoticeRow = ({ children }: NoticeRowProps) => {
  return (
    <div className="flex items-center justify-end gap-x-2 px-4 text-sm">
      {children}
    </div>
  );
};
