import { FunctionComponent, useEffect } from 'react';
import {
  RegisterOptions,
  useFormContext,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { clsx } from 'clsx';
import { FieldError } from '@mosey/components/forms/FieldError';

type FieldProps = {
  name: string;
  label: string;
  error?: FieldErrorT;
  className?: string;
  labelClassName?: string;
  optionClassName?: string;
  description?: string;
  options?: { name?: string; value: unknown }[];
  reactFormConfig?: RegisterOptions;
};

export const MultiCheckboxField: FunctionComponent<FieldProps> = ({
  name,
  label,
  error,
  className,
  labelClassName,
  optionClassName,
  description,
  options,
}) => {
  const { register, setFocus } = useFormContext();

  useEffect(() => {
    if (error && options) {
      // setFocus needs specific input to focus
      setFocus(`${name}.${options[0].value}`);
    }
  }, [error, options, name]);

  return (
    <div className="mb-8">
      <div>
        <label
          className={clsx(
            {
              'font-semibold block mb-2': !labelClassName,
            },
            labelClassName,
          )}
          htmlFor={name}
        >
          {label}
        </label>
        {description && (
          <p className="mb-4 text-sm text-gray-600">{description}</p>
        )}
        <div
          className={clsx(
            {
              'flex flex-wrap gap-y-3 items-center': !className,
            },
            className,
          )}
        >
          {options?.map((option) => (
            <label
              key={`opt-label-${option.name}`}
              htmlFor={`${name}.${option.value}`}
              className={clsx(
                {
                  'flex grow items-center mr-6 whitespace-nowrap':
                    !optionClassName,
                },
                optionClassName,
              )}
            >
              <input
                type="checkbox"
                className={clsx('mr-2 mt-1 w-4 rounded', {
                  'border-red-500': error,
                })}
                id={`${name}.${option.value}`}
                {...register(`${name}.${option.value}`)}
              />
              {option.name}
            </label>
          ))}
        </div>
      </div>
      <FieldError error={error} />
    </div>
  );
};
