import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { BackButton } from '../../../components/buttons/BackButton';
import { ReactNode } from 'react';
import { DescriptiveProgress } from '../../../components/progress/DescriptiveProgress';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader } from '../types';
import { Button } from '@mosey/components/buttons/Button';
import { usePolicyList } from '../hooks/usePolicyList';

type HandbookHeaderProps = {
  action?: ReactNode;
};

export const HandbookHeader = ({ action }: HandbookHeaderProps) => {
  const { published, draft } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;
  const { actionableItems, numItems } = usePolicyList();
  const numActionedPolicies = numItems - actionableItems.length;
  const progress = Math.round((numActionedPolicies * 100) / numItems);

  return (
    <PageHeader>
      <BackButton to="/handbook" />
      <div className="flex flex-1 items-center justify-end gap-6">
        {!published && <DescriptiveProgress progress={progress} />}
        {draft?.is_publishable && (
          <Button as={Link} to="/handbook/publish">
            Publish
          </Button>
        )}
        {action}
      </div>
    </PageHeader>
  );
};
