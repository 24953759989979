import { FC, ReactNode, useState } from 'react';
import { clsx } from 'clsx';
import {
  ArrowRightIcon,
  ErrorIcon,
  LightningBoltIcon,
  LoadingIcon,
  RecurIcon,
  RobotIcon,
  UnlockIcon,
  UploadIcon,
  IconProps,
} from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { useScrollIntoView } from '@mosey/components/hooks';
import { AssignSelect } from '../../components';
import { Requirement, RequirementPatchUpdate, User } from '../../types';
import { fetchApi } from '../../utils/fetchApi';
import { ApiStatus } from '../../utils/types';
import incompleteImage from '../../assets/incomplete.png';
import successImage from '../../assets/success.svg';
import automatedImage from '../../assets/automated.svg';
import answeringQuestionsImage from '../../assets/answering-questions.png';

type ConfirmationFeedItemProps = {
  feedItem: Feed;
  variant: ConfirmationTypeEnum;
};

export enum ConfirmationTypeEnum {
  Incomplete = 'incomplete',
  Completed = 'completed',
  Automated = 'automated',
  Skipped = 'skipped',
}

const confirmationImageMapping: Record<ConfirmationTypeEnum, string> = {
  [ConfirmationTypeEnum.Incomplete]: incompleteImage,
  [ConfirmationTypeEnum.Completed]: successImage,
  [ConfirmationTypeEnum.Automated]: automatedImage,
  [ConfirmationTypeEnum.Skipped]: answeringQuestionsImage,
};

const confirmationIconMap: Record<
  ConfirmationTypeEnum,
  React.FunctionComponent<IconProps>
> = {
  [ConfirmationTypeEnum.Incomplete]: ErrorIcon,
  [ConfirmationTypeEnum.Completed]: RecurIcon,
  [ConfirmationTypeEnum.Automated]: RobotIcon,
  [ConfirmationTypeEnum.Skipped]: ErrorIcon,
};

const ConfirmationIcon = (props: { type: ConfirmationTypeEnum }) => {
  const IconComponent = confirmationIconMap[props.type];
  return <IconComponent className="size-4 text-white" />;
};

const confirmationClasses = {
  incomplete: {
    bgIcon: 'bg-rose-800',
  },
  completed: {
    bgIcon: 'bg-lime-500',
  },
  automated: {
    bgIcon: 'bg-indigo-600',
  },
  skipped: {
    bgIcon: 'bg-indigo-600',
  },
};

const ConfirmationFeedItem: FC<ConfirmationFeedItemProps> = ({
  feedItem,
  variant,
}) => {
  return (
    <>
      <li key={feedItem.title}>
        <div className="relative pb-6">
          <div className="relative ml-px flex items-start space-x-3">
            <div>
              <div className="relative flex w-12 justify-center">
                <div
                  className={clsx(
                    'flex size-8 items-center justify-center rounded-full',
                    {
                      [confirmationClasses[variant].bgIcon]: !!variant,
                    },
                  )}
                >
                  <ConfirmationIcon type={variant} />
                </div>
              </div>
            </div>
            <div className="min-w-0 flex-1 py-1.5">
              <div className="text-sm font-bold uppercase text-gray-700">
                <span>{feedItem.title}</span>
                <span className="ml-2.5 rounded-xl bg-teal-700 px-3 py-0.5 text-xs font-bold text-white">
                  {feedItem.items.length}
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>

      {!!feedItem?.subtitle && (
        <li>
          <div className="relative overflow-hidden pb-4">
            <div className="relative flex items-center justify-center">
              <span
                className="absolute left-6 top-4 -mb-px mt-1 h-0.5 w-full bg-gray-200"
                aria-hidden="true"
              />
              <div>
                <div className="relative flex w-6 justify-center" />
              </div>
              <div className="z-10 min-w-0 py-1.5">
                <div className="flex items-center justify-center gap-x-2 rounded-full border-1 border-gray-200 bg-white p-2">
                  <span className="shrink-0">
                    <LightningBoltIcon className="size-4 text-gray-200" />
                  </span>
                  <span className="text-xs font-bold uppercase text-sage-600">
                    {feedItem.subtitle}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      )}

      {feedItem.items.map((item) => (
        <li key={item.title} className="max-w-2xl">
          <div className="relative pb-8">
            <span
              className="absolute left-7 top-6 mt-0.5 h-0.5 w-8 bg-gray-200"
              aria-hidden="true"
            />
            <div className="relative ml-px flex items-start space-x-3">
              <div>
                <div className="relative mt-0.5 flex w-12 justify-center py-5">
                  <div className="size-2.5 rounded-full border-1 border-gray-200 bg-white" />
                </div>
              </div>
              <div className="min-w-0 flex-1">
                <div className="flex items-center justify-between rounded-md border-1 border-gray-300 bg-white p-4">
                  <span className="text-sm font-bold text-zinc-800">
                    {item.title}
                  </span>
                  <span>
                    <UnlockIcon className="size-4 text-sage-700" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};

type FeedItem = {
  title: string;
};

export type Feed = {
  title: string;
  subtitle?: string;
  items: FeedItem[];
};

type ConfirmationProps = {
  users: User[];
  requirement: Requirement;
  title: string;
  description: string;
  handleImportSetup?: () => void;
  feed?: Feed[] | null;
  children?: ReactNode;
  variant?: ConfirmationTypeEnum;
  onNext: () => void;
};

export const Confirmation: FC<ConfirmationProps> = ({
  users,
  requirement,
  title,
  description,
  handleImportSetup,
  feed,
  variant = ConfirmationTypeEnum.Completed,
  onNext,
  children,
}) => {
  const [scrollIntoViewRef] = useScrollIntoView();
  const [hasAssignedUser, setHasAssignedUser] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const hasFutureItems = !!feed?.length;

  const handleAssignmentChange = async (userId: string | null) => {
    setError(null);
    setIsLoading(true);

    let body: RequirementPatchUpdate;

    if (userId) {
      body = {
        operation: 'assign',
        user_id: userId,
      };
    } else {
      body = {
        operation: 'unassign',
      };
    }

    const { status } = await fetchApi({
      url: `/api/requirements/${requirement.id}`,
      method: 'PATCH',
      body,
    });

    if (status === ApiStatus.Error || status === ApiStatus.ErrorUnauthorized) {
      setError({ message: 'Something went wrong, please try again.' });
    } else if (userId) {
      setHasAssignedUser(true);
    } else {
      setHasAssignedUser(false);
    }

    setIsLoading(false);
  };

  return (
    <div
      ref={scrollIntoViewRef}
      className="flex flex-col items-center justify-center pt-16"
    >
      <div className="p-1">
        <img src={confirmationImageMapping[variant]} alt="Success" />
      </div>
      <div className="mt-10 max-w-2xl">
        <div>
          <h3 className="text-center text-3xl font-black text-gray-700">
            {title}
          </h3>
        </div>
        <p className="mt-4 px-12 text-center text-lg text-zinc-800">
          {description}
        </p>

        {handleImportSetup && (
          <div className="mt-10 flex items-center gap-x-4 rounded-lg border-1 border-teal-500 bg-teal-400/60 px-6 py-4">
            <div className="flex size-14 shrink-0 items-center justify-center rounded-full bg-teal-500">
              <UploadIcon className="size-8 text-white" />
            </div>
            <div className="text-sm text-zinc-800">
              <h4 className="font-bold">Import Your Setup</h4>
              <p className="mt-1">
                Import the account numbers for the{' '}
                <span className="font-bold">{requirement.title}</span>{' '}
                requirement
              </p>
            </div>

            <Button
              onClick={handleImportSetup}
              variant="secondary"
              leftIcon={<UploadIcon className="size-4" />}
            >
              Import Setup
            </Button>
          </div>
        )}

        {variant !== ConfirmationTypeEnum.Skipped && hasFutureItems && (
          <div
            className={clsx(
              'flex items-center gap-x-4 rounded-lg bg-sage-200 px-6 py-4',
              {
                'mt-10': !handleImportSetup,
                'mt-4': !!handleImportSetup,
              },
            )}
          >
            <div className="flex size-14 shrink-0 items-center justify-center rounded-full bg-sage-600">
              <LightningBoltIcon className="size-8 text-white" />
            </div>
            <div className="text-sm text-zinc-800">
              <h4 className="font-bold">Your Activity Stream</h4>
              <p className="mt-1">
                Future requirements, tasks, and the tools you need to manage
                them, like assigning them to other members, will be shown in the
                activity stream below.
              </p>
            </div>
          </div>
        )}
      </div>

      <div
        className={clsx('relative mt-16 w-full', {
          'bg-sage-200': hasFutureItems,
        })}
      >
        {hasFutureItems ? (
          <>
            <span
              className="absolute left-6 ml-32 h-full w-0.5 bg-gray-200 2xl:ml-72"
              aria-hidden="true"
            />
            <div className="-mt-6 ml-32 2xl:ml-72">
              <ul className="-mt-6">
                <li>
                  <div className="relative pb-8">
                    <div className="relative ml-px flex items-start space-x-3">
                      <div className="relative">
                        <div className="flex size-12 items-center justify-center rounded-full bg-white ring-1 ring-slate-300">
                          <LightningBoltIcon className="size-6 text-slate-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {variant === ConfirmationTypeEnum.Incomplete && (
                  <li className="max-w-2xl">
                    <div className="relative z-20 mb-6 rounded-lg border-1 border-rose-200 bg-rose-100 p-6 text-sm">
                      <span className="mb-2 font-bold">Assign an Owner</span>
                      <p>This requirement will become one of their tasks.</p>
                      <div className="mt-3.5 flex flex-col rounded-lg">
                        <span className="mb-2 font-bold">Assign to</span>
                        {isLoading && (
                          <LoadingIcon className="mr-1 inline size-5 cursor-pointer text-gray-400" />
                        )}
                        <AssignSelect
                          className="w-full bg-white"
                          users={users}
                          assignedTo={requirement.assigned_user}
                          onChange={handleAssignmentChange}
                          alignRight
                        />
                        {!!error && (
                          <p className="mt-2 flex items-center text-xs text-red-600">
                            <span>
                              <ErrorIcon className="size-4" />
                            </span>
                            <span className="ml-1">{error.message}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </li>
                )}

                {!!feed?.length &&
                  feed.map((feedItem) => (
                    <ConfirmationFeedItem
                      key={feedItem.title}
                      feedItem={feedItem}
                      variant={variant}
                    />
                  ))}
              </ul>
            </div>
          </>
        ) : (
          <></>
        )}
        {children}
        <div className="flex w-full justify-end pb-9 pr-9">
          <Button
            size="xlarge"
            className="mt-4"
            onClick={() => {
              if (
                variant === ConfirmationTypeEnum.Incomplete &&
                !hasAssignedUser
              ) {
                setError({ message: 'Please assign a user to the task.' });
                return;
              }

              onNext();
            }}
            rightIcon={<ArrowRightIcon className="w-4" />}
          >
            Next step
          </Button>
        </div>
      </div>
    </div>
  );
};
