import {
  ChangeEvent,
  MouseEvent,
  useState,
  useEffect,
  FunctionComponent,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';
import { API_BASE_URL } from '../settings/config';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus } from '../utils/types';

export const MailUploadView: FunctionComponent = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const params = new URLSearchParams(useLocation().search);
  const sessionId = params.get('session_id');
  const { token } = useParams<Record<string, string>>();

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchApi({
      url: `/api/guest/validate`,
      method: 'POST',
      body: { token, session_id: sessionId },
    }).then(({ status }) => {
      if (status === ApiStatus.Error) {
        setIsTokenValid(false);
      } else {
        setIsTokenValid(true);
      }
    });
  }, [token]);

  if (!token) {
    return <p>This token is invalid. Please contact support@mosey.com</p>;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      setFile(event.target.files[0]);
    } else {
      setFile(null);
    }
  };
  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setError('');
    if (file === null) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('token', token);
    formData.append('session_id', sessionId || '');
    const request: RequestInit = {
      method: 'POST',
      body: formData,
      mode: 'cors',
    };

    const url = `${API_BASE_URL}/api/guest/document`;

    const resp = await fetch(url, request);

    if (resp.ok) {
      setSuccess(true);
    } else {
      setError((await resp.json())['detail']);
    }
  };

  return (
    <div className="flex flex-col">
      <div>
        <div className="overflow-hidden pb-24 pt-8 lg:py-24">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8">
            <div>
              <div className="flex flex-col items-center">
                <div className="mb-14 flex items-center justify-center rounded-full border-transparent bg-rose-700 text-white">
                  <div className="flex h-10 w-16 items-center justify-center rounded-l-full text-xs font-bold text-white">
                    New!
                  </div>
                  <div className="flex h-10 items-center justify-center space-x-2 rounded-r-full bg-teal-600 px-5">
                    <span>💌</span>
                    <div className="mr-2">Letter and Notices Review</div>
                  </div>
                </div>
                <div className="mx-auto text-center sm:max-w-2xl">
                  <h1 className="text-5xl font-extrabold tracking-tight sm:text-6xl">
                    <span className="block">
                      Have questions about a letter or notice?
                    </span>
                  </h1>
                  <p className="mt-6 text-xl font-semibold text-gray-600">
                    Mosey will review a letter or notice from your state agency
                    and help you resolve it.
                  </p>
                  <p className="mt-6 text-xl font-semibold text-gray-600">
                    {success ? 'We' : 'Once you submit, we'} will get back to
                    you within one business day.
                  </p>
                </div>
                {isTokenValid &&
                  (success ? (
                    <div className="mt-4 w-full rounded border border-green-200 bg-green-100 p-8 text-center text-green-700">
                      Your document has been submitted
                    </div>
                  ) : (
                    <form
                      className="flex flex-col items-center space-y-2"
                      action="#"
                      method="POST"
                    >
                      {error && (
                        <div className="my-4 w-full rounded border border-red-200 bg-red-100 px-4 py-2 text-red-700">
                          {error}
                        </div>
                      )}
                      <input
                        type="file"
                        onChange={handleChange}
                        className="mt-6 inline-flex w-full text-xl font-semibold text-gray-600 file:mr-8 file:rounded file:border-1 file:border-sage-600 file:bg-white file:px-4 file:py-2 file:text-base file:font-bold file:text-gray-500"
                      />
                      <Button
                        type="submit"
                        size="large"
                        onClick={handleSubmit}
                        isDisabled={file === null}
                        isFullWidth
                      >
                        Submit
                      </Button>
                    </form>
                  ))}
                <p className="mt-6 text-base font-semibold text-gray-600">
                  Please reach out to support@mosey.com if you have any
                  questions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
