import { LinkProps } from 'react-router-dom';
import { NextButton } from './tasks-framework/common/NextButton';
import { AssessmentInterstitial } from './tasks-framework/resolver/AssessmentInterstitial';

interface LocationDetailViewAssessmentInterstitialProps {
  resolverUrl: LinkProps['to'];
}

export const LocationDetailViewAssessmentInterstitial = ({
  resolverUrl,
}: LocationDetailViewAssessmentInterstitialProps) => {
  return (
    <AssessmentInterstitial
      actions={
        <NextButton
          to={resolverUrl}
          state={{ resolverFinishUrl: location.pathname }}
        />
      }
    />
  );
};
