import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useBatchApi } from '../hooks';
import { Button } from '@mosey/components/buttons/Button';
import {
  BatchApiStatusHandler,
  Renderer,
  Section,
  SectionHeading,
  formSpecToRendererConfig,
} from '../components';
import { FormSpec } from '../types';
import { cleanIntakeDates } from '../utils/intake';
import { fetchApi } from '../utils/fetchApi';
import { IApiData } from '../utils/types';
import { useMatch, useParams, useSearchParams } from 'react-router-dom';
import { transformServerFieldErrors } from '../utils/form';

type FormPreviewProps = {
  formSpecs?: FormSpec[];
  formIndex: number;
  onFormComplete: () => void;
};

const FormPreview: FunctionComponent<FormPreviewProps> = ({
  formSpecs,
  formIndex,
  onFormComplete,
}) => {
  const isPendingChange = useMatch('/ddc/pending-changes/:formId');
  const disabledSubmit = !!isPendingChange;

  if (!formSpecs) {
    // TODO handle this case
    return <>No missing fields</>;
  }

  const formSpec = formSpecs[formIndex];
  const formIsEmpty = formSpec.sections[0].form_fields.length === 0;
  const isLastForm = formIndex === formSpecs.length - 1;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    reset(formSpecs[formIndex].default_values);
  }, [formIndex]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (formIsEmpty && !isLastForm) {
      onFormComplete();
    }
  }, [formIsEmpty, isLastForm]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formMethods = useForm({ defaultValues: formSpec.default_values });
  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = formMethods;
  console.log(errors);

  const onSubmit = (data: unknown) => {
    clearErrors();
    const cleanedData = cleanIntakeDates(data);
    const payload = { data: cleanedData };

    fetchApi({
      url: `/api/form_captures/save/${formSpec.id}`,
      method: 'POST',
      body: payload,
    }).then(({ error }: IApiData) => {
      if (error) {
        if (error.detail && Array.isArray(error.detail)) {
          const errorMap = transformServerFieldErrors(error.detail);
          Object.keys(errorMap).forEach((fieldKey) =>
            setError(fieldKey, errorMap[fieldKey], { shouldFocus: true }),
          );
        } else {
          alert('Error with form submission - see dev console');
          console.log(error);
        }
      } else {
        if (isLastForm) {
          alert('Form successfully submitted!');
        } else {
          onFormComplete();
        }
      }
    });
  };

  return (
    <Section className="flex flex-col items-center">
      <SectionHeading className="w-96 pb-4" text={formSpec.title} />
      <FormProvider {...formMethods}>
        <form className="my-8 w-96" onSubmit={handleSubmit(onSubmit)}>
          <Renderer
            config={formSpecToRendererConfig(formSpec)}
            errors={errors}
          />
          <Button
            type="submit"
            isFullWidth
            size="large"
            disabled={disabledSubmit}
          >
            {isLastForm ? 'Submit' : 'Continue'}
          </Button>
        </form>
      </FormProvider>
    </Section>
  );
};

export const FormPreviewView: FunctionComponent = () => {
  const [formIndex, setFormIndex] = useState<number>(0);
  const { formId } = useParams<Record<string, string>>();
  const [searchParams] = useSearchParams();
  const showAllFields = searchParams.get('show_all_fields') || false;
  const isPendingChange = useMatch('/ddc/pending-changes/:formId');

  const batchResponse = useBatchApi(
    isPendingChange
      ? [
          {
            url: `/api/pending_changes/${formId}/preview`,
            method: 'GET',
          },
        ]
      : [
          {
            url: `/api/form_captures/view/${formId}`,
            method: 'POST',
            body: {
              show_all_fields: showAllFields,
            },
          },
        ],
    [formIndex, formId, showAllFields],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentPropsFn = ([formSpecs]: any[]): any => {
    return {
      formSpecs: isPendingChange ? [formSpecs] : formSpecs,
      onFormComplete: () => setFormIndex(formIndex + 1),
      formIndex,
    };
  };

  return (
    <BatchApiStatusHandler
      batchResponse={batchResponse}
      component={FormPreview}
      componentProps={componentPropsFn}
    />
  );
};
