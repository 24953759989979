import { Fragment, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { Link, useSearchParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { ArrowRightIcon, CloseIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { IconButton } from '@mosey/components/buttons/IconButton';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { QuickFilters, Switch } from '../../components';
import { FilterPlatformTasksBy } from '../../utils/filter';
import { fetchApi } from '../../utils/fetchApi';
import { ApiStatus } from '../../utils/types';
import {
  LocationFilter,
  ManagedByTag,
  TaskDueDate,
  isOverDue,
  isUpcoming,
} from './common';
import {
  PlatformTaskStatus,
  PlatformTask,
  PlatformLegalEntityLocation,
} from '../../types';

interface PlatformTaskCardProps {
  task: PlatformTask;
  refreshView: () => void;
}

export const PlatformTaskCard = ({
  task: {
    managed_provider: managedProvider,
    id,
    due_date: dueDate,
    definition: { title, description },
  },
  refreshView,
}: PlatformTaskCardProps) => {
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [isDismissing, setIsDismissing] = useState(false);
  const [show, setShow] = useState(true);
  const padding = 'px-4 py-5 sm:px-6';

  const handleDismiss = async (dismissId: string) => {
    setMutationError(null);
    setIsDismissing(true);

    const response = await fetchApi({
      url: `/tasks/${dismissId}`,
      method: 'PATCH',
      body: {
        status: PlatformTaskStatus.Dismissed,
      },
      isPlatform: true,
    });

    if (response.status === ApiStatus.Error) {
      setMutationError('Error dismissing task. Please try again later.');
      setIsDismissing(false);
    } else if (response.status === ApiStatus.Success) {
      setShow(false);
    }
  };

  return (
    <Transition
      as={Fragment}
      show={show}
      leave="transition ease-in duration-200"
      leaveFrom="transform opacity-100 translate-x-0"
      leaveTo="transform opacity-0 translate-x-full"
      afterLeave={refreshView}
    >
      <li className="divide-y divide-gray-200 overflow-hidden border bg-white">
        <header className={padding}>
          <div className="flex items-center gap-x-1">
            <h2 className="leading-0 text-lg font-bold text-gray-900">
              {title}
            </h2>

            <div className="ml-auto flex items-center gap-x-1">
              <ManagedByTag managedProvider={managedProvider} />

              {!managedProvider && (
                <IconButton
                  className="-mr-2 !border-0"
                  isFullRounded
                  variant="secondary"
                  isLoading={isDismissing}
                  icon={<CloseIcon className="size-3" />}
                  onClick={() => handleDismiss(id)}
                />
              )}
            </div>
          </div>

          <BlockAlert
            show={!!mutationError}
            variant="error"
            message={mutationError}
          />
        </header>

        <div className={clsx(padding, 'space-y-4 text-sm')}>
          <TaskDueDate dueDate={dueDate} />

          <p className="line-clamp-3 text-gray-700">{description}</p>
        </div>

        <footer className={clsx(padding, 'flex items-center gap-x-2')}>
          <Button
            as={Link}
            to={`/compliance-tasks/detail/${id}`}
            rightIcon={<ArrowRightIcon className="w-4" />}
            isDisabled={isDismissing}
          >
            Go to task
          </Button>
        </footer>
      </li>
    </Transition>
  );
};

interface PlatformTasksProps {
  locations: PlatformLegalEntityLocation[];
  tasks: PlatformTask[];
  refreshView: () => void;
}

export const PlatformTasks = ({
  tasks,
  locations,
  refreshView,
}: PlatformTasksProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const includeManaged = searchParams.get('include_managed') === 'true';
  const [filter, setFilter] = useState<FilterPlatformTasksBy>(
    Object.values(FilterPlatformTasksBy).includes(
      searchParams.get('filter') as FilterPlatformTasksBy,
    )
      ? (searchParams.get('filter') as FilterPlatformTasksBy)
      : FilterPlatformTasksBy.All,
  );

  const handleFilterChange = (filter: FilterPlatformTasksBy) => {
    setFilter(filter);

    searchParams.set('filter', filter);
    setSearchParams(searchParams);
  };

  const taskByStatus = useMemo(() => {
    const result: { [key in FilterPlatformTasksBy]: PlatformTask[] } = {
      [FilterPlatformTasksBy.All]: [],
      [FilterPlatformTasksBy.TimeSensitive]: [],
      [FilterPlatformTasksBy.InProgress]: [],
      [FilterPlatformTasksBy.Archived]: [],
    };

    for (let i = 0; i < tasks.length; i += 1) {
      const task = tasks[i];

      if (
        task.status === PlatformTaskStatus.Todo ||
        task.status === PlatformTaskStatus.InProgress
      ) {
        result[FilterPlatformTasksBy.All].push(task);

        if (
          task.due_date &&
          (isOverDue(task.due_date) || isUpcoming(task.due_date))
        ) {
          result[FilterPlatformTasksBy.TimeSensitive].push(task);
        }
      }

      if (task.status === PlatformTaskStatus.InProgress) {
        result[FilterPlatformTasksBy.InProgress].push(task);
      }

      if (
        task.status === PlatformTaskStatus.Dismissed ||
        task.status === PlatformTaskStatus.Done
      ) {
        result[FilterPlatformTasksBy.Archived].push(task);
      }
    }

    return result;
  }, [tasks]);

  return (
    <>
      <div className="mb-6 flex items-center">
        <LocationFilter locations={locations} />

        <div className="mx-auto flex grow items-center justify-center">
          <Switch
            label="Include managed?"
            checked={includeManaged}
            onChange={() => {
              searchParams.set('include_managed', `${!includeManaged}`);
              setSearchParams(searchParams);
            }}
          />
        </div>

        <div className="item-center ml-auto mt-2 flex gap-x-4">
          <QuickFilters
            items={Object.values(FilterPlatformTasksBy).map((f) => ({
              label: f,
              count: taskByStatus[f].length,
              isSelected: filter === f,
            }))}
            onClick={handleFilterChange}
          />
        </div>
      </div>

      {taskByStatus[filter].length ? (
        <ul className="space-y-3">
          {taskByStatus[filter].map((task) => {
            return (
              <PlatformTaskCard
                key={task.id}
                task={task}
                refreshView={refreshView}
              />
            );
          })}
        </ul>
      ) : (
        <div className="flex grow items-center justify-center">
          <p className="font-semibold text-gray-400">
            No Tasks!
            {tasks.length > 0 && ' (Try a different filter)'}
          </p>
        </div>
      )}
    </>
  );
};
