import { FunctionComponent } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { clsx } from 'clsx';
import { NumberPattern } from '@mosey/utils/validation/patterns';
import {
  BaseTextField,
  BaseTextFieldProps,
  TextFieldSizes,
} from '@mosey/components/forms/BaseFormField';
import { FormField, FormFieldProps } from '@mosey/components/forms/FormField';

export type NumberFieldProps = {
  size?: TextFieldSizes;
  reactFormConfig?: RegisterOptions;
  isCurrency?: boolean;
  inputClassName?: string;
} & BaseTextFieldProps &
  FormFieldProps;

/**
 * Component to be used within a FormProvider to get the form context
 */
export const NumberField: FunctionComponent<NumberFieldProps> = ({
  name,
  label,
  rightButton,
  error,
  className,
  description,
  descriptionLink,
  size = 'medium',
  reactFormConfig,
  isCurrency,
  inputClassName,
  ...props
}) => {
  const { register } = useFormContext();

  const blurIfActive = (element: HTMLInputElement) => {
    // passive event listener - can't prevent default
    // instead we remove focus to avoid scroll wheeling changing its value
    if (element === document.activeElement) {
      element.blur();
    }
  };

  return (
    <FormField
      name={name}
      className={className}
      error={error}
      label={label}
      description={description}
      descriptionLink={descriptionLink}
      rightButton={rightButton}
    >
      {isCurrency && (
        <div
          aria-label="Dollar symbol"
          className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <span>$</span>
        </div>
      )}
      <BaseTextField
        type="number"
        size={size}
        error={error}
        className={clsx(
          {
            'pl-7': isCurrency,
          },
          inputClassName,
        )}
        id={name}
        {...props}
        {...register(name, {
          pattern: NumberPattern,
          ...reactFormConfig,
        } as RegisterOptions)}
        onWheel={(e) => blurIfActive(e.target as HTMLInputElement)}
      />
    </FormField>
  );
};
