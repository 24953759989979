import { clsx } from 'clsx';
import { Tab } from '@headlessui/react';

interface TaskTabProps {
  Icon: React.ComponentType<
    Pick<React.HTMLAttributes<HTMLElement>, 'aria-hidden' | 'className'>
  >;
  children: React.ReactNode;
}

export const TaskTab = ({ Icon, children }: TaskTabProps) => {
  return (
    <Tab className="mb-[-3px] border-b-[3px] border-transparent py-2 pl-[10px] pr-3 font-bold leading-6 text-zinc-800 outline-none hover:border-gray-300 hover:text-zinc-900 data-[selected]:border-rose-700 data-[selected]:text-rose-700 data-[selected]:hover:border-rose-800 data-[selected]:hover:text-rose-800">
      {({ focus, hover }) => (
        <div
          className={clsx(
            'flex items-center gap-x-2 rounded-sm outline-2 outline-offset-2 outline-rose-700',
            focus && 'outline',
            hover && 'outline-rose-800',
          )}
        >
          <Icon className="size-4 shrink-0" aria-hidden="true" />
          {children}
        </div>
      )}
    </Tab>
  );
};
