import { FunctionComponent, useEffect } from 'react';
import * as d3select from 'd3-selection';
import * as d3 from 'd3';
import * as d3geo from 'd3-geo';
import { useNavigate, NavigateFunction } from 'react-router';
import { clsx } from 'clsx';

import { US_STATES } from '../constants';
import { Region } from '../types';

export type Location = {
  name: string;
  lat: number;
  lon: number;
};

const drawMap = (
  navigate: NavigateFunction,
  states: Region[],
  locations: Location[],
) => {
  // Width and height of map
  const width = 960;
  const height = 500;

  // D3 Projection
  // Marking as any to prevent typescript complaining about being null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const projection: any = d3geo
    .geoAlbersUsa()
    .translate([width / 2, height / 2]) // translate to center of screen
    .scale(900); // scale things down so see entire US

  // Define path generator
  const path = d3geo
    .geoPath() // path generator that will convert GeoJSON to SVG paths
    .projection(projection); // tell path generator to use AlbersUsa projection

  const svg = d3select
    .select('#map')
    .attr('preserveAspectRatio', 'xMinYMin meet')
    .attr('viewBox', '0 0 960 500');

  // Clear contents to redraw from existing svg
  svg.selectAll('*').remove();

  // Load GeoJSON data
  const data = US_STATES.features;
  // Lookup which states to highlight based on name
  const highlightedStateNames = states.map((i) => i.name);

  // Draw each state in the map
  svg
    .selectAll('path')
    .data(data)
    .enter()
    .append('path')
    // This needs to be `any` because the `data` type doesn't match
    // the path type. https://stackoverflow.com/questions/33108209/compilation-errors-when-drawing-a-piechart-using-d3-js-and-typescript
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('d', path as any)
    .style('stroke', '#bbb')
    .style('stroke-width', 1)
    .attr('class', (d) => {
      // Highlight any states passed in as an argument
      if (highlightedStateNames.includes(d.properties.name)) {
        return 'fill-current text-teal-200 cursor-pointer hover:text-teal-400';
      }
      return 'fill-current text-white';
    })
    .on('click', (d) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selected: any = d3.select(d.target).data()[0];
      const region = states.find((i) => i.name === selected.properties.name);
      if (region) {
        navigate(`/locations/usa/${region.code.toLowerCase()}`);
      }
    })
    .append('title')
    .text((d) => d.properties.name);

  svg
    .selectAll('circle')
    .data(locations)
    .enter()
    .append('circle')
    .attr('cx', (d) => {
      return projection([d.lon, d.lat])[0];
    })
    .attr('cy', (d) => {
      return projection([d.lon, d.lat])[1];
    })
    .attr('r', 8)
    .style('fill', 'rgb(251, 191, 36)')
    .style('opacity', 0.75);
};

type USMapProps = {
  locations: Location[];
  states: Region[];
  fixed?: boolean;
  className?: string;
};

export const USMap: FunctionComponent<USMapProps> = ({
  states,
  locations,
  fixed,
  className,
}) => {
  const navigate = useNavigate();
  // Draw thee map once the svg element is rendered
  useEffect(() => {
    drawMap(navigate, states, locations);
  });

  return (
    <svg
      id="map"
      className={clsx(
        {
          fixed,
        },
        className,
      )}
    />
  );
};
