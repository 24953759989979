import { fetchApi } from './fetchApi';
import { ApiStatus } from './types';
import { handleTokenReponse } from './auth';

/**
 * Accept an invite to join an existing legal entity and store JSON web token on success
 *
 * @param inviteToken
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const acceptInvite = async (inviteToken: string, password: string) => {
  // Assert all fields are not empty
  if (!(inviteToken.length > 0)) {
    throw new Error('Invalid invite token');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }

  const body = {
    token: inviteToken,
    password,
  };

  const { status, error, data } = await fetchApi({
    url: `/api/invites/accept`,
    method: 'POST',
    credentials: 'include',
    body,
  });

  if (status === ApiStatus.Error) {
    throw new Error(
      error?.detail && !Array.isArray(error.detail)
        ? error.detail
        : "Something went wrong, we're looking into it.",
    );
  }

  handleTokenReponse(data);

  return data;
};
