import clsx from 'clsx';
import { FunctionComponent, ReactNode } from 'react';

type RequirementStatusMessageVariants = 'done' | 'inProgress';

type RequirementStatusMessageProps = {
  title: string;
  description: string;
  variant?: RequirementStatusMessageVariants;
  icon?: ReactNode;
};

export const REQUIREMENT_STATUS_CLASSES = {
  done: {
    text: 'text-lime-500',
    bg: 'bg-lime-500',
  },
  inProgress: {
    text: 'text-teal-800',
    bg: 'bg-teal-800',
  },
};

export const RequirementStatusMessage: FunctionComponent<
  RequirementStatusMessageProps
> = ({ title, description, variant = 'done', icon }) => (
  <div className="flex flex-col items-center justify-center">
    {icon && (
      <div
        className={clsx('size-10 rounded-full p-1 text-white', {
          [REQUIREMENT_STATUS_CLASSES[variant].bg]: !!variant,
        })}
      >
        {icon}
      </div>
    )}
    <div>
      <h3
        className={clsx('text-2xl font-bold', {
          [REQUIREMENT_STATUS_CLASSES[variant].text]: !!variant,
        })}
      >
        {title}
      </h3>
    </div>
    <div className="mt-4 max-w-md text-center">
      <p className="text-gray-500">{description}</p>
    </div>
  </div>
);
