import { AttributeValues, DetailedPolicy } from '../types';
import { useMemo } from 'react';
import { fillContentItem } from '../utils';
import { useRouteLoaderData } from 'react-router-dom';
import { RegionScopedContent } from '@mosey/components/handbook/RegionScopedContent';

type PolicyGroupMainContentProps = {
  policies: Array<DetailedPolicy>;
  regions: Array<string>;
};

export const PolicyGroupMainContent = ({
  policies,
  regions,
}: PolicyGroupMainContentProps) => {
  const attributeValues = useRouteLoaderData(
    'policy-section-details',
  ) as AttributeValues;
  const mainContent = useMemo(
    () =>
      policies[0].content.main.map((piece) => ({
        ...piece,
        content: fillContentItem(piece.content, attributeValues),
      })),
    [policies, attributeValues],
  );
  return <RegionScopedContent regions={regions} mainContent={mainContent} />;
};
