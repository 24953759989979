import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import { Fee, FeeType } from '../../types';
import { formatFee } from '../../utils/format';
import { ManagedRequirementNote } from './ManagedRequirementNote';

type RequirementFeeProps = {
  fee: Fee;
};

export const RequirementFee: FunctionComponent<RequirementFeeProps> = ({
  fee,
}) => {
  const { value, explanation } = formatFee(fee);
  return (
    <ManagedRequirementNote
      callout={
        <div className="space-y-2 text-center">
          <div className="mt-1 text-xs font-medium uppercase text-zinc-600">
            Estimated Passthrough Fees
          </div>
          <div
            className={clsx('font-bold text-zinc-800', {
              'text-4xl': fee.type === FeeType.Fixed,
              'text-xl': fee.type === FeeType.Variable,
              'text-2xl': fee.type === FeeType.Range,
            })}
          >
            {value}
          </div>
        </div>
      }
      details={<p>{explanation}</p>}
    />
  );
};
