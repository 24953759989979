import { useState } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { FieldError } from './FieldError';
import { TaskQuestionFieldBaseProps } from './types';

interface IncrementButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  isDecrement?: boolean;
  step: number;
}

const IncrementButton = ({
  isDecrement = false,
  step,
  ...rest
}: IncrementButtonProps) => {
  const Icon = isDecrement ? MinusIcon : PlusIcon;

  return (
    <button
      {...rest}
      type="button"
      aria-label={`${isDecrement ? 'Decrement' : 'Increment'} value by ${step}`}
      className="group shrink-0 p-7 focus:outline-none"
    >
      <div className="flex size-6 items-center justify-center rounded-full border-2 border-stone-600 text-stone-600 group-hover:border-teal-700 group-hover:bg-teal-600 group-hover:text-teal-700 group-focus-visible:ring-2 group-focus-visible:ring-teal-700 group-focus-visible:ring-offset-2">
        <Icon className="size-6" />
      </div>
    </button>
  );
};

export const TaskNumberField = ({
  field: {
    name,
    label,
    description,
    required,
    component: { params },
  },
  value: _value,
  errors = {},
}: TaskQuestionFieldBaseProps) => {
  const min = params.min ?? 0;
  const max = params.max ?? Number.MAX_SAFE_INTEGER;
  const step = params.step ?? 1;
  const error = errors[name];
  const [value, setValue] = useState<string>(
    _value?.toString() || min.toString(),
  );
  const handleValueChange = (isDecrement = false) => {
    let newValue = Number.parseInt(value || '0');

    if (Number.isNaN(newValue)) {
      newValue = 0;
    }

    newValue = isDecrement
      ? Math.max(min, newValue - step)
      : Math.min(max, newValue + step);

    setValue(newValue.toString());
  };

  const handleDecrement = () => {
    handleValueChange(true);
  };

  const handleIncrement = () => {
    handleValueChange();
  };

  const descriptionId = `description-${name}`;
  const errorMessageId = `form-field-error-message-${name}`;

  return (
    <>
      <div className="mb-6 flex flex-col gap-y-2">
        <label htmlFor={name} className="block max-w-3xl cursor-pointer">
          {label}
        </label>
        {description && <p id={descriptionId}>{description}</p>}
      </div>

      <div className="flex h-20 w-fit flex-wrap items-center gap-x-2 rounded border border-stone-200">
        <IncrementButton isDecrement onClick={handleDecrement} step={step} />

        <input
          type="number"
          name={name}
          id={name}
          min={min}
          max={max}
          required={Boolean(required)}
          step={step}
          className="hide-spin-buttons peer max-w-64 rounded border border-transparent text-center text-[40px] font-medium leading-[48px] transition-shadow duration-150 hover:border-gray-300 focus:border-teal-500 focus:ring-teal-500"
          aria-describedby={[
            description ? descriptionId : '',
            errorMessageId,
          ].join(' ')}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <IncrementButton onClick={handleIncrement} step={step} />
      </div>

      <FieldError id={errorMessageId} error={Boolean(error)}>
        {error}
      </FieldError>
    </>
  );
};
