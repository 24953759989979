import { SectionTitle } from '../components/SectionTitle';
import { SignaturesDonut } from '../components/SignaturesDonut';

export const OverviewSignaturesSection = () => {
  return (
    <div className="flex min-w-[350px] flex-col gap-4">
      <SectionTitle title="Signatures" to="/handbook/signatures" />
      <SignaturesDonut to="/handbook/signatures" />
    </div>
  );
};
