import { Link, LinkProps, useNavigate } from 'react-router-dom';

interface BackButtonProps {
  to?: LinkProps['to'];
}

export const BackButton = ({ to }: BackButtonProps) => {
  const navigate = useNavigate();

  const content = (
    <span className="flex gap-x-1 font-semibold hover:text-teal-800">
      <svg
        className="size-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
        />
      </svg>
      <span>Back</span>
    </span>
  );

  if (to) {
    return <Link to={to}>{content}</Link>;
  }

  return (
    <button type="button" onClick={() => navigate(-1)}>
      {content}
    </button>
  );
};
