import { Button } from 'react-aria-components';
import { api } from '../../utils/fetchApi';
import { PageHeader } from '@mosey/components/page-header/PageHeader';
import { Table } from '@mosey/components/table/Table';
import { HeaderRow } from '@mosey/components/table/HeaderRow';
import { BackButton } from '../../components';
import { SignaturesDonut } from './components/SignaturesDonut';
import {
  getLabelFromAcknowledgement,
  getTaskCardStatusFromAcknowledgement,
} from './utils';
import { HeaderCell } from '@mosey/components/table/HeaderCell';
import { TableBody } from '@mosey/components/table/TableBody';
import { TableRow } from '@mosey/components/table/TableRow';
import { TableCell } from '@mosey/components/table/TableCell';
import { StatusCell } from '@mosey/components/table/StatusCell';
import { EmptyStateCell } from '@mosey/components/table/EmptyStateCell';
import { formatLocalDate } from '../../utils/format';
import { StandaloneStatusIcon } from '@mosey/components/layout/StatusIcon';
import { Tooltip } from '@mosey/components/layout/Tooltip';
import { QueryFilter } from '@mosey/components/menus/QueryFilter';
import { useSignatures } from './hooks/useSignatures';
import { TextLink } from '@mosey/components/navigation/TextLink';

export const Signatures = () => {
  const { allEmployees, filteredEmployees, filterOptions } = useSignatures();

  return (
    <>
      <PageHeader hasBorder={false}>
        <BackButton to="/handbook" />
      </PageHeader>
      <div className="px-8 py-4">
        <section>
          <h1 className="mb-6 text-xl font-semibold">Signatures</h1>
          <SignaturesDonut />
        </section>
        <section className="mt-8">
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-xl font-semibold">Employees</h1>
            <div className="flex items-center gap-6">
              <QueryFilter queryKey="status" options={filterOptions.status} />
              <QueryFilter
                queryKey="location"
                options={filterOptions.location}
              />
            </div>
          </div>
          <Table>
            <HeaderRow>
              <th className="w-[42px]">
                <span className="sr-only">Status</span>
              </th>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell>City</HeaderCell>
              <HeaderCell>State</HeaderCell>
              <HeaderCell>Last signed</HeaderCell>
            </HeaderRow>
            <TableBody>
              {filteredEmployees.length === 0 && (
                <EmptyStateCell colSpan={5}>
                  <div className="flex flex-col items-center gap-1">
                    <p>No employees found</p>
                    {allEmployees.length > 0 && (
                      <TextLink to="/handbook/signatures">
                        Clear filters
                      </TextLink>
                    )}
                  </div>
                </EmptyStateCell>
              )}
              {filteredEmployees.map((employee) => (
                <TableRow key={employee.id}>
                  <StatusCell>
                    <Tooltip
                      label={getLabelFromAcknowledgement(employee)}
                      offset={10}
                    >
                      <Button className="size-[18px] pt-1">
                        <StandaloneStatusIcon
                          status={getTaskCardStatusFromAcknowledgement(
                            employee,
                          )}
                        />
                      </Button>
                    </Tooltip>
                  </StatusCell>
                  <TableCell>
                    <span className="text-zinc-700">{employee.email}</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">{employee.city_name}</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">
                      {employee.region.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className="text-zinc-700">
                      {employee.acknowledged_at
                        ? formatLocalDate(
                            new Date(`${employee.acknowledged_at}Z`),
                          )
                        : '--'}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      </div>
    </>
  );
};

Signatures.loader = async () => {
  return api({
    method: 'GET',
    url: '/api/handbook/employees',
  });
};
