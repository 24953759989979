import { FunctionComponent } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

type StatusBarProps = {
  label?: string;
  complete: number;
  incomplete: number;
  inProgress: number;
  url?: string;
  completeLabel?: string;
  incompleteLabel?: string;
  inProgressLabel?: string;
};

export const StatusBar: FunctionComponent<StatusBarProps> = ({
  label,
  complete,
  incomplete,
  inProgress,
  url,
  completeLabel = 'complete',
  incompleteLabel = 'incomplete',
  inProgressLabel = 'in progress',
}) => {
  let bgColor;
  if (complete === 100) {
    bgColor = 'bg-lime-500';
  } else if (complete === 0 && incomplete === 0 && inProgress === 0) {
    bgColor = 'bg-gray-300';
  } else {
    bgColor = 'bg-rose-700';
  }

  const ariaLabel = [
    { label: completeLabel, value: complete },
    { label: inProgressLabel, value: inProgress },
    { label: incompleteLabel, value: incomplete },
  ]
    .filter(({ value }) => value > 0)
    .map(({ label, value }) => `${value.toFixed(0)}% ${label}`)
    .join(', ');

  const content = (
    <>
      {label && (
        <span className="mr-2 w-32 truncate text-sm text-gray-800">
          {label}
        </span>
      )}
      <figure className="relative w-full" aria-label={ariaLabel}>
        <div
          className={clsx('flex h-2 overflow-hidden rounded text-xs', bgColor)}
        >
          <div
            style={{ width: `${complete}%` }}
            className="flex flex-col justify-center whitespace-nowrap bg-lime-500 text-center text-white shadow-none"
          />
          <div
            style={{ width: `${inProgress}%` }}
            className="flex flex-col justify-center whitespace-nowrap bg-amber-200 text-center text-white shadow-none"
          />
          <div
            style={{ width: `${incomplete}%` }}
            className="flex flex-col justify-center whitespace-nowrap bg-rose-700 text-center text-white shadow-none"
          />
        </div>
      </figure>
    </>
  );
  if (url) {
    return (
      <Link to={url} className="flex w-full cursor-pointer items-center">
        {content}
      </Link>
    );
  } else {
    return <div className="flex w-full items-center">{content}</div>;
  }
};

type StatusBarListProps = {
  stats: StatusBarProps[];
};

export const StatusBarList: FunctionComponent<StatusBarListProps> = ({
  stats,
}) => {
  return (
    <>
      {stats &&
        stats.map((s) => (
          <StatusBar
            key={s.label}
            label={s.label}
            complete={s.complete}
            incomplete={s.incomplete}
            inProgress={s.inProgress}
            url={s.url}
          />
        ))}
    </>
  );
};
