import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import { useTasksOverviewData } from '../../utils/hooks';

export const InProgressCollection = () => {
  const { inProgress } = useTasksOverviewData();

  return (
    <TruncatedList
      title="Automations in Progress"
      body="You will be notified if action is required"
      itemLabel="automations"
      isStacked
      as="nav"
      aria-live="polite"
    >
      {inProgress.map(({ id, title, status }) => {
        return (
          <li key={id}>
            <TaskSummaryCard
              title={title}
              status={status === 'deferred' ? 'todo' : status}
              variant="nav"
              size="medium"
              to={`/requirement/${id}`}
            />
          </li>
        );
      })}
    </TruncatedList>
  );
};
