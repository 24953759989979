import { Person } from '.';

export type FieldDependency = {
  targetField: string;
  targetIsInArrayField?: boolean;
  visibilityFunction: string;
};

export type FormSpecField = {
  name: string;
  label?: string | React.ReactNode;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: { type: string; params?: any };
  required?: string | { value: boolean; message: string };
  validate?: string[];
  pattern?: string;
  children?: FormSpecField[];
  // TODO: remove field_dependencies
  field_dependencies?: {
    target_field: string;
    target_is_in_array_field?: boolean;
    visibility_function: string;
  }[];
  field_rules?: {
    target_field: string;
    target_is_in_array_field?: boolean;
    condition: RuleCondition;
  }[];
  value_as?: string;
};

export type FormSpecSection = {
  label?: string;
  description?: string;
  form_fields: FormSpecField[];
};

export type FormSpec = {
  id?: string;
  title: string;
  sections: FormSpecSection[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  default_values?: any;
};

export type StateSetupProps = {
  peopleData: Person[];
  onSubmitSuccess?: () => void;
  onEnterLater?: () => void;
};

export enum PeopleOptionsType {
  businessOwners = 'business_owners',
  officers = 'officers',
  all = 'all',
}

export enum PersonIdentifierType {
  doesNotHave = 'does_not_have',
  ssn = 'ssn',
  itin = 'itin',
}

export interface AddressIFormValues {
  address_line_1: string | null;
  address_line_2: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country?: string;
  is_business_address?: boolean;
  is_employee_address?: boolean;
}

export type PersonIFormValues = {
  person_type: string | null;

  // Person fields
  first_name: string | null;
  last_name: string | null;
  title: string | null;
  email: string | null;
  phone_number: string | null;
  identifier_type?: PersonIdentifierType | null;
  identifier?: string | null;
  start_date?: string | null;
  personal_address?: AddressIFormValues | null;
  is_officer?: boolean;
  is_owner?: boolean;
  is_member?: boolean;
  is_llc_member?: boolean;
  birth_date?: string;
  ownership_start_date?: string | null;
  ownership_percentage?: number | null;
};

export type BusinessIFormValues = {
  person_type: string | null;
  name?: string | null;
  business_identifier?: string | null;
  incorporation_region?: string | null;
  incorporation_date?: string | null;
  physical_address?: AddressIFormValues | null;
  mailing_address?: AddressIFormValues | null;
  is_physical_address_same_as_mailing_address?: boolean | null;
  personal_address?: AddressIFormValues | null;
  ownership_start_date?: string | null;
  ownership_percentage?: number | null;
  is_llc_managed?: boolean;
};

export type SelectOptionType = { name?: string; value: string | number };

export enum RuleConditionType {
  Equals = 'equals',
  Exists = 'exists',
  Not = 'not',
  And = 'and',
  Or = 'or',
  LowerBounds = 'lower_bounds',
  UpperBounds = 'upper_bounds',
}

export type RuleConditionEqual = {
  type: string; // RuleConditionType.Equals;
  value: string | number;
};

export type RuleConditionExists = {
  type: string; // RuleConditionType.Exists;
};

export type RuleConditionNot = {
  type: string; // RuleConditionType.Not;
  not_condition: RuleCondition;
};

export type RuleConditionAnd = {
  type: string; // RuleConditionType.And;
  and_conditions: RuleCondition[];
};

export type RuleConditionOr = {
  type: string; // RuleConditionType.Or;
  or_conditions: RuleCondition[];
};

export type RuleConditionLowerBounds = {
  type: string; // RuleConditionType.LowerBounds;
  value: number;
};

export type RuleConditionUpperBounds = {
  type: string; // RuleConditionType.UpperBounds;
  value: number;
};

export type RuleCondition =
  | RuleConditionEqual
  | RuleConditionNot
  | RuleConditionAnd
  | RuleConditionOr
  | RuleConditionLowerBounds
  | RuleConditionUpperBounds
  | RuleConditionExists;

export type FieldRule = {
  targetField: string;
  targetIsInArrayField?: boolean;
  condition: RuleCondition;
};
