import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { logout } from '../utils/auth';
import { LoadingFullScreen } from '../views/Loading';
import { useSearchParams } from 'react-router-dom';

export const Logout: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const callLogout = async (): Promise<void> => {
    await logout();
    const redirect = searchParams.get('redirect');

    navigate(
      `/login${redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''}`,
    );
  };

  useEffect(() => {
    callLogout();
  }, []);

  return <LoadingFullScreen />;
};

export default Logout;
