import {
  Dispatch,
  FunctionComponent,
  MouseEvent,
  SetStateAction,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ArrowRightIcon, ErrorIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { useScrollIntoView } from '@mosey/components/hooks';
import { AttributeType, Requirement, RequirementStatus } from '../../types';
import {
  RequirementContentWrapper,
  RequirementSetupStepEnum,
} from '../LocationDetailSetup_NEW';
import { fetchApi } from '../../utils/fetchApi';
import { DeterminationDatumQuestion } from './DeterminationDatumQuestion';
import { RequirementImportDatumFormNew } from '../../components';
import { useLocationDetailContext } from '../../contexts/locationDetailContext';
import { ManagedPayrollReqsByState } from '../../utils/requirement';

enum ActionDoneTypeEnum {
  Yes = 'yes',
  No = 'no',
  NotApplicable = 'notApplicable',
}

type RequirementSetupOptionsForm = {
  isActionDone: ActionDoneTypeEnum;
};

type RequirementQuestionsProps = {
  requirementData: Requirement;
  locationId: string;
  isAutomatable: boolean;
  setStep: Dispatch<SetStateAction<RequirementSetupStepEnum>>;
  isImportable: boolean;
  setHasSeenSuccessPage: Dispatch<SetStateAction<boolean>>;
  handleNotApplicable: (event?: MouseEvent) => void;
  handleSuccessRefresh: () => void;
};

export const RequirementQuestions: FunctionComponent<
  RequirementQuestionsProps
> = ({
  requirementData,
  locationId,
  isAutomatable,
  setStep,
  isImportable,
  setHasSeenSuccessPage,
  handleNotApplicable,
  handleSuccessRefresh,
}) => {
  const [scrollIntoViewRef, triggerScroll] = useScrollIntoView();
  const [locationDetailState, dispatch] = useLocationDetailContext();
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const formMethods = useForm<RequirementSetupOptionsForm>();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = formMethods;

  const handleDone = async () => {
    setIsNextLoading(true);

    const reqPatchResponse = await fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PUT',
      body: { status: RequirementStatus.Done },
    });

    if (reqPatchResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('isActionDone', err);
    }

    setIsNextLoading(false);
  };

  const handleSkipRequirement = async () => {
    setIsSkipLoading(true);

    const reqPatchResponse = await fetchApi({
      url: `/api/requirements/${requirementData.id}`,
      method: 'PUT',
      body: { status: RequirementStatus.Skipped },
    });

    if (reqPatchResponse.error) {
      const err = {
        type: 'manual',
        message: `Something went wrong, we're looking into it.`,
      };
      setError('isActionDone', err);
    }

    setIsSkipLoading(false);
    setStep(RequirementSetupStepEnum.EnterLaterSuccess);
  };

  const onSubmit = async (data: RequirementSetupOptionsForm) => {
    if (!data.isActionDone) {
      setError('isActionDone', {
        message: 'At least one option is required.',
      });
      return;
    }

    if (data.isActionDone === ActionDoneTypeEnum.Yes) {
      if (isImportable && !locationDetailState.isImporting) {
        dispatch({ type: 'setIsImporting', payload: true });
        triggerScroll();
      } else {
        await handleDone();
        handleSuccessRefresh();
        setStep(RequirementSetupStepEnum.CompletedRequirement);
      }
    }

    if (data.isActionDone === ActionDoneTypeEnum.No) {
      if (isAutomatable) {
        setStep(RequirementSetupStepEnum.AutomationPrompt);
        return;
      }

      const locationAutomatedRequirements =
        ManagedPayrollReqsByState[locationId];

      if (locationAutomatedRequirements.includes(requirementData.data_id)) {
        setStep(RequirementSetupStepEnum.AutomationPrompt);
      } else {
        setStep(RequirementSetupStepEnum.Incomplete);
      }
    }

    if (data.isActionDone === ActionDoneTypeEnum.NotApplicable) {
      handleNotApplicable();
      setStep(RequirementSetupStepEnum.Deferred);
    }
  };

  const isAnyButtonLoading = isSkipLoading || isNextLoading;

  const requirementHasDeterminationAttribute =
    requirementData.produces.length === 1 &&
    requirementData.produces[0].attribute.type === AttributeType.Boolean;

  if (requirementHasDeterminationAttribute) {
    return (
      <DeterminationDatumQuestion
        requirementData={requirementData}
        attributeId={requirementData.produces[0].attribute.id}
        regionCode={locationId}
        onNotApplicable={() => {
          handleNotApplicable();
          setStep(RequirementSetupStepEnum.Deferred);
        }}
        onSubmitSuccess={() => {
          setStep(RequirementSetupStepEnum.CompletedRequirement);
          setHasSeenSuccessPage(true);
          handleSuccessRefresh();
        }}
        onSkip={handleSkipRequirement}
      />
    );
  }

  return (
    <RequirementContentWrapper
      title={requirementData.title}
      description={requirementData.description}
      resources={requirementData.resources}
      ref={scrollIntoViewRef}
    >
      <h3 className="mb-6 text-lg font-bold">Have you done this?</h3>
      <div>
        {locationDetailState.isImporting ? (
          <RequirementImportDatumFormNew
            requirementId={requirementData.id!}
            regionCode={requirementData.region[0].toLowerCase()}
            attributes={requirementData.produces}
            onSubmitSuccess={() => {
              dispatch({ type: 'setIsImporting', payload: false });
              setStep(RequirementSetupStepEnum.CompletedRequirement);
              setHasSeenSuccessPage(true);
              handleSuccessRefresh();
            }}
            onCancel={() =>
              dispatch({ type: 'setIsImporting', payload: false })
            }
          />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="divide-y-2 divide-sage-100 bg-white">
                <div>
                  <label
                    className="cursor-pointer text-lg font-bold"
                    htmlFor="yes"
                  >
                    <div className="flex items-center gap-x-4 px-6 py-3">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        value="yes"
                        id="yes"
                        disabled={isAnyButtonLoading}
                        {...register('isActionDone')}
                      />
                      Yes
                    </div>
                  </label>
                </div>
                <div>
                  <label
                    className="cursor-pointer text-lg font-bold"
                    htmlFor="no"
                  >
                    <div className="flex items-center gap-x-4 px-6 py-3">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        value="no"
                        id="no"
                        disabled={isAnyButtonLoading}
                        {...register('isActionDone')}
                      />
                      No
                    </div>
                  </label>
                </div>
                <div>
                  <label
                    className="cursor-pointer text-lg font-bold"
                    htmlFor="notApplicable"
                  >
                    <div className="flex items-center gap-x-4 px-6 py-3">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        value="notApplicable"
                        id="notApplicable"
                        disabled={isAnyButtonLoading}
                        {...register('isActionDone')}
                      />
                      Not Applicable
                    </div>
                  </label>
                </div>
              </div>

              <div className="mt-6 flex gap-x-6">
                <Button
                  type="button"
                  isFullWidth
                  disabled={isAnyButtonLoading}
                  isLoading={isSkipLoading}
                  onClick={handleSkipRequirement}
                  variant="secondary"
                  size="xlarge"
                >
                  Skip for now
                </Button>
                <Button
                  disabled={isAnyButtonLoading}
                  isLoading={isNextLoading}
                  isFullWidth
                  rightIcon={<ArrowRightIcon className="w-4" />}
                  size="xlarge"
                >
                  Next
                </Button>
              </div>
            </form>
          </FormProvider>
        )}
        {errors.isActionDone && (
          <div className="mt-2 flex items-center text-xs text-red-600">
            <span>
              <ErrorIcon className="mr-1 size-4" />
            </span>
            <div>
              <p>{`${
                errors.isActionDone.message || 'Something went wrong.'
              }`}</p>
            </div>
          </div>
        )}
      </div>
    </RequirementContentWrapper>
  );
};
