import { useSearchParams } from 'react-router-dom';
import { CloseButton } from '@headlessui/react';
import {
  LocationCard,
  LocationCardBaseProps,
  LocationCardProps,
} from '@mosey/components/layout/LocationCard';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { useUser } from '../../../../hooks/useUser';

export const RegionFilter = (
  props: Omit<LocationCardProps<typeof CloseButton>, 'as'>,
) => {
  const { region } = props;
  const { summary } = useTasksOverviewData();
  const {
    legal_entity: { incorporation_region: incorporationRegion },
  } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId = useTasksOverviewLocationId();
  const code = region.code.toLowerCase();
  const isCurrentLocation = code === locationId;

  const {
    has_unread_mail: hasUnreadMail,
    is_assessment: isAssessment,
    is_setup: isSetup,
    overdue_count: overdueCount,
  } = summary[region.code];
  let status: LocationCardBaseProps['status'] = undefined;

  if (overdueCount > 0) {
    status = 'overdue';
  } else if (isSetup) {
    status = 'setup';
  } else if (isAssessment) {
    status = 'assessment';
  } else if (incorporationRegion?.code === region.code) {
    status = 'home';
  }

  return (
    <LocationCard
      {...props}
      as={CloseButton}
      onClick={() => {
        searchParams.set('location_id', code);
        setSearchParams(searchParams);
      }}
      aria-pressed={isCurrentLocation}
      aria-label={`Show ${region.name} tasks`}
      selected={isCurrentLocation}
      notification={hasUnreadMail ? 'unread_mail' : undefined}
      status={status}
    />
  );
};
