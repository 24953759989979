import {
  TaskStatusPill,
  TaskStatusPillProps,
} from '@mosey/components/badges/TaskStatusPill';
import { getStateSealImage } from '../../../utils/seals';
import {
  useDueDate,
  useIsOverdue,
  useTask,
  useTaskLocation,
} from '../utils/hooks';

export const TaskHeader = () => {
  const { title, status } = useTask();
  const taskLocation = useTaskLocation();
  const isOverdue = useIsOverdue();
  const dueDate = useDueDate();
  let pillStatus: TaskStatusPillProps['status'] =
    status === 'deferred' ? 'todo' : status;

  if (isOverdue) {
    pillStatus = 'overdue';
  }

  return (
    <header className="space-y-4">
      <div className="flex items-center">
        {taskLocation && (
          <div className="flex items-center gap-x-2">
            <div className="size-6 shrink-0">
              <img
                src={getStateSealImage(taskLocation.code)}
                className="size-full object-contain"
                alt={`${taskLocation.name} state seal`}
              />
            </div>

            <h2 className="text-lg font-medium text-zinc-700">
              {taskLocation.name}
            </h2>
          </div>
        )}

        <div className="ml-auto flex items-center gap-x-6">
          {dueDate && (
            <div className="text-sm leading-6 text-zinc-700">
              Due <time dateTime={dueDate}>{dueDate}</time>
            </div>
          )}

          <TaskStatusPill status={pillStatus} />
        </div>
      </div>

      <h1 className="grow text-4xl font-bold leading-10 tracking-tight">
        {title}
      </h1>
    </header>
  );
};
