import { useEffect, useRef } from 'react';
import { fetchApi } from '../utils/fetchApi';
import { FormSpec } from '../types';
import { useSearchParams } from 'react-router-dom';
import { useSafeLocationUpdate } from '../hooks/useSafeLocationUpdate';
import { successCallbackPath } from '../utils/paths';
import { Loading } from './Loading';

type AutomationAutoSubmitProps = {
  form: FormSpec;
  automationPath: string;
  callbackUrl?: string;
  onAutomationComplete?: () => void;
};

export const AutomationAutoSubmit = ({
  form,
  automationPath,
  onAutomationComplete,
  callbackUrl,
}: AutomationAutoSubmitProps) => {
  const submitted = useRef(false);
  const updateLocation = useSafeLocationUpdate();
  const [searchParams] = useSearchParams();
  const skipSuccess = searchParams.get('skip_success_screen') === 'true';
  useEffect(() => {
    if (submitted.current) {
      return;
    }
    submitted.current = true;
    fetchApi({
      url: `/api/automation/${automationPath}`,
      method: 'POST',
      body: {
        form_submission: { form_id: form.id, data: {} },
      },
    }).then(() => {
      if (callbackUrl) {
        updateLocation(
          skipSuccess ? callbackUrl : successCallbackPath(callbackUrl),
          true,
        );
      } else if (onAutomationComplete) {
        onAutomationComplete();
      }
    });
  }, []);
  return <Loading className="w-full py-12" />;
};
