import { TaskSummaryCard } from '@mosey/components/layout/Card';
import { TruncatedList } from '@mosey/components/layout/TruncatedList';
import {
  useTasksOverviewData,
  useTasksOverviewLocationId,
} from '../../utils/hooks';
import { formatDateFromString } from '../../../../utils/format';

export const LegislationCollection = () => {
  const locationId = useTasksOverviewLocationId();
  const { legislation } = useTasksOverviewData();
  const filteredLegislation = legislation.filter(({ region }) => {
    if (locationId && region.code.toLowerCase() !== locationId) {
      return false;
    }

    return true;
  });

  return (
    <TruncatedList
      title="Legislation"
      body="Tasks will be generated for legislation that applies to you"
      itemLabel="updates"
      isStacked
      as="nav"
      aria-live="polite"
    >
      {filteredLegislation.map(
        ({ id, title, effective_date: effectiveDate }) => {
          const formattedEffectiveDate = effectiveDate
            ? formatDateFromString(effectiveDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })
            : undefined;
          const reason = formattedEffectiveDate
            ? {
                title: `Effective ${formattedEffectiveDate}`,
              }
            : undefined;

          return (
            <li key={id}>
              <TaskSummaryCard
                to={`/legislation#${id}`}
                title={title}
                variant="nav"
                size="medium"
                reason={reason}
                aria-label={reason && `${title} ${reason.title}`}
              />
            </li>
          );
        },
      )}
    </TruncatedList>
  );
};
