import { getStateSealImage } from '../../../utils/seals';
import { useResolverLocation } from '../utils/hooks';

// TODO: Merge this with <StateSealCropped /> once Home v2 is stable
export const MutedStateSeal = () => {
  const location = useResolverLocation();

  if (!location) {
    return null;
  }

  const imgSrc = getStateSealImage(location.code);

  return (
    <div
      className="relative mx-auto size-full overflow-hidden bg-white"
      style={{
        maskImage: `url(${imgSrc})`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        maskSize: 'contain',
      }}
    >
      <div
        className="absolute inset-0 overflow-visible bg-teal-100 mix-blend-color"
        aria-hidden
      />
      <div className="absolute inset-0">
        <img
          className="size-full object-contain opacity-50 grayscale"
          src={imgSrc}
          alt={`${location.name} state seal`}
        />
      </div>
      <div
        className="absolute inset-0 bg-teal-400 opacity-50 mix-blend-color"
        aria-hidden
      />
    </div>
  );
};
