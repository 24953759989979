import { ComponentProps } from 'react';
import { useNavigation } from 'react-router-dom';
import { Button } from '@mosey/components/buttons/Button';

type SubmitButtonProps = {
  intent: string;
  children?: ComponentProps<typeof Button>['children'];
} & Omit<ComponentProps<typeof Button>, 'children'>;

export const SubmitButton = ({
  intent,
  children = 'Continue',
  ...buttonProps
}: SubmitButtonProps) => {
  const { state } = useNavigation();

  return (
    <Button
      {...buttonProps}
      type="submit"
      size="xlarge"
      name="intent"
      value={intent}
      isLoading={state === 'submitting'}
    >
      {children}
    </Button>
  );
};
