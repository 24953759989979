import { Link, LinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { PlusCircleIcon } from '@heroicons/react/outline';

interface BigAddLinkProps extends Omit<LinkProps, 'className'> {
  size?: 'base' | 'small';
  fitToHeight?: boolean;
}

export const BigAddLink = ({
  size = 'base',
  fitToHeight,
  children,
  ...props
}: BigAddLinkProps) => {
  return (
    <Link
      {...props}
      className={clsx(
        'flex items-center justify-center gap-x-3 rounded-md border border-dashed border-sage-500 bg-sage-100 px-4 text-gray-700 focus-visible:outline-2 focus-visible:outline-rose-700',
        size === 'base' && 'font-bold',
        size === 'small' && 'text-sm font-semibold',
        fitToHeight && 'h-full',
        !fitToHeight && 'py-4',
      )}
    >
      {children}
      <PlusCircleIcon
        className={clsx(
          'shrink-0 text-sage-600',
          size === 'base' && 'size-6',
          size === 'small' && 'size-5',
        )}
      />
    </Link>
  );
};
