import { Requirement, RequirementOverview, RequirementStatus } from '../types';
import { convertDateToUTC } from '@mosey/utils/dates';
import {
  isDeferred,
  isDone,
  isInProgress,
  isNew,
  isOverdue,
  isSkipped,
  isTodo,
  isUpcoming,
} from './requirement';

export const requirementPercentComplete = (req: Requirement): number => {
  const completedRemediations = req.remediations.filter(
    (rem) => rem.is_done,
  ).length;
  return (
    Math.floor((completedRemediations / req.remediations.length) * 100) || 0
  );
};

type RequirementStats = {
  complete: number;
  incomplete: number;
  inProgress: number;
};

export const requirementStats = (
  requirements: RequirementOverview[],
): RequirementStats => {
  // Reduce each requirement into the following counts
  let complete = 0;
  let incomplete = 0;
  let inProgress = 0;

  requirements.forEach((req) => {
    if (req.is_virtual || req.is_blocked) {
      return;
    }

    // For now we will count deferred as inProgress "yellow"
    if (isDeferred(req)) {
      inProgress += 1;
      return;
    }

    // Handle recurring requirements
    if (req.due_date) {
      let today = new Date();
      today = convertDateToUTC(today);
      // It's not overrdue if requirement is done and today is
      // greater than previous due date and less than next due date
      if (
        req.status === RequirementStatus.Done &&
        today < new Date(req.due_date)
      ) {
        complete += 1;
        return;
      }
    }

    // Count managed requirements as completed
    if (req.is_managed) {
      complete += 1;
      return;
    }

    // Handle one time requirements
    if (isDone(req)) {
      complete += 1;
      return;
    }

    if (isSkipped(req)) {
      complete += 1;
      return;
    }

    if (isTodo(req)) {
      incomplete += 1;
      return;
    }

    if (isInProgress(req)) {
      inProgress += 1;
    }
  });

  const total = complete + incomplete + inProgress;

  const counts = {
    complete: Math.floor((complete / total) * 100) || 0,
    incomplete: Math.floor((incomplete / total) * 100) || 0,
    inProgress: Math.floor((inProgress / total) * 100) || 0,
  };

  return counts;
};

export type RequirementCounts = {
  todoCount: number;
  inProgressCount: number;
  overdueCount: number;
  doneCount: number;
  deferredCount: number;
  lockedCount: number;
  allCount: number;
  upcomingCount: number;
  newCount: number;
};

export const computedRequirementCounts = (
  requirements: RequirementOverview[],
): RequirementCounts => {
  let todoCount = 0;
  let inProgressCount = 0;
  let overdueCount = 0;
  let doneCount = 0;
  let deferredCount = 0;
  let lockedCount = 0;
  let upcomingCount = 0;
  let allCount = 0;
  let newCount = 0;

  requirements.forEach((req) => {
    if (isTodo(req)) {
      todoCount += 1;
    }
    if (isUpcoming(req)) {
      upcomingCount += 1;
    }
    if (isOverdue(req)) {
      overdueCount += 1;
    }
    if (isDone(req)) {
      doneCount += 1;
    }
    if (req.is_blocked) {
      lockedCount += 1;
    }
    if (isInProgress(req)) {
      inProgressCount += 1;
    }
    if (isDeferred(req)) {
      deferredCount += 1;
    }
    if (isNew(req)) {
      newCount += 1;
    }
    allCount += 1;
  });

  const counts = {
    todoCount,
    inProgressCount,
    overdueCount,
    doneCount,
    deferredCount,
    lockedCount,
    allCount,
    upcomingCount,
    newCount,
  };
  return counts;
};
