import { FaxIcon } from '@mosey/components/Icons';
import {
  LinkIcon,
  MailOpenIcon,
  MailIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import { TaskRemediation } from '../utils/types';
import { TextLink } from '@mosey/components/navigation/TextLink';

interface RemediationProps {
  remediation: TaskRemediation;
}

export const Remediation = ({ remediation }: RemediationProps) => {
  return (
    <>
      <p>{remediation.description}</p>

      {remediation.paths.length > 0 && (
        <ul className="mt-4 space-y-2 text-gray-500">
          {remediation.paths.map((path) => {
            let Icon;
            let content = null;

            switch (path.type) {
              case 'link':
                Icon = LinkIcon;
                content = (
                  <TextLink
                    to={path.value}
                    target="_blank"
                    aria-label={`${path.text || path.value} – opens in new tab`}
                  >
                    {path.text || path.value}
                  </TextLink>
                );
                break;

              case 'mail':
                Icon = MailOpenIcon;
                content = (
                  <address className="not-italic text-black">
                    {path.value}
                  </address>
                );
                break;

              case 'phone':
                Icon = PhoneIcon;
                content = (
                  <TextLink to={`tel:${path.value}`}>{path.value}</TextLink>
                );
                break;

              case 'fax':
                Icon = FaxIcon;
                content = path.value;

                break;

              case 'email':
                Icon = MailIcon;
                content = (
                  <TextLink to={`mailto:${path.value}`}>{path.value}</TextLink>
                );
                break;

              default:
                return null;
            }

            return (
              <li key={path.value} className="flex gap-x-2">
                <Icon
                  className="mt-1 size-4 shrink-0 text-zinc-600"
                  aria-hidden="true"
                />
                {content}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
