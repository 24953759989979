import { ComponentPropsWithoutRef } from 'react';

interface NavTooltipProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'className' | 'style'> {
  y: number;
}

export const NavTooltip = ({ y, ...props }: NavTooltipProps) => {
  return (
    <div
      {...props}
      className="absolute left-full w-max -translate-y-1/2 translate-x-2 rounded bg-black px-3 py-1 text-sm font-medium text-white"
      style={{
        top: y,
      }}
    />
  );
};
