import { useRouteLoaderData } from 'react-router-dom';
import { HandbookDataLoader } from '../types';
import {
  categorizeAndGroupPolicies,
  getDetailedPolicy,
  aggregatePoliciesBySection,
} from '../utils';

export function usePolicyList() {
  const { policies, draft, published } = useRouteLoaderData(
    'handbook',
  ) as HandbookDataLoader;
  const detailedPolicies = policies.map((policy) =>
    getDetailedPolicy(policy, draft, published),
  );
  const sections = aggregatePoliciesBySection(detailedPolicies);
  const sectionsWithListItems = sections.map((section) => ({
    ...section,
    items: categorizeAndGroupPolicies(section.policies),
  }));
  const items = sectionsWithListItems.map(({ items }) => items).flat();
  const actionableItems = items.filter(
    (item) => item.status === 'adoptable' || item.status === 'updateable',
  );
  return {
    policies: detailedPolicies,
    sections: sectionsWithListItems,
    actionableItems,
    hasActionableItems: actionableItems.length > 0,
    numItems: items.length,
  };
}
