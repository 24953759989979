import { FunctionComponent } from 'react';

import { Gate } from '../components/base/Gate';
import { useUser } from '../hooks/useUser';

type GateActiveUserProps = {
  children: React.ReactNode;
};

export const GateActiveUser: FunctionComponent<GateActiveUserProps> = ({
  children,
}) => {
  const { is_active: isActive } = useUser();

  return (
    <Gate
      shouldRenderChildren={isActive}
      redirectUrl="/onboarding/verify-email"
    >
      {children}
    </Gate>
  );
};
