import {
  TaskSummaryCard,
  TaskSummaryCardProps,
} from '@mosey/components/layout/Card';
import { POLICY_TO_TASK_STATUS, PolicyListItem } from '../types';

type PolicyCardProps = {
  item: PolicyListItem;
} & Pick<TaskSummaryCardProps, 'variant' | 'size'>;

export const PolicyOverviewCard = ({
  item,
  variant,
  ...props
}: PolicyCardProps) => {
  const hasCustomPolicy = item.type === 'category' && item.customPolicy;
  return (
    <li>
      <TaskSummaryCard
        {...props}
        variant={variant}
        to={`/handbook/section/${item.sectionId}/policies#${item.slug}`}
        status={POLICY_TO_TASK_STATUS[item.status]}
        title={item.title}
        isExactMatch
        region={
          variant === 'card' && !hasCustomPolicy && item.regionDescription
        }
        reason={
          variant === 'card' && item.regionDescription
            ? {
                title: hasCustomPolicy
                  ? 'Company-wide policy'
                  : 'State compliance',
                type: hasCustomPolicy ? 'account' : 'new-requirement',
              }
            : undefined
        }
      />
    </li>
  );
};
