import { FunctionComponent } from 'react';
import {
  RegisterOptions,
  useFormContext,
  FieldError as FieldErrorT,
} from 'react-hook-form';
import { CheckBox } from './CheckBox';

type FieldProps = {
  name: string;
  label: string;
  labelClassName?: string;
  error?: FieldErrorT;
  className?: string;
  description?: string;
  reactFormConfig?: RegisterOptions;
  hidden?: boolean;
  defaultChecked?: boolean;
};

/**
 * Component to be used within a FormProvider to get the form context
 */
export const CheckBoxField: FunctionComponent<FieldProps> = ({
  name,
  label,
  labelClassName,
  error,
  className,
  description,
  reactFormConfig,
  hidden,
  defaultChecked,
}) => {
  const { register } = useFormContext();
  return (
    <CheckBox
      error={error}
      description={description}
      hidden={hidden}
      label={label}
      className={className}
      labelClassName={labelClassName}
      id={name}
      inputProps={{
        defaultChecked,
        ...register(name, { ...reactFormConfig }),
      }}
    />
  );
};
