import { RefObject, useEffect, useRef } from 'react';

type UseScrollToElementParams = {
  elementId?: string;
  bufferSpacing?: number;
};

function attemptToScroll(scrollRef: React.RefObject<HTMLElement>, top: number) {
  setTimeout(
    () =>
      scrollRef?.current?.scrollTo?.({
        top,
        behavior: 'smooth',
      }),
    0,
  );
}

export function useScrollToElement<T extends HTMLElement>({
  elementId,
  bufferSpacing = 10,
}: UseScrollToElementParams): [RefObject<T>, () => void] {
  const ref = useRef<T>(null);

  const retriggerScroll = () => {
    if (!elementId) {
      attemptToScroll(ref, 0);
      return;
    }
    const element = document.getElementById(elementId);
    if (!element) {
      attemptToScroll(ref, 0);
      return;
    }
    attemptToScroll(
      ref,
      element.offsetTop - (ref.current?.offsetTop || 0) - bufferSpacing,
    );
  };

  useEffect(() => {
    retriggerScroll();
    // we specifically don't want to retrigger when the bufferSpacing changes
  }, [elementId]);

  return [ref, retriggerScroll];
}
