import {
  RefreshTokenError,
  getAccessToken,
  setAccessToken,
} from '@mosey/utils/auth';
import * as config from '../settings/config';
import { ApiStatus, IApiData } from './types';
import { GENERIC_SSO_ERROR } from './constants';

export const getPlatformAccessToken = () => {
  return (
    localStorage.getItem('platform_token') ||
    (localStorage.getItem('impersonator') ? getAccessToken() : null)
  );
};

// TODO: Fix type when we have type generated from the OpenAPI spec
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleTokenReponse = (data: any) => {
  if ('access_token' in data) {
    setAccessToken(data.access_token);
  } else if (data.token_type === 'sso' && data.sso_redirect_url) {
    // trusted URL - server generated
    window.location.href = data.sso_redirect_url;
  }
};

export const setPlatformAccessToken = (accessToken: string) => {
  localStorage.setItem('platform_token', accessToken);
};

const setAccessTokenFromResponse = async (
  response: Response,
): Promise<IApiData> => {
  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    const err = {
      status: response.status,
      message: data?.detail || null,
    };

    return { status: ApiStatus.Error, error: err, data: null };
  }

  handleTokenReponse(data);

  return { status: ApiStatus.Success, error: null, data };
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${config.API_BASE_URL}/api/token`, {
    method: 'POST',
    body: formData,
    mode: 'cors',
    credentials: 'include',
  });

  const response = await fetch(request);

  return setAccessTokenFromResponse(response);
};

/**
 * Login platform user to backend and store JSON web token on success
 *
 * @param string
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const loginPlatformUser = async (token: string): Promise<IApiData> => {
  // Assert token isn't empty
  if (!token) {
    throw new Error('Token was not provided');
  }
  const formData = new FormData();
  formData.append('token', token);

  const request = new Request(`${config.API_BASE_URL}/api/platform_token`, {
    method: 'POST',
    body: formData,
    mode: 'cors',
    credentials: 'include',
  });

  const response = await fetch(request);

  return setAccessTokenFromResponse(response);
};

/**
 * Sign up via backend and store JSON web token on success
 *
 * @param companyName
 * @param email
 * @param password
 * @param code
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const signUp = async (
  companyName: string,
  email: string,
  password: string,
  code: string | null,
) => {
  // Assert all fields are not empty
  if (!(companyName.length > 0)) {
    throw new Error('Company name is required');
  }
  if (!(email.length > 0)) {
    throw new Error('Email address is required');
  }
  if (!(password.length > 0)) {
    throw new Error('Password is required');
  }

  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('company_name', companyName);
  formData.append('username', email);
  formData.append('password', password);

  // Formdata does not accept a null value
  if (code) {
    formData.append('code', code);
  }

  const response = await fetch(`${config.API_BASE_URL}/api/signup`, {
    method: 'POST',
    body: formData,
    mode: 'cors',
    credentials: 'include',
  });

  if (response.status === 500) {
    throw new Error("Something went wrong, we're looking into it.");
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  handleTokenReponse(data);

  return data;
};

export const clearPlatformData = () => {
  localStorage.removeItem('platform_token');
};

export const logout = async () => {
  localStorage.removeItem('legal_entity_id');
  localStorage.removeItem('token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('expires');
  localStorage.removeItem('impersonator');
  clearPlatformData();
  const request = new Request(`${config.API_BASE_URL}/api/logout`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  });
  return await fetch(request);
};

/*
Get a new access token for the user and legal entity.
*/
export const refreshToken = async () => {
  // Always include the legal entity since access tokens are scoped to
  // the user and legal entity
  const legalEntityId = localStorage.getItem('legal_entity_id');

  const request = new Request(`${config.API_BASE_URL}/api/refresh_token`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      legal_entity_id: legalEntityId,
    }),
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw RefreshTokenError.InternalServerError;
  }

  if (response.status === 401) {
    throw RefreshTokenError.Expired;
  }

  const data = await response.json();

  handleTokenReponse(data);

  return data;
};

/*
Get a new access token for the impersonator when switching legal entity.
*/
export const switchLegalEntityForImpersonator = async (
  legalEntityId: string,
) => {
  // Always include the legal entity since access tokens are scoped to
  // the user and legal entity
  localStorage.setItem('legal_entity_id', legalEntityId);
  const token = getAccessToken();
  const request = new Request(`${config.API_BASE_URL}/api/impersonate/token`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      legal_entity_id: legalEntityId,
    }),
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw RefreshTokenError.InternalServerError;
  }

  if (response.status === 401) {
    throw RefreshTokenError.Expired;
  }

  const data = await response.json();

  handleTokenReponse(data);

  return data;
};

/*
Get a new access token for the platform legal entity.
*/
export const refreshPlatformToken = async () => {
  const legalEntityId = localStorage.getItem('legal_entity_id');

  const request = new Request(
    `${config.API_BASE_URL}/api/refresh_platform_token`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ legal_entity_id: legalEntityId }),
    },
  );

  const response = await fetch(request);

  if (response.status === 500) {
    throw RefreshTokenError.InternalServerError;
  }

  if (response.status === 401) {
    throw RefreshTokenError.Expired;
  }

  const data = await response.json();

  if ('access_token' in data) {
    setPlatformAccessToken(data.access_token);
  }

  return data;
};

export const switchLegalEntity = async (legalEntityId: string) => {
  localStorage.setItem('legal_entity_id', legalEntityId);
  await refreshToken();
};

export const requestPasswordReset = async (email: string) => {
  const request = new Request(`${config.API_BASE_URL}/api/password_resets`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  return fetch(request);
};

export const redeemPasswordReset = async (token: string, password: string) => {
  const request = new Request(
    `${config.API_BASE_URL}/api/password_resets/redeem`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, password }),
    },
  );

  return fetch(request);
};

/**
 * Get the SSO Auth URL
 *
 * @param email
 * @returns JSON data containing auth URL on success
 * @throws Error on http errors or failed attempts
 */
export const getSsoAuthUrl = async (
  email: string,
  state: Record<string, string> = {},
): Promise<string> => {
  // Assert email or password is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }

  const request = new Request(`${config.API_BASE_URL}/api/sso_auth_url`, {
    method: 'POST',
    body: JSON.stringify({
      username: email,
      state,
    }),
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const response = await fetch(request);
  if (response.ok) {
    return response.json();
  } else {
    throw new Error(GENERIC_SSO_ERROR);
  }
};

/**
 * Get the SSO token
 *
 * @param code
 * @param string
 * @returns JSON data containing the bearer token
 * @throws Error on http errors or failed attempts
 */
export const getSsoToken = async (code: string, state?: string) => {
  const params = new URLSearchParams();
  params.append('code', code);
  if (state) {
    params.append('state', state);
  }
  const request = new Request(
    `${config.API_BASE_URL}/api/sso_token?${params.toString()}`,
    {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    },
  );

  const response = await fetch(request);

  return setAccessTokenFromResponse(response);
};
