import { Button } from '@mosey/components/buttons/Button';
import { Link } from 'react-router-dom';
import toolsReportsImg from '../../assets/rippling/toolsreports.webp';
import filtersImg from '../../assets/rippling/filters.webp';
import addFilterImg from '../../assets/rippling/add-filter.webp';
import { RipplingFields } from './RipplingFields';
import { RipplingConnectWrapper } from './RipplingConnectWrapper';
import { RipplingStep } from './RipplingStep';
import { RipplingSteps } from './RipplingSteps';

export const RipplingReportView = () => {
  return (
    <RipplingConnectWrapper>
      <RipplingSteps>
        <RipplingStep title="Log in to Rippling" />
        <RipplingStep
          title={
            <>
              Go to <em>Tools</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Select <em>Reports</em>
            </>
          }
          images={[toolsReportsImg]}
        />
        <RipplingStep
          title={
            <>
              Click <em>New Report</em> (top right)
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Select <em>Start from scratch</em> (at the top)
            </>
          }
        />
        <RipplingStep
          title={
            <>
              In the Variable Selector, you will be presented with a list of
              categories (on the left) which you will use to navigate to the
              appropriate variables using the following table as a guide:
            </>
          }
          content={<RipplingFields />}
        />
        <RipplingStep
          title={
            <>
              Click <em>Continue</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Under filters, add <em>Employment status</em> if not already
              added. If the <em>Employment status</em> filter is already on the
              list, edit the filter by clicking on the filter and toggle on{' '}
              <em>Select all</em> and click <em>Apply</em>
            </>
          }
          images={[filtersImg, addFilterImg]}
        />
        <RipplingStep
          title={
            <>
              Click <em>Run Report</em>
            </>
          }
        />
        <RipplingStep
          title={
            <>
              Click <em>Save</em> and name the report{' '}
              <em>Mosey - Employee Report</em>
            </>
          }
        />
      </RipplingSteps>

      <div className="mt-10 flex justify-center gap-4">
        <Button
          variant="secondary"
          to="/onboarding/setup"
          as={Link}
          isFullWidth
        >
          Back
        </Button>
        <Button
          as={Link}
          variant="primary"
          isFullWidth
          to="/onboarding/rippling/workflow"
        >
          Next Step
        </Button>
      </div>
    </RipplingConnectWrapper>
  );
};
