import { Button } from '@mosey/components/buttons/Button';
import { fetchApi } from '../../../utils/fetchApi';
import { ApiStatus } from '../../../utils/types';
import { RedactableTextField } from '../../forms/RedactableTextField';
import { GuidedStepContentProps } from './types';

export const OtpStep = ({ login, nextStep }: GuidedStepContentProps) => {
  const getOtp = async (): Promise<string | null> => {
    const response = await fetchApi({
      url: `/api/accounts/otp/${login.public_id}`,
      method: 'GET',
    });

    if (response.status === ApiStatus.Success) {
      return response.data;
    }

    return null;
  };

  return (
    <div className="mt-8 flex flex-col items-start gap-8">
      <h5 className="text-2xl font-semibold ">Fetch one-time passcode</h5>
      <div className="flex flex-col gap-4">
        <p className="max-w-xl">
          {login.account_name} requires a one-time passcode (OTP). Once you are
          prompted for an OTP, copy it from the field below.
        </p>

        <RedactableTextField
          label="One-Time Passcode"
          name="otp"
          getExpiringValue={getOtp}
          disabled
          shouldRenderCopyButton
          hasMargin={false}
        />
      </div>
      <Button onClick={nextStep}>Next</Button>
    </div>
  );
};
