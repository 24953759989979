import { Link, useRouteLoaderData } from 'react-router-dom';
import configurationImg from '../../../../assets/configuration.svg';
import { PolicySectionLoader } from '../../types';
import { Button } from '@mosey/components/buttons/Button';

export const PolicySectionConfigureWelcome = () => {
  const [firstForm] = useRouteLoaderData(
    'policy-section',
  ) as PolicySectionLoader;
  const section = firstForm?.policy.policy_section;
  return (
    <div className="flex min-h-[calc(100vh-144px)] w-full flex-col items-center justify-center gap-4 px-4">
      <img src={configurationImg} alt="" />
      <div className="flex flex-col items-center justify-center gap-1">
        <h2 className="text-xl font-semibold">
          {section?.title} Configuration
        </h2>
        <p>
          Provide some additional information to view and adopt{' '}
          {section?.title?.toLowerCase()} policies.
        </p>
      </div>
      <Button
        as={Link}
        to={`/handbook/section/${firstForm.policy.policy_section.id}/configure/${firstForm.policy.slug}`}
      >
        Start
      </Button>
    </div>
  );
};
