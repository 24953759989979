import { FunctionComponent, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { isAuthenticated } from '@mosey/utils/auth';
import { redeemPasswordReset } from '../utils/auth';
import { fetchApi } from '../utils/fetchApi';
import { ApiStatus } from '../utils/types';
import { FilledLockIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import palmTreeImage from '../assets/palm-tree.svg';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const PasswordResetRedemption: FunctionComponent = () => {
  const query = useQuery();
  const resetToken = query.get('token');

  const [password, setPassword] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!resetToken) {
      setError('Sorry, this password reset token expired or is invalid');
      setShowForm(false);
      return;
    }

    fetchApi({
      url: `/api/password_resets/verify`,
      method: 'POST',
      body: { token: resetToken },
    }).then(({ status }) => {
      if (status === ApiStatus.Error) {
        setError('Sorry, this password reset token expired or is invalid');
      }
    });
    // Add a dependency on the invite token so we don't accidentally
    // call the `/verify` endpoint every time this component is
    // re-rendered
  }, [resetToken]);

  const handleSubmit = async (ev: React.MouseEvent) => {
    ev.preventDefault();

    if (password === '') {
      setError('Please choose a new password.');
      return;
    }
    if (password.length < 11) {
      setError('Passwords must at least 11 characters');
      return;
    }

    setError('');

    let response;

    if (resetToken) {
      response = await redeemPasswordReset(resetToken, password);
      if (response.ok) {
        setSuccess(true);
        setShowForm(false);
        return;
      }

      const data = await response.json();

      if (data?.detail) {
        setError(data.detail);
        return;
      }
    }

    setError('Sorry, this password reset token expired or is invalid');
    setShowForm(false);
  };

  return isAuthenticated() ? (
    <Navigate to="/" replace />
  ) : (
    <div className="flex min-h-screen items-center justify-center px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img alt="Palm tree" className="m-auto w-16" src={palmTreeImage} />
          <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
            Reset your password
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          {error && (
            <div>
              <div className="my-4 w-full rounded border border-red-200 bg-red-100 px-4 py-2 text-red-700">
                {error}
              </div>
            </div>
          )}
          {success && (
            <div>
              <div className="my-4 w-full rounded border border-green-200 bg-green-100 px-4 py-2 text-green-700">
                Your password has been reset!{' '}
                <u>
                  <a href="/login">Click here to login</a>
                </u>
              </div>
            </div>
          )}
          {showForm && (
            <>
              <input type="hidden" name="remember" value="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder:text-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500"
                    placeholder="Password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.currentTarget.value)
                    }
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  size="large"
                  leftIcon={<FilledLockIcon className="size-5" />}
                  isFullWidth
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetRedemption;
