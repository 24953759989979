import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { IconType } from '../badges/Pill';
import { FilterCount } from './common/FilterCount';

export type FilterOption = {
  icon?: {
    Component: IconType;
    baseColor: string;
  };
  label: string;
  value: string;
  count?: number;
};

type FilterProps = {
  options: Array<FilterOption>;
  size?: 'small' | 'base';
  selectedOption: FilterOption;
  setSelectedOption: (option: FilterOption) => void;
};

export const Filter = ({
  options,
  selectedOption,
  setSelectedOption,
  size = 'base',
}: FilterProps) => {
  return (
    <Listbox value={selectedOption} onChange={setSelectedOption}>
      <ListboxButton
        className={clsx(
          'group flex items-center gap-1 py-2 font-semibold text-zinc-800 focus-visible:outline-teal-600 data-[focus]:text-black data-[hover]:text-black data-[open]:text-teal-900',
          {
            'text-sm': size === 'small',
          },
        )}
      >
        {selectedOption.label}
        <ChevronDownIcon
          className={clsx('transition group-data-[open]:-rotate-180', {
            'size-3': size === 'small',
            'size-4': size === 'base',
          })}
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom start"
        className="rounded-lg border bg-white shadow-xl focus-visible:outline-teal-600"
      >
        {options.map((option) => (
          <ListboxOption
            key={option.label}
            value={option}
            className="group flex cursor-pointer flex-row-reverse items-center gap-3 px-3 py-2 data-[focus]:bg-teal-300 data-[selected]:bg-teal-700"
          >
            {option.count !== undefined && <FilterCount count={option.count} />}
            <p className="flex-1 text-nowrap text-sm font-semibold text-black group-data-[selected]:text-white">
              {option.label}
            </p>
            {option.icon && (
              <option.icon.Component
                className={clsx(
                  option.icon.baseColor,
                  'size-[18px] group-data-[selected]:text-white',
                )}
              />
            )}
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
