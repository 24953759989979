import { useTooltipTriggerState } from '@react-stately/tooltip';
import { useTooltipTrigger, useTooltip } from '@react-aria/tooltip';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { clsx } from 'clsx';
import { NavTooltip } from './NavTooltip';

type NavItemProps = {
  name: React.ReactNode;
  Icon: React.ElementType<{ className?: string }>;
  isClosed?: boolean;
  children?: React.ReactNode;
  tooltipMessage?: React.ReactNode;
} & Omit<NavLinkProps, 'className' | 'children'>;

type NavItemNotDisabled = {
  isDisabled?: false;
  disabledTooltipMessage?: React.ReactNode;
};

type NavItemPropsDisabled = {
  isDisabled: true;
  disabledTooltipMessage: React.ReactNode;
};

export const NavItem = ({
  name,
  Icon,
  isClosed = false,
  isDisabled = false,
  tooltipMessage,
  disabledTooltipMessage,
  children,
  onClick,
  ...props
}: NavItemProps & (NavItemNotDisabled | NavItemPropsDisabled)) => {
  const tooltipState = useTooltipTriggerState({ delay: 0, closeDelay: 0 });
  const navLinkRef = useRef<HTMLAnchorElement>(null);
  const { triggerProps, tooltipProps } = useTooltipTrigger(
    {},
    tooltipState,
    navLinkRef,
  );
  const { tooltipProps: ariaTooltipProps } = useTooltip(
    tooltipProps,
    tooltipState,
  );
  const subNavRef = useRef<HTMLDivElement>(null);
  const toolTipContent = isDisabled ? disabledTooltipMessage : tooltipMessage;
  const linkRect = navLinkRef.current?.getBoundingClientRect();

  return (
    <li>
      <NavLink
        {...mergeProps(triggerProps, { ref: navLinkRef })}
        aria-disabled={isDisabled}
        className={({ isActive }) => {
          return clsx(
            'group block py-1 font-semibold outline-none transition-nav duration-300 ease-in-out hover:bg-teal-350',
            isClosed && 'px-4',
            !isClosed && 'px-3',
            isDisabled && 'cursor-not-allowed text-gray-400',
            isActive && 'text-rose-800 motion-reduce:transition-none',
            !isActive && !isDisabled && 'text-zinc-700 hover:text-zinc-800',
          );
        }}
        onClick={(event) => {
          if (isDisabled) {
            event.preventDefault();
          }

          if (!isDisabled && onClick) {
            onClick(event);
          }
        }}
        {...props}
      >
        {({ isActive }) => (
          <div className="flex items-center rounded p-1 group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-rose-700">
            <Icon
              className={clsx(
                '-ml-px size-6 shrink-0',
                isActive && 'text-rose-700',
                !isActive && 'text-gray-400 group-hover:text-gray-500',
              )}
            />

            <div
              className={clsx(
                'ml-4 grow overflow-hidden truncate transition-all duration-300 ease-in-out',
                isClosed && 'w-0 opacity-0',
                !isClosed && 'w-24',
              )}
            >
              {name}
            </div>

            {children &&
              !isClosed &&
              subNavRef.current &&
              createPortal(
                <ul
                  className={clsx(
                    'flex flex-col gap-y-2 overflow-hidden p-0.5',
                    !isActive && 'hidden',
                  )}
                >
                  {children}
                </ul>,
                subNavRef.current,
              )}
          </div>
        )}
      </NavLink>

      {tooltipState.isOpen && linkRect && (isClosed || isDisabled) && (
        <NavTooltip
          {...ariaTooltipProps}
          y={linkRect.top + linkRect.height / 2}
        >
          {toolTipContent ?? name}
        </NavTooltip>
      )}

      <div
        ref={subNavRef}
        className={clsx(
          'transition-nav duration-300 ease-in-out',
          !isClosed && 'pl-[46px] pr-0',
          isClosed && 'pl-[50px] pr-1',
        )}
      />
    </li>
  );
};
