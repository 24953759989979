import { FunctionComponent, useState } from 'react';
import { BlockAlert } from '@mosey/components/layout/BlockAlert';
import { Invite, User } from '../../types';
import { clsx } from 'clsx';
import { CircleAddIcon } from '@mosey/components/Icons';
import { Button } from '@mosey/components/buttons/Button';
import { Link } from 'react-router-dom';
import { fetchApi } from '../../utils/fetchApi';
import {
  ApiStatus,
  IApiGenericError,
  IApiPydanticError,
} from '../../utils/types';

type AccountTeamProps = { usersAndInvites: Array<User | Invite> };
export const Team: FunctionComponent<AccountTeamProps> = ({
  usersAndInvites,
}) => {
  const [isLoading, setIsLoading] = useState<{
    [key: number | string]: boolean;
  }>({});
  const [error, setError] = useState<string | null>(null);
  const [deleted, setDeleted] = useState<{ [key: number]: boolean }>({});
  const revokeInvite = async (
    inviteId: Invite['id'],
    rowKey: number | string,
  ) => {
    // TODO: Add confirmation modal
    if (confirm('Are you sure you want to revoke this invite?') === false)
      return;

    setIsLoading((prev) => ({ ...prev, [rowKey]: true }));
    setError(null);

    const {
      error: apiError,
      status,
      statusCode,
    } = await fetchApi({
      url: `/api/invites/${inviteId}`,
      method: 'DELETE',
    });

    if (status === ApiStatus.Error && statusCode !== 404) {
      setError(
        apiError
          ? (apiError as IApiPydanticError)?.message ??
              (apiError as IApiGenericError).detail
          : 'It may have already been revoked. Try refreshing the page and reach out to support if the problem persists.',
      );
    } else {
      setError(null);
      setDeleted((prev) => ({ ...prev, [rowKey]: true }));
    }

    setIsLoading((prev) => {
      delete prev[rowKey];
      return { ...prev };
    });
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">Team</h3>

        <Button
          as={Link}
          size="small"
          variant="secondary"
          leftIcon={<CircleAddIcon className="size-4 text-sage-600" />}
          to="/settings/invite"
        >
          Invite
        </Button>
      </div>

      <BlockAlert
        variant="error"
        show={!!error}
        message="Unable to revoke this invitation"
      >
        {error}
      </BlockAlert>

      <table className="w-full border" cellPadding="0">
        <thead>
          <tr className="border-b text-sm uppercase text-gray-500">
            <th className="px-6 py-3 text-left font-medium tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left font-medium tracking-wider">
              Email
            </th>
          </tr>
        </thead>

        <tbody className="divide-y">
          {usersAndInvites.length > 0 ? (
            usersAndInvites.map((userOrInvite, index) => {
              const isInvite = 'is_accepted' in userOrInvite;
              const key = isInvite
                ? `${userOrInvite.email}-${index}`
                : userOrInvite.id;

              return isInvite && key in deleted ? null : (
                <tr key={key}>
                  <td>
                    <div className="w-full">
                      <p
                        className={clsx('p-6 font-medium', {
                          'text-gray-400': isInvite,
                          'text-gray-900': !isInvite,
                        })}
                      >
                        {isInvite
                          ? 'Invite Pending'
                          : `${userOrInvite.first_name || ''} ${
                              userOrInvite.last_name || ''
                            }`}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="w-full">
                      <p
                        className={clsx('p-6', {
                          'text-gray-400': isInvite,
                          'text-gray-900': !isInvite,
                        })}
                      >
                        {userOrInvite.email || ''}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="grid w-full grid-flow-row justify-items-center p-6">
                      {isInvite && (
                        <Button
                          type="button"
                          size="small"
                          onClick={() => revokeInvite(userOrInvite.id, key)}
                          isDisabled={key in isLoading}
                          isLoading={key in isLoading}
                        >
                          Revoke Invite
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6}>
                <div className="flex h-64 items-center justify-items-center">
                  <div className="m-auto">
                    <p className="italic text-gray-400">
                      No team members found.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
