import { ComponentProps } from 'react';
import { Filter, FilterOption } from './Filter';
import { useSearchParams } from 'react-router-dom';
import { usePendingSearchParamsValue } from '../hooks/navigation';

type QueryFilterProps = {
  options: Array<FilterOption>;
  size?: ComponentProps<typeof Filter>['size'];
  queryKey: string;
};

export const QueryFilter = ({ options, size, queryKey }: QueryFilterProps) => {
  const [params, setParams] = useSearchParams();
  const value = usePendingSearchParamsValue(queryKey);

  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  const setSelectedOption = (option: FilterOption) => {
    const updatedParams = new URLSearchParams(params);
    updatedParams.set(queryKey, option.value);
    setParams(updatedParams);
  };

  return (
    <Filter
      selectedOption={selectedOption}
      options={options}
      size={size}
      setSelectedOption={setSelectedOption}
    />
  );
};
