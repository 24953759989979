import { ElementType } from 'react';

type BetaProps = {
  as?: ElementType;
};

export const Beta = ({ as: Component = 'span' }: BetaProps) => {
  return (
    <Component className="rounded-full border border-gray-300 px-3 py-0.5 text-xs font-bold uppercase text-zinc-600">
      Beta
    </Component>
  );
};
