import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { NotFound } from './NotFound';
import { FatalErrorFullScreen } from './FatalError';
import { ApiStatusHandler } from '../components';
import { useApi } from '../hooks';
import * as config from '../settings/config';
import { logout } from '../utils/auth';
import { fetchApi } from '../utils/fetchApi';
import { safelyUpdateLocation } from '../utils/redirect';

export const FinchConnectRedirect: FunctionComponent = () => (
  <Navigate to="/settings/integrations" replace />
);

type FinchConnectProps = {
  code: string;
  redirectURI: string;
};

type FinchRedirectProps = {
  code: string | null;
  internalRedirectURI: string;
  externalRedirectURI: string;
};

export const FinchConnect: FunctionComponent<FinchConnectProps> = ({
  code,
  redirectURI,
}) => {
  // Post the code to the connect API to complete the oauth connection
  const { status, data, error } = useApi({
    url: '/api/connect/finch',
    method: 'POST',
    body: {
      code,
      redirect_uri: redirectURI, // eslint-disable-line
    },
  });

  return (
    <div className="absolute left-0 top-0 z-50 flex min-h-screen w-screen items-center justify-center bg-white px-4 pb-48 pt-12 sm:px-6 lg:px-8">
      <ApiStatusHandler
        status={status}
        error={error}
        data={data}
        component={FinchConnectRedirect}
      />
    </div>
  );
};

export const RedirectUserOutOfMosey: FunctionComponent<FinchRedirectProps> = ({
  code,
  internalRedirectURI,
  externalRedirectURI,
}) => {
  safelyUpdateLocation(externalRedirectURI);
  if (code) {
    fetchApi({
      url: '/api/connect/finch',
      method: 'POST',
      body: {
        code,
        redirect_uri: internalRedirectURI,
      },
    }).then(() => {
      logout();
      return <div />;
    });
  }
  logout();
  return <div />;
};

export const FinchConnectView: FunctionComponent = () => {
  // This view handles the following cases
  // - Success: The url has a search param for `code`
  // - Error: The url has a search param with `error`
  // - Error with description: The url has a search param with `error` and `error_description`
  // - Null: The url has no search param for `code` or `error`
  const urlParams = window.location.search;
  const params = new URLSearchParams(urlParams);
  const code = params.get('code');
  const authError = params.get('error');
  const redirectUri = params.get('redirectUri');
  // const authErrorDescription = params.get('error_description');
  const callbackUrlFromState = params.get('state');
  if (redirectUri) {
    safelyUpdateLocation(redirectUri);
  }
  if (callbackUrlFromState) {
    return (
      <RedirectUserOutOfMosey
        code={code}
        internalRedirectURI={config.FINCH_REDIRECT_URI}
        externalRedirectURI={decodeURIComponent(callbackUrlFromState)}
      />
    );
  }

  if (authError) {
    return <FatalErrorFullScreen />;
  }

  if (code) {
    return <FinchConnect code={code} redirectURI={config.FINCH_REDIRECT_URI} />;
  }
  return <NotFound />;
};
