import clsx from 'clsx';

type PageHeaderProps = {
  children: React.ReactNode;
  hasBorder?: boolean;
};

export const PageHeader = ({ children, hasBorder = true }: PageHeaderProps) => {
  return (
    <header
      className={clsx(
        'sticky top-0 z-10 flex min-h-20 items-center gap-6 bg-white px-8 py-6',
        {
          'border-b border-gray-200': hasBorder,
        },
      )}
    >
      {children}
    </header>
  );
};
